import {put, takeLatest} from 'redux-saga/effects';
import Notifications from 'react-notification-system-redux';

import {
  ADD_CONTACT_SUCCESS,
  REMOVE_CONTACT_SUCCESS,
  SWITCH_PRIMARY_CONTACT_SUCCESS,

  ADD_CONTRACT_SUCCESS,
  CONFIRM_CONTRACT_SUCCESS,
  EDIT_CONTRACT_SUCCESS,
  REMOVE_CONTRACT_SUCCESS,
  REJECT_CONTRACT_SUCCESS,
} from 'state/actions';

import {
  EDIT_ORGANIZATION_PROFILE_SUCCESS,
  EDIT_ORGANIZATION_SURVEY_SUCCESS,
  EDIT_ORGANIZATION_PROFILE_FAILURE,
  EDIT_ORGANIZATION_SURVEY_FAILURE,
  SHOW_COMMENTS_MODAL_ERROR_MESSAGE,
} from 'state/constants';


export default function* () {
  yield takeLatest(ADD_CONTACT_SUCCESS,
    triggerSuccessNotification, 'Contact Added');
  yield takeLatest(REMOVE_CONTACT_SUCCESS,
    triggerSuccessNotification, 'Contact Removed');
  yield takeLatest(SWITCH_PRIMARY_CONTACT_SUCCESS,
    triggerSuccessNotification, 'Primary Contact Switched');

  yield takeLatest(ADD_CONTRACT_SUCCESS,
    triggerSuccessNotification, 'Contract Added');
  yield takeLatest(CONFIRM_CONTRACT_SUCCESS,
    triggerSuccessNotification, 'Contract Confirmed');
  yield takeLatest(EDIT_CONTRACT_SUCCESS,
    triggerSuccessNotification, 'Contract Updated');
  yield takeLatest(REMOVE_CONTRACT_SUCCESS,
    triggerSuccessNotification, 'Contract Removed');
  yield takeLatest(REJECT_CONTRACT_SUCCESS,
    triggerSuccessNotification, 'Contract rejected');

  yield takeLatest(EDIT_ORGANIZATION_PROFILE_SUCCESS,
    triggerSuccessNotification, 'Profile Updated');
  yield takeLatest(EDIT_ORGANIZATION_SURVEY_SUCCESS,
    triggerSuccessNotification, 'Profile Updated');

  yield takeLatest(EDIT_ORGANIZATION_PROFILE_FAILURE,
    triggerFailureNotification);
  yield takeLatest(EDIT_ORGANIZATION_SURVEY_FAILURE,
    triggerFailureNotification);
  yield takeLatest(SHOW_COMMENTS_MODAL_ERROR_MESSAGE,
    triggerFailureNotification);
}

function* triggerSuccessNotification(message) {
  yield put(Notifications.success({
    message,
    position: 'tr',
  }));
}

function* triggerFailureNotification({error}) {
  let children;
  let message;
  if (typeof error === 'string') {
    message = error;
  } else {
    children = error;
  }
  yield put(Notifications.error({
    autoDismiss: 10,
    children,
    message,
    position: 'tr',
  }));
}
