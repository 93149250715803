import React from 'react';
import {connect} from 'react-redux';
import { stringify } from 'csv-stringify';
import Cookies from 'js-cookie';

import {
  ORGANIZATION_HP,
  ORGANIZATION_PO,
  HP_CONTRACT_CSV_HEADER_ITEMS,
  HP_CONTRACT_CSV_ROW_ITEMS,
  PO_CONTRACT_CSV_HEADER_ITEMS,
  PO_CONTRACT_CSV_ROW_ITEMS,
} from 'state/constants';

import {getSortedContracts} from 'state/selectors';

class ExportContractsButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: '',
    };
    this.updateCsvData();
  }
  componentDidUpdate(prevProps, prevState) {
    // eslint-disable-next-line no-unused-vars
    let rowItems;
    if (this.props.organization.org_type == ORGANIZATION_HP) {
      rowItems = HP_CONTRACT_CSV_ROW_ITEMS;
    } else if (this.props.organization.org_type == ORGANIZATION_PO) {
      rowItems = PO_CONTRACT_CSV_ROW_ITEMS;
    } else {
      rowItems = [];
    }

    if (prevProps.organization.id != this.props.organization.id ||
        prevProps.contracts.length != this.props.contracts.length ||
        this.props.contracts.some((contract, index) => {
          // The 'some' part checks to see if any of the contract
          // statuses have changed.
          const oldContract = prevProps.contracts[index];
          return oldContract.contract_status != contract.contract_status;
        })) {
      this.updateCsvData();
    }
  }
  updateCsvData() {
    let headerItems;
    let rowItems;
    if (this.props.organization.org_type == ORGANIZATION_HP) {
      headerItems = HP_CONTRACT_CSV_HEADER_ITEMS;
      rowItems = HP_CONTRACT_CSV_ROW_ITEMS;
    } else if (this.props.organization.org_type == ORGANIZATION_PO) {
      headerItems = PO_CONTRACT_CSV_HEADER_ITEMS;
      rowItems = PO_CONTRACT_CSV_ROW_ITEMS;
    } else {
      headerItems = [];
      rowItems = [];
    }
    const csvData = [headerItems].concat(
      this.props.contracts.map(c => rowItems.map(
        i => i === 'status' ? c[i].label : c[i])));
    stringify(csvData, (err, data) => this.setState({data}));
  }
  render() {
    const filename = `${this.props.organization.name}_contracts`;
    return (
      <form className='export-contracts-button'
            method='POST'
            action='/api/csv/'>
        <input name='csrfmiddlewaretoken'
               type='hidden'
               value={Cookies.get('csrftoken')} />
        <input name='filename' type='hidden' value={filename} />
        <input name='data' type='hidden' value={this.state.data} />
         <button className="button is-dark">
                        <span className="icon">
                                <i className="fas fa-file-download"></i>
                        </span>
                        <span>Export</span>

                    </button>
      </form>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    contracts: getSortedContracts(state, ownProps),
  };
};

export default connect(mapStateToProps)(ExportContractsButton);
