import React, { useState } from 'react';
import { connect } from 'react-redux';
import './styles.scss';
import { getData } from 'common/request';
import { showCommentsModalErrorMessage, addContractComment, deleteContractComment } from '../../state/contracts/actions';
import Comment from './Comment';
import AddRecipientElem from './AddRecipientElem';
import { siteMessages } from '../../../src/utils/copy';
import ConfirmationDialog from '../../common/confirmation-dialog';

const formatInvalid = (errors) => {
  return (
    <div>
      {errors.map((error, ix) => <div key={ix}>{error}</div>)}
    </div>
  );
}

const CommentsModal = ({show, data, organization, hide, handleCustomClassName, deleteContractComment, addContractComment, showErrorMessage, pconEmail, hidePconCommenting}) => {
  const [text, setText] = useState(""),
        [subject, setSubject] = useState(""),
        [isLoading, setIsLoading] = useState(false),
        [customClassName, setCustomClassName] = useState(false),
        [showConfirmation, setShowConfirmation] = useState(false),
        [commentToRemove, setCommentToRemove] = useState(null),
        [addCommentEnabled, setAddCommentEnabled] = useState(null),
        [showModal, setShowModal] = useState(false),
        [recipients, setRecipients] = useState([]),
        [hpPconSelected, setHpPconSelected] = useState(false),
        [poPconSelected, setPoPconSelected] = useState(false),
        [ihaContactSelected, setIhaContactSelected] = useState(false);
  const hideModal = () => {
    hide();
    handleCustomClassName();
  }
  const toggle_recipients = () => {
    setShowModal(!showModal)
  }
  const clear_recipients = () => {
    setRecipients([])
  }
  const addRecipients = (recipients) => {
    setRecipients(recipients)
  }
  const addPconContactsToRecipent = () => {
    var fullRecipientList = []
    if (data.pcon_contacts.hp_contact && hpPconSelected) {
      fullRecipientList.push(data.pcon_contacts.hp_contact)
    }
    if (data.pcon_contacts.po_contact && poPconSelected) {
      fullRecipientList.push(data.pcon_contacts.po_contact)
    }
    if (ihaContactSelected) {
      fullRecipientList.push(pconEmail)
    }
    return fullRecipientList.concat(recipients)
  }

  return (
    <div>
      <div className={`modal-card ${show ? 'fadeInDown' : 'fadeOutUp'} customModalCard comments-modal `}>
        <header className="modal-card-head">
          <p className="modal-card-title">{siteMessages['amp.sitewide-nav.contracting.comments.modal.title']}</p>
          <button className="delete" aria-label="close"
            onClick={hideModal.bind(this)}>
          </button>
        </header>
        <section className="modal-card-body comments">
          {data.comments_list.length > 0 ? data.comments_list.map((comment) =>
            <Comment
              key={comment.id}
              comment={comment}
              isLoading={isLoading}
              onClick={() => {
                setShowConfirmation(true);
                setCustomClassName('active_bg');
                setCommentToRemove(comment);
              }} />
          ) :
            <div className="no-comments-message">{siteMessages['amp.sitewide-nav.contracting.comments.modal.no-data']}</div>
          }
          <div className={`modal-container control-item edit-contact ${customClassName}`}>
            <ConfirmationDialog show={showConfirmation}
              hide={() => setShowConfirmation(false)}
              container={document.getElementById('app')}
              handleCustomClassName={() => setCustomClassName("")}
              title={siteMessages['amp.sitewide-nav.contracting.comments.modal.confirm-title']}
              text={siteMessages['amp.sitewide-nav.contracting.comments.modal.confirm-message']}
              yesButtonText={siteMessages['amp.sitewide-nav.contracting.comments.modal.confirm-yes']}
              noButtonText={siteMessages['amp.sitewide-nav.contracting.comments.modal.confirm-no']}
              action={() => {
                setIsLoading(true);
                setShowConfirmation(false);
                setCustomClassName('');

                getData('/api/comments/delete/', {
                  "comment_id": commentToRemove.id,
                  "po": data.po_id,
                  "hp": data.hp_id,
                }).then(() => {
                  deleteContractComment({ ...commentToRemove, contract_id: data.id }, organization);
                  setIsLoading(false);
                }, () => { setIsLoading(false); });
              }}/>
          </div>
        </section>
        {addCommentEnabled &&
          <section className="modal-card-body add-comment">
            <p>
              <label className="label">
                {siteMessages['amp.sitewide-nav.contracting.comments.modal.add-subject']}
              </label>
              <input type="text" maxLength={50} className="input" disabled={isLoading} value={subject}
                onChange={e => setSubject(e.target.value)} ></input>
            </p>
            <div className="recipient">
            <div className='pcon_contact_select'>
                <div>
                  <label className='label'>{siteMessages['amp.recipient.include.pcon.contacts']}</label>
                </div>
                <div className={"info_tooltip"}>
                  <span className={"info_icon icon has-text-info"}>
                      <i className="fas fa-info-circle" />
                  </span>
                  <span className={"info_tooltiptext"}>
                    {siteMessages['amp.recipient.pcon_tooltip.text']}
                  </span>
                </div>
                <div className="control">
                  <label key={"hp_pcon"} className="checkbox">
                    <input type='checkbox' name="answer"
                      value={data.pcon_contacts.hp_contact}
                      checked={hpPconSelected}
                      onChange={()=>setHpPconSelected(!hpPconSelected)}
                      />
                    <div>HP: {data.pcon_contacts.hp_contact ? <span>{data.pcon_contacts.hp_contact}</span> : <span className='tag'>No Assigned Contact</span>}</div>
                  </label>
                </div>
                <div className="control">
                  <label key={"po_pcon"} className="checkbox">
                    <input type='checkbox' name="answer"
                      value={data.pcon_contacts.hp_contact}
                      checked={poPconSelected}
                      onChange={()=>setPoPconSelected(!poPconSelected)}
                      />
                    <div>PO: {data.pcon_contacts.po_contact ? <span>{data.pcon_contacts.po_contact}</span> : <span className='tag'>No Assigned Contact</span>}</div>
                  </label>
                </div>
              </div>
              <div className='iha_contact_select'>
                <div>
                  <label className='label'>{siteMessages['amp.recipient.include.pcon.contacts.iha']}</label>
                </div>
                <div className={"info_tooltip"}>
                  <span className={"info_icon icon has-text-info"}>
                      <i className="fas fa-info-circle" />
                  </span>
                  <span className={"info_tooltiptext"}>
                    {siteMessages['amp.recipient.iha_tooltip.text']}
                  </span>
                </div>
                <div className="control">
                  <label key={"iha_pcon"} className="checkbox">
                    <input type='checkbox' name="answer"
                      value={pconEmail}
                      checked={ihaContactSelected}
                      onChange={()=>setIhaContactSelected(!ihaContactSelected)}
                      />
                    <div>{siteMessages['amp.recipient.include.pcon.contacts.iha.label']}</div>
                  </label>
                </div>
              </div>
              <div>
                  <div className='label-tooltip'>
                    <label className='label'>{siteMessages['amp.recipient.display.all.recipients']}</label>
                    <div className={"info_tooltip"}>
                      <span className={"info_icon icon has-text-info"}>
                          <i className="fas fa-info-circle" />
                      </span>
                      <span className={"info_tooltiptext"}>
                        {siteMessages['amp.recipient.misc_recipients.tooltip.text']}
                      </span>
                    </div>
                  </div>
                  {recipients.length >= 0? <p className='recipients-list'>{recipients.join(", ")}</p> : ''}
              </div>
              <div>
                <button className='button add-contact-btn' onClick={toggle_recipients}>{siteMessages['amp.recipient.button.add']}</button>
                <button className='button ' onClick={clear_recipients}>{siteMessages['amp.recipient.button.clear']}</button>
              </div>
            </div>
              {showModal?
                <AddRecipientElem show={showModal}
                  hide={() => setShowModal(false)}
                  addRecipients={addRecipients}
                  container={document.getElementById('app')}
                  data={{
                    hp_id:data.hp_id,
                    po_id:data.po_id,
                    recipients:recipients,
                    pcon_contacts:[data.pcon_contacts.hp_contact, data.pcon_contacts.po_contact]
                  }}
                />:''}
            <p className="control">
              <label className="label"> {siteMessages['amp.sitewide-nav.contracting.comments.modal.add-text']}</label>
              <textarea className="textarea" disabled={isLoading} value={text}
                onChange={e => setText(e.target.value)}></textarea>
            </p>
            <p className="add-comment-controls">
              <button disabled={isLoading} className="button is-dark btn-hover"
              onClick={() => {
                let errors = [];

                if (!subject) {
                  errors.push(siteMessages['amp.sitewide-nav.contracting.comments.modal.subject-validation']);
                }
                if (!text) {
                  errors.push(siteMessages['amp.sitewide-nav.contracting.comments.modal.text-validation']);
                }

                if (errors.length > 0) {
                  showErrorMessage(formatInvalid(errors));
                  return;
                }

                setIsLoading(true);
                getData('/api/comments/create/', {
                  "content": text,
                  "contract_id": data.id,
                  "subject": subject,
                  "po": data.po_id,
                  "hp": data.hp_id,
                  "recipients": addPconContactsToRecipent(),
                  }).then((data) => {
                  if (data.result) {
                    addContractComment(data.result, organization);
                    setText('');
                    setSubject('');
                    setAddCommentEnabled(false);
                    setRecipients([]);
                    setIhaContactSelected(false);
                    setHpPconSelected(false);
                    setPoPconSelected(false);
                  } else {
                    showErrorMessage(data);
                  }                   
                  setIsLoading(false);
                });
              }}
              >
                Send {isLoading && <div><i className="fas fa-spinner fa-pulse fa-1x"></i></div>}
              </button>
              <button disabled={isLoading}
                onClick={() => { setText(''); setSubject(''); setAddCommentEnabled(false); setRecipients([]); setPoPconSelected(false); setHpPconSelected(false) }}
                className="button btn-hover">{siteMessages['amp.sitewide-nav.contracting.comments.modal.cancel']}</button>
            </p>
          </section>
        }
        <footer className="modal-card-foot">
          <button onClick={hideModal.bind(this)}>{siteMessages['amp.sitewide-nav.contracting.comments.modal.close']}</button>
          {!addCommentEnabled && !hidePconCommenting &&
            <button disabled={isLoading} bsstyle='primary'
              onClick={() => setAddCommentEnabled(true)}>
              {siteMessages['amp.sitewide-nav.contracting.comments.modal.add-comment']}
        </button>}
        </footer>
      </div>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    pconEmail: state.config.PCON_NOTIF_DEFAULT_FROM_EMAIL,
    hidePconCommenting: state.config.HIDE_PCON_COMMENTING
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addContractComment: (comment, organization) => {
      dispatch(addContractComment(comment, organization));
    },
    deleteContractComment: (comment, organization) => {
      dispatch(deleteContractComment(comment, organization));
    },
    showErrorMessage: (error) => {
      dispatch(showCommentsModalErrorMessage(error));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CommentsModal);
