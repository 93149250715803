import React from 'react';
import {connect} from 'react-redux';
import {
  BrowserRouter,
  Navigate,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";

import {
  getUsername,
  getUserOrgTypes,
  getUseReact,
} from 'state/selectors';

import Root from 'pages/root/page';
import Unauth from 'pages/unauth/page';
import Loading from 'common/Loading';

import ContactsPage from 'pages/contacts/page';
import ContractsPage from 'pages/contracts/page';
import OrganizationProfilePage from 'pages/organization-profile/page';

import Measures from 'pages/measures/page';
import MeasuresAbout from 'pages/measures/about/page';
import MeasuresDashboard from 'pages/measures/dashboard/page';
import MeasuresMeasure from 'pages/measures/measures/page';
import MeasuresDownload from 'pages/measures/download/page';
import MeasuresReports from 'pages/measures/reports/page';
import Summary from 'pages/measures/reports/summary';
import PoWorksheet from 'pages/measures/reports/poworksheet';
import SPMSummary from 'pages/measures/reports/spm';

// Router's key is set to a random number because old versions (<v4) of
// react-router do not allow the changing of routes within a Router
// By changing the key prop on every rerender, we trick react into creating a
// new Router every time. Luckily, this is not as insane as it sounds, since we
// only ever rerender when the user session changes, which happens at most once
// per user session.
const rand = () => '' + Math.random();


// We're changing the Router's routes to render different components based on if
// the user is logged in or not
const SiteRouter = ({username, orgTypes, history, USE_REACT}) => {
  let routes;
  const location = history.location;

  const switchedRoutes = USE_REACT =>
    USE_REACT ? [
      <Route
        key='organization-profile' path='organization-profile'
        component={OrganizationProfilePage}
        element={<OrganizationProfilePage history={history} location={location} />}
      />,
      <Route
        key='contracts' path='contracts'
        element={<ContractsPage history={history} location={location} />} />,
    ] : [];

  if (!username) {
    const UnauthComponent = username === undefined ? Loading : Unauth;
    routes = [
      <Route key='unauth' path='/*' component={UnauthComponent} />,
    ];
  } else {
    routes = [
      ...switchedRoutes(USE_REACT),
      <Route key='contacts' path='contacts' element={<ContactsPage history={history} location={location}/> }/>,
      <Route key='measures' path='measures' element={<Measures history={history} location={location}/> }>
        {orgTypes.length === 0 ? null :
          orgTypes[0] == 0 ?
            <Route path='/measures' element={<Navigate to='/measures/measure' />} /> :
            <Route path='/measures' element={<Navigate to='/measures/about' />} />
        }
        <Route path='dashboard' element={<MeasuresDashboard history={history} location={location}/>}/>
        <Route path='about' element={<MeasuresAbout history={history} location={location}/>}/>
        <Route path='reports' element={<MeasuresReports history={history} location={location}/>}>
          <Route path='summary' element={<Summary history={history} location={location}/>}/>
          <Route path='spm' element={<SPMSummary history={history} location={location}/>}/>
          <Route path='po_worksheet' element={<PoWorksheet history={history} location={location} sharedableLink={true}/>}/>
        </Route>
        <Route path='measure' element={<MeasuresMeasure history={history} location={location}/>}/>
        <Route path='downloads' element={<MeasuresDownload history={history} location={location}/>}/>
      </Route>,
    ];
  }
  return (
    <BrowserRouter key={rand()} history={history}>
      <Routes>
        <Route key='root' path='/' element={<Root/>}>
          <Route path='/' element={<Navigate to='/measures'/>}/>
          <Route path='/home' element={<Navigate to='/measures'/>}/>
          <Route path='/login' element={<Navigate to='/measures'/>}/>
          {routes}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

const mapStateToProps = state => {
  return {
    username: getUsername(state),
    orgTypes: getUserOrgTypes(state),
    USE_REACT: getUseReact(state),
  };
};

export default connect(mapStateToProps)(SiteRouter);
