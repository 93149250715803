import React from 'react';
import FormattedMessage from 'components/FormattedMessageMarkdown';

const OrglessWarning = props => {
  return (
    <div className="row">
      <div className="col-md-4 col-md-offset-4 alert alert-danger">
        <FormattedMessage id='amp.orgless.warning.content'
          textComponent='span'
        />
      </div>
    </div>
  );
};

export default OrglessWarning;
