/* eslint-disable no-case-declarations */
import {
  BASE_SURVEY_CATEGORIES,
  UPDATE_ORGANIZATION_SURVEY_CATEGORY,
  UPDATE_ORGANIZATION_SURVEY,
} from './constants';

const initialState = {
  // category - an object that determines which survey 'category' is visible
  // See src/state/organization-survey/constants.js
  category: BASE_SURVEY_CATEGORIES[0],
  // surveys - an object, with the keys being the id of an organization,
  //   and the values being objects representing the survey q/a split by
  //   category
  //   Caveat: The categories of survey differ between health plans and POs
  //   than PO contract objects. The differences are best seen looking
  //   at the src/state/organization-survey/constants.js file.
  surveys: {},
};

export default function(state=initialState, action) {
  switch (action.type) {
    case UPDATE_ORGANIZATION_SURVEY_CATEGORY:
      return {
        ...state,
        category: action.category,
      };
    case UPDATE_ORGANIZATION_SURVEY:
      const prevOrganizationSurvey = state.surveys[action.organization] || {};
      return {
        ...state,
        surveys: {
          ...state.profiles,
          [action.organization]: {
            ...prevOrganizationSurvey,
            [action.category]: action.survey,
          },
        },
      };
  }
  return state;
}
