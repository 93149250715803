import {
  UPDATE_CONFIG_LIST,
} from './actions';

const initialState = {
  CONTRACTS_FROZEN:false
};

export default function(state=initialState, action) {
  switch (action.type) {
    case UPDATE_CONFIG_LIST:
      return {
        ...state,
        ...action.config,
      };
  }
  return state;
}
