import { siteMessages } from 'src/utils/copy';

let round = num => {
  let n = num * 100;
  n = Math.round(n);
  return n / 100;
};

let getScoreLine = (props) => {
  // Fix for ENG-1380
  if (props.instances.length == 1
    && props.instances[0].data_provider_id
    == props.instances[0].organization_id) {
    props.instances[0].options.is_score = true;
  }

  let scoreLine = props.instances && props.instances.length ?
    props.instances.filter(elt => !!elt.options.is_score) : [];
  if (scoreLine.length > 0) {
    scoreLine = Object.assign({}, scoreLine[0], scoreLine[0].fields);
    Object.keys(scoreLine).map(f => {
      if (Number.isFinite(scoreLine[f])) {
        scoreLine[f] = round(scoreLine[f]);
      }
    });
  }
  let scoreName = props.measure.function__logic;
  
  // Added this so self reported measure instances don't get removed if
  // they are the ONLY record.
  // See: https://ihaorg.atlassian.net/browse/ENG-1379
  if (props.instances.length == 1
    && props.instances[0].data_provider_id
    == props.instances[0].organization_id) {
    // Keep the sole self reported rate for display as an instance
    props.instances[0].options.is_score = false;
  }
  // filter out scoreLine here -- once
  let instances = props.instances && props.instances.length ?
    props.instances.filter(elt => {
      if (elt.options.is_score) {
        return false;
      } else {
        return true;
      }
    }).map(elt => {
      if (elt.options.is_no_benefit) {
        elt.fields[scoreName.score] = siteMessages[
          'amp.page.measures.measures.po.score.rate.NB'
        ];
      } else if (elt.options.is_biased) {
        elt.fields[scoreName.score] = siteMessages[
          'amp.page.measures.measures.po.score.rate.BR'
        ];
      } else if (elt.options.is_not_reported) {
        elt.fields[scoreName.score] = siteMessages[
          'amp.page.measures.measures.po.score.rate.NR'
        ];
      } else if (elt.options.is_undefined) {
        elt.fields[scoreName.score] = siteMessages[
          'amp.page.measures.measures.po.score.rate.UD'
        ];
      }
      return elt;
    }) : [];

  return {
    instances: instances,
    scoreLine: scoreLine,
  };
};

export default getScoreLine;
