import React from 'react';

import getScoreLine from './getScoreLine';

import ClinicalView from './clinical';
import EncounterView from './encounter';
import PasView from './pas';
import MuhitView from './muhit';
import EnrollmentView from './enrollment';
import AruView from './aru';
import PcrView from './pcr';

import FormattedMessage from 'components/FormattedMessageMarkdown';

// eslint-disable-next-line no-unused-vars
let round = num => {
  let n = num * 100;
  n = Math.round(n);
  return n / 100;
};

// return the right view depending on the prop info (mostly function id)
let getMeasureView = (props) => {
  if (props.measure.function_id == 1) {
    return ClinicalView;
  } else if (props.measure.function_id == 2) {
    return ClinicalView;
  } else if (props.measure.function_id == 3) {
    return EncounterView;
  } else if (props.measure.function_id == 4) {
    return MuhitView;
  } else if (props.measure.function_id == 5) {
    return PasView;
  } else if (props.measure.function_id == 6) {
    return EnrollmentView;
  } else if (props.measure.function_id == 7) {
    return PcrView;
  } else if (props.measure.function_id > 27) {
    return AruView;
  }
};

class MeasureOverview extends React.Component {
  render() {
    let renderElt = null;
    if (this.props.instances.length === 0) {
      renderElt = (<p><b><FormattedMessage
            id='amp.page.measures.measures.po.overview.nodata'
            textComponent='span'
          />
        </b></p>);
    } else {
      let {instances, scoreLine} = getScoreLine(this.props);
      let ProperView = getMeasureView(this.props);
      renderElt = (
        <ProperView
          organization={this.props.organization}
          duration={this.props.duration}
          measure={this.props.measure}
          instances={instances}
          stats={this.props.stats}
          scoreLine={scoreLine} />
      );
    }
    return renderElt;
  }
}

export default MeasureOverview;
