import React from 'react';
import validate from 'validate.js';
import urlRegex from 'url-regex';
import {
  ORGANIZATION_HP,
} from 'state/constants';
import FormattedMessage from 'components/FormattedMessageMarkdown';
import {siteMessages} from 'src/utils/copy';
import Loading from '../../common/Loading';



/*
  The following fields are editable:
  address1
  address2
  city
  state
  zipcode
  phonenumber
  phoneext
  website
  dmhc_id (only for Health Plans)
  notes
*/

// Configure validate.js
validate.validators.presence.options = {
  allowEmpty: false,
  message: siteMessages[
    'amp.page.organization.profile.validate.message'
  ],
};

class OrganizationProfileForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      profile: props.profile,
    };
  }
  //eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(newProps) {
    let profile = newProps.profile;
    // For some yet-unknown reason, selecting an organization with profile data,
    // then selecting an organization with some profile data being null results
    // in those null-valued entries retaining their previous state in the DOM,
    // despite the component state being updated. Here we update all null-valued
    // entried to be the empty string to avoid this situation.
    Object.keys(profile).forEach(k => {
      if (profile[k] === null) {
        profile[k] = '';
      }
    });
    this.setState({profile});
  }
  validateOrganizationProfile() {
    // http://regexlib.com/RETester.aspx?regexp_id=1574
    // eslint-disable-next-line max-len
    const stateRegex = /^(?:(A[KLRZ]|C[AOT]|D[CE]|FL|GA|HI|I[ADLN]|K[SY]|LA|M[ADEINOST]|N[CDEHJMVY]|O[HKR]|P[AR]|RI|S[CD]|T[NX]|UT|V[AIT]|W[AIVY]))$/;
    const zipRegex = /\d{5}/;
    const phoneRegex =
      /^((([0-9]{3}))|([0-9]{3}))[-\s.]?[0-9]{3}[-\s.]?[0-9]{4}$/;
    const websiteRegex = urlRegex({strict: false});

    const invalid = validate(this.state.profile, {
      address1: {
        presence: {
          message: siteMessages[
            'amp.page.organization.profile.address.message'
          ],
        },
      },
      city: {
        presence: true,
      },
      state: {
        presence: true,
        format: stateRegex,
      },
      zipcode: {
        presence: true,
        format: zipRegex,
      },
      phonenumber: {
        presence: {
          message: siteMessages[
            'amp.page.organization.profile.phone.req.message'
        ],
        },
        format: {
          message: siteMessages[
            'amp.page.organization.profile.phone.invalid.message'
          ],
          pattern: phoneRegex,
        },
      },
      website: {
        presence: true,
        format: {
          message: siteMessages[
            'amp.page.organization.profile.web.invalid.message'
          ],
          pattern: websiteRegex,
        },
      },
    });
    // Only return the first error for each attribute
    return !invalid ? invalid : Object.values(invalid).map(msgs => msgs[0]);
  }
  formatInvalid(invalid) {
    return (
      <div>
        {invalid.map((error, ix) => <div key={ix}>{error}</div>)}
      </div>
    );
  }
  editOrganizationProfile() {
    const invalid = this.validateOrganizationProfile();
    if (!invalid) {
      this.props.editOrganizationProfile(
        this.props.organization, this.state.profile);
    } else {
      this.props.editOrganizationProfileFailure(
        this.props.organization, this.formatInvalid(invalid));
    }
  }
  handleChange(key, value) {
    this.setState({
      profile: {
        ...this.state.profile,
        [key]: value,
      },
    });
  }
 
  render(){
    return(
     
      <div className="container column is-10">
        { this.props.organizationProfileIsloading &&
          <div className="loader-wrapper">
            <Loading />
          </div>
        }
      <div className="org-profile-form ">
          {/* Organization Name */}
      <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">
            <FormattedMessage
                id='amp.page.organization.profile.org.name.label'
                textComponent='span'
              />
            </label>
          </div>
          <div className="field-body org-name-field">
            <div className="field">
              <p className="control is-expanded">
                  <label className="label"> {this.props.organization.name}</label>
             </p>
            </div>
           
          </div>
        </div>
        {/* DMHC ID */}
        <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">
              <FormattedMessage
                id='amp.page.organization.profile.DMHC.label'
                textComponent='span'
              />
                </label>
            </div>
            <div className="field-body org-id-field">
              <div className="field">
                <p className="control is-expanded">
                    <label className="label"> {this.props.profile.dmhc_id} {this.props.profile.dmhc_sub_id}</label>
               </p>
              </div>
             
            </div>
          </div>
          <hr/>
          {/* {Address} */}
    <div className="field is-horizontal">
      <div className="field-label is-normal">
        <label className="label">
        <FormattedMessage
         id='amp.page.organization.profile.address.label'
        textComponent='span'
                />
          </label>
      </div>
      <div className="field-body">
        <div className="field">
          <p className="control is-expanded">
            <input type="text"  className="input"  value={this.state.profile.address1}
                   onChange={e =>
                  this.handleChange('address1', e.target.value)} ></input>
         </p>
        </div>
       
      </div>
    </div>
    <div className="field is-horizontal">
      <div className="field-label is-normal">
        <label className="label"></label>
      </div>
      <div className="field-body">
        <div className="field">
          <p className="control is-expanded">
            <input className="input" type="text" placeholder=""  value={this.state.profile.address2}
                  onChange={e =>
                    this.handleChange('address2', e.target.value)}/>
         </p>
        </div>
       
      </div>
    </div>
    {/* city,state,zip */}
    <div className="field is-horizontal">
      <div className="field-label is-normal">
        <label className="label"><FormattedMessage
         id='amp.page.organization.profile.city.state.label'
          textComponent='span'
              /></label>
      </div>
      <div className="field-body">
        <div className="field">
          <p className="control is-expanded">
            <input className="input" type="text" placeholder="" value={this.state.profile.city}
               onChange={e => this.handleChange('city', e.target.value)}/>
         </p>
        </div>
        <div className="field">
          <p className="control is-expanded">
            <input className="input" type="text" placeholder="" value={this.state.profile.state}
               onChange={e => this.handleChange('state', e.target.value)} />
         </p>
        </div>

        <div className="field">
          <p className="control is-expanded">
            <input className="input" type="text" placeholder=""
             value={this.state.profile.zipcode}               
             onChange={e => this.handleChange('zipcode', e.target.value)}
            />
         </p>
        </div>
      </div>
    </div>
    {/* phonenumber */}
     <div className="field is-horizontal">
        <div className="field-label is-normal">
          <label className="label">
            <FormattedMessage              
            id='amp.page.organization.profile.phone.label'
            textComponent='span'
             /></label>
        </div>
        <div className="field-body ">
          <div className="field column ">
            <p className="control is-expanded">
              <input className="input" type="text" placeholder="" value={this.state.profile.phonenumber}
                onChange={e =>
                   this.handleChange('phonenumber', e.target.value)}/>
           </p>
          </div>
          <div className="field column ">
            <p className="control is-expanded">
         
            {/* <FormattedMessage
                  id='amp.page.organization.profile.phone.ext.label'
                    textComponent='span' /> */}
  
              <input className="input" type="text" placeholder="" value={this.state.profile.phoneext}
                 onChange={e => this.handleChange('phoneext', e.target.value)}/>
           </p>
          </div>

          <div className="field  column">
            <p className="control is-expanded">
                <label className="label"></label>
           </p>
          </div>
        </div>
      </div> 


      <hr/>
      <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">
            <FormattedMessage
            id='amp.page.organization.profile.website.label'
            textComponent='span'
               /></label>
          </div>
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded">
                <input className="input" type="text" placeholder="" value={this.state.profile.website}
                onChange={e => this.handleChange('website', e.target.value)}></input>
             </p>
            </div>
           
          </div>
        </div>

         {/* DMHC ID
              NOTE: Only available to edit for Health Plans */}
               {
            this.props.organization.org_type == ORGANIZATION_HP &&
        <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label"><FormattedMessage
                  id='amp.page.organization.profile.DMHC.input.label'
                  textComponent='span'
                /></label>
            </div>
            <div className="field-body">
              <div className="field">
                <p className="control is-expanded">
                  <input className="input" type="text" placeholder="" 
                  value={this.state.profile.dmhc_id}
                  onChange={e =>
                  this.handleChange('dmhc_id', e.target.value)}/>
               </p>
              </div>
             
            </div>
          </div>
  }
            {/* Notes */}
          <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label"><FormattedMessage
                id='amp.page.organization.profile.general.notes.label'
                textComponent='span'
              /></label>
              </div>
              <div className="field-body">
                <div className="field">
                  <p className="control is-expanded">
                      <textarea className="textarea"  value={this.state.profile.notes}
                onChange={e => this.handleChange('notes', e.target.value)}></textarea>
                 </p>
                </div>
               
              </div>
            </div>
            <hr />
            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label"><FormattedMessage
                  id='amp.page.organization.profile.general.pcon_email'
                  textComponent='span'
                /></label>
              </div>
              <div className="field-body">
                <div className="field">
                  <p className="control is-expanded">
                    <input type="checkbox" checked={this.state.profile.pcon_email_opt_out} 
                      onChange={e => this.handleChange('pcon_email_opt_out', e.target.checked)}/>
                 </p>
                </div>
              </div>
            </div>
            <div className="field is-horizontal">
                <div className="field-label is-normal">
                    <label className="label"></label>
                  </div>
                  <div className="field-body">
                  <button className="button is-dark btn-hover" onClick={() => this.editOrganizationProfile()}>
                <FormattedMessage
                  id='amp.page.organization.profile.button.save.label'
                  textComponent='span'
                /></button>
                </div>
               
              </div>
            </div>
            </div>
           
    )
  }
}

export default OrganizationProfileForm;
