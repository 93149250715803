import React from 'react';
import './arrowchart.scss';
import ReactTooltip from 'react-tooltip';
import { round } from './calculations';

const Icon = () => (
    <svg preserveAspectRatio='none' viewBox="0 0 24 24">
        <path d="M13.293 7.293a.999.999 0 0 0 0 1.414L15.586 11H8a1 1 0 0 0 0 2h7.586l-2.293 2.293a.999.999 0 1 0 1.414 1.414L19.414 12l-4.707-4.707a.999.999 0 0 0-1.414 0z" />
    </svg>
);

export const colors = ["green", "yellow", "orange", "red", "purple", "light-blue"];

export const ArrowChart = ({ topData, bottomData, topResult, bottomResult, inverted, unitsLabel, functionSuffix, upperAxisLable, bottomAxisLable, nodataEnding }) => {

    const maxValue = Math.max(...[...topData, ...bottomData, topResult, bottomResult]),
          minValue = Math.min(...[...topData, ...bottomData, topResult, bottomResult]),
          currentColors = colors.slice(0, topData.length),
          getRelativeValue = (value) => (inverted ? 100 - (minValue - value)*100/(minValue - maxValue) : (value - minValue)*100/(maxValue - minValue));

    const generateChart = (className, result, axisLabel, data) => {
        const resultRelativeValue = getRelativeValue(result),
              noResult = result === null;
        return (
            <div className={`${className} ${noResult ? "no-result": ""}`}>
                { noResult && <span className="no-data-message">{axisLabel} {nodataEnding}</span>}
                {!noResult &&
                    <div className="value"
                        data-tip={`${round(result)}${functionSuffix}`}
                        style={{ marginLeft: `${resultRelativeValue * 0.9 + 9 + (100 - resultRelativeValue) / 100}%` }} ></div>}
                <div className="axis-label"><span>{axisLabel}</span></div>
                <div className="left" style={{ width: `${getRelativeValue(data[0])}%` }}></div>
                {data.map((value, index) => {
                    if (index <= data.length - 1)
                        return (
                            <div className={currentColors[currentColors.length - 1 - index]}
                                style={{ width: `${(index === data.length - 1 ? 100 : getRelativeValue(data[index + 1])) - getRelativeValue(value)}%` }}>
                                <div className="bar" data-tip={`${round(value)}${functionSuffix}`}  ></div></div>
                        );
                })}
            </div>
        );
    }

    return (
        <div className="arrow-chart">
            <div className="chart-container">
                {generateChart("upper-half-container", topResult, upperAxisLable, topData)}
                <div className="line">
                </div>
                {generateChart("bottom-half-container", bottomResult, bottomAxisLable, bottomData)}
                <div className="units-label"><span>{unitsLabel}</span></div>
            </div>
            <div className="icon-container">
                <Icon />
            </div>
            <ReactTooltip className="arrow-bar-tooltip"/>
        </div>);
};
