import React from 'react';
import { connect } from 'react-redux';

import {
  requestContractList,
} from 'state/actions';

import { ORGANIZATION_PO } from 'state/constants';
import { ORGANIZATION_HP } from 'state/constants';

import {
  getUserState,
  isConsistentUserState,
  isOrglessAndNotLoading,
  isContractsFrozen,
} from 'state/selectors';

import Loading from 'common/Loading';
import OrglessWarning from 'common/OrglessWarning';
import StateSelector from 'common/state-selector';
import ContractList from './ContractList';
import AddContractButton from './AddContractButton';
import ExportContractsButton from './ExportContractsButton';
import POInstructions from './POInstructions';
import HPInstructions from './HPInstructions';
import UrlParams from '../../common/urlParams';


class ContactsPage extends React.PureComponent {
  constructor(props) {
    super(props);
  }
  componentWillUpdate(nextProps) {
    if (nextProps.organization != this.props.organization) {
          this.requestContractList(nextProps);
    }
  }
  componentWillMount() {
    this.requestContractList(this.props);
  }
  requestContractList(props) {
    if (props.isConsistentUserState) {
      props.requestContractList(props.organization.id);
    }
  }
  render() {
    let content;
    if (this.props.isConsistentUserState) {
      content = (
        <div>
          <div className='contracts-actions clearfix'>
            {this.props.organization.org_type == ORGANIZATION_HP &&
              <HPInstructions {...this.props} />}
            <ExportContractsButton {...this.props} />
            {!this.props.isFrozen ?
              <AddContractButton {...this.props} /> : <div />}
          </div>
          {this.props.contractsAreLoading ? <Loading /> : <ContractList {...this.props} /> }
          {this.props.organization.org_type == ORGANIZATION_PO &&
            <POInstructions {...this.props} />}
        </div>
      );
    } else if (this.props.isOrglessAndNotLoading) {
      content = <OrglessWarning />;
    } else {
      content = <Loading />;
    }
    return (
      <div className='contracts-page'>
        <UrlParams
          location={this.props.location}
          history={this.props.history} 
          baseUrl="/contracts"
          params={["organization"]}/>
        <StateSelector
          hideDuration={true}
          hideProduct={true} />
        <div className='center-block contacts-content'>
          {content}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...getUserState(state),
    isConsistentUserState: isConsistentUserState(state),
    isOrglessAndNotLoading: isOrglessAndNotLoading(state),
    isFrozen: !!state.config.CONTRACTS_FROZEN,
    contractsAreLoading: state.contracts.contractsAreLoading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    requestContractList: organization => dispatch(
      requestContractList(organization))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ContactsPage);
