import {call, put, takeLatest} from 'redux-saga/effects';

import {getData} from 'common/request';

import {USE_BACKEND_CONFIG,
  CONTRACTS_FROZEN,
  ENABLE_ACO_DOWNLOAD_UPTO,
  ENABLE_HP_ARU_TCOC_DOWNLOAD,
  NEW_MEASURE_DOWNLOAD_IMPLEMENTATION,
  PING_FREQUENCY,
  SESSION_AUTO_LOGOUT_TIME,
  USE_BACKEND_DOWNLOADS_API,
  PCON_NOTIF_DEFAULT_FROM_EMAIL,
  HIDE_PCON_COMMENTING,
  FREEZE_PRE_PCON_SURVEYS
} from 'utils/constants';

import {
  REQUEST_CONFIG_LIST,
  requestConfigList,
  requestConfigListFailure,
  updateConfigList,
} from './actions';

export default function* () {
  console.log('EXPORT DEFAULT SAGA CONFIG...');
  if (USE_BACKEND_CONFIG == true) {
    yield takeLatest(REQUEST_CONFIG_LIST, executeConfigListRequest);
  } else {
    yield put(updateConfigList({CONTRACTS_FROZEN,
      ENABLE_ACO_DOWNLOAD_UPTO,
      ENABLE_HP_ARU_TCOC_DOWNLOAD,
      NEW_MEASURE_DOWNLOAD_IMPLEMENTATION,
      PING_FREQUENCY,
      SESSION_AUTO_LOGOUT_TIME,
      USE_BACKEND_DOWNLOADS_API,
      PCON_NOTIF_DEFAULT_FROM_EMAIL,
      HIDE_PCON_COMMENTING,
      FREEZE_PRE_PCON_SURVEYS}));
  }
}

function* triggerConfigListRequest({}) {
  yield put(requestConfigList());
}

function* executeConfigListRequest({}) {
  const resp = yield call(getData, '/api/v2/config/', {"app":"amp"});
  if (resp.response === 'error') {
    yield put(requestConfigListFailure(null));
    return;
  }
  yield put(updateConfigList(resp));
}


