export const REQUEST_CONFIG_LIST = 'REQUEST_CONFIG_LIST';
export const REQUEST_CONFIG_LIST_FAILURE = 'REQUEST_CONFIG_LIST_FAILURE';
export const UPDATE_CONFIG_LIST = 'UPDATE_CONFIG_LIST';


export function requestConfigList() {
  return {
    type: REQUEST_CONFIG_LIST
  };
}

export function requestConfigListFailure(error) {
  return {
    type: REQUEST_CONFIG_LIST_FAILURE,
    error,
  };
}

export function updateConfigList(config) {
  return {
    type: UPDATE_CONFIG_LIST,
    config,
  };
}


