import React from 'react';
import {
  connect
} from 'react-redux';

import {
  editOrganizationProfile,
  editOrganizationProfileFailure,
  editOrganizationSurvey,
  editOrganizationSurveyFailure,
  updateOrganizationSurveyCategory,
  updateOrganization,
  downloadMeasureList,
} from 'state/actions';

import {
  getUserOrganization,
  isConsistentUserState,
  isOrglessAndNotLoading,
  getOrganizationProfile,
  getOrganizationSurvey,
  getOrganizationSurveyCategory,
  getOrganizationSurveyCategories,
  getUserState
} from 'state/selectors';

import queryString from 'query-string';

import OrglessWarning from 'common/OrglessWarning';
import Loading from 'common/Loading';
import StateSelector from 'common/state-selector';

import CategorySelector from './CategorySelector';
import OrganizationProfileForm from './OrganizationProfileForm';
import OrganizationSurveyForm from './OrganizationSurveyForm';
import UrlParams from '../../common/urlParams';


class OrganizationProfilePage extends React.Component {
  constructor(props) {
    super(props);
  }
  handleCategoryChange(category) {
    this.props.updateOrganizationSurveyCategory(category);
  }
  render() {
    let content;
    if (this.props.isConsistentUserState) {
      let innerContent = [ <
        CategorySelector
        key = 'category-selector' {
          ...this.props
        }
        onClick = {
          this.handleCategoryChange.bind(this)
        }
        />,
      ];
      if (this.props.category.id === 'profile') {
        innerContent.push( <
          OrganizationProfileForm key = 'form' {
            ...this.props
          }
          />
        );
      } else {
        innerContent.push( <
          OrganizationSurveyForm key = 'form' {
            ...this.props
          }
          />
        );
      }
      content = ( <div className="columns"> {innerContent} </div>
      );
    } else if (this.props.isOrglessAndNotLoading) {
      content = < OrglessWarning / > ;
    } else {
      content = < Loading / > ;
    }
    return ( <div className = 'organization-profile-page' >
      <UrlParams
        location={this.props.location}
        history={this.props.history}
        baseUrl="/organization-profile"
        params={["organization"]} />
      <StateSelector hideDuration = {true}
      hideProduct = {true}/> 
      <div className = 'center-block organization-profile-content' > 
   
      {content} 
      </div> </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    organization: getUserOrganization(state),
    category: getOrganizationSurveyCategory(state),
    categories: getOrganizationSurveyCategories(state),
    isConsistentUserState: isConsistentUserState(state),
    isOrglessAndNotLoading: isOrglessAndNotLoading(state),
    profile: getOrganizationProfile(state),
    survey: getOrganizationSurvey(state),
    userState: getUserState(state),
    organizationProfileIsloading: state.organizationProfile.organizationProfileIsloading,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    editOrganizationProfile: (organization, profile) => dispatch(
      editOrganizationProfile(organization.id, profile)),
    editOrganizationProfileFailure: (organization, profile, error) => dispatch(
      editOrganizationProfileFailure(organization.id, profile, error)),

    editOrganizationSurvey: (organization, category, survey) => dispatch(
      editOrganizationSurvey(organization.id, category.id, survey)),
    editOrganizationSurveyFailure: (organization, category, error) => dispatch(
      editOrganizationSurveyFailure(organization.id, category.id, error)),

    updateOrganizationSurveyCategory: category => dispatch(
      updateOrganizationSurveyCategory(category)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(
  OrganizationProfilePage);
  