/* eslint-disable react/no-deprecated */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortAmountDown } from '@fortawesome/free-solid-svg-icons'
import { faSortAmountDownAlt } from '@fortawesome/free-solid-svg-icons'


let toSortable = item => {
  let itemType = typeof (item);
  if (itemType === 'number') {
    return item;
  }
  if (itemType === 'string') {
    if (
      item === 'SD' || item === 'LR' || item === 'BR' ||
      item === 'NR' || item === 'UD' || item === 'OL' ||
      item === 'PRT'
    ) {
      return -1;
    }
    return item;
  }
  return item;
};

let isNumber = thing => {
  return !isNaN(parseFloat(thing)) && isFinite(thing);
};

let round = (num, places) => {
  let multiplier = Math.pow(10, places);
  let n = num * multiplier;
  n = Math.round(n);
  return n / multiplier;
};

function isEquivalent(a, b) {
  // Create arrays of property names
  let aProps = Object.getOwnPropertyNames(a);
  let bProps = Object.getOwnPropertyNames(b);

  // If number of properties is different,
  // objects are not equivalent
  if (aProps.length != bProps.length) {
    return false;
  }

  for (let i = 0; i < aProps.length; i++) {
    let propName = aProps[i];

    // If values of same property are not equal,
    // objects are not equivalent
    if (a[propName] !== b[propName]) {
      return false;
    }
  }

  // If we made it this far, objects
  // are considered equivalent
  return true;
}

// props: instances, specialRows, columns, topRow
export class Table extends React.Component {
  state = {
    sortKey: null,
    sortAsc: true,
    dataSum: 0,
  };
  static defaultProps = {
    data: [],
    columns: [],
    topRow: [],
    classSetter: function () {
      return '';
    },
    elementSetter: function (a, b) {
      return b[a];
    },
    toSortable: toSortable,
    sortable: true,
  }
  componentWillReceiveProps(nextProps) {
    if (!isEquivalent(this.props, nextProps) && !this.props.isEditable) {
      this.setState({ sortKey: null, sortAsc: true });
    }
  }
  handleSort(sortKey) {
    if (!this.props.sortable) return;
    if (this.state.sortKey === sortKey) {
      this.setState({ sortAsc: !this.state.sortAsc });
    } else {
      this.setState({ sortKey: sortKey });
    }
  }
  render() {
    // props = data, columns
    let data = this.props.data ? this.props.data.slice() : [];
    let columns = this.props.columns || [];
    let tableRows = [];
    let tableHead = null;
    let theadEltStyle = {
      fontWeight: 'bold', backgroundColor: '#fafafa',
      border: '1px solid #e3e3e3', borderBottom: '2px solid #e3e3e3',
    };

    // sort data
    if (this.props.sortable) {
      data = data.map(elt => {
        Object.keys(elt).forEach(k => {
          if (isNumber(elt[k])) elt[k] = round(elt[k], 2);
        });
        elt.__sort = this.props.toSortable(elt[this.state.sortKey]);
        return elt;
      });
      let sortFn = (key, asc) => {
        if (data.every(elt => typeof (elt[key]) === 'string')) {
          if (asc) {
            return (a, b) => a.__sort.localeCompare(b.__sort);
          } else {
            return (a, b) => b.__sort.localeCompare(a.__sort);
          }
        } else {
          if (asc) {
            return (a, b) => a.__sort - b.__sort;
          } else {
            return (a, b) => b.__sort - a.__sort;
          }
        }
      };
      data.sort(sortFn(this.state.sortKey, this.state.sortAsc));

      // set header style
      theadEltStyle = Object.assign(theadEltStyle, { cursor: 'pointer' });
    }

    // create rows
    tableRows = data.map((dataRow, rIx) => {
      let rowElts = columns.map((column, cIx) => {
        return (
          <td key={`item.${rIx}.${cIx}`}
            className={this.props.classSetter(column.key, dataRow)}>
            {this.props.elementSetter(column.key, dataRow)}
          </td>
        );
      });
      return (
        <tr key={`row.${rIx}`}>
          {rowElts}
        </tr>
      );
    });
    // create thead
    let headElts = columns.map((column, cIx) => {
      let sortIcon;
      if (column.key === this.state.sortKey) {
        let icon = this.state.sortAsc ? faSortAmountDownAlt : faSortAmountDown
        sortIcon = <FontAwesomeIcon icon={icon} />
      }
      return (
        <th key={`head.${cIx}`}
          style={theadEltStyle}
          onClick={this.handleSort.bind(this, column.key)}>
          {column.label} {sortIcon}
        </th>
      );
    });
    let headEltsList = [];
    if (this.props.topRow.length > 0) {
      let topR = this.props.topRow.map((elt, cIx) => {
        return (
          <th key={`head2.${cIx}`}
            style={Object.assign({}, theadEltStyle, {
              textAlign: 'center',
              borderBottom: '1px solid #e3e3e3',
            })}
            colSpan={elt[1]}>
            {elt[0]}
          </th>
        );
      });
      headEltsList.push(<tr key="head1">{topR}</tr>);
    }
    headEltsList.push(<tr key="head2">{headElts}</tr>);
    tableHead = (
      <thead>
        {headEltsList}
      </thead>
    );

    // table
    let table = (
      <table
        className="table table-responsive table-bordered"
        style={this.props.style}
      >
        {tableHead}
        <tbody>
          {tableRows}
          {this.props.children}
        </tbody>
      </table>
    );
    return table;
  }
};

export class Tr extends React.Component {
  static defaultProps = {
    data: [],
    columns: [],
  };
  render() {
    let rowElts = this.props.columns.map(column => {
      return (
        <td key={column.key}>{this.props.data[column.key]}</td>
      );
    });
    return (
      <tr style={this.props.style}>
        {rowElts}
      </tr>
    );
  }
};
