import React from 'react';

import {
    Accordion,
    AccordionItem,
    AccordionItemTitle,
    AccordionItemBody,
} from 'react-accessible-accordion';
import Select from 'react-select';
import 'react-accessible-accordion/dist/fancy-example.css';
import './healthplan.scss';

export default class HealthPlan extends React.Component  {
    state = {
        filter: {
            selectedOption: null
        }
    };
    render() {

        return (
            <div className="health-plan">
                <div className="flex-row filter">
                    <div className="search">
                        <i className="fas fa-search fa-lg"></i>
                        <input placeholder="Search PO_ID or PO Name"></input>
                    </div>
                    <div className="filter">
                        <i className="fas fa-filter fa-lg"></i>
                        <Select
                            className="filter-options"
                            classNamePrefix="filter-options"
                            isSearchable={false}
                            isMulti={true}
                            value={this.state.filter.selectedOption}
                            onChange = {(selectedOption) => {
                                this.setState({
                                    filter: {
                                        selectedOption: selectedOption
                                    }
                                });
                            }}
                            placeholder="Filter by [Options]"
                            options={[
                                { value: 0, label: 'Pos who only earned shared' },
                                { value: 1, label: 'Pos who only earned attainment' },
                                { value: 2, label: 'Pos who did not earned any incentive' },
                                { value: 3, label: 'Pos that passed quality gate' },
                                { value: 4, label: 'Pos that passed trend gate' },
                                { value: 5, label: 'Pos that passed cost gate' },
                              ]}
                        />
                    </div>
                </div>
                <div className="flex-row table-header">
                    <div className="id">
                        <span>PO ID</span>
                    </div>
                    <div className="name">
                        <span>PO Name</span>
                    </div>
                    <div className="region">
                        <span>Region</span>
                    </div>
                    <div className="high-cost">
                        <span>High Cost</span>
                    </div>
                    <div className="full-risk">
                        <span>Full Risk</span>
                    </div>
                    <div className="gates">
                        <span>Gates</span>
                    </div>
                    <div className="member-years">
                        <span>Member Years (Med Coverage)</span>
                    </div>
                    <div className="quality-multiplier">
                        <span>Quality Multiplier</span>
                    </div>
                    <div className="attainment-shared-savings">
                        <span></span>
                    </div>
                    <div className="combined-incentive">
                        <span>Combined Incentive</span>
                    </div>
                    <div className="total-incentive">
                        <span>Total PO Incentive Earned</span>
                    </div>
                    <div className="total-incentive-pmpm">
                        <span>Total PO Incentive Earned $PMPM</span>
                    </div>
                </div>
                <Accordion>
                    <AccordionItem>
                        <AccordionItemTitle>
                            <div className="accordion-row flex-row">
                                <div className="id">
                                    <span>1001000</span>
                                </div>
                                <div className="name">
                                    <span>Affinity Medical Group</span>
                                </div>
                                <div className="region">
                                    <span>Bay Area</span>
                                </div>
                                <div className="high-cost">
                                    <span><i className="fas fa-exclamation-circle fa-lg"></i></span>
                                </div>
                                <div className="full-risk">
                                    <span><i className="fas fa-check-circle fa-lg"></i><i className="fas fa-times-circle fa-lg"></i></span>
                                </div>
                                <div className="gates">
                                    <div className="circle-diagram"></div>
                                </div>
                                <div className="member-years">
                                    <span>1.546</span>
                                </div>
                                <div className="quality-multiplier">
                                    <span>1.07</span>
                                </div>
                                <div className="attainment-shared-savings flex-row">
                                    <div className="flex-column">
                                        <span className="attainment-label">Attainment $23.9K</span>
                                        <span className="shared-savings-label">Shared Savings $8.2K</span>
                                    </div>
                                    <div className="scale-container">
                                        <div className="scale">
                                            <div className="value"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="combined-incentive">
                                    <span>$32.1K</span>
                                </div>
                                <div className="total-incentive">
                                    <span>$16K</span>
                                </div>
                                <div className="total-incentive-pmpm">
                                    <span>$1.38</span>
                                </div>
                            </div>
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            <div className="flex-column accordion-body">
                                <div className="flex-row headers">
                                    <div><span>AHU_TOTAL</span></div>
                                    <div><span>EDU</span></div>
                                    <div><span>GRX_ALLRX2</span></div>
                                    <div><span>OSU_ASC2</span></div>
                                    <div><span>PCR18OV_IHS_RISK_ADJ_19</span></div>
                                </div>
                                <div className="flex-row values">
                                    <div className="flex-column">
                                        <span>$13.9K</span>
                                        <input value={'MY 2019 - 31.79'}></input>
                                        <input value={'MY 2018 - 34.58'}></input>
                                        <input className="green text-center" value={'Benchmark'}></input>
                                    </div>
                                    <div className="flex-column">
                                        <span>$0.0</span>
                                        <input value={'MY 2019 - 138.74'}></input>
                                        <input value={'MY 2018 - 118.60'}></input>
                                        <input className="text-center" value={'No Attainment'}></input>
                                    </div>
                                    <div><span>-$940</span></div>
                                    <div><span>$8.6K</span></div>
                                    <div><span>$49.6K</span></div>
                                </div>
                                <div className="flex-row values">
                                    <div className="flex-column">
                                        <span>$11.7K</span>
                                        <input value={'MY 2019 O/E Ratio - 1.03'}></input>
                                        <input value={'MY 2018 O/E Ratio - 1.10'}></input>
                                        <input value={'MY 2019 Expected Rate - 169.72'}></input>
                                        <input value={'MY 2019 Member Years - 1.546'}></input>
                                        <input className="text-center green" value={'18.36 Resource Use Saved'}></input>
                                    </div>
                                </div>
                            </div>
                        </AccordionItemBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemTitle>  
                            <div className="accordion-row flex-row">
                                <div className="id">
                                    <span>1001001</span>
                                </div>
                                <div className="name">
                                    <span>CA One Medical</span>
                                </div>
                                <div className="region">
                                    <span>Central Coast</span>
                                </div>
                                <div className="high-cost">
                                    <span><i className="fas fa-exclamation-circle fa-lg"></i></span>
                                </div>
                                <div className="full-risk">
                                    <span><i className="fas fa-check-circle fa-lg"></i><i className="fas fa-times-circle fa-lg"></i></span>
                                </div>
                                <div className="gates">
                                    <div className="circle-diagram"></div>
                                </div>
                                <div className="member-years">
                                    <span>2.546</span>
                                </div>
                                <div className="quality-multiplier">
                                    <span>0.85</span>
                                </div>
                                <div className="attainment-shared-savings flex-row">
                                    <div className="flex-column">
                                        <span className="attainment-label">Attainment $23.9K</span>
                                        <span className="shared-savings-label">Shared Savings $8.2K</span>
                                    </div>
                                    <div className="scale-container">
                                        <div className="scale">
                                            <div className="value"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="combined-incentive">
                                    <span>$22.4K</span>
                                </div>
                                <div className="total-incentive">
                                    <span>$12K</span>
                                </div>
                                <div className="total-incentive-pmpm">
                                    <span>$1.55</span>
                                </div>
                            </div>
                        </AccordionItemTitle>
                        <AccordionItemBody>
    
                        <div className="flex-column accordion-body">
                                <div className="flex-row headers">
                                    <div><span>AHU_TOTAL</span></div>
                                    <div><span>EDU</span></div>
                                    <div><span>GRX_ALLRX2</span></div>
                                    <div><span>OSU_ASC2</span></div>
                                    <div><span>PCR18OV_IHS_RISK_ADJ_19</span></div>
                                </div>
                                <div className="flex-row values">
                                    <div className="flex-column">
                                        <span>$13.9K</span>
                                        <input value={'MY 2019 - 31.79'}></input>
                                        <input value={'MY 2018 - 34.58'}></input>
                                        <input className="green text-center" value={'Benchmark'}></input>
                                    </div>
                                    <div className="flex-column">
                                        <span>$0.0</span>
                                        <input value={'MY 2019 - 138.74'}></input>
                                        <input value={'MY 2018 - 118.60'}></input>
                                        <input className="text-center" value={'No Attainment'}></input>
                                    </div>
                                    <div><span>-$940</span></div>
                                    <div><span>$8.6K</span></div>
                                    <div><span>$49.6K</span></div>
                                </div>
                                <div className="flex-row values">
                                    <div className="flex-column">
                                        <span>$11.7K</span>
                                        <input value={'MY 2019 O/E Ratio - 1.03'}></input>
                                        <input value={'MY 2018 O/E Ratio - 1.10'}></input>
                                        <input value={'MY 2019 Expected Rate - 169.72'}></input>
                                        <input value={'MY 2019 Member Years - 1.546'}></input>
                                        <input className="text-center green" value={'18.36 Resource Use Saved'}></input>
                                    </div>
                                </div>
                            </div>
                        </AccordionItemBody>
                    </AccordionItem>
                </Accordion>
            </div>
        );
    }
}