import React from 'react';
import {Table, Thead} from 'reactable';

import {ScoreGauge} from 'common/score-gauge';
import {siteMessages} from 'src/utils/copy';

let round = num => {
  let n = num * 100;
  n = Math.round(n);
  return n / 100;
};

// eslint-disable-next-line react/no-deprecated
class PcrView extends React.Component {
  render() {
    let tableData = [];
    let scoreLine = {};
    let loading = false;
    if (!this.props.instances.response && this.props.instances.length > 0) {
      scoreLine = this.props.scoreLine;
      if (scoreLine !== null) {
        scoreLine.data_provider__name='Score';
      }
      tableData = this.props.instances.map(elt => {
        let fields = elt.fields;
        Object.keys(fields).map(f => {
          if (Number.isFinite(fields[f])) {
            fields[f] = round(fields[f]);
          }
        });
        fields.data_provider__name = elt.data_provider__name;
        return fields;
      });
    } else {
      loading = true;
    }
    // table columns
    let columns = [];
    if (this.props.measure.fields) {
      columns = this.props.measure.fields.standard.map(elt => {
        let k = Object.keys(elt)[0];
        let v = elt[k];
        return {key: k, label: v};
      });
      columns.unshift({
        key: 'data_provider__name',
        label: siteMessages['amp.page.measures.measures.po.dataprovider.label'],
      });
    }

    // table
    let table;
    if (scoreLine) {
      table = (
        <Table columns={columns}
          className='table table-responsive table-bordered'
          data={tableData} sortable={true}
        >
          <Thead className="score-row"
            data={scoreLine} />
        </Table>
      );
    } else {
      table = (
        <Table columns={columns} className='table-responsive' data={tableData}>
        </Table>
      );
    }
    if (loading) {
      table = (
        <div></div>
      );
    }
    let elements = [];
    if (Object.keys(scoreLine).length > 0 &&
      this.props.measure.function_id == 1
    ) {
      elements.push(
      <div style={{width: '60%', marginBottom: '10px'}}>
        <ScoreGauge score={scoreLine.rate} />
      </div>);
    }
    let renderElt = (
      <div style={this.props.style}>
        {elements}
        {table}
      </div>
    );
    return (
      renderElt
    );
}
};


export default PcrView;
