/* eslint-disable no-console */
// log anything >= to this level.
let logLevel = 'error';

const logLevels = {
  'debug': 0,
  'info': 1,
  'warn': 2,
  'error': 3,
};

class Log {
  constructor(level) {
    this.level = level;
    this.transport = (...values) => console.log(...values);
  }
  debug(...values) {
    if (logLevels[this.level] <= logLevels['debug']) {
this.transport('[debug]', ...values);
}
  }
  info(...values) {
    if (logLevels[this.level] <= logLevels['info']) {
this.transport('[info]', values);
}
  }
  warn(...values) {
    if (logLevels[this.level] <= logLevels['warn']) {
this.transport('[warn]', values);
}
  }
  error(...values) {
    if (logLevels[this.level] <= logLevels['error']) {
this.transport('[error]', values);
}
  }
}

let log = new Log(logLevel);

export default log;
