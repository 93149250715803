export const REQUEST_ORGANIZATION_PROFILE = 'REQUEST_ORGANIZATION_PROFILE';
export const REQUEST_ORGANIZATION_PROFILE_FAILURE =
  'REQUEST_ORGANIZATION_PROFILE_FAILURE';
export const UPDATE_ORGANIZATION_PROFILE = 'UPDATE_ORGANIZATION_PROFILE';

export const EDIT_ORGANIZATION_PROFILE = 'EDIT_ORGANIZATION_PROFILE';
export const EDIT_ORGANIZATION_PROFILE_SUCCESS =
  'EDIT_ORGANIZATION_PROFILE_SUCCESS';
export const EDIT_ORGANIZATION_PROFILE_FAILURE =
  'EDIT_ORGANIZATION_PROFILE_FAILURE';
