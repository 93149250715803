import React from 'react';

import FormattedMessage from 'components/FormattedMessageMarkdown';
import './styles.scss';

const HPInstructions = props => {
  if (props.isFrozen) {
    return (
      <div className='hp-contract-instructions clearfix'>
        <div className='row center-block'>
          <strong>
            <FormattedMessage
              id='amp.contract.HP.instruction.frozen.content'
              textComponent='span'
            />
          </strong>
        </div>
      </div>
    );
  } else {
    return (
      <div className='hp-contract-instructions'>
      </div>
    );
  }
};

export default HPInstructions;
