import React from 'react';
import {connect} from 'react-redux';
import { Outlet } from 'react-router-dom';
import Loading from 'common/Loading';
import OrglessWarning from 'common/OrglessWarning';
import StateSelector from 'common/state-selector';
import {MeasuresSidebar as Sidebar} from 'common/sidebar';

import {
  getUserState,
  isConsistentUserState,
  isOrglessAndNotLoading,
} from 'state/selectors';

/* #######################
# measures generic page #
#######################*/

const MeasuresPage = props => {
  let content;
  if (props.isConsistentUserState) {
    content = <Outlet context={{
      organization: props.organization,
      product: props.product,
      duration: props.duration,
    }} />;
  } else if (props.isOrglessAndNotLoading) {
    content = <OrglessWarning />;
  } else {
    content = <Loading />;
  }
  return (
    <div style={props.style}>
      <div className='measures-page'>
        <Sidebar {...props} />
        <div className='measures-content'>
          <StateSelector sharedableLink={window.location.href.includes('po_worksheet')}/>
          {content}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    ...getUserState(state),
    isConsistentUserState: isConsistentUserState(state),
    isOrglessAndNotLoading: isOrglessAndNotLoading(state),
  };
};

export default connect(mapStateToProps)(MeasuresPage);
