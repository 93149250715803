export const REQUEST_ORGANIZATION_SURVEY = 'REQUEST_ORGANIZATION_SURVEY';
export const REQUEST_ORGANIZATION_SURVEY_FAILURE =
  'REQUEST_ORGANIZATION_SURVEY_FAILURE';
export const UPDATE_ORGANIZATION_SURVEY = 'UPDATE_ORGANIZATION_SURVEY';

export const EDIT_ORGANIZATION_SURVEY = 'EDIT_ORGANIZATION_SURVEY';
export const EDIT_ORGANIZATION_SURVEY_SUCCESS =
  'EDIT_ORGANIZATION_SURVEY_SUCCESS';
export const EDIT_ORGANIZATION_SURVEY_FAILURE =
  'EDIT_ORGANIZATION_SURVEY_FAILURE';

export const UPDATE_ORGANIZATION_SURVEY_CATEGORY =
  'UPDATE_ORGANIZATION_SURVEY_CATEGORY';

export const SURVEY_TEXT = 0;
export const SURVEY_RADIO = 1;
export const SURVEY_MULT = 2;
export const SURVEY_HEADER = 3;
export const SURVEY_FOOTER = 4;
export const SURVEY_INFO = 5;
export const SURVEY_TEXTAREA = 6;

export const SIMPLE_SURVEY_TYPES = [
  SURVEY_TEXT,
  SURVEY_TEXTAREA,
  SURVEY_HEADER,
  SURVEY_FOOTER,
  SURVEY_INFO,
];

export const INFO_SURVEY_TYPES = [
  SURVEY_HEADER,
  SURVEY_FOOTER,
  SURVEY_INFO,
];

export const BASE_SURVEY_CATEGORIES = [
  { id: 'profile', name: 'Contact Information' },
];
export const PO_SURVEY_CATEGORIES = [
  ...BASE_SURVEY_CATEGORIES,
  { id: 'ext-profile', name: 'About Your PO' },
  { id: 'participation', name: 'AMP Participation' },
  { id: 'prepcon', name: 'Pre-Participation Confirmation' },
];

export const HP_SURVEY_CATEGORIES = [
  ...BASE_SURVEY_CATEGORIES,
  { id: 'prepcon-hp', name: 'Pre-Participation Confirmation' },
];

