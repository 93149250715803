import {
  ORGANIZATION_HP,
  ORGANIZATION_PO,
} from 'state/constants';

import {
  UPDATE_CONTRACT_LIST,
  REQUEST_CONTRACT_LIST,
  REQUEST_CONTRACT_LIST_FAILURE,
  UPDATE_CONTRACT_ORGANIZATIONS,
  UPDATE_ORGANIZATION_PRODUCTS,
  ADD_CONTRACT_COMMENT,
  DELETE_CONTRACT_COMMENT,
} from './actions';

import {CONTRACTS_FROZEN} from 'utils/constants';


const initialState = {
  // frozen - a boolean which represents whether contracts can be
  //   edited/added. There is currently no actions/sagas pertaining to this, but
  //   it can easily be added in the future. The UI uses this to determine
  //   whether to show/allow various contract UI elements relating to changing
  //   the contract listing.
  frozen: CONTRACTS_FROZEN,
  // contracts - an object, with the keys being the id of an organization,
  //   and the values being a list of contract objects pertaining to that
  //   organization. Caveat: The keys of health plan contract objects are
  //   different than PO contract objects. The differences are best seen looking
  //   at the src/state/contracts/constants.js file.
  contracts: {},
  // products - an object, with the keys being the id of an organization, and
  //   the values being a list of product objects pertaining to that
  //   organization.
  products: {},
  // hps - a list of health plan objects
  hps: [],
  // pos - a list of phys org objects
  pos: [],
};

export default function(state=initialState, action) {
  switch (action.type) {
    case UPDATE_CONTRACT_LIST:
      return {
        ...state,
        contractsAreLoading: false,
        contracts: {
          ...state.contracts,
          [action.organization]: action.contracts,
        },
      };
    case ADD_CONTRACT_COMMENT:
      return {
        ...state,
        contracts: {
          [action.organization.id]: state.contracts[action.organization.id].map(
          (contract) => contract.id === action.comment.contract_id ?
            {
              ...contract,
              comments_list: [action.comment,...contract.comments_list ]
            }
            : contract)
        }
      };
    case DELETE_CONTRACT_COMMENT:
      return {
        ...state,
        contracts: {
          [action.organization.id]: state.contracts[action.organization.id].map(
            (contract) => contract.id === action.comment.contract_id ?
              {
                ...contract,
                comments_list: [...contract.comments_list.filter(item => item.id !== action.comment.id)]
              }
              : contract)
        }
      };
    case REQUEST_CONTRACT_LIST: 
      return {
        ...state,
        contractsAreLoading: true,
      };
    case REQUEST_CONTRACT_LIST_FAILURE: 
      return {
        ...state,
        contractsAreLoading: false,
      };
    case UPDATE_CONTRACT_ORGANIZATIONS:
      return {
        ...state,
        hps: action.organizationType == ORGANIZATION_PO ?
          action.organizations : state.hps,
        pos: action.organizationType == ORGANIZATION_HP ?
          action.organizations : state.pos,
      };
    case UPDATE_ORGANIZATION_PRODUCTS:
      return {
        ...state,
        products: {
          ...state.products,
          [action.organization]: action.products,
        },
      };
  }
  return state;
}
