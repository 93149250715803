import React from 'react';

import {getData} from 'common/request.js';
import {Table} from 'common/prototable';
import {siteMessages} from 'src/utils/copy';

let round = num => {
  let n = num * 100;
  n = Math.round(n);
  return n / 100;
};

// eslint-disable-next-line react/no-deprecated
class DefaultImprovement extends React.Component {
  defaultProps ={
    instances: [],
  };
  state = {
    lastInstances: [],
    loading: false,
  };
  updateMeasureView(obj) {
    if (obj.measure && obj.duration && obj.organization) {
      this.setState({loading: true});
      getData('/api/measures/lastorginstances/', {
        duration: obj.duration.id,
        measure: obj.measure.id,
        organization: obj.organization.id,
      }).then((data) => {
        this.setState({lastInstances: data, loading: false});
      });
    }
  }
  componentWillMount() {
    this.updateMeasureView(this.props);
  }
  componentWillReceiveProps(nextProps) {
    this.updateMeasureView(nextProps);
  }
  render() {
    let iJson = this.props.measure.function__logic.improvement;
    let tableData = [];
    let scoreLine = {};
    let loading = false;
    // table column labels
    let allColumns = [];
    if (this.props.measure.fields) {
      allColumns = this.props.measure.fields.standard.map(elt => {
        let k = Object.keys(elt)[0];
        let v = elt[k];
        return {key: k, label: v};
      });
      allColumns.unshift({
        key: 'data_provider__name',
        label: siteMessages['amp.page.measures.measures.po.dataprovider.label'],
      });
    }
    let improvementColumns = allColumns.filter(elt => {
      if (elt.key === 'data_provider__name') {
        return true;
      } else if (iJson.indexOf(elt.key) != -1) {
        return true;
      } else {
        return false;
      }
    });
    if (!this.props.instances.response && this.props.instances.length > 0 &&
      this.state.loading===false
    ) {
      scoreLine = this.props.scoreLine;
      if (scoreLine !== null) {
        if (scoreLine.data_provider_id == -1) {
          scoreLine.data_provider__name = siteMessages[
            'amp.page.measures.measures.po.dataprovider.health.aggre'
          ];
        } else {
          scoreLine.data_provider__name= siteMessages[
            'amp.page.measures.measures.po.dataprovider.self.repo'
          ];
        }
      }
      tableData = this.props.instances.map(elt => {
        let fields = elt.fields;
        Object.keys(fields).map(f => {
          if (Number.isFinite(fields[f])) {
            fields[f] = round(fields[f]);
          }
        });
        fields.data_provider__name = elt.data_provider__name;
        if (elt.data_provider_id == elt.organization_id) {
          fields.data_provider__name = siteMessages[
            'amp.page.measures.measures.po.dataprovider.self'
          ];
          if (fields.denominator < 30) {
            fields[this.props.measure.function__logic.score] = siteMessages[
              'amp.page.measures.measures.po.score.rate.SD'
            ];
          }
        }
        if (elt.data_provider__name === siteMessages[
          'amp.page.measures.measures.po.dataprovider.iha'
        ]) {
          fields.data_provider__name = siteMessages[
            'amp.page.measures.measures.po.dataprovider.health'
          ];
        }
        // merge in improvement data
        let irow = this.state.lastInstances.find(iElt =>
          iElt.data_provider_id == elt.data_provider_id
        );
        if (irow) {
          improvementColumns.forEach(iCol => {
            fields['last_' + iCol.key] = irow.fields[iCol.key];
          });
        }
        return fields;
      });
    } else {
      loading = true;
    }

    // double the rows
    let numCols = improvementColumns.length;
    let newCols = improvementColumns.concat([]).slice(1, -1);
    let c1 = improvementColumns.shift();
    newCols = newCols.map(elt => {
      let obj = Object.assign({}, elt);
      obj.key = 'last_' + obj.key;
      return obj;
    });
    newCols.unshift(c1);
    improvementColumns = newCols.concat(improvementColumns);
    let table;
    let topRow = null;
    if (this.state.lastInstances.length > 0) {
      topRow = [
        ['', 1],
        [this.state.lastInstances[0].duration__name, newCols.length-1],
        [this.props.duration.name, numCols],
      ];
    }
    table = (
      <Table
        columns={improvementColumns}
        className='table table-responsive table-bordered'
        topRow={topRow}
        data={tableData}
        sortable={true} >
      </Table>
    );
    if (loading) {
      table = (
        <div></div>
      );
    }

    let renderElt = (
      <div style={this.props.style}>
          <div className="panel panel-body"
            style={{marginBottom: '0px', padding: '0px', marginTop: '-1px'}}
          >
            <div>{table}</div>
          </div>
      </div>
    );
    return (
      renderElt
    );
}
};

export default DefaultImprovement;
