import {createSelector} from 'reselect';

import {
  getUserOrganization,
  getAllOrganizationProfiles,
} from 'state/selectors/single';


// contacts

export const getOrganizationProfile = createSelector(
  getAllOrganizationProfiles,
  getUserOrganization,
  (profiles, organization) => organization && profiles[organization.id] || {},
);
