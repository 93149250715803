import React from 'react';
import {connect} from 'react-redux';
import {
  addContract,
  editContract,
  requestOrganizationProducts,
  requestContractOrganizations,
} from 'state/actions';

import {
  ORGANIZATION_HP,
  ORGANIZATION_PO,

  PRODUCT_COMMERCIAL,
  PRODUCT_MEDICARE,
  PRODUCT_MEDI_CAL,

  CONTRACT_DURATION_ID,
  PO_CONTRACT_LIST_ROW_ITEMS,
} from 'state/constants';

import {
  getOrganizationProducts,
  getSortedContractOrganizations,
} from 'state/selectors';

import FormattedMessage from 'components/FormattedMessageMarkdown';
import './styles.scss';

const validP4PProduct = product =>
  (product.id == PRODUCT_COMMERCIAL ||
   product.id == PRODUCT_MEDICARE ||
   product.id == PRODUCT_MEDI_CAL);

const initialContractState = () => {
  return {
    contract: null,
    selectedOrganization: null,
    selectedProduct: null,
    riskOptions: [],
    notes: '',
  };
};

class ContractModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialContractState();
    props.requestContractOrganizations(props.organization.org_type);
  }
  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(newProps) {
    if (this.isEditing() && (!PO_CONTRACT_LIST_ROW_ITEMS.every(
      item => this.props.contract[item] == newProps.contract[item]) ||
      (!this.props.show && newProps.show))) {
      this.setEditState(newProps);
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.organization.id != this.props.organization.id) {
      this.props.requestContractOrganizations(this.props.organization.org_type);
    }
    if (!prevProps.show && this.props.show && this.state.selectedOrganization ||
        (prevState.selectedOrganization != this.state.selectedOrganization)) {
      this.requestOrganizationProducts();
    }
  }
  setEditState(props) {
    let selectedOrganization;
    if (props.organization.org_type == ORGANIZATION_HP) {
      selectedOrganization = props.contract.po_id;
    } else if (props.organization.org_type == ORGANIZATION_PO) {
      selectedOrganization = props.contract.hp_id;
    }
    this.setState({
      contract: props.contract.id,
      selectedOrganization,
      selectedProduct: props.contract.product_id,
      duration: props.contract.duration_id,
      notes: props.contract.notes,
      riskOptions: props.contract.options.split(','),
    });
  }
  handleOrganizationChange(event) {
    this.setState({
      ...initialContractState(),
      selectedOrganization: event.target.value,
    });
  }
  handleProductChange(event) {
    this.setState({selectedProduct: event.target.value});
  }
  handleNotesChange(event) {
    this.setState({notes: event.target.value});
  }
  handleAddContract() {
    let hp;
    let po;
    if (this.props.organization.org_type == ORGANIZATION_HP) {
      hp = this.props.organization.id;
      po = this.state.selectedOrganization;
    } else if (this.props.organization.org_type == ORGANIZATION_PO) {
      hp = this.state.selectedOrganization;
      po = this.props.organization.id;
    }
    let doContractArgs = [
      this.props.organization.id,
      hp,
      po,
      this.state.selectedProduct,
      CONTRACT_DURATION_ID,
      this.state.notes,
      this.state.riskOptions.join(','),
    ];
    if (this.props.contract) {
      doContractArgs.unshift(this.props.contract.id);
      this.props.editContract(...doContractArgs);
    } else {
      this.props.addContract(...doContractArgs);
    }
    this.hide();
  }
  requestOrganizationProducts() {
    if (this.props.organization.org_type == ORGANIZATION_HP) {
      this.props.requestOrganizationProducts(this.props.organization.id);
    } else if (this.props.organization.org_type == ORGANIZATION_PO) {
      this.props.requestOrganizationProducts(this.state.selectedOrganization);
    }
  }
  selectedOrganizationProducts() {
    let organizationId;
    if (this.props.organization.org_type == ORGANIZATION_HP) {
      organizationId = this.props.organization.id;
    } else if (this.props.organization.org_type == ORGANIZATION_PO) {
      organizationId = this.state.selectedOrganization;
    }
    return (this.props.products[organizationId] || [])
      .filter(validP4PProduct);
  }
  isEditing() {
    return !!this.props.contract;
  }
  hide() {
    if (this.isEditing()) {
      this.setEditState(this.props);
    } else {
      this.setState(initialContractState());
    }
    this.props.hide();
    this.props.handleCustomClassName();
  }
  render() {
    const title = this.isEditing() ?
      'Edit Contract' : 'Add Contract';
    const closeTitle = this.isEditing() ?
      'Cancel' : 'Close';
    return (
    <div >
    <div className={`modal-card ${this.props.show ? 'fadeInDown' : 'fadeOutUp'} customModalCard`}>
    <header className="modal-card-head">
    <p className="modal-card-title">{title}</p>
      <button className="delete" aria-label="close" 
              onClick={this.hide.bind(this)}>
      </button>
    </header>
    <section className="modal-card-body">
    <div>
            <div className='add-contract-organization-list'>
              <label> <FormattedMessage
                  id='amp.contract.modal.select.org.label'
                  textComponent='span'
                />
              </label>
              <select className='form-control'
                      value={this.state.selectedOrganization}
                      onChange={this.handleOrganizationChange.bind(this)}>
                {!this.state.selectedOrganization &&
                  <option key='null' value={null} />}
                {this.props.organizations.map(o => {
                  if (o.org_type == 3) {
                    return;
                  }
                  return (
                    <option key={o.id} value={o.id}>
                      {o.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className='add-contract-product-list'>
              <label><FormattedMessage
                  id='amp.contract.modal.select.product.label'
                  textComponent='span'
                />
              </label>
              <select className='form-control'
                      value={this.state.selectedProduct}
                      onChange={this.handleProductChange.bind(this)}>
                {!this.state.selectedProduct &&
                  <option key='null' value={null} />}
                {this.selectedOrganizationProducts().map(p => {
                  return (
                    <option key={p.id} value={p.id}>
                      {p.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <label className='add-contracts-notes'><FormattedMessage
                id='amp.contract.modal.notes.label'
                textComponent='span'
              />
            </label>
            <textarea className='form-control contract-notes'
                      value={this.state.notes}
                      onChange={this.handleNotesChange.bind(this)} />
            <small><FormattedMessage
                id='amp.contract.modal.notes.textarea.footer'
                textComponent='span'
              />
            </small>
          </div>
      
    </section>
    <footer className="modal-card-foot">
    <button onClick={this.hide.bind(this)}>{closeTitle}</button>
          <button bsstyle='primary' onClick={this.handleAddContract.bind(this)}>
            {title}
          </button>
    </footer>
  </div>
</div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    organizations: getSortedContractOrganizations(state, ownProps),
    products: getOrganizationProducts(state, ownProps),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addContract: (organization, hp, po, product, duration, notes, options,
      ) => dispatch(
      addContract(organization, hp, po, product, duration, notes, options)),
    editContract: (contract, organization, hp, po,
      product, duration, notes, options) => dispatch(editContract(
        contract, organization, hp, po, product, duration, notes, options)),
    requestContractOrganizations: (orgType) => dispatch(
      requestContractOrganizations(orgType)),
    requestOrganizationProducts: (organization) => dispatch(
      requestOrganizationProducts(organization)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContractModal);
