
import React from 'react';
import Button from 'common/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { siteMessages } from 'utils/copy';

export class Sidebar extends React.Component {
  render() {

    let elements = this.props.navs.map((elt, ix1) => {
      let navElts = elt.map((eltTemplate, ix2) => {
        let key = ix1 + ' ' + ix2;
        let navElt;
        if (!eltTemplate.to) {
          navElt =
            <li className="sidebar-elt" key={key}>
              <label className="sidebar-title">{eltTemplate.title}</label>
            </li>;
        } else {
          navElt =
            <li className="sidebar-elt" key={key}>
              <Button to={eltTemplate.to} kind="sidebar">
                {eltTemplate.title}
              <FontAwesomeIcon className="sidebar-icon" icon={eltTemplate.icon} />
              </Button>
            </li>;
        }
        return navElt;
      });
      return (
        <ul className="menu-list" key={ix1}>{navElts}</ul>
      );
    });
    return (
      <aside className="menu amp-side-nav">
        {elements}
      </aside>
    );
  }
}

/* #######################
# measures sidebar      #
#######################*/

export class MeasuresSidebar extends React.Component {
  static defaultProps = {
    organization: {
      org_type: -1,
    },
  }
  render() {
    let sidebarNavs = [[]];
    const organization = this.props.organization || { id: null, org_type: -2 };

    // change things by org_type
    if (organization.org_type == -1) { // IHA
    } else if (organization.org_type == 0) { // Health Plan
      sidebarNavs = [
        [{
          title: siteMessages['amp.measure.sidebar.measures.title'],
          to: siteMessages['amp.measure.sidebar.measures.url'] + '/',
          icon: 'th-list',
        }],
        [{
          title: siteMessages['amp.measure.sidebar.downloads.title'],
          to: siteMessages['amp.measure.sidebar.downloads.url'],
          icon: 'download',
        }],
      ];
    } else if (organization.org_type == 1) { // Physician Org
      sidebarNavs = [
        [{
          title: siteMessages['amp.measure.sidebar.about.title'],
          to: siteMessages['amp.measure.sidebar.about.url'],
          icon: 'question-circle',
        }],
        [
          {
            title: siteMessages['amp.measure.sidebar.reports.title'],
            to: siteMessages['amp.measure.sidebar.reports.url'],
            icon: 'signal',
          },
          {
            title: siteMessages['amp.measure.sidebar.measures.title'],
            to: siteMessages['amp.measure.sidebar.measures.url'] + '/',
            icon: 'th-list',
          },
        ],
        [{
          title: siteMessages['amp.measure.sidebar.downloads.title'],
          to: siteMessages['amp.measure.sidebar.downloads.url'],
          icon: 'download',
        }],
      ];
    } else if (organization.org_type == 13) { // The same as Physician Org, adding a new block just in case we have some extra cases in the future.
      sidebarNavs = [
        [{
          title: siteMessages['amp.measure.sidebar.about.title'],
          to: siteMessages['amp.measure.sidebar.about.url'],
          icon: 'question-circle',
        }],
        [
          {
            title: siteMessages['amp.measure.sidebar.reports.title'],
            to: siteMessages['amp.measure.sidebar.reports.url'],
            icon: 'signal',
          },
          {
            title: siteMessages['amp.measure.sidebar.measures.title'],
            to: siteMessages['amp.measure.sidebar.measures.url'] + '/',
            icon: 'th-list',
          },
        ],
        [{
          title: siteMessages['amp.measure.sidebar.downloads.title'],
          to: siteMessages['amp.measure.sidebar.downloads.url'],
          icon: 'download',
        }],
      ];
    }
    return (
      <Sidebar navs={sidebarNavs} />
    );
  }
}



