import React from 'react';
import {getData} from 'common/request.js';
import {ScoreGaugeLegend} from 'common/score-gauge';
import ClinicalSummary from './clinicalsummary';
import AruSummary from './arusummary';
import FormattedMessage from 'components/FormattedMessageMarkdown';
import {siteMessages} from 'src/utils/copy';
import './styles.scss';
import UrlParams from '../../../common/urlParams';
import { useOutletContext } from 'react-router-dom';


/* #########
# summary #
#########*/

class SummaryPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      measures: [],
      domainMap: {},
      selectedDomain: {
        id: null,
        name: null,
      },
      instances: [],
    };
  }
  updateMeasureList(props) {
    getData('/api/measures/list/', {
      duration: props.duration.id,
      product: props.product.id,
    }).then((data) => {
      let domainMap = {};
      // filter by function id
      data = data.filter(elt =>
        [1, 2, 3, 4, 5, 6].indexOf(elt.function_id) != -1
      );
      data.filter(elt => {
        if (domainMap.hasOwnProperty(elt.domain_id)) {
          return false;
        } else {
          // trickiness warning
          return (domainMap[elt.domain_id] = elt.domain__suite_name);
        }
      });
      let selectedDomain = {id: Object.keys(domainMap)[0]};
      selectedDomain.name = domainMap[selectedDomain.id];
      this.setState({measures: data, domainMap: domainMap});
      this.updateDomain(selectedDomain);
    });
  }
  updateDomain(domain) {
    console.log('domain-->',domain)
    this.setState({selectedDomain: domain});
  }
  updateInstances(props) {
    getData('/api/measures/orgsummary/', {
      duration: props.duration.id,
      product: props.product.id,
      organization: props.organization.id,
    }).then(data => {
        if (data.instances) {
          this.setState({instances: data.instances});
        }
    });
  }
  componentWillReceiveProps(nextProps) {
    if ((this.props.duration.id !== nextProps.duration.id) ||
    (this.props.organization.id !== nextProps.organization.id) ||
    (this.props.product.id !== nextProps.product.id)) {
      this.updateMeasureList(nextProps);
      this.updateInstances(nextProps);
    }
  }
  componentWillMount() {
    document.title = siteMessages[
      'amp.page.measures.reports.summary.page.title'
    ];
    this.updateMeasureList(this.props);
    this.updateInstances(this.props);
  }
  handleSelect=(selectedKey)=> {
    let kv = selectedKey.target.id.split('.');
    if (kv[0] === 'd') {
      this.updateDomain({id: kv[1], name: this.state.domainMap[kv[1]]});
    }
  }
  render() {
    let domainOptions = Object.keys(this.state.domainMap).map((elt, i) => {
      return (
        <li className={this.state.selectedDomain.id==elt?'is-active':''} key={i} ><a id={`d.${elt}`}>{this.state.domainMap[elt]}</a></li>
      );
    });
    let relevantMeasure = {};
    this.state.measures.filter(elt => {
      return elt.domain_id == this.state.selectedDomain.id;
    }).forEach(elt => {
      elt.fieldNames = elt.fields;
      relevantMeasure[elt.id] = elt;
    });

    let instances = [];
    this.state.instances.filter(elt => {
      return relevantMeasure[elt.measure_spec_id];
    }).map(elt => {
      let obj = {};
      Object.assign(obj, relevantMeasure[elt.measure_spec_id], elt);
      instances.push(obj);
      return elt;
    });
    let Summary;
    if (this.state.selectedDomain.name !== 'ARU') {
      Summary = ClinicalSummary;
    } else {
      Summary = AruSummary;
    }

    let summaryview;
    if (instances.length != 0) {
      summaryview = (
        <Summary instances={instances} />
      );
    } else {
      summaryview = (
        <div><FormattedMessage
          id='amp.page.measures.reports.summary.page.no.measure'
          textComponent='span'
        /></div>
      );
    }
    let renderElt = (
      <div>
        <UrlParams
          location={this.props.location}
          history={this.props.history}
          baseUrl="/measures/reports/summary"
          params={["organization", "duration", "product"]} />
        <h2 className="core-title"><FormattedMessage
          id='amp.page.measures.reports.summary.page.heading'
          textComponent='span'
        /></h2>
        <div className="summary-top-container">
          <div className='pull-right summary-legend-container'>
            <ScoreGaugeLegend percentiles sd lr />
          </div>
        </div>
          <div className="tabs is-boxed" style={{clear:'both'}} value={`d.${this.state.selectedDomain.id}`}
           onClick={this.handleSelect.bind(this)}>
           <ul>{domainOptions}</ul>
       </div>
        <div className="summary-bottom-container">
          {summaryview}
        </div>
      </div>
    );
    return (
      renderElt
    );
  }
}

const SummaryPageProxy = (props) => {
  const context = useOutletContext();
  return <SummaryPage {...props} {...context}/>;
};

export default SummaryPageProxy;




