import {createSelector} from 'reselect';

import {
  getContacts,
  getUsername,
} from 'state/selectors/single';


// contacts

export const getUserContact = createSelector(
  [getContacts, getUsername],
  (contacts, username) => contacts.find(c => c.username == username),
);

export const isPrimaryContact = createSelector(
  [getUserContact],
  user => user && user.primary_contact,
);
