/* eslint-disable react/no-deprecated */
import React from 'react';
import Highchart from 'react-highcharts';

export class BarChart extends React.Component {
  static defaultProps = {
    title: null,
    subtitle: null,
    organization: null,
    height: null,
    data: [],
    dataName: null,
    xAxis: {
      title: null,
    },
    yAxis: {
      title: null,
    },
    colors: {
      primary: '#00ff00',
      secondary: '#ff0000',
    },
    plotBands: [{
        from: null,
        to: null,
        color: null,
        axis: null,
    }],
    tooltip: 'Score: <b>{point.y:.2f}</b>',
  }
  render() {
    let config = {
        chart: {
            type: 'column',
            height: '40%'
        },
        title: {
            text: this.props.title,
        },
        subtitle: {
            text: this.props.subtitle,
        },
        xAxis: {
            title: {
                text: this.props.xAxis.title,
            },
        },
        yAxis: {
            // type: 'logarithmic',
            title: {
                text: this.props.yAxis.title,
            },
        },
        tooltip: {
            pointFormat: this.props.tooltip,
        },
        legend: {
          enabled: false,
        },
        credits: {
          enabled: false,
        },
        series: [{
            name: this.props.dataName,
            data: this.props.data,
            colors: this.props.dataColors,
            colorByPoint: !!this.props.dataColors,
            pointPadding: 0,
            groupPadding: 0,
            borderWidth: 0.1,
        }],
    };

    // plot bands -- the shaded sections of the graph
    let xPlotBands = [];
    let yPlotBands = [];
    this.props.plotBands.forEach(elt => {
        if (elt.axis === 'x') {
            xPlotBands.push(elt);
        }
        if (elt.axis === 'y') {
            xPlotBands.push(elt);
        }
    });
    config.xAxis.plotBands = xPlotBands;
    config.yAxis.plotBands = yPlotBands;

    // label each datapoint (only if there isn't too much)
    if (this.props.data.length < 20) {
      let dataLabels = {
          enabled: true,
          rotation: 0,
          color: '#FFFFFF',
          align: 'right',
          format: '{point.y:.1f}', // one decimal
          y: 10, // 10 pixels down from the top
          style: {
              fontSize: '13px',
              fontFamily: 'Verdana, sans-serif',
          },
      };
      config.series = config.series.map(elt => {
        elt.dataLabels = dataLabels;
        return elt;
      });
    }
    return (
      <div>
        {this.props.data.length === 0 && <div className="chart-no-data-message">No Data Available</div>}
        <Highchart style={this.props.style} config={config}></Highchart>
      </div>
    );
  }
};

export class ScoreRankChart extends React.Component {
  static defaultProps = {
    data: [],
    percentiles: [],
    score: 'score',
    group: null,
    labelPicker: null,
    colorPicker: null,
    colorPalette: {
      primary: '#000000',
      secondary: '#00b346',
      success: '#00b346',
      warning: '#f2eb3b',
      danger: '#cc1b00',
    },
  }
  render() {
    // data in:
    //   [{somegroup: someId, somescore: N}, {}...]
    // data out:
    //   [[labels[someId] || someId, N], []...]
    let data = this.props.data.slice();
    let colors = [];
    let labelPicker = this.props.labelPicker || function(x) {
      return x;
    };
    let colorPicker = this.props.colorPicker || function(x) {
      return x;
    };
    data = data.map(elt => {
      colors.push(this.props.colorPalette[colorPicker(
        elt[this.props.group],
        elt[this.props.score]
      )]);
      return [labelPicker(elt[this.props.group]), elt[this.props.score]];
    });
    // inherit props we don't use
    let newProps = Object.assign({}, this.props);
    let thisProps = {
      data: null,
      score: null,
      group: null,
      labels: null,
      colorLabels: null,
      colorPalette: null,
    };
    Object.keys(thisProps).forEach(key => {
      delete newProps[key];
    });
    return (
      <BarChart
        title="Statewide Comparison"
        subtitle={`N = ${data.length}`}
        plotBands={[{from: 400000, to: 500000, color: '#0000ff', axis: 'y'}]}
        dataColors={colors}
        data={data}
        {...newProps} />
    );
  }
};
