import {createSelector} from 'reselect';
export const getMeasuresState = state => state;
export const getDomainList = state => state.domainList;
export const getMeasureList = state => state.measureList;
export const getSelectedDomain = (state, props) => props.selectedDomain;

export const getRelevantMeasureList = createSelector(
  [getMeasureList, getSelectedDomain],
  (measureList, domain) => measureList
    .filter(measure => measure.domain_id == domain.id)
);
