import React from 'react';

import FormattedMessage from 'components/FormattedMessageMarkdown';

const Loading = props => {
  return (
    <div className="loading-indicator">
      <b>
        <i className="fas fa-spinner fa-pulse fa-2x"></i>
        <FormattedMessage id='amp.data.loading.text'
          textComponent='span'
        />
      </b>
    </div>
  );
};

export default Loading;
