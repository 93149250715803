import {call, put, select, takeLatest} from 'redux-saga/effects';

import {getData} from 'common/request';

import {
  UPDATE_ORGANIZATION,
  requestOrganizationSurvey,
  requestOrganizationSurveyFailure,
  updateOrganizationSurvey,
  editOrganizationSurveySuccess,
  editOrganizationSurveyFailure,
  updateOrganizationSurveyCategory,
} from 'state/actions';

import {
  REQUEST_ORGANIZATION_SURVEY,
  EDIT_ORGANIZATION_SURVEY,
  EDIT_ORGANIZATION_SURVEY_SUCCESS,
  UPDATE_ORGANIZATION_SURVEY_CATEGORY,
} from 'state/constants';

import {
  getUserOrganization,
  getOrganizationSurveyCategory,
  getOrganizationSurveyCategories,
} from 'state/selectors';

export default function* () {
  yield takeLatest(REQUEST_ORGANIZATION_SURVEY,
    executeRequestOrganizationSurvey);
  yield takeLatest(EDIT_ORGANIZATION_SURVEY,
    executeEditOrganizationSurvey);
  yield takeLatest(EDIT_ORGANIZATION_SURVEY_SUCCESS,
    triggerRequestOrganizationSurvey);
  yield takeLatest([
    UPDATE_ORGANIZATION,
    UPDATE_ORGANIZATION_SURVEY_CATEGORY,
  ], triggerRequestOrganizationSurvey);
  yield takeLatest(UPDATE_ORGANIZATION,
    resetOrganizationSurveyCategory);
}

function* executeRequestOrganizationSurvey({organization, category}) {
  const resp = yield call(getData, '/api/organization/survey/',
    {organization, category});
  if (resp.response === 'error') {
    yield put(requestOrganizationSurveyFailure(organization, category));
    return;
  }
  yield put(updateOrganizationSurvey(organization, category, resp.survey));
}

function* executeEditOrganizationSurvey({organization, category, survey}) {
  const resp = yield call(getData, '/api/organization/survey/edit/',
    {organization, category, survey});
  if (resp.response === 'error') {
    yield put(editOrganizationSurveyFailure(organization, category));
    return;
  }
  yield put(editOrganizationSurveySuccess(organization, category));
}

function* triggerRequestOrganizationSurvey() {
  const organization = yield select(getUserOrganization);
  const category = yield select(getOrganizationSurveyCategory);
  if (organization) {
    yield put(requestOrganizationSurvey(organization.id, category.id));
  }
}

function* resetOrganizationSurveyCategory() {
  const category = yield select(getOrganizationSurveyCategory);
  const categories = yield select(getOrganizationSurveyCategories);
  if (!categories.find(c => c.id == category.id)) {
    yield put(updateOrganizationSurveyCategory(categories[0]));
  }
}
