import React from 'react';

export default class DownloadButton extends React.Component {
    constructor (props){
        super(props);

        this.state = {
            downloadIsInProgress: false
        };
    }

    download = this.props.onClick ?
        () => this.props.onClick(this.handleClick.bind(this)) :
        () => this.handleClick(this.props.href);

    getFileName(request){
        const contentDisposition = request.getResponseHeader('Content-Disposition');

        if (contentDisposition) {
            return contentDisposition.split("filename=")[1].replace(/^"(.+(?="$))"$/, '$1');
        }

        let filename = request.responseURL.split('/').pop();

        if (filename.indexOf('?') !== -1){
            filename = filename.substr(0, filename.indexOf('?'));
        }

        return filename;
    }

    handleClick(href){
        if (!href) {
            this.setState({
                downloadIsInProgress: false
            });

            return;
        }

        let request = new XMLHttpRequest();

        request.addEventListener('readystatechange', (() => {
            if (request.readyState === 4) {
                this.setState({
                    downloadIsInProgress: false
                });

                if (request.status !== 200) {
                    this.props.onDownloadFailed();
                    return;
                }

                if (request.response) {
                    const blob = URL.createObjectURL(request.response);

                    this.setState({
                        href: blob,
                        download: this.getFileName(request)
                    });

                    this.fileUpload.click();
                }
            }
        }));

        request.responseType = 'blob';

        request.open('get', href);

        request.send();
    }

    render() {
        return (
            <div className={`download-button ${this.state.downloadIsInProgress && 'download-progress'}`}>
                <a className={`${(this.props.kind ? this.props.kind + '-btn btn' : 'btn')} ${this.props.className} ${this.state.downloadIsInProgress && 'customDisabled'}`}
                    onClick={() => {
                        if (this.state.downloadIsInProgress) {
                            return;
                        }

                        this.setState({
                            downloadIsInProgress: true
                        });

                        this.download();
                    }}>
                           { this.state.downloadIsInProgress &&
                           <div><i className="fas fa-spinner fa-pulse fa-2x"></i></div> }
                            <div className="button-text">{this.props.children}</div>
                        </a>
                <a style={{ display: "none" }} href={this.state.href} download={this.state.download} ref={a => this.fileUpload = a}>...</a>
            </div>
        );
    }
}