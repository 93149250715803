
import React from 'react';
import { marked } from 'marked';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './styles.scss';
import { siteMessages } from 'src/utils/copy';

let TagItem = (props) => {
  return (
    <span className="tag is-dark">
      {props.children}
    </span>
  );
};
let DangerTagItem = (props) => {
  return (
    <span
      className="tag is-danger"
      title="This measure's data has known errors with revisions pending."
    >
      {props.children}
    </span>
  );
};


class MeasureHeader extends React.Component {
  render() {
    let tagList = this.props.measure.tags || [];
    let tags = tagList.map((tag, ix) => {
      if (tag === 'Revisions Pending') {
        return <DangerTagItem key={`tag${ix}`}>{tag} <FontAwesomeIcon icon="exclamation-triangle" /> </DangerTagItem>;
      }
      return <TagItem key={`tag${ix}`}>{tag}</TagItem>;
    }
    );

    let reportedHigherBetter;
    if (this.props.measure.is_reported_higher_better === true) {
      reportedHigherBetter = (
        <TagItem>
          {siteMessages['amp.measures.header.higher.text']}
          <FontAwesomeIcon icon="arrow-circle-up" />
        </TagItem>
      );
    } else {
      reportedHigherBetter = (
        <TagItem>
          {siteMessages['amp.measures.header.lower.text']}
          <FontAwesomeIcon icon="arrow-circle-down" />
        </TagItem>
      );
    }
    return (
      <div style={this.props.style} >
        <h3>{this.props.measure.name_full}</h3>
        <div className="measure-header-tags tags">
          {reportedHigherBetter}
          {tags}
        </div>
        <div dangerouslySetInnerHTML={{
          __html: marked(this.props.measure.description),
        }} />
      </div>
    );
  }
}

export default MeasureHeader;
