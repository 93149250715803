import contactsReducer from './contacts/reducers';
import configReducer from './config/reducers';
import contractsReducer from './contracts/reducers';
import organizationProfileReducer from './organization-profile/reducers';
import organizationSurveyReducer from './organization-survey/reducers';
import measuresReducer from './measures/reducers';
import userReducer from './user/reducers';

export default {
  config: configReducer,
  contacts: contactsReducer,
  contracts: contractsReducer,
  organizationProfile: organizationProfileReducer,
  organizationSurvey: organizationSurveyReducer,
  measures: measuresReducer,
  user: userReducer
};
