import {call, put, select, takeEvery, takeLatest} from 'redux-saga/effects';

import {getData} from 'common/request';

import {
  getUserOrganizations,
  getUserDuration,
  getContractHps,
  getContractPos,
} from 'state/selectors';
import {ORGANIZATION_HP} from 'state/constants';

import {
  REQUEST_CONTRACT_LIST,
  requestContractList,
  requestContractListFailure,
  updateContractList,

  REQUEST_CONTRACT_ORGANIZATIONS,
  requestContractOrganizationsFailure,
  updateContractOrganizations,

  REQUEST_ORGANIZATION_PRODUCTS,
  requestOrganizationProductsFailure,
  updateOrganizationProducts,

  ADD_CONTRACT,
  ADD_CONTRACT_SUCCESS,
  addContractSuccess,
  addContractFailure,
  EDIT_CONTRACT,
  EDIT_CONTRACT_SUCCESS,
  editContractSuccess,
  editContractFailure,
  REMOVE_CONTRACT,
  REMOVE_CONTRACT_SUCCESS,
  removeContractSuccess,
  removeContractFailure,
  CONFIRM_CONTRACT,
  CONFIRM_CONTRACT_SUCCESS,
  confirmContractSuccess,
  confirmContractFailure,
  REJECT_CONTRACT,
  REJECT_CONTRACT_SUCCESS,
  rejectContractSuccess,
  rejectContractFailure,
} from './actions';

export default function* () {
  yield takeLatest(REQUEST_CONTRACT_LIST, executeContractListRequest);
  yield takeLatest(REQUEST_CONTRACT_ORGANIZATIONS,
    executeContractOrganizationsRequest);
  yield takeEvery(REQUEST_ORGANIZATION_PRODUCTS,
    executeOrganizationProductsRequest);
  yield takeLatest(ADD_CONTRACT, executeAddContract);
  yield takeLatest(EDIT_CONTRACT, executeEditContract);
  yield takeLatest(REMOVE_CONTRACT, executeRemoveContract);
  yield takeLatest(CONFIRM_CONTRACT, executeConfirmContract);
  yield takeLatest(REJECT_CONTRACT, executeRejectContract);
  yield takeLatest([
    ADD_CONTRACT_SUCCESS,
    EDIT_CONTRACT_SUCCESS,
    REMOVE_CONTRACT_SUCCESS,
    CONFIRM_CONTRACT_SUCCESS,
    REJECT_CONTRACT_SUCCESS,
  ], triggerContractListRequest);
}

function* triggerContractListRequest({organization}) {
  const duration = yield select(getUserDuration);
  yield put(requestContractList(organization, duration));
}

function* executeContractListRequest({organization, duration}) {
  const organizations = yield select(getUserOrganizations);
  const rawType = organizations.find(o => o.id == organization).org_type;
  const orgType = rawType == ORGANIZATION_HP ? 'hp' : 'po';
  const resp = yield call(getData, `/api/contracts/list/${orgType}/`,
    {organization, duration});
  if (resp.response === 'error') {
    yield put(requestContractListFailure(organization, duration, null));
    return;
  }
  yield put(updateContractList(organization, duration, resp.contracts));
}

function* executeContractOrganizationsRequest({organizationType}) {
  const getContractOrgs = organizationType == ORGANIZATION_HP ?
    getContractPos : getContractHps;
  const orgs = yield select(getContractOrgs);
  if (orgs.length != 0) {
    // organization data only needs to be fetched once
    return;
  }
  const orgType = organizationType == ORGANIZATION_HP ? 'pos' : 'hps';
  const resp = yield call(getData, `/api/${orgType}/`);
  if (resp.response === 'error') {
    yield put(requestContractOrganizationsFailure(organizationType, null));
    return;
  }
  yield put(updateContractOrganizations(organizationType, resp[orgType]));
}

function* executeOrganizationProductsRequest({organization}) {
  const resp = yield call(getData, '/api/products/', {hp: organization});
  if (resp.response === 'error') {
    yield put(requestOrganizationProductsFailure(null));
    return;
  }
  yield put(updateOrganizationProducts(organization, resp));
}

function* executeAddContract({
  organization,
  hp,
  po,
  product,
  duration,
  notes,
  options,
}) {
  const resp = yield call(getData, '/api/contracts/create/', {
    organization,
    hp,
    po,
    product,
    duration,
    notes,
    options,
  });
  if (resp.response === 'error') {
    yield put(addContractFailure(
      organization,
      hp,
      po,
      product,
      duration,
      notes,
      options,
    ));
    return;
  }
  if (resp.response === 'success') {
    // Only do the success actions if really a success.
    yield put(addContractSuccess(
      organization,
      hp,
      po,
      product,
      duration,
      notes,
      options,
    ));
  }
}


function* executeEditContract({
  contract,
  organization,
  hp,
  po,
  product,
  duration,
  notes,
  options,
}) {
  const resp = yield call(getData, '/api/contracts/edit/', {
    contract,
    organization,
    hp,
    po,
    product,
    duration,
    notes,
    options,
  });
  if (resp.response === 'error') {
    yield put(editContractFailure(
      contract,
      organization,
      hp,
      po,
      product,
      duration,
      notes,
      options,
    ));
    return;
  }
  if (resp.response === 'success') {
    // Only do the success actions if really a success.
    yield put(editContractSuccess(
      contract,
      organization,
      hp,
      po,
      product,
      duration,
      notes,
      options,
    ));
  }
}

function* executeRemoveContract({contract, organization}) {
  const resp = yield call(
    getData, '/api/contracts/delete/', {contract, organization});
  if (resp.response === 'error') {
    yield put(removeContractFailure(contract, organization));
    return;
  }
  yield put(removeContractSuccess(contract, organization));
}

function* executeConfirmContract({contract, organization}) {
  const resp = yield call(
    getData, '/api/contracts/confirm/', {contract, organization});
  if (resp.response === 'error') {
    yield put(confirmContractFailure(contract, organization));
    return;
  }
  yield put(confirmContractSuccess(contract, organization));
}

function* executeRejectContract({contract, organization}) {
  const resp = yield call(
    getData, '/api/contracts/reject/', {contract, organization});
  if (resp.response === 'error') {
    yield put(rejectContractFailure(contract, organization));
    return;
  }
  yield put(rejectContractSuccess(contract, organization));
}
