/* eslint-disable react/no-deprecated */
/* eslint-disable no-unused-vars */
import React from 'react';
import { getData } from 'common/request.js';
import { ScoreGaugeLegend } from 'common/score-gauge';
import { ScoreRankChart } from 'common/bar-chart';
import { HistogramLineChart } from 'common/line-chart';

import FormattedMessage from 'components/FormattedMessageMarkdown';
import { siteMessages } from 'src/utils/copy';

// function_id: [min, max, number of bins]
let fnHistogramCuts = {}
// 6: [0, 150000, 30],
// 28: [0, 150000, 30],
// 29: [0, 150000, 30],
// 30: [10, 75, 13],
// 31: [10, 75, 13],
// 32: [10, 75, 13],
// 33: [35, 170, 9],
// 34: [35, 170, 9],
// 35: [35, 170, 9],
// 36: [0, 5, 10],
// 37: [0, 30, 10],
// 38: [0, 60, 12],
// 39: [0, 10, 10],
// 40: [0, 25, 25],
// 41: [0, 25, 25],
// 42: [0, 25, 25],
// 43: [0, 25, 25],
// 44: [0, 25, 25],
// 45: [0, 25, 25],
// 46: [0, 25, 25],
// 47: [0, 25, 25],
// 48: [0, 25, 25],
// 49: [0, 25, 25],
// 50: [0, 25, 25],
// 51: [0, 25, 25],
// 52: [80, 200, 8],
// 53: [80, 200, 8],
// 54: [80, 200, 8],
// 55: [0, 100, 20],
// 56: [0, 100, 20],
// 57: [0, 10, 10],
// 61: [0, 8000, 16],
// 62: [0, 8000, 16],
// 63: [0, 8000, 16],
// 64: [0, 8000, 16],
// 65: [-100, 100, 16],
// 66: [-100, 100, 16]
// };

// TODO: This needs to be updated, but I'm not sure what the intent
// is.
let suppressedStatewide = [28, 29, 31, 34, 41, 44, 47, 50, 53, 62];

let round = num => {
  let n = num * 100;
  n = Math.round(n);
  return n / 100;
};

class MeasureVisualization extends React.Component {
  state = {
    statewide: {},
    plan_specific: {},
    loading: true,
  };
  updateMeasureView(obj) {
    if (obj.measure && obj.duration && obj.organization) {
      this.setState({ loading: true, statewide: {}, plan_specific: {} });
      getData('/api/measures/dp/statewidecompare/', {
        duration: obj.duration.id,
        measure: obj.measure.id,
        organization: obj.organization.id,
      }).then((data) => {
        this.setState({ plan_specific: data, loading: false });
      });
      getData('/api/measures/dp/statewidecompare2/', {
        duration: obj.duration.id,
        measure: obj.measure.id,
        organization: obj.organization.id,
      }).then((data) => {
        if (data.response === 'error') {
          this.setState({ loading: false });
        } else {
          this.setState({ statewide: data, loading: false });
        }
      });
    }
  }
  componentWillMount() {
    this.updateMeasureView(this.props);
  }
  componentWillReceiveProps(nextProps) {
    this.updateMeasureView(nextProps);
  }
  handleSelect(selectedKey) {
  }
  shouldRenderChart2() {
    if (suppressedStatewide.indexOf(this.props.measure.function_id) != -1) {
      return false;
    } else if (this.props.measure.abbr === 'TCC_GEO_RISK_ADJ') {
      return false;
    } else if (
      this.props.organization.org_type === 0 &&
      this.props.measure.domain__suite_name === 'ARU'
    ) {
      // See https://phabricator.iha.org/T836 point 2.
      return false;
    }
    return true;
  }
  shouldShowStatewideScores() {
    return true;
  }
  render() {
    let renderElt = null;
    if (this.state.loading === true) {
      renderElt = (<p><b><FormattedMessage
        id='amp.page.measures.measures.hp.visualization.loading'
        textComponent='span'
      />
      </b></p>);
    } else if (Object.keys(this.state.statewide) == 0) {
      renderElt = (<p><b><FormattedMessage
        id='amp.page.measures.measures.hp.visualization.nodata'
        textComponent='span'
      />
      </b></p>);
    } else {
      let statewideLabels = {};
      let statewideColorLabels = {};
      this.state.statewide.scores.forEach(elt => {
        statewideLabels[elt.organization_id] = elt.organization__name;
      });
      let colorPicker = (p50, p75) => {
        let oid = this.props.organization.id;
        let hib = this.props.measure.is_reported_higher_better;
        return (orgid, num) => {
          if (orgid == oid) {
            return 'primary';
          } else if ((num < p50 && hib) || (num > p50 && !hib)) {
            return 'danger';
          } else if ((num < p75 && hib) || (num > p75 && !hib)) {
            return 'warning';
          } else {
            return 'success';
          }
        };
      };
      let labelPicker = () => {
        let oid = this.props.organization.id;
        let oname = this.props.organization.name;
        return (num) => {
          if (num == oid) {
            return oname;
          } else {
            return statewideLabels[num];
          }
        };
      };
      let p50 = this.state.statewide.percentiles[2] || null;
      let p75 = this.state.statewide.percentiles[3] || null;
      let scoreName = this.props.measure.fields.standard.find(elt => {
        return Object.keys(elt)[0] == this.props.measure.function__logic.score;
      });
      scoreName = scoreName[this.props.measure.function__logic.score];

      if (this.state.statewide.scores) {
        let data = [];
        if (this.shouldShowStatewideScores()) {
          data.unshift({
            name: siteMessages['amp.page.measures.measures.hp.statescore.name'],
            data: this.state.statewide.scores,
          });
        }
        if (this.state.plan_specific.scores) {
          data.unshift({
            name: this.props.organization.name,
            data: this.state.plan_specific.scores,
          });
        }
        let Chart1;
        let Chart2;
        let Legend;
        if ([].indexOf(this.props.measure.function_id) != -1) {
          Chart1 = <div></div>;
        } else {
          let hCuts = fnHistogramCuts[this.props.measure.function_id];
          if (hCuts) {
            Chart1 = <div>
              <HistogramLineChart
                title={this.props.measure.name_full}
                subtitle={siteMessages[
                  'amp.page.measures.measures.hp.hcuts.chart1.subtitle'
                ]}
                data={data}
                binMin={hCuts[0]}
                binMax={hCuts[1]}
                bins={hCuts[2]}
                suffix={this.props.measure.function__suffix}
                xAxis={{ title: scoreName }}
                style={{ width: '99%' }}
              />
            </div>;
          } else {
            Chart1 = <div>
              <HistogramLineChart
                title={this.props.measure.name_full}
                subtitle={siteMessages[
                  'amp.page.measures.measures.hp.chart1.subtitle'
                ]}
                data={data}
                suffix={this.props.measure.function__suffix}
                xAxis={{ title: scoreName }}
                style={{ width: '99%' }}
              />
            </div>;
          }
        }
        Chart2 = <div></div>;
        Legend = <div></div>;
        if (this.shouldRenderChart2()) {
          Chart2 = <ScoreRankChart
            title={this.props.measure.name_full}
            subtitle={siteMessages[
              'amp.page.measures.measures.hp.chart2.subtitle'
            ]}
            score='score'
            group='organization_id'
            labelPicker={labelPicker()}
            colorPicker={colorPicker(p50, p75)}
            data={this.state.statewide.scores}
            xAxis={{ title: 'Organization Ranking' }}
            yAxis={{ title: scoreName }}
            style={{ width: '99%' }}
          />;
          Legend = <div className='container'>
            <div>
              <ScoreGaugeLegend percentiles={true} />
            </div>
          </div>;
        }
        renderElt =
          <div>
            {Chart1}
            {Legend}
            {Chart2}
          </div>;
      } else {
        renderElt = (<div><b><FormattedMessage
          id='amp.page.measures.measures.hp.visualization.loading'
          textComponent='span'
        />
        </b></div>);
      }
    }
    return (
      renderElt
    );
  }
};

export default MeasureVisualization;
