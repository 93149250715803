import React from 'react';
import {connect} from 'react-redux';
import ReactNotifications from 'react-notification-system-redux';

/*
Style override docs:
  https://github.com/igorprado/react-notification-system/blob/master/src/styles.js
*/
const style = {
  Containers: {
    DefaultStyle: {
      marginTop: '6rem',
    },
  },
};

const Notifications = props => (
  <div id='notifications'>
    <ReactNotifications
      notifications={props.notifications}
      style={style}/>
  </div>
);

const mapStateToProps = state => {
  return {
    notifications: state.notifications,
  };
};

export default connect(mapStateToProps)(Notifications);
