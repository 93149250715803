/* eslint-disable camelcase */
/* eslint-disable react/jsx-key */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable max-len */
import { siteMessages } from 'src/utils/copy';

import {
  PRODUCT_ACO,
} from '../../../state/user/constants';

export const downloadList = (organization, filters) => {
  let qualityTabElts = [];
  let efficiencyTabElts = [];
  let opaTabElts = [];

  // eslint-disable-next-line max-len
  let urlEnding = 'organization=' + organization.id + '&duration=' + filters.duration.id + '&product=' + filters.product.id;

  /* NOTE: With the addition of Medi-Cal (Medicaid) product data, ARU/TCOC
  reports need to be take into account product_id, since these data cannot
  be assumed to be from Commercial products anymore. Since urlEnding changes
  below, we preserve its original state that includes the product parameter,
  using the variable: urlEndingOrigWithProduct. */
  let urlEndingOrigWithProduct = urlEnding;

  let qualityThresholdLink;
  let effiencyThresholdLink;

  if (filters.duration.id == 12) {
    qualityThresholdLink = siteMessages['amp.measures.downloads.quality.threshold.link12'];
  }
  if (filters.duration.id == 13) {
    qualityThresholdLink = siteMessages['amp.measures.downloads.quality.threshold.link13'];
    effiencyThresholdLink = siteMessages['amp.measures.downloads.efficiency.threshold.link13'];
  }
  if (filters.duration.id == 14) {
    qualityThresholdLink = siteMessages['amp.measures.downloads.quality.threshold.link14'];
    effiencyThresholdLink = siteMessages['amp.measures.downloads.efficiency.threshold.link14'];
  }
  if (filters.duration.id == 15) {
    qualityThresholdLink = siteMessages['amp.measures.downloads.quality.threshold.link15'];
    effiencyThresholdLink = siteMessages['amp.measures.downloads.efficiency.threshold.link15'];
  }
  if (filters.duration.id == 16) {
    qualityThresholdLink = siteMessages['amp.measures.downloads.quality.threshold.link16'];
    effiencyThresholdLink = siteMessages['amp.measures.downloads.efficiency.threshold.link16'];
  }
  if (filters.duration.id == 17) {
    qualityThresholdLink = siteMessages['amp.measures.downloads.quality.threshold.link17'];
    effiencyThresholdLink = siteMessages['amp.measures.downloads.efficiency.threshold.link17'];
  }
  if (filters.duration.id == 18) {
    qualityThresholdLink = siteMessages['amp.measures.downloads.quality.threshold.link18'];
    effiencyThresholdLink = siteMessages['amp.measures.downloads.efficiency.threshold.link18'];
  }

  // Quality
  let aruComingSoon = siteMessages['amp.measures.downloads.aru.soon.content'];

  // Resource Use: ARU and TCC
  let efficiencyDelayNotice = siteMessages['amp.measures.downloads.efficency.notice.content'];


  let efficiencyStatewideThresholds = siteMessages['amp.measures.downloads.efficency.statewide.threshold.content'];
  let hpSpecificThresholds = siteMessages['amp.measures.downloads.hp.specific.threshold.content'];

  let hpNotes = '';
  let poNotes = '';


  let opaReportCardName = siteMessages['amp.measures.downloads.opa.report.card.name'];

  let opaReportCardDesc = siteMessages['amp.measures.downloads.opa.reportcard.desc'];
  let opaReportCardDesc16 = siteMessages['amp.measures.downloads.opa.reportcard.desc16'];
  let opaReportCardDesc17 = siteMessages['amp.measures.downloads.opa.reportcard.desc17'];

  let opaReportCardLink = siteMessages['amp.measures.downloads.opa.reportcard.link'];
  let opaReportCardLink15 = siteMessages['amp.measures.downloads.opa.reportcard.link15'];
  let opaReportCardLink16 = siteMessages['amp.measures.downloads.opa.reportcard.link16'];
  let opaReportCardLink17 = siteMessages['amp.measures.downloads.opa.reportcard.link17'];

  let opaIntro = siteMessages['amp.measures.downloads.opa.intro.content'];
  let opaIntro16 = siteMessages['amp.measures.downloads.opa.intro16.content'];
  let opaIntro17 = siteMessages['amp.measures.downloads.opa.intro17.content'];

  let opaPOFooter = siteMessages['amp.measures.downloads.opaPO.footer.content'];
  let opaHPFooter = siteMessages['amp.measures.downloads.opaHP.footer.content'];

  let opaMAReportCardDesc = siteMessages['amp.measures.downloads.opaMAcard.content'];
  let opaMAReportCardLink = siteMessages['amp.measures.downloads.opaMAcard.link'];
  let opaMAReportCardLink15 = siteMessages['amp.measures.downloads.opaMAcard.link15'];

  let opaMAIntro = siteMessages['amp.measures.downloads.opaMA.intro.content'];
  let opaMAPOFooter = siteMessages['amp.measures.downloads.opaMAPO.footer.content'];
  let opaMAHPFooter = siteMessages['amp.measures.downloads.opaMAHP.footer.content'];

  let disableARURatings = false;
  let disableOPARatings = false;
  if (filters.product.id == 1) {
    if (filters.duration.id == 17) {
      opaTabElts.push({
        name: ``,
        description: `${opaIntro17}`,
        metadata: {
          link: undefined,
          item: undefined,
          suite: `OPA Ratings`,
          direct: false,
          s3: false,
          orgSpecificResult: true,
        },
      });
    } else if (filters.duration.id == 16) {
      opaTabElts.push({
        name: ``,
        description: `${opaIntro16}`,
        metadata: {
          link: undefined,
          item: undefined,
          suite: `OPA Ratings`,
          direct: false,
          s3: false,
          orgSpecificResult: true,
        },
      });
    } else {
      opaTabElts.push({
        name: ``,
        description: `${opaIntro}`,
        metadata: {
          link: undefined,
          item: undefined,
          suite: `OPA Ratings`,
          direct: false,
          s3: false,
          orgSpecificResult: true,
        },
      });
    }
  } else {
    opaTabElts.push({
      name: ``,
      description: `${opaMAIntro}`,
      metadata: {
        link: undefined,
        item: undefined,
        suite: `OPA Ratings`,
        direct: false,
        s3: false,
        orgSpecificResult: true,
      },
    });
  }
  let qualityScores;
  let acoDownloadItem;


  if (filters.duration.id == 14) {
    let qDesc = siteMessages['amp.measures.downloads.qDesc.content14'];
    qualityScores = ({
      name: siteMessages['amp.measures.downloads.quality.score.name14'],
      description: `${qDesc}`,
      metadata: {
        link: siteMessages['amp.measures.downloads.quality.score.link14'],
        item: undefined,
        suite: `Quality`,
        direct: true,
        s3: false,
        orgSpecificResult: false,
      },
    });

    let opaDownloadItem;
    if (filters.product.id == 1) {
      opaDownloadItem = ({
        name: `${opaReportCardName}`,
        description: `${opaReportCardDesc}`,
        metadata: {
          link: `${opaReportCardLink}`,
          item: undefined,
          suite: `OPA Ratings`,
          direct: true,
          s3: false,
          orgSpecificResult: true,
        },
      });
    } else {
      opaDownloadItem = ({
        name: `${opaReportCardName}`,
        description: `${opaMAReportCardDesc}`,
        metadata: {
          link: `${opaMAReportCardLink}`,
          item: undefined,
          suite: `OPA Ratings`,
          direct: true,
          s3: false,
          orgSpecificResult: true,
        },
      });
    }
    opaTabElts.push(opaDownloadItem);
  } else if (filters.duration.id == 15) {
    let qDesc = siteMessages['amp.measures.downloads.qDesc.content15'];
    qualityScores = ({
      name: siteMessages['amp.measures.downloads.quality.score.name15'],
      description: `${qDesc}`,
      metadata: {
        link: undefined,
        item: siteMessages['amp.measures.downloads.quality.score.item15'],
        suite: `Quality`,
        direct: false,
        s3: true,
        orgSpecificResult: false,
      },
    });

    let opaDownloadItem;
    if (filters.product.id == 1) {
      opaDownloadItem = ({
        name: `${opaReportCardName}`,
        description: `${opaReportCardDesc}`,
        metadata: {
          link: `${opaReportCardLink15}`,
          item: undefined,
          suite: `OPA Ratings`,
          direct: true,
          s3: false,
          orgSpecificResult: true,
        },
      });
    } else {
      opaDownloadItem = ({
        name: `${opaReportCardName}`,
        description: `${opaMAReportCardDesc}`,
        metadata: {
          link: `${opaMAReportCardLink15}`,
          item: undefined,
          suite: `OPA Ratings`,
          direct: true,
          s3: false,
          orgSpecificResult: true,
        },
      });
    }
    opaTabElts.push(opaDownloadItem);
  } else if (filters.duration.id == 16) {
    let qDesc = siteMessages['amp.measures.downloads.qDesc.content16'];
    qualityScores = ({
      name: siteMessages['amp.measures.downloads.quality.score.name16'],
      description: `${qDesc}`,
      metadata: {
        link: undefined,
        item: siteMessages['amp.measures.downloads.quality.score.item16'],
        suite: `Quality`,
        direct: false,
        s3: true,
        orgSpecificResult: false,
      },
    });

    let opaDownloadItem;
    if (filters.product.id == 1) {
      opaDownloadItem = ({
        name: `${opaReportCardName}`,
        description: `${opaReportCardDesc16}`,
        metadata: {
          link: `${opaReportCardLink16}`,
          item: undefined,
          suite: `OPA Ratings`,
          direct: true,
          s3: false,
          orgSpecificResult: true,
        },
      });
      // eslint-disable-next-line no-constant-condition
    } else if (false) {
      opaDownloadItem = ({
        name: `${opaReportCardName}`,
        description: `${opaMAReportCardDesc}`,
        metadata: {
          link: `${opaMAReportCardLink16}`,
          item: undefined,
          suite: `OPA Ratings`,
          direct: true,
          s3: false,
          orgSpecificResult: true,
        },
      });
    }
    if (opaDownloadItem) {
      opaTabElts.push(opaDownloadItem);
    } else {
      disableOPARatings = true;
    }
  } else if (filters.duration.id == 17) {
    // Updated for MY2017, data now available.
    let qDesc = siteMessages['amp.measures.downloads.qDesc.content17'];
    qualityScores = ({
      name: siteMessages['amp.measures.downloads.quality.score.name17'],
      description: `${qDesc}`,
      metadata: {
        link: undefined,
        item: siteMessages['amp.measures.downloads.quality.score.item17'],
        suite: `Quality`,
        direct: false,
        s3: true,
        orgSpecificResult: false,
      },
    });


    if (filters.ENABLE_ACO_DOWNLOAD_UPTO >= filters.duration.id && filters.product.id == PRODUCT_ACO) {
      let acoDesc = siteMessages['amp.measures.downloads.ACO.content_5_my17'];
      acoDownloadItem = ({
        name: siteMessages['amp.measures.downloads.ACO.score.name_5_my17'],
        description: `${acoDesc}`,
        metadata: {
          link: undefined,
          item: siteMessages['amp.measures.downloads.ACO.score.item_5_my17'],
          direct: false,
          s3: true,
          suite: `Quality`,
          orgSpecificResult: true,
        },
      });
    }

    let opaDownloadItem;
    if (filters.duration.id == 17) {
      if (filters.product.id == 1) {
        opaDownloadItem = ({
          name: `${opaReportCardName}`,
          description: `${opaReportCardDesc17}`,
          metadata: {
            link: `${opaReportCardLink17}`,
            item: undefined,
            suite: `OPA Ratings`,
            direct: true,
            s3: false,
            orgSpecificResult: true,
          },
        });
        // eslint-disable-next-line brace-style
      }
      // TODO(daishi): Enable this once we have an OPA Medicare Advantage download file.
      // eslint-disable-next-line no-constant-condition
      else if (false) {
        opaDownloadItem = ({
          name: `${opaReportCardName}`,
          description: `${opaMAReportCardDesc}`,
          metadata: {
            link: `${opaMAReportCardLink16}`,
            item: undefined,
            suite: `OPA Ratings`,
            direct: true,
            s3: false,
            orgSpecificResult: true,
          },
        });
      }
    }

    if (opaDownloadItem) {
      opaTabElts.push(opaDownloadItem);
    } else {
      disableOPARatings = true;
    }
  } else if (filters.duration.id == 18) {
    if (filters.ENABLE_ACO_DOWNLOAD_UPTO >= filters.duration.id && filters.product.id == PRODUCT_ACO) {
      let acoDesc = siteMessages['amp.measures.downloads.ACO.content_5_my18'];
      acoDownloadItem = ({
        name: siteMessages['amp.measures.downloads.ACO.score.name_5_my18'],
        description: `${acoDesc}`,
        metadata: {
          link: undefined,
          item: siteMessages['amp.measures.downloads.ACO.score.item_5_my18'],
          suite: `Quality`,
          direct: false,
          s3: true,
          orgSpecificResult: true,
        },
      });
    }
  }
  // The qcs/spm download is only available for the commercial and
  // Medicaid (Medi-Cal) products during and after MY2018
  let qcsDownloadHp;
  let qcsDownloadPo;
  if ((filters.product.id == 1) || (
    filters.product.id == 4 && filters.duration.id >= 18)
  ) {
    qcsDownloadHp = ({
      name: siteMessages['amp.measures.downloads.qcs.hp.name1'],
      description: siteMessages['amp.measures.downloads.hp.SPM.content'],
      metadata: {
        link: `/api/measures/dl/spm/?${urlEnding}`,
        item: undefined,
        direct: false,
        s3: false,
        suite: `Quality`,
        orgSpecificResult: true,
      },
    });
    qcsDownloadPo = ({
      name: siteMessages['amp.measures.downloads.qcs.po.name1'],
      description: siteMessages['amp.measures.downloads.po.SPM.content'],
      metadata: {
        link: `/api/measures/dl/spm/?${urlEnding}`,
        item: undefined,
        direct: false,
        s3: false,
        suite: `Quality`,
        orgSpecificResult: true,
      },
    });
  }

  if (organization.org_type == -1) {
    // Integrated Healthcare Association
  } else if (organization.org_type == 0) { // Health Plan
    urlEnding = 'organization=' + organization.id + '&duration=' + filters.duration.id;

    if (filters.ENABLE_ACO_DOWNLOAD_UPTO >= filters.duration.id && filters.product.id == PRODUCT_ACO) {
      let acoDesc = siteMessages['amp.measures.downloads.HPACO.content_5_my17'];
      acoDownloadItem = ({
        name: siteMessages['amp.measures.downloads.HPACO.score.name_5_my17'],
        description: `${acoDesc}`,
        metadata: {
          link: undefined,
          item: siteMessages['amp.measures.downloads.HPACO.score.item_5_my17'],
          suite: `Quality`,
          direct: false,
          s3: true,
          orgSpecificResult: true,
        },
      });
    }

    if (filters.ENABLE_ACO_DOWNLOAD_UPTO >= filters.duration.id && filters.product.id == PRODUCT_ACO && filters.duration.id == 18) {
      let acoDesc = siteMessages['amp.measures.downloads.HPACO.content_5_my18'];
      acoDownloadItem = ({
        name: siteMessages['amp.measures.downloads.HPACO.score.name_5_my18'],
        description: `${acoDesc}`,
        metadata: {
          link: undefined,
          item: siteMessages['amp.measures.downloads.HPACO.score.item_5_my18'],
          suite: `Quality`,
          direct: false,
          s3: true,
          orgSpecificResult: true,
        },
      });
    }

    qualityTabElts = [
      {
        name: siteMessages['amp.measures.downloads.qualityTab.PO.name'],
        description: siteMessages['amp.measures.downloads.hp.aggregated.content'],
        metadata: {
          link: `/api/measures/dl/agg/?${urlEnding}`,
          item: undefined,
          direct: false,
          s3: false,
          suite: `Quality`,
          orgSpecificResult: true,
        },
      },
      {
        name: siteMessages['amp.measures.downloads.qualityTab.plan.name'],
        description: siteMessages['amp.measures.downloads.hp.unaggregated.content'],
        metadata: {
          link: `/api/measures/dl/unagg/?${urlEnding}`,
          item: undefined,
          direct: false,
          s3: false,
          suite: `Quality`,
          orgSpecificResult: true,
        },
      },
    ];

    if (qcsDownloadHp) {
      qualityTabElts.push(qcsDownloadHp);
    }
    if (acoDownloadItem) {
      qualityTabElts.push(acoDownloadItem);
    }
    qualityTabElts.push({
      name: `MY ${filters.duration.id + 2000} Thresholds`,
      description: siteMessages['amp.measures.downloads.quality.statewide.content'],
      metadata: {
        link: `${qualityThresholdLink}`,
        item: undefined,
        direct: true,
        s3: false,
        suite: `Quality`,
        orgSpecificResult: false,
      },
    });

    let hp_spec_threshold;
    if (filters.duration.id == 14) {
      hp_spec_threshold = ({
        name: siteMessages['amp.measures.downloads.hpspec.threshold.name14'],
        description: `${efficiencyStatewideThresholds}`,
        metadata: {
          link: undefined,
          item: siteMessages['amp.measures.downloads.hpspec.threshold.item14'],
          direct: false,
          s3: true,
          suite: `Resource Use`,
          orgSpecificResult: false,
        },
      });
    }
    if (filters.duration.id == 15) {
      hp_spec_threshold = ({
        name: siteMessages['amp.measures.downloads.hpspec.threshold.name15'],
        description: `${efficiencyStatewideThresholds}`,
        metadata: {
          link: undefined,
          item: siteMessages['amp.measures.downloads.hpspec.threshold.item15'],
          direct: false,
          s3: true,
          suite: `Resource Use`,
          orgSpecificResult: false,
        },
      });
    }

    let hpAllResults;
    if (filters.ENABLE_HP_ARU_TCOC_DOWNLOAD) {
      // This enables us to keep this download button hidden
      // incase there is concern over sensitive data leak.
      hpAllResults = ({
        name: siteMessages['amp.measures.downloads.hp.allresult.name'],
        description: siteMessages['amp.measures.downloads.hp.efficency.all.content'],
        metadata: {
          link: `/api/measures/dl/aru/?${urlEndingOrigWithProduct}`,
          item: undefined,
          suite: `Resource Use`,
          direct: false,
          s3: false,
          orgSpecificResult: true,
        },
      });
    }

    efficiencyTabElts.push(hpAllResults);
    efficiencyTabElts.push(
      {
        name: siteMessages['amp.measures.downloads.efficiencyTab.improve.name'],
        description: siteMessages['amp.measures.downloads.hp.efficency.YY.content'],
        metadata: {
          link: `/api/measures/dl/aru-improvement/?${urlEndingOrigWithProduct}`,
          item: undefined,
          suite: `Resource Use`,
          direct: false,
          s3: false,
          orgSpecificResult: true,
        },
      },
      {
        name: `Plan-Aggregated Thresholds`,
        description: `${efficiencyStatewideThresholds}`,
        metadata: {
          link: `${effiencyThresholdLink}`,
          item: undefined,
          suite: `Resource Use`,
          direct: true,
          s3: false,
          orgSpecificResult: false,
        },
      },
    );
    if (hp_spec_threshold) {
      efficiencyTabElts.push(hp_spec_threshold);
    }
    if (filters.product.id == 1) {
      opaTabElts.push({
        name: ``,
        description: `${opaHPFooter}`,
        metadata: {
          link: undefined,
          item: undefined,
          suite: `OPA Ratings`,
          direct: false,
          s3: false,
          orgSpecificResult: true,
        },
      });
    } else {
      opaTabElts.push({
        name: ``,
        description: `${opaMAHPFooter}`,
        metadata: {
          link: undefined,
          item: undefined,
          suite: `OPA Ratings`,
          direct: false,
          s3: false,
          orgSpecificResult: true,
        },
      });
    }
  } else if (organization.org_type == 1) { // Physician Org
    let worksheet;
    let durationIdsThatHaveWorkSheet = [14, 15, 16, 17, 18];
    qualityTabElts = [
      {
        name: siteMessages['amp.measures.downloads.qualityTab.quality.name'],
        description: siteMessages['amp.measures.downloads.po.aggregated.content'],
        metadata: {
          link: `/api/measures/dl/agg/?${urlEnding}`,
          item: undefined,
          suite: `Quality`,
          direct: false,
          s3: false,
          orgSpecificResult: true,
        },
      },
      {
        name: siteMessages['amp.measures.downloads.qualityTab.unaggre.rates.name'],
        description: siteMessages['amp.measures.downloads.po.unaggregated.content'],
        metadata: {
          link: `/api/measures/dl/unagg/?${urlEnding}`,
          item: undefined,
          suite: `Quality`,
          direct: false,
          s3: false,
          orgSpecificResult: true,
        },
      },
      {
        name: `MY ${filters.duration.id + 2000} Thresholds`,
        description: siteMessages['amp.measures.downloads.quality.statewide.content'],
        metadata: {
          link: `${qualityThresholdLink}`,
          item: undefined,
          suite: `Quality`,
          direct: true,
          s3: false,
          orgSpecificResult: false,
        },
      },
    ];

    if (qcsDownloadPo) {
      qualityTabElts.push(qcsDownloadPo);
    }
    if (acoDownloadItem) {
      qualityTabElts.push(acoDownloadItem);
    }
    if (qualityScores) {
      qualityTabElts.push(qualityScores);
    }

    if (durationIdsThatHaveWorkSheet.indexOf(filters.duration.id) > -1) {
      worksheet = ({
        name: siteMessages['amp.measures.downloads.worksheet.name'],
        description: `${siteMessages['amp.measures.downloads.wDesc.content']}`,
        metadata: {
          link: undefined,
          item: `${filters.duration.id}-PO-Worksheet`,
          suite: `Quality`,
          direct: false,
          s3: true,
          orgSpecificResult: true,
        },
      });
    }
    if (worksheet) {
      qualityTabElts.push(worksheet);
    }
    efficiencyTabElts = [
      {
        name: siteMessages['amp.measures.downloads.effTab.po.allresult.name'],
        description: siteMessages['amp.measures.downloads.po.efficency.all.content'],
        metadata: {
          link: `/api/measures/dl/aru/?${urlEnding}`,
          item: undefined,
          suite: `Resource Use`,
          direct: false,
          s3: false,
          orgSpecificResult: true,
        },
      },
      {
        name: siteMessages['amp.measures.downloads.effTab.po.improve.name'],
        description: siteMessages['amp.measures.downloads.po.efficency.YY.content'],
        metadata: {
          link: `/api/measures/dl/aru-improvement/?${urlEnding}`,
          item: undefined,
          suite: `Resource Use`,
          direct: false,
          s3: false,
          orgSpecificResult: true,
        },
      },
      {
        name: `MY ${filters.duration.id + 2000} Thresholds`,
        description: `${efficiencyStatewideThresholds}`,
        metadata: {
          link: `${effiencyThresholdLink}`,
          item: undefined,
          suite: `Resource Use`,
          direct: true,
          s3: false,
          orgSpecificResult: false,
        },
      },
    ];
    if (filters.product.id == 1) {
      opaTabElts.push({
        name: ``,
        description: `${opaPOFooter}`,
        metadata: {
          link: undefined,
          item: undefined,
          suite: `OPA Ratings`,
          direct: false,
          s3: false,
          orgSpecificResult: false,
        },
      });
    } else {
      opaTabElts.push({
        name: ``,
        description: `${opaMAPOFooter}`,
        metadata: {
          link: undefined,
          item: undefined,
          suite: `OPA Ratings`,
          direct: false,
          s3: false,
          orgSpecificResult: false,
        },
      });
    }
  }
  let qualityRenderElt = qualityTabElts;
  let efficiencyRenderElt = efficiencyTabElts;
  let opaTabElt = opaTabElts;

  return ({
    downloads: [...qualityRenderElt, ...efficiencyRenderElt, ...opaTabElt],
    metadata: {
      disableARURatings: disableARURatings,
      disableOPARatings: disableOPARatings,
    },
  });
};
