/* eslint-disable react/no-deprecated */
import React from 'react';
import FormattedMessage from 'components/FormattedMessageMarkdown';
import './styles.scss';

/* ####################
# measure tag legend #
####################*/

class MeasureTagLegend extends React.Component {
  render() {
    return (
      <div className="measure-tag-legend">
        <div className="measure-tag-legend-title">
          <b>
            <FormattedMessage id='amp.measure-tag-legend.title.text'
              textComponent='span'
            />
          </b>
        </div>
        <table>
          <tbody>
          <tr>
            <td className="measure-tag-legend-icon">
              <span className="panel-icon has-text-link">
                <i className="fas fa-star" aria-hidden="true"></i>
              </span>
            </td>
            <td className="measure-tag-legend-label">
              <FormattedMessage id='amp.measure-tag-legend.label.first-year'
                textComponent='span'
              />
            </td>
          </tr>
          <tr>
            <td className="measure-tag-legend-icon">
              <span className="panel-icon has-text-success">
                <i className="fas fa-dollar-sign"></i>
              </span>
            </td>
            <td className="measure-tag-legend-label">
              <FormattedMessage id='amp.measure-tag-legend.label.recommended'
                textComponent='span'
              />
            </td>
          </tr>
          <tr>
            <td className="measure-tag-legend-icon">
              <i className="fas fa-search panel-icon has-text-danger"></i>
            </td>
            <td className="measure-tag-legend-label">
              <FormattedMessage id='amp.measure-tag-legend.label.testing'
                textComponent='span'
              />
            </td>
          </tr>
          </tbody>
        </table>
    </div>
    );
  }
};

export default MeasureTagLegend;
