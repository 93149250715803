import {call, takeEvery} from 'redux-saga/effects';

import {getData} from 'common/request';
import {PING_USER_SESSION} from 'state/constants';

export default function* () {
  yield takeEvery(PING_USER_SESSION, pingUserSession);
}

function* pingUserSession() {
  yield call(getData, '/api/about');
}
