import React from 'react';
import MeasureTagLegend from 'common/measure-tag.jsx';
import FormattedMessage from 'components/FormattedMessageMarkdown';
import './styles.scss';
import UrlParams from '../../../common/urlParams';
/* #######
# about #
########*/

class AboutSection extends React.Component {
  render() {
    if (this.props.children) {
      return (
        <div className='measures-about-page-content'>
          <h4 className="page-header measures-about-page-header" style={{ fontSize: '18px' }}>
            {this.props.header}
          </h4>
          <div>
            {this.props.children}
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <h4 className="page-header">{this.props.header}</h4>
          <div>
            <p dangerouslySetInnerHTML={{ __html: this.props.body }}></p>
          </div>
        </div>
      );
    }
  }
}

class MeasuresAboutPage extends React.Component {
  render() {
    // organized by "tab"
    // 2 tabs: quality and ARU/TCC
    //

    let qualityAboutPanel = (
      <div className='col-sm-12' style={{
        paddingLeft: '0px',
        paddingRight: '0px',
        marginTop: '10px'
      }}
      >
        <UrlParams
          location={this.props.location}
          history={this.props.history}
          baseUrl="/measures/about"
          params={["organization", "duration", "product"]} />
        <AboutSection header={
          <FormattedMessage id='amp.page.measures.header.about.results'
            textComponent='span'
          />
        }
        >
          <FormattedMessage id='amp.page.measures.about.results'
            textComponent='span'
          />
        </AboutSection>
        <AboutSection header={
          <FormattedMessage id='amp.page.measures.about.header.data.sources'
            textComponent='span'
          />
        } >
          <FormattedMessage id='amp.page.measures.about.data.sources'
            textComponent='span'
          />
        </AboutSection>
        <AboutSection header={
          <FormattedMessage id='amp.page.measures.about.header.score.legend'
            textComponent='span'
          />
        }>
          <FormattedMessage id='amp.page.measures.about.score.legend'
            textComponent='span'
          />
          <p></p>
          <MeasureTagLegend />
          <p></p>
          <p><FormattedMessage id='amp.page.measures.about.help.score.legend'
            textComponent='span'
          />
          </p>
        </AboutSection>
        <AboutSection header={
          <FormattedMessage
            id='amp.page.measures.about.quality.composite.header'
            textComponent='span'
          />
        }>
          <FormattedMessage id='amp.page.measures.about.quality.composite'
            textComponent='span'
          />
        </AboutSection>
        <AboutSection header={
          <FormattedMessage id='amp.page.measures.about.clinical.domain.header'
            textComponent='span'
          />
        }>
          <FormattedMessage id='amp.page.measures.about.clinical.domain'
            textComponent='span'
          />
        </AboutSection>
        <AboutSection header={
          <FormattedMessage id='amp.page.measures.about.ACI.domain.header'
            textComponent='span'
          />
        }>
          <FormattedMessage id='amp.page.measures.about.ACI.domain'
            textComponent='span' className='aci-domain'
          />
        </AboutSection>
        <AboutSection header={
          <FormattedMessage
            id='amp.page.measures.about.patient.experience.header'
            textComponent='span'
          />
        }>
          <FormattedMessage id='amp.page.measures.about.patient.experience'
            textComponent='span'
          />
        </AboutSection>
        <AboutSection header={
          <FormattedMessage
            id='amp.page.measures.about.quality.report.resource.header'
            textComponent='span'
          />
        }>
          <FormattedMessage id='amp.page.measures.about.quality.report.resource'
            textComponent='span'
          />
        </AboutSection>
        <AboutSection header={
          <FormattedMessage
            id='amp.page.measures.about.quality.report.feedback.header'
            textComponent='span'
          />
        }>
          <FormattedMessage id='amp.page.measures.about.quality.report.feedback'
            textComponent='span'
          />
        </AboutSection>
      </div>
    );


    // eslint-disable-next-line no-unused-vars
    let aruAboutPanel = (
      <div className='about-container'>
        <AboutSection header={
          <FormattedMessage
            id='amp.page.measures.about.efficiency.sources.header'
            textComponent='span'
          />
        }>
          <FormattedMessage id='amp.page.measures.about.efficiency.sources'
            textComponent='span'
          />
        </AboutSection>
        <AboutSection header={
          <FormattedMessage
            id='amp.page.measures.about.efficiency.timetable.old.header'
            textComponent='span'
          />
        }>
          <FormattedMessage
            id='amp.page.measures.about.efficiency.timetable.old'
            textComponent='span'
          />
        </AboutSection>
        <AboutSection header={
          <FormattedMessage
            id='amp.page.measures.about.efficiency.report.feedback.header'
            textComponent='span'
          />
        }>
          <FormattedMessage
            id='amp.page.measures.about.efficiency.report.feedback'
            textComponent='span'
          />
        </AboutSection>
      </div>
    );

    /* <Tab eventKey={1} title="Appropriate Resource Use / Total Cost of Care">
        {aruAboutPanel}
      </Tab> */
    return (
      <div className="about-page">
        <div className="tabs is-boxed">
          <ul>
            <li className="is-active" data-tab="1">
              <a>
                <span>Quality</span>
              </a>
            </li>
          </ul>
        </div>
        <div className="tab-content">
          {qualityAboutPanel}
        </div>
      </div>
    );
  }
}

export default MeasuresAboutPage;
