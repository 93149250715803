/* eslint-disable camelcase */
/* eslint-disable react/no-deprecated */
import React from 'react';

import FormattedMessage from 'components/FormattedMessageMarkdown';
import {getData} from '../../../common/request.js';
import {ScoreGauge} from '../../../common/score-gauge';
import './styles.scss';

// eslint-disable-next-line no-unused-vars
let round = num => {
  let n = num * 100;
  n = Math.round(n);
  return n / 100;
};

class OverallSPMDashboard extends React.Component {
  state = {
    overall_score: null,
    loading: false,
  };
  updateMeasureView(obj) {
    if (obj.duration && obj.organization) {
      this.setState({overall_score: null, loading: true});
      getData('/api/measures/spm/overall/', {
        duration: obj.duration.id,
        organization: obj.organization.id,
        product: obj.product.id,
      }).then(data => {
        if (data.overall_score) {
          this.setState({
            overall_score: data.overall_score,
            percentiles: data.percentiles,
            loading: false,
          });
        } else {
          this.setState({loading: false});
        }
      });
    }
  }
  componentWillMount() {
    this.updateMeasureView(this.props);
  }
  componentWillReceiveProps(nextProps) {
    this.updateMeasureView(nextProps);
  }
  render() {
    let renderElt = null;
    if (this.state.loading) {
      renderElt = (<p className='measures-dashboard-load'><b><FormattedMessage
        id='amp.page.measures.dashboard.loading.text'
        textComponent='span'
      /></b></p>);
    } else if (this.state.overall_score == null) {
      renderElt = (<p><b></b></p>);
    } else {
      let pctile_50 = this.state.percentiles[2];
      let pctile_75 = this.state.percentiles[3];
      let height = this.props.height ? this.props.height : 350;
      renderElt = (
        <div className='measure-dashboard-gauge'>
          <ScoreGauge
            dataName={'Quality Score'}
            height={height}
            suffix="Points"
            score={this.state.overall_score}
            yellow={pctile_50}
            green={pctile_75} />
          <h3 style={{textAlign: 'center'}}><FormattedMessage
            id='amp.page.measures.dashboard.gauge.label'
            textComponent='span'
          />
          </h3>
        </div>
      );
    }
    return (
      <div style={this.props.style}>
        {renderElt}
      </div>
    );
  }
};


export default OverallSPMDashboard;
