import React, { useEffect, useState } from 'react';
import {connect} from 'react-redux';
import { getUsername } from 'state/selectors';
import { siteMessages } from '../../../src/utils/copy';

const Comment = ({comment, isLoading, onClick, tableCellView, userName, hidePconCommenting}) => {

    const [styles, setStyles] = useState({});

    useEffect(() => {
        setTimeout(() => {
            
            setStyles({display: 'flex',
            opacity: 1});
        }, 300);
    }, []);
    
    return (
        <div className="comment">
            <label style={styles} className="label">{comment.subject}</label>
            <div style={{...styles, "whiteSpace": "pre-line"}} className="content">{comment.content}</div>
            <div style={styles}className="comment-footer">
                <div>
                    <div className="author">{`From: ${comment.author}`}</div>
                        { comment.recipients && comment.recipients.length > 0 && <div className="author">{`To: ${comment.recipients.join(', ')}`}</div> }
                    <div className="time">{ new Date(comment.date_created).toLocaleString() }</div>
                </div>
                {!tableCellView && userName === comment.author && !hidePconCommenting &&
                <button className="remove-button button is-small is-dark" disabled={isLoading} onClick={onClick}>
                    {siteMessages['amp.sitewide-nav.contracting.comments.remove']}
                    {isLoading && <div><i className="fas fa-spinner fa-pulse fa-1x"></i></div>} 
                </button>}
            </div>
        </div>
    )
};

const mapStateToProps = (state) => {
    return {
        userName: getUsername(state),
        hidePconCommenting: state.config.HIDE_PCON_COMMENTING
    };
  };
  
export default connect(mapStateToProps)(Comment);