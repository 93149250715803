
import React from 'react';
import {connect} from 'react-redux';
import {
  getSortedContacts,
  isPrimaryContact,
} from 'state/selectors';

import ContactListHeader from './ContactListHeader';
import ContactListRow from './ContactListRow';
import Loading from 'common/Loading';

const ContactList = props => {
  return (
    <div className="table-container">
    <table className="table is-bordered is-striped  is-hoverable is-fullwidth">
      <ContactListHeader isPrimaryContact={props.isPrimaryContact} />
      {props.contactsAreLoading ? <Loading /> :
        <tbody>
          {props.contacts.map(contact => {
            return (
              <ContactListRow
                key={contact.username}
                contact={contact}
                organization={props.organization}
                isPrimaryContact={props.isPrimaryContact} />
            );
          })}
        </tbody>
      }
    </table>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    contacts: getSortedContacts(state, ownProps),
    isPrimaryContact: isPrimaryContact(state, ownProps),
  };
};

export default connect(mapStateToProps)(ContactList);
