

import React from 'react';
import { connect } from 'react-redux';
import { Table } from '../../common/prototable';
import ContractControls from './ContractControls';
import './styles.scss';
import {
  getSortedContracts,
} from 'state/selectors';

import { ORGANIZATION_HP, ORGANIZATION_PO } from 'state/constants';

import {
  confirmContract,
  removeContract,
  rejectContract,
} from 'state/actions';

import './styles.scss';
import ShowComments from './ShowComments';

const ContractList = props => {

  // HP specific columns
  const columns_hp = [
    { label: 'DMHC ID', key: 'dmhc_id' },
    { label: 'Physician Organization', key: 'po' },
    { label: 'Signed Consent', key: 'signed_consent' },
    { label: 'Product', key: 'product' },
    { label: 'Contract Tags', key: 'options' },
    { label: 'Notes', key: 'notes' },
    { label: 'Status', key: 'status' },
    { label: 'Year', key: 'duration' },
    { label: 'Comments', key: 'comments_list' },
    { label: '', key: 'controls' },
  ];

  // PO specific columns
  const columns_po = [
    { label: 'Health Plan', key: "hp"},
    { label: 'Product', key: "product"},
    { label: 'Status', key: "status"},
    { label: 'Year', key: 'duration' },
    { label: 'Contract Tags', key: 'options' },
    { label: 'Comments', key: 'comments_list' },
    { label: '', key: 'controls' },
  ]

  // Get appropriate columns based on org type (PO/HP)
  const getHeaderItems = function (organization) {
    const headerItems = organization.org_type == ORGANIZATION_HP ?
      columns_hp :
      organization.org_type == ORGANIZATION_PO ?
        columns_po : [];
    return headerItems;
  };

  const pctlSetter = function (columnKey, rowObject) {
    if (props.organization.org_type == ORGANIZATION_HP) {
      return rowObject.status.color.hp;
    } else if (props.organization.org_type == ORGANIZATION_PO) {
      return rowObject.status.color.po;
    } else {
      return '';
    }
  };

  const eltSetter = function (columnKey, rowObject) {
    if (columnKey == 'status') {
      return rowObject.status.label;
    } else if (columnKey == 'controls' && !props.isFrozen){
      return <ContractControls
        {...props}
        contract={rowObject}
        organization={props.organization} />
    } else if (columnKey == 'comments_list') {
      return <ShowComments
        {...props}
        data={rowObject} />
    } 
    else {
      return rowObject[columnKey];
    }
  };
  return (    
    <Table data={props.contracts}
    classSetter={pctlSetter}
    elementSetter={eltSetter}
    isEditable={true}
    columns={getHeaderItems(props.organization)} />
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    contracts: getSortedContracts(state, ownProps)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    confirmContract: (organization, contract) => dispatch(
      confirmContract(organization.id, contract.id)),
    removeContract: (organization, contract) => dispatch(
      removeContract(organization.id, contract.id)),
    rejectContract: (organization, contract) => dispatch(
      rejectContract(organization.id, contract.id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContractList);








