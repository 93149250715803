import React from 'react';
import clone from 'clone';

import {
  SURVEY_TEXT,
  SURVEY_RADIO,
  SURVEY_MULT,
  SURVEY_TEXTAREA,

  SIMPLE_SURVEY_TYPES,
  INFO_SURVEY_TYPES,
} from 'state/constants';

import FormattedMessage from 'components/FormattedMessageMarkdown';
import './styles.scss';
import { connect } from 'react-redux';

/*
  Survey question choices and Survey answers may be compound in nature, with
  either a single or multiple answers allowed and are stored in the database
  joined by semicolons.

  In this component, we split out compound choices and answers into an array of
  pairs, with a pair per choice, the first in the pair being the choice name,
  and the second in the pair being whether it has been selected as an answer or
  not.

  eg:
    in the db:
      choices: 'a;b;Other' answer: 'b;Other'
    in this component:
      choices: [['a', false], ['b', true], ['Other', true]]
*/

// parse choices/answers for complex survey types
const unpackQuestion = question => {
  if (SIMPLE_SURVEY_TYPES.some(x => x == question.survey_type)) {
    return question;
  }
  const answers = question.answer ?
    question.answer.split(';').map(a => a.trim()) :
    [];
  return {
    ...question,
    color: null,
    choices: question.choices
      .split(';')
      .map(c => c.trim())
      .map(choice => {
        const isAnswer = answers.find(answer => answer == choice);
        return [choice, !!isAnswer];
      }),
  };
};

// recreate answer into packed format for complex survey types
const packQuestion = question => {
  if (SIMPLE_SURVEY_TYPES.some(x => x == question.survey_type)) {
    return question;
  }
  return {
    ...question,
    answer: question.choices
      .filter(([choice, isAnswer]) => isAnswer)
      .map(([choice, isAnswer]) => choice)
      .join(';'),
    choices: question.choices
      .map(([choice, _]) => choice)
      .join(';'),
  };
};

const importSurvey = s => {
  let survey = clone(s, false);
  Object.keys(survey).forEach(key => {
    survey[key] = unpackQuestion(survey[key]);
  });
  return survey;
};

const exportSurvey = survey => {
  Object.keys(survey).forEach(key => {
    survey[key] = packQuestion(survey[key]);
  });
  return survey;
};

const colorSurvey = survey => {
  // Adds color class information to survey questions, so that
  // followup questions have the same color as their parent.
  // This assumes that the survey is already sorted properly.
  let surveyColors = {};
  const aColor = 'row bg-info';
  const bColor = 'row';
  let colorClass = aColor;
  survey.forEach(question => {
    if (!question.parent_question_id) {
      surveyColors[question.id] = colorClass;
      colorClass = colorClass == aColor ? bColor : aColor;
    }
  });
  survey = survey.map(question => {
    // Adds a color to a question. If a question has a parent, use the
    // parent question's color, if not, use the color assigned to the
    // question with the question.id as the key.
    question.color = surveyColors[
      question.parent_question_id !== null ?
        question.parent_question_id : question.id
    ];
    return question;
  });
  return survey;
};

// Children questions are meant to be displayed in scenarios that require
// further explanation or further detail. Here we define those scenarios to be
// when the parent answer is 'fulfilled', when it is in the 'fulfilledAnswers'
// array
const hasFulfilledParent = (question, questions) => {
  if (!question.parent_question_id) {
    return true;
  }
  const parentQuestion = questions[question.parent_question_id];
  const fulfilledAnswers = [question.expected_parent_option.toLowerCase()]
  const hasFulfilledAnswer =
    parentQuestion.choices.find(([choice, isAnswer]) => {
      const lowerChoice = choice.toLowerCase();
      return isAnswer && fulfilledAnswers.find(a => a == lowerChoice);
    });
  return hasFulfilledAnswer;
};

// Question Components

const InfoQuestion = ({question}) => (
  <div>
    <label>{question.name}</label>
    <p style={{"whiteSpace": "pre-line"}}><small>{question.description}</small></p>
  </div>
);

const TextQuestion = ({question, onChange}) => (
  <div>
    <label>{question.name}</label>
    <p style={{"whiteSpace": "pre-line"}}><small>{question.description}</small></p>
    <input
      type='text'
      className='input'
      value={question.answer}
      onChange={onChange} />
  </div>
);

const TextAreaQuestion = ({question, onChange}) => (
  <div>
    <label>{question.name}</label>
    <p style={{"whiteSpace": "pre-line"}}><small>{question.description}</small></p>
    <textarea
      type='textarea'
      className='textarea'
      value={question.answer}
      onChange={onChange} />
  </div>
);

// Common core of radio and checkbox questions
const _makeMultiQuestionComponent = (className, inputType) => {
  const Component = ({question, onChange}) => (
    <div>
      <label>{question.name}</label>
      <p><small>{question.description}</small></p>
      {
        question.choices.map(([choice, isAnswer]) => {
          return (
            <label key={choice} className={className}>
              <input
                type={inputType}
                checked={isAnswer}
                onChange={() => onChange(choice)} />
              {choice}
            </label>
          );
        })
      }
    </div>
  );
  Component.displayName = `${inputType}-question`;
  return Component;
};

const RadioQuestion =
  _makeMultiQuestionComponent('radio-inline', 'radio');

const MultQuestion =
  _makeMultiQuestionComponent('checkbox-inline', 'checkbox');

class OrganizationSurveyForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      survey: importSurvey(props.survey),
    };
  }
  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(newProps) {
    this.setState({
      survey: importSurvey(newProps.survey),
    });
  }
  validateOrganizationSurvey(survey) {
    const neededQuestions = Object.values(survey)
      .filter(q => q.is_required)
      .filter(q => !q.answer);
    return neededQuestions.length === 0 ? null : neededQuestions;
  }
  formatInvalid(questions) {
    return (
      <div>
        <div className='org-survey-format'><FormattedMessage
            id='amp.page.organization.survey.heading'
            textComponent='span'
          />
        </div>
        <ul>
          {questions.map((q, ix) => <li key={ix}>{q.name}</li>)}
        </ul>
      </div>
    );
  }
  editOrganizationSurvey() {
    // clone survey so state doesn't get reset (questions unpacked) if validation fails
    const exported = exportSurvey(clone(this.state.survey));
    const invalid = this.validateOrganizationSurvey(exported);
    if (!invalid) {
      this.props.editOrganizationSurvey(
        this.props.organization, this.props.category, Object.values(exported));
    } else {
      this.props.editOrganizationSurveyFailure(
        this.props.organization, this.props.category,
        this.formatInvalid(invalid));
    }
  }
  _handleChange(questionId, key, value) {
    this.setState({
      survey: {
        ...this.state.survey,
        [questionId]: {
          ...this.state.survey[questionId],
          [key]: value,
        },
      },
    });
  }
  handleTextChange(key, value) {
    this._handleChange(key, 'answer', value);
  }
  handleRadioChange(key, value) {
    const question = this.state.survey[key];
    const newChoices = question.choices
      .map(([choice, _]) => ([
        choice,
        value == choice ? true : false,
      ]));
    this._handleChange(key, 'choices', newChoices);
  }
  handleMultChange(key, value) {
    const question = this.state.survey[key];
    const newChoices = question.choices
      .map(([choice, isAnswer]) => ([
        choice,
        value == choice ? !isAnswer: isAnswer,
      ]));
    this._handleChange(key, 'choices', newChoices);
  }
  render() {
    let survey = Object.values(this.state.survey)
      .filter(q => hasFulfilledParent(q, this.state.survey));
    survey.sort((a, b) => a.sort - b.sort);
    survey = colorSurvey(survey);
    return (
      <div className='card panel-default'>
        <div className='card-content'>
          <div className="row bg-warning"><label>The Pre-PCON surveys are due on November 9, 2024 and cannot be changed after then.</label></div>

          {
            survey.map(question => {
              let qComponent;
              if (INFO_SURVEY_TYPES.find(t => t == question.survey_type)) {
                qComponent = <InfoQuestion question={question} />;
              } else if (question.survey_type == SURVEY_TEXT) {
                qComponent = (
                  <TextQuestion
                    question={question}
                    onChange={evt =>
                      this.handleTextChange(question.id, evt.target.value)} />
                );
              } else if (question.survey_type == SURVEY_RADIO) {
                qComponent = (
                  <RadioQuestion
                    question={question}
                    onChange={choice =>
                      this.handleRadioChange(question.id, choice)} />
                );
              } else if (question.survey_type == SURVEY_MULT) {
                qComponent = (
                  <MultQuestion
                    question={question}
                    onChange={choice =>
                      this.handleMultChange(question.id, choice)} />
                );
              } else if (question.survey_type == SURVEY_TEXTAREA) {
                qComponent = (
                  <TextAreaQuestion
                    question={question}
                    onChange={evt =>
                      this.handleTextChange(question.id, evt.target.value)} />
                );
              }
              return (
                <div key={question.id} className={question.color}>
                  <div className='col-sm-12'>
                    <br />
                    {qComponent}
                    <br />
                  </div>
                </div>
              );
            })
          }

            <div className='col-sm-10 org-survey-save'>
              <br/>
              <button className='button is-dark'
               disabled={this.props.freezePrePCONSurveys}
               onClick={() => this.editOrganizationSurvey()}>
                {this.props.freezePrePCONSurveys &&  <div className="lock-icon"><i class="fas fa-solid fa-lock"></i></div>}
                <FormattedMessage
                  id='amp.page.organization.survey.save.label'
                  textComponent='span'
                />
              </button>
            </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    freezePrePCONSurveys: state.config.FREEZE_PRE_PCON_SURVEYS
  };
};

export default connect(mapStateToProps)(OrganizationSurveyForm);
