/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React from 'react';

import { Table, Tr } from 'common/prototable';
import { ScoreGauge, ScoreGaugeLegend } from 'common/score-gauge';

import { RightScorePanel } from './measure-score-panel';

import { siteMessages } from 'src/utils/copy';

let round = num => {
  if (num == 'N/A') return num;
  let n = num * 100;
  n = Math.round(n);
  return n / 100;
};

let confidenceUpper = (rate, den) => {
  if (rate === 'SD') {
    return 'N/A';
  }
  rate = rate / 100;
  let c = (rate + 1.96 * Math.sqrt((rate * (1 - rate)) / den) + (1 / (2 * den))) * 100;
  c = round(c);
  if (c > 100) {
    return '100%';
  } else if (c < 0) {
    return '0%';
  } else {
    return `${c}%`;
  }
};

let confidenceLower = (rate, den) => {
  if (rate === 'SD') {
    return 'N/A';
  }
  rate = rate / 100;
  let c = (rate - 1.96 * Math.sqrt((rate * (1 - rate)) / den) - (1 / (2 * den))) * 100;
  c = round(c);
  if (c > 100) {
    return '100%';
  } else if (c < 0) {
    return '0%';
  } else {
    return `${c}%`;
  }
};

// eslint-disable-next-line react/no-deprecated
class PasView extends React.Component {
  render() {
    let tableData = [];
    let scoreLine = {};
    let loading = false;
    let rank = (this.props.stats ?
      (this.props.stats.rank ? this.props.stats.rank : null)
      : null
    );
    let num_orgs = (this.props.stats ?
      (this.props.stats.number_orgs ? this.props.stats.number_orgs : null)
      : null
    );
    let last_score = (this.props.stats ?
      (this.props.stats.last_score ? this.props.stats.last_score : null)
      : null
    );
    let spm = (this.props.stats ?
      (this.props.stats.spm ? this.props.stats.spm : null)
      : null
    );
    if (!this.props.instances.response && this.props.instances.length > 0) {
      scoreLine = this.props.scoreLine;
      if (scoreLine !== null) {
        scoreLine.data_provider__name = 'Score';
      }
      tableData = this.props.instances.map(elt => {
        let fields = elt.fields;
        Object.keys(fields).map(f => {
          if (Number.isFinite(fields[f])) {
            fields[f] = round(fields[f]);
          }
        });
        fields.data_provider__name = elt.data_provider__name;

        return fields;
      });
    } else {
      loading = true;
    }
    // table columns
    let columns = [];
    if (this.props.measure.fields) {
      columns = this.props.measure.fields.standard.map(elt => {
        let k = Object.keys(elt)[0];
        let v = elt[k];
        return { key: k, label: v };
      });
      columns.unshift({
        key: 'data_provider__name',
        label: siteMessages[
          'amp.page.measures.measures.po.dataprovider.label'
        ],
      });
    }

    // table
    let table;
    if (scoreLine) {
      table = (
        <Table columns={columns}
          className='table table-responsive table-bordered'
          data={tableData} sortable={false}
        >
          <Tr className="score-row"
            style={{ fontWeight: 'bold', borderTop: '2px solid #e3e3e3' }}
            columns={columns}
            data={scoreLine} />
        </Table>
      );
    } else {
      table = (
        <Table columns={columns} className='table-responsive' data={tableData}>
        </Table>
      );
    }
    if (loading) {
      table = (
        <div></div>
      );
    }
    let elements = [];
    let percentiles = (this.props.stats.percentiles ?
      this.props.stats.percentiles : [0, 0, 0, 0, 0]
    );
    if (Object.keys(scoreLine).length > 0) {
      let pctile_50 = percentiles[2];
      let pctile_75 = percentiles[3];
      let higher_is_better = this.props.measure.is_reported_higher_better;
      let scoreGaugeArea;
      let wellScore;
      if (scoreLine.adj_result === 'SD') {
        wellScore = scoreLine.adj_result;
        scoreGaugeArea = (
          <div style={{ height: '50px' }}></div>
        );
      } else {
        wellScore = scoreLine.adj_result + this.props.measure.function__suffix;
        scoreGaugeArea = (
          <ScoreGauge
            height={350}
            score={scoreLine.adj_result}
            hib={higher_is_better}
            yellow={pctile_50}
            green={pctile_75}
            suffix={this.props.measure.function__suffix} />
        );
      }
      elements.push(
        <div className="panel panel-body"
          style={{ marginBottom: '0px', padding: '0px', marginTop: '-1px' }}
        >
          <div className="col-sm-6"
            style={{
              width: '50%', padding: '0px', margin: '0px', float: 'left',
            }}
          >
            {scoreGaugeArea}
            <div className="well" style={{ marginRight: '10px', textAlign: 'center' }}>
              <b style={{ fontSize: '22px' }}> {wellScore} </b>
              <br />
              {scoreLine.data_provider__name}
            </div>
          </div>
          <div className="col-sm-6 pull-right"
            style={{ width: '50%', padding: '0px', margin: '0px' }}
          >
            <ScoreGaugeLegend percentiles={true} sd={true} lr={true} />
            <RightScorePanel
              rank={rank}
              numorgs={num_orgs}
              spm={spm}
              last_score={last_score}
              confidenceUpper={scoreLine.ci_95pct_upper}
              confidenceLower={scoreLine.ci_95pct_lower} />
          </div>
        </div>
      );
    }
    let renderElt = (
      <div style={this.props.style}>
        <div className="panel panel-body"
          style={{ marginBottom: '0px', padding: '0px', marginTop: '-1px' }}
        >
          <div>{elements}</div>
          <div>{table}</div>
        </div>
      </div>
    );
    return (
      renderElt
    );
  }
};


export default PasView;
