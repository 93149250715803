/* eslint-disable react/no-deprecated */
import React from 'react';

import OverallSPM from './overallspm';
import {siteMessages} from 'src/utils/copy';

/* ####################
# measures dashboard #
####################*/

class MeasuresDashboardPage extends React.Component {
  componentWillMount() {
    document.title = siteMessages['amp.page.measures.dashboard.title'];
  }
  render() {
    return (
      <div>
        <div>
          <OverallSPM organization={this.props.organization}
            duration={this.props.duration}
          />
        </div>
      </div>
    );
  }
};

/* <OverallSPM
      organization={this.props.organization}
      duration={this.props.duration}
    >
    </OverallSPM> */

export default MeasuresDashboardPage;
