// UI Constants

// Change to true to enable the delete/remove button
// NOTE: Deleting contract records may negatively impact intensions
// workflows.
export const CONTRACT_CAN_REMOVE = false;

export const HP_CONTRACT_LIST_HEADER_ITEMS = [
  'DMHC ID', 'Physician Organization', 'Signed Consent',
  'Product', 'Contract Tags', 'Notes', 'Status', 'Year'
];

export const SHOW_COMMENTS_MODAL_ERROR_MESSAGE = "SHOW_COMMENTS_MODAL_ERROR_MESSAGE";

export const HP_CONTRACT_LIST_ROW_ITEMS = [
  'dmhc_id', 'po', 'signed_consent',
  'product', 'options', 'notes', 'status', 'duration'
];

export const PO_CONTRACT_LIST_HEADER_ITEMS = [
  'Health Plan', 'Product', 'Status', 'Year', 'Contract Tags'
];

export const PO_CONTRACT_LIST_ROW_ITEMS = [
  'hp', 'product', 'status', 'duration', 'options'
];

// CSV constants

export const HP_CONTRACT_CSV_HEADER_ITEMS = [
  'DMHC ID', 'Physician Organization', 'Signed Consent',
  'Product', 'Notes', 'Status',
];

export const HP_CONTRACT_CSV_ROW_ITEMS = [
  'dmhc_id', 'po', 'signed_consent',
  'product', 'notes', 'status',
];

export const PO_CONTRACT_CSV_HEADER_ITEMS = [
  'Health Plan', 'Product', 'Status',
];

export const PO_CONTRACT_CSV_ROW_ITEMS = [
  'hp', 'product', 'status',
];

// Other

export const CONTRACT_DURATION_ID = 16;

export const CONTRACT_PO_ACCEPTED = 0;
export const CONTRACT_HP_ACCEPTED = 1;
export const CONTRACT_PO_HP_ACCEPTED = 2;
export const CONTRACT_PO_REJECTED = 3;
export const CONTRACT_HP_REJECTED = 4;
export const CONTRACT_PO_HP_REJECTED = 5;
export const CONTRACT_PO_HP_INITIAL = 6;

// Added to explicitly record contract states with disputed status
export const CONTRACT_PO_REJECTED_HP_ACCEPTED = 7;
export const CONTRACT_PO_ACCEPTED_HP_REJECTED = 8;

export const STATUS_CONFIG = {
  [CONTRACT_PO_ACCEPTED_HP_REJECTED]: {
    label: 'Physician Group Confirmed; Health Plan Denied',
    color: {
      po:'has-background-danger',
      hp:'has-background-danger'
    },
    sortOrder: {
      po: 8,
      hp: 9,
    },
  },
  [CONTRACT_PO_REJECTED_HP_ACCEPTED]: {
    label: 'Physician Group Denied; Health Plan Confirmed',
    color: {
      po:'has-background-danger',
      hp:'has-background-danger'
    },
    sortOrder: {
      po: 9,
      hp: 8,
    },
  },
  [CONTRACT_PO_ACCEPTED]: {
    label: 'Physician Group Confirmed; Awaiting Health Plan Confirmation',
    color: {
      po: '',
      hp:''
    },
    sortOrder: {
      po: 3,
      hp: 6,
    },
  },
  [CONTRACT_HP_ACCEPTED]: {
    label: 'Health Plan Confirmed; Awaiting Physician Group Confirmation',
    color: {
      po: 'bg-danger',
      hp: '',
    },
    sortOrder: {
      po: 6,
      hp: 3,
    },
  },
  [CONTRACT_HP_REJECTED]: {
    label: 'Health Plan Denied; Awaiting Physician Group Confirmation',
    color: {
  po:'has-background-danger',
      hp: '',
    },
    sortOrder: {
      po: 7,
      hp: 4,
    },
  },
  [CONTRACT_PO_REJECTED]: {
    label: 'Physician Group Denied; Awaiting Health Plan Confirmation',
    color: {
      po: '',
     hp:'has-background-danger'
    },
    sortOrder: {
      po: 4,
      hp: 7,
    },
  },
  [CONTRACT_PO_HP_ACCEPTED]: {
    label: 'Confirmed',
    color: {
      po:'has-background-success',
      hp:'has-background-success',
    },
    sortOrder: {
      po: 1,
      hp: 1,
    },
  },
  [CONTRACT_PO_HP_REJECTED]: {
    label: 'Physician Group and Health Plan Denied',
    color: {
      po:'has-background-success',
      hp:'has-background-success'
    },
    sortOrder: {
      po: 2,
      hp: 2,
    },
  },
  [CONTRACT_PO_HP_INITIAL]: {
    label: 'Awaiting Physician Group and Health Plan Confirmation',
    color: {
      po:'has-background-danger',
      hp:'has-background-danger',
    },
    sortOrder: {
      po: 5,
      hp: 5,
    },
  },
};
