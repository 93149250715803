/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-no-target-blank */

import React from 'react';
import { getData } from 'common/request.js';
import { Table } from 'common/prototable';
import { ScoreGaugeLegend, ScoreGauge } from 'common/score-gauge';
import FormattedMessage from 'components/FormattedMessageMarkdown';
import { siteMessages } from 'src/utils/copy';
import './styles.scss';
import UrlParams from '../../../common/urlParams';
import { useOutletContext } from 'react-router-dom';

/* ############
# spm report #
############*/

class SPMSummaryPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      measures: [],
      domainMap: {},
      selectedDomain: {
        id: null,
        name: null,
      },
      instances: [],
      percentiles: [],
    };
  }
  updateMeasureList(props) {
    getData('/api/measures/spmlist/', {
      duration: props.duration.id,
      product: props.product.id,
    }).then((data) => {
      let domainMap = {};
      data.filter(elt => {
        return domainMap.hasOwnProperty(elt.domain_id) ? false : (domainMap[elt.domain_id] = elt.domain__suite_name);
      });
      // specifically remove Overall
      Object.keys(domainMap).forEach(k => {
        if (domainMap[k] === 'SPM') {
          delete domainMap[k]; // KILL
        }
      });
      let selectedDomain = { id: Object.keys(domainMap)[0] };
      selectedDomain.name = domainMap[selectedDomain.id];
      this.setState({ measures: data, domainMap: domainMap });
      this.updateDomain(selectedDomain);
    });
  }
  updateDomain(domain) {
    this.setState({ selectedDomain: domain });
  }
  updateInstances(props) {
    getData('/api/measures/orgspmsummary/', {
      duration: props.duration.id,
      organization: props.organization.id,
      product: props.product.id,
    }).then(data => {
      if (data.instances) {
        this.setState({ instances: data.instances });
      } else {
        this.setState({ instances: [] });
      }
    });
    getData('/api/measures/spm/overall/', {
      duration: props.duration.id,
      organization: props.organization.id,
      product: props.product.id,
    }).then(data => {
      if (data.overall_score) {
        this.setState({ percentiles: data.percentiles });
      } else {
        this.setState({ percentiles: [] });
      }
    });
  }
  componentWillReceiveProps(nextProps) {
    if ((this.props.duration.id !== nextProps.duration.id) ||
    (this.props.organization.id !== nextProps.organization.id) ||
    (this.props.product.id !== nextProps.product.id)) {
      this.updateMeasureList(nextProps);
      this.updateInstances(nextProps);
    }
  }
  componentWillMount() {
    document.title = siteMessages['amp.page.measures.reports.spm.title'];
    this.updateMeasureList(this.props);
    this.updateInstances(this.props);
  }
  handleSelect(selectedKey) {
    let kv = selectedKey.target.id.split('.');
    if (kv[0] === 'd') {
      this.updateDomain({ id: kv[1], name: this.state.domainMap[kv[1]] });
    }
  }
  render() {
    let domainOptions = Object.keys(this.state.domainMap).map((elt, i) => {
      return (
        <li className={this.state.selectedDomain.id == elt ? 'is-active' : ''} key={i} ><a id={`d.${elt}`}>{this.state.domainMap[elt]}</a></li>
      );
    });

    let relevantMeasure = {};
    this.state.measures.filter(elt => {
      return elt.domain_id == this.state.selectedDomain.id;
    }).filter(elt => {
      return [27, 26, 25, 24].indexOf(elt.function_id) === -1;
    }).forEach(elt => {
      elt.fieldNames = elt.fields;
      relevantMeasure[elt.id] = elt;
    });
    let instances = [];
    this.state.instances.filter(elt => {
      return relevantMeasure[elt.measure_spec_id];
    }).map(elt => {
      let obj = {};
      Object.assign(obj, relevantMeasure[elt.measure_spec_id], elt);
      instances.push(obj);
      return elt;
    });
    instances.forEach((instance, index) => {
      let nInstance = {
        'name_full': instance.name_full,
        'abbr': instance.abbr,
        'Prior-Year 50th Percentile': instance['Prior-Year 50th Percentile'],
        'Prior-Year 75th Percentile': instance['Prior-Year 75th Percentile'],
        'Prior-Year 95th Percentile': instance['Prior-Year 95th Percentile'],
        'Prior-Year Score': instance['Prior-Year Score'],
        'Current-Year Score': instance['Current-Year Score'],
      };
      nInstance = Object.assign(nInstance, instance.fields);
      instances[index] = nInstance;
    });
    let columns = [
      {
        key: 'name_full',
        label: siteMessages['amp.page.measures.reports.spm.col.label.mName'],
      },
      {
        key: 'abbr',
        label: siteMessages['amp.page.measures.reports.spm.col.label.mAbbr'],
      },
      {
        key: 'Prior-Year 50th Percentile',
        label: siteMessages['amp.page.measures.reports.spm.col.label.PY50%ile'],
      },
      {
        key: 'Prior-Year 75th Percentile',
        label: siteMessages['amp.page.measures.reports.spm.col.label.PY75%ile'],
      },
      {
        key: 'Prior-Year 95th Percentile',
        label: siteMessages['amp.page.measures.reports.spm.col.label.PY95%ile'],
      },
      {
        key: 'Prior-Year Score',
        label: siteMessages['amp.page.measures.reports.spm.col.label.PY.score'],
      },
      {
        key: 'Current-Year Score',
        label: siteMessages['amp.page.measures.reports.spm.col.label.CY.score'],
      },
    ];
    if (this.state.selectedDomain.id == 2) {
      columns = [
        {
          key: 'name_full',
          label: siteMessages['amp.page.measures.reports.spm.col.label.mName'],
        },
        {
          key: 'abbr',
          label: siteMessages['amp.page.measures.reports.spm.col.label.mAbbr'],
        },
        {
          key: 'Current-Year Score',
          label: siteMessages['amp.page.measures.reports.spm.col.label.CY.score'],
        },
      ];
    }

    if (!!relevantMeasure && relevantMeasure != {}) {
      Object.keys(relevantMeasure).forEach(inst => {
        let instance = relevantMeasure[inst];
        instance.fieldNames.standard.forEach(fieldName => {
          let k = Object.keys(fieldName)[0];
          let v = fieldName[k];
          let exists = columns.filter(col => {
            return col.key == k;
          });
          if (exists.length === 0) {
            columns.push({ key: k, label: v });
          }
        });
      });
    }

    let getScore = (scoreName, funcId) => {
      let measure = this.state.measures.filter(elt => {
        return elt.function_id == funcId;
      });
      if (measure.length === 0) {
        return 'N/A';
      }
      let scoreL = this.state.instances.filter(elt => {
        return elt.measure_spec_id == measure[0].id;
      });
      if (scoreL.length === 0) {
        return 'N/A';
      } else {
        return scoreL[0].fields[scoreName];
      }
    };

    let spmDescriptionLink = siteMessages[
      'amp.page.measures.reports.spm.description.link'
    ];
    let score = getScore('overall_score', 27);
    let hib = true;
    let max = this.props.product.id == 4 ? 90 : 100;
    let yellow = (this.state.percentiles.length ?
      this.state.percentiles[2] : 30);
    let green = (this.state.percentiles.length ?
      this.state.percentiles[3] : 40);
    let suffix = 'Points';
    let clinicalDomain = getScore('domain_score', 24);
    let muhitDomain = getScore('domain_score', 25);
    let pasDomain = getScore('domain_score', 26);

    let summaryview;
    if (instances.length != 0) {
      summaryview = (
        <Table data={instances} columns={columns} />
      );
    } else {
      summaryview = (
        <div><FormattedMessage
          id='amp.page.measures.reports.spm.page.nomeasure'
          textComponent='span'
        />
        </div>
      );
    }
    let qcsWeights = { 'clinical': 50, 'muhit': 30, 'pas': 20 };
    let compositeScoreFormula;
    let compositeScoreFormulaAbbr;
    if (this.props.duration.id > 15) {
      qcsWeights = { 'clinical': 60, 'muhit': 10, 'pas': 30 };
      if (this.props.product.id == 4) {
        qcsWeights = { 'clinical': 60, 'pas': 30 };
      }
      // formula changes for MY2018, Medicaid
      if (this.props.product.id == 4) {
        compositeScoreFormulaAbbr = `(Clinical * ${qcsWeights.clinical}%) + (Patient Experience *
          ${qcsWeights.pas}%)`;
        compositeScoreFormula = `( ${qcsWeights.clinical}% * Clinical Domain
          Score ) + (
          ${qcsWeights.pas}% * Patient Experience Domain Score )`;
      }
      else {
        compositeScoreFormulaAbbr = `(Clinical * ${qcsWeights.clinical}%) +
          (ACI * ${qcsWeights.muhit}%) + (Patient Experience *
          ${qcsWeights.pas}%)`;
        compositeScoreFormula = `( ${qcsWeights.clinical}% * Clinical Domain
          Score ) + ( ${qcsWeights.muhit}% * ACI Domain Score ) + (
          ${qcsWeights.pas}% * Patient Experience Domain Score )`;
      }
    }
    let renderElt = (
      <div className='spm-report-page'>
        <UrlParams
          location={this.props.location}
          history={this.props.history}
          baseUrl="/measures/reports/spm"
          params={["organization", "duration", "product"]} />
        <h2 className="core-title"><FormattedMessage
          id='amp.page.measures.reports.spm.page.title'
          textComponent='span'
        />
        </h2>
        <div>
          <div className="spm-report-page-left">
            <ScoreGauge
              dataName={siteMessages[
                'amp.page.measures.reports.spm.gauge.label'
              ]}
              height={300}
              score={score}
              hib={hib}
              yellow={yellow}
              max={max}
              green={green}
              suffix={suffix} />
            <ScoreGaugeLegend percentiles sd lr />
            <br />
            <div className="table-container">
              <table className="table is-bordered is-fullwidth">
                <tbody>
                  <tr>
                    <td><FormattedMessage
                      id='amp.page.measures.reports.spm.clinical.domain'
                      textComponent='span'
                    />
                    </td>
                    <td>{clinicalDomain} / 100</td>
                  </tr>
                  {this.props.product.id == 4 ? "" :
                    <tr>
                      <td><FormattedMessage
                        id='amp.page.measures.reports.spm.ACI.domain'
                        textComponent='span'
                      />
                      </td>
                      <td>{muhitDomain} / 100</td>
                    </tr>}
                  <tr>
                    <td><FormattedMessage
                      id='amp.page.measures.reports.spm.patient.exp.domain'
                      textComponent='span'
                    />
                    </td>
                    <td>{pasDomain} / 100</td>
                  </tr>
                  {this.props.product.id == 4 ?
                    <tr>
                      <td><b><FormattedMessage
                        id='amp.page.measures.reports.spm.quality.score'
                        textComponent='span'
                      /><span style={{ color: 'red' }}>*</span>:</b></td>
                      <td><b> {score} / 90 </b></td>
                    </tr> :
                    <tr>
                      <td><b><FormattedMessage
                        id='amp.page.measures.reports.spm.quality.score'
                        textComponent='span'
                      /><span style={{ color: 'red' }}>*</span>:</b></td>
                      <td><b>{score} / 100</b></td>
                    </tr>}

                </tbody>
              </table>
            </div>
            <div style={{ marginLeft: '5px', marginBottom: '15px' }}>
              <span style={{ color: 'red' }}>*</span> {compositeScoreFormulaAbbr}
            </div>
          </div>
          <div className="spm-report-page-left spm-report-description" >
            <h4><FormattedMessage
              id='amp.page.measures.reports.spm.description.heading'
              textComponent='span'
            /></h4>
            <p><FormattedMessage
              id='amp.page.measures.reports.spm.description.content1'
              textComponent='span'
            /></p>
            <p>Overall AMP Quality Composite Score = {compositeScoreFormula}</p>
            <p>Please refer to <a target="_blank" href={spmDescriptionLink}>IHA's website</a> <FormattedMessage
              id='amp.page.measures.reports.spm.description.content2'
              textComponent='span'
            /></p>
            {/* ENG-1260, additional desc for Medicaid */}
            {this.props.product.id == 4 && this.props.duration.id >= 18 ?
              <p><FormattedMessage
                id='amp.page.measures.reports.spm.description.content3'
                textComponent='span'
              /></p> : ""}
          </div>
        </div>
        <div>
          <div className="tabs is-boxed" style={{ clear: 'both' }} value={`d.${this.state.selectedDomain.id}`}
            onClick={this.handleSelect.bind(this)}>
            <ul>{domainOptions}</ul>
          </div>
          <div className="container"
            style={{
              marginTop: '15px', marginLeft: 'auto', marginRight: 'auto',
            }}
          >
            {summaryview}
          </div>
        </div>
      </div>
    );
    return (
      renderElt
    );
  }
}


const SPMSummaryPageProxy = (props) => {
  const context = useOutletContext();
  return <SPMSummaryPage {...props} {...context}/>;
};

export default SPMSummaryPageProxy;