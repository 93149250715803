import React from 'react';
import { connect } from 'react-redux';

import {
  getUserState
} from 'state/selectors';

import {
  updateOrganization,
  updateDuration,
  updateProduct
} from 'state/actions';
import queryString from 'query-string';

class UrlParams extends React.Component {
    constructor(props) {
        super(props);

        this.updateViewFrmURL(props);
    }
    updateViewFrmURL(props, onpopstate = false) {
        this.url = props.location.search;
        this.url_params = queryString.parse(props.location.search);
        try {
            if (Object.keys(this.url_params).length >= props.params.length) {
                props.params.forEach(param => {
                    const paramValue = props[`${param}s`].find(x => x.id.toString() === this.url_params[param]);
                    paramValue && props[param].id.toString() !== this.url_params[param] &&
                        props.updateReduxSelectorState(param, paramValue);
                });
            } else {
                if (onpopstate == false)
                    this.updateUrl(this.props)
            }
        } catch (error) { console.log(error) }

        this.props.onUrlUpdate && this.props.onUrlUpdate(this.url_params, onpopstate, props);
    }
    updateUrl(props) {
        let that = this;
        setTimeout(() => {
            let params = "";

            props.params.forEach( (param, index) => {
                const propValue = props[param];
                if (propValue) {
                    params += `${(index === 0) ? "?" : "&"}${param}=${propValue.id}`;
                }
            });

            if (props.urlEnding) {
                params += props.urlEnding;
            }

            const url = `${props.baseUrl}${params}`;
            that.url = params;
            props.history.push(url);
        }, 50)
    }
    componentWillUpdate(nextProps) {
        if (this.props.params.map(param => nextProps[param] != this.props[param]).includes(true) || 
            this.props.urlEnding !== nextProps.urlEnding) {
            this.updateUrl(nextProps);
        }
    }
    componentDidUpdate() {
        window.onpopstate = (e) => {
            if (this.url !== this.props.location.search) {
                this.updateViewFrmURL(this.props, true)
            }
        }
    }
    render() {
        return null;
    }
}

const mapStateToProps = state => {
    return getUserState(state);
};

const mapDispatchToProps = dispatch => {
    return {
        updateReduxSelectorState: (key, value) => {
            if (key === 'organization') {
                dispatch(updateOrganization(value));
            } else if (key === 'duration') {
                dispatch(updateDuration(value));
            } else if (key === 'product') {
                dispatch(updateProduct(value));
            }
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UrlParams);