import {createSelector} from 'reselect';

export const getUserState = state => state;

export const getUserSession = state => state.session;
export const getUsername = createSelector(
  getUserSession,
  session => session.username,
);
export const isLoggedIn = createSelector(
  getUsername,
  username => !!username,
);
export const getUserOrgTypes = createSelector(
  getUserSession,
  session => session.org_types,
);

export const getUseReact = createSelector(
  getUserSession,
  session => session.USE_REACT,
);

export const isOrglessAndNotLoading = state => state.noOrganizations;

export const getUserOrganization = state => state.organization;
export const getUserProduct = state => state.product;
export const getUserDuration = state => state.duration;

export const getUserOrganizations = state => state.organizations;
export const getUserProducts = state => state.products;
export const getUserDurations = state => state.durations;

export const isConsistentUserState = createSelector(
  [getUserOrganization, getUserProduct, getUserDuration],
  (organization, product, duration) => !!(organization && product && duration)
);
