import {shiftSelectors} from 'state/utils/shiftSelectors';

import * as contactsSelectors from 'state/contacts/selectors';
import * as contractsSelectors from 'state/contracts/selectors';
import * as organizationProfileSelectors
  from 'state/organization-profile/selectors';
import * as organizationSurveySelectors
  from 'state/organization-survey/selectors';
import * as measuresSelectors from 'state/measures/selectors';
import * as userSelectors from 'state/user/selectors';

const selectors = {
  ...shiftSelectors(state => state.contacts, contactsSelectors),
  ...shiftSelectors(state => state.contracts, contractsSelectors),
  ...shiftSelectors(state => state.organizationProfile,
    organizationProfileSelectors),
  ...shiftSelectors(state => state.organizationSurvey,
    organizationSurveySelectors),
  ...shiftSelectors(state => state.measures, measuresSelectors),
  ...shiftSelectors(state => state.user, userSelectors),
};

// using older style export so we can dynamically export all selector functions
// without using default export
module.exports = selectors;
