import React from 'react';
import './styles.scss';

function Dialog({header, onCloseClick, children, isActive}){
    return (
            <div className="dialog">
                <div className="modal-background" onClick={onCloseClick}></div>
                <div className={`modal-card ${isActive ? 'fadeInDown' : 'fadeOutUp'}`}>
                    <header className="modal-card-head">
                        {header}
                        <button className="delete" aria-label="close" onClick={onCloseClick}></button>
                        </header>
                    <section className="modal-card-body">{children}</section>
                    <footer className="modal-card-foot">
                        <div className="buttons">
                            <button className="button" onClick={onCloseClick}>
                                <span className="markdown-content"><div><p>Close</p></div></span>
                            </button>
                        </div>
                    </footer>
                </div>
            </div>
    );
}

export default Dialog;