/* eslint-disable react/no-deprecated */
import React from 'react';
import { getData } from 'common/request';
import MeasureHeader from 'common/measure-header';
import MeasureOverview from './overview';
import MeasureVisualization from './visualization';
import MeasureImprovement from './improvement';
import FormattedMessage from 'components/FormattedMessageMarkdown';
import './styles.scss';

let censoredViz = ['DX_', 'TCC_TREND'];

class MeasureView extends React.Component {
  state = {
    tab: 1,
    instances: [],
    stats: {},
    instancesLoading: true,
    statsLoading: true,
  };
  updateMeasureView(obj) {
    if (this.state.tab == 2) {
      if (censoredViz.some(elt => obj.measure.abbr.includes(elt))) {
        this.setState({ tab: 1 });
      }
    }
    if (this.state.tab == 3) {
      if (obj.measure.function__logic &&
        !obj.measure.function__logic.improvement
      ) {
        this.setState({ tab: 1 });
      }
    }
    if (obj.measure && obj.duration && obj.organization) {
      this.setState({ instancesLoading: true, statsLoading: true });
      getData('/api/measures/orginstances/', {
        duration: obj.duration.id,
        measure: obj.measure.id,
        organization: obj.organization.id,
      }).then((data) => {
        this.setState({ instances: data, instancesLoading: false });
      });
      getData('/api/measures/orginstancesextra/', {
        duration: obj.duration.id,
        measure: obj.measure.id,
        organization: obj.organization.id,
      }).then((data) => {
        this.setState({ stats: data, statsLoading: false });
      });
    }
  }
  componentWillMount() {
    this.updateMeasureView(this.props);
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.duration.id != nextProps.duration.id ||
      this.props.measure.id != nextProps.measure.id ||
      this.props.organization.id != nextProps.organization.id) {
      this.updateMeasureView(nextProps);
    }
  }
  handleSelect(selectedKey) {
    let kv = selectedKey.target.id.split('.');
    if (kv[0] === 't') {
      this.setState({ tab: kv[1] });
    }
  }
  render() {
    let renderElt = null;
    let ProperView;
    if (this.state.tab == 1) {
      ProperView = MeasureOverview;
    } else if (this.state.tab == 2) {
      ProperView = MeasureVisualization;
    } else if (this.state.tab == 3) {
      ProperView = MeasureImprovement;
    }

    if (this.state.instancesLoading || this.state.statsLoading) {
      renderElt = (<div key="content"><b><FormattedMessage
        id='amp.page.measures.measures.po.page.loading'
        textComponent='span'
      />
      </b></div>);
    } else {
      renderElt = (
        <ProperView
          key="content"
          organization={this.props.organization}
          duration={this.props.duration}
          product={this.props.product}
          measure={this.props.measure}
          instances={this.state.instances}
          stats={this.state.stats} />
      );
    }
    let tabs = [];
    if (this.props.measure && this.props.measure.function__logic) {
      // eslint-disable-next-line no-constant-condition
      if (true) {
        tabs.push(<li key="t.1" className={this.state.tab == 1 ? 'is-active' : ''} ><a id={`t.1`}>
          Overview
         </a></li>)
      }
      // eslint-disable-next-line no-constant-condition
      if (true) {
        let vizDisabled = false;
        if (this.props.measure.abbr) {
          vizDisabled = censoredViz.some(elt =>
            this.props.measure.abbr.includes(elt)
          );
        }
        tabs.push(
          <li key="t.2" className={this.state.tab == 2 ? 'is-active' : ''} disabled={vizDisabled} ><a id={`t.2`}>
            Visualization
          </a></li>)
      }
    }
    return (
      <div style={this.props.style} >
        <div className='container measures-measure-view-container'>
          <div className="tabs is-boxed" value={`t.${this.state.tab}`}
            onClick={(e) => this.handleSelect(e)}>
            <ul>{tabs}</ul>
          </div>
          <div key="content-body"
            // eslint-disable-next-line max-len
            className="panel panel-default measures-measure-view-content-container"
          >
            <MeasureHeader key="header"
              measure={this.props.measure} />
            {renderElt}
          </div>
        </div>
      </div>
    );
  }
};

export default MeasureView;
