import React from 'react';

import {getData} from 'common/request.js';
import {ScoreGaugeLegend} from 'common/score-gauge';
import {ScoreRankChart} from 'common/bar-chart';
import {YoyInstanceChart} from 'common/line-chart';

import FormattedMessage from 'components/FormattedMessageMarkdown';
import {siteMessages} from 'src/utils/copy';

// Actually the following should be measure *fragments* since we do a
// substring search instead of a match.
const MEASURES_CENSORED_STATEWIDE = ['CLM_ENR_MBRS', 'MBRYRS_', 'MBRMOS_',
  'OSU_', 'TCC_OBS', 'TCC_RISK_ADJ'];

// This controls whether the statewide distribution of performance is
// displayed.
function censoredStatewide(props) {
  if (
    MEASURES_CENSORED_STATEWIDE.some(elt => props.measure.abbr.includes(elt))
  ) {
    return true;
  }
  return false;
}

let round = num => {
  let n = num * 100;
  n = Math.round(n);
  return n / 100;
};

// eslint-disable-next-line react/no-deprecated
class MeasureVisualization extends React.Component {
  state = {
    statewide: [],
    yoy: [],
    statewideLoading: true,
    yoyLoading: true,
  };
  updateMeasureView(obj) {
    if (obj.measure && obj.duration && obj.organization) {
      this.setState({statewideLoading: true, yoyLoading: true});
      if (!censoredStatewide(obj)) {
        getData('/api/measures/org/statewidecompare/', {
          duration: obj.duration.id,
          measure: obj.measure.id,
          organization: obj.organization.id,
        }).then((data) => {
          this.setState({statewide: data, statewideLoading: false});
        });
      }
      getData('/api/measures/org/yearoveryearcompare/', {
        duration: null,
        measure: obj.measure.id,
        organization: obj.organization.id,
      }).then((data) => {
        this.setState({yoy: data, yoyLoading: false});
      });
}
  }
  componentWillMount() {
    this.updateMeasureView(this.props);
  }
  componentWillReceiveProps(nextProps) {
    this.updateMeasureView(nextProps);
  }
  handleSelect(selectedKey) {
  }
  render() {
    let renderElt = null;
    let ScoreChart;
    let censored = censoredStatewide(this.props);
    let statewideLoading = this.state.statewideLoading && !censored;
    if (statewideLoading || this.state.yoyLoading) {
      renderElt = (<p><b><FormattedMessage
            id='amp.page.measures.measures.po.page.loading'
            textComponent='span'
          />
        </b></p>);
    } else if (this.state.statewide.length === 0 && !censored) {
      renderElt = (<p><b><FormattedMessage
            id='amp.page.measures.measures.po.overview.nodata'
            textComponent='span'
          />
        </b></p>);
    } else {
      let score;
      let scoreName = this.props.measure.fields.standard.find(elt => {
        return Object.keys(elt)[0] == this.props.measure.function__logic.score;
      });
      scoreName = scoreName[this.props.measure.function__logic.score];
      if (censored) {
        ScoreChart = <div></div>;
      } else {
        let statewideLabels = {};
        let statewideColorLabels = {};
        score = this.state.statewide.scores && this.state.statewide.scores.find(elt => {
         return elt.organization_id == this.props.organization.id;
        });
        if (score) {
          score = score.score;
          score = round(score);
        }
        statewideLabels[0] = 'Other Organization';
        statewideColorLabels[0] = 'secondary';
        statewideLabels[
          this.props.organization.id
        ] = this.props.organization.name;
        statewideColorLabels[this.props.organization.id] = 'primary';
        let colorPicker = (p50, p75) => {
          let oid = this.props.organization.id;
          let hib = this.props.measure.is_reported_higher_better;
          return (orgid, num) => {
            if (orgid == oid) {
              return 'primary';
            } else if ((num < p50 && hib) || (num > p50 && !hib)) {
              return 'danger';
            } else if ((num < p75 && hib) || (num > p75 && !hib)) {
              return 'warning';
            } else {
              return 'success';
            }
          };
        };
        let labelPicker = () => {
          let oid = this.props.organization.id;
          let oname = this.props.organization.name;
          return (num) => {
            if (num == oid) {
              return oname;
            } else {
              return 'Other Organization';
            }
          };
        };
        let p50 = this.state.statewide.percentiles && this.state.statewide.percentiles[2] || null;
        let p75 = this.state.statewide.percentiles && this.state.statewide.percentiles[3] || null;
        if ([3, 6].indexOf(this.props.measure.function_id) != -1 || censored) {
          ScoreChart = <div></div>;
        } else {
          ScoreChart = <ScoreRankChart
              title={this.props.measure.name_full}
              subtitle={siteMessages[
                'amp.page.measures.measures.po.scorechart.subtitle'
              ]}
              score='score'
              group='organization_id'
              labelPicker={labelPicker()}
              colorPicker={colorPicker(p50, p75)}
              data={this.state.statewide.scores}
              xAxis={{title: siteMessages[
                'amp.page.measures.measures.po.scorechart.xTitle'
              ]}}
              yAxis={{title: scoreName}}
              style={{width: '99%'}}
            />;
        }
      }
      let scoreWell;
      let legend;
      if (score) {
        scoreWell = <div className="well" style={{
            margin: '0px', verticalAlign: 'center', textAlign: 'center',
          }}>
              <b style={{fontSize: '22px'}}>
                Score: {score} {this.props.measure.function__suffix}
              </b>
            </div>;
        legend = <div>
              <ScoreGaugeLegend percentiles={true} />
            </div>;
      } else {
        scoreWell = <div></div>;
        legend = <div></div>;
      }
      renderElt =
        <div>
          <div className='container-fluid'
            style={{marginTop: '20px', marginBottom: '20px'}}
          >
            <div className='container-fluid-score-card'>
              {scoreWell}
            </div>
            <div className='container-fluid-legend pull-right'>
              {legend}
            </div>
          </div>
          {ScoreChart}
          <YoyInstanceChart
            title={this.props.measure.name_full}
            subtitle="Year-over-Year Scores"
            data={this.state.yoy}
            xAxis={{title: 'Measurement Year'}}
            yAxis={{title: scoreName}}
            style={{width: '99%'}}
          />
        </div>;
    }
    return (
      renderElt
    );
  }
};

export default MeasureVisualization;
