import React from 'react';

import getScoreLine from './getScoreLine';
import DefaultImprovement from './improvement-default';
import TccImprovement from './improvement-tcc';

// eslint-disable-next-line react/no-deprecated
class ImprovementView extends React.Component {
  render() {
    let {instances, scoreLine} = getScoreLine(this.props);
    let Improvement;
    if ([62, 63].indexOf(this.props.measure.function_id) != -1) {
      Improvement = TccImprovement;
    } else {
      Improvement = DefaultImprovement;
    }
    return (
      <Improvement
        {...this.props}
        instances={instances}
        scoreLine={scoreLine} />
    );
  }
};

export default ImprovementView;
