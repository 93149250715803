import {call, put, select, takeLatest} from 'redux-saga/effects';

import {getData} from 'common/request';

import {
  UPDATE_ORGANIZATION,
  requestOrganizationProfile,
  requestOrganizationProfileFailure,
  updateOrganizationProfile,
  editOrganizationProfileSuccess,
  editOrganizationProfileFailure,
} from 'state/actions';

import {
  REQUEST_ORGANIZATION_PROFILE,
  EDIT_ORGANIZATION_PROFILE,
  EDIT_ORGANIZATION_PROFILE_SUCCESS,
} from './constants';

import {
  getUserOrganization,
} from 'state/selectors';

export default function* () {
  yield takeLatest(REQUEST_ORGANIZATION_PROFILE,
    executeRequestOrganizationProfile);
  yield takeLatest(EDIT_ORGANIZATION_PROFILE,
    executeEditOrganizationProfile);
  yield takeLatest(EDIT_ORGANIZATION_PROFILE_SUCCESS,
    triggerRequestOrganizationProfile);
  yield takeLatest(UPDATE_ORGANIZATION,
    triggerRequestOrganizationProfile);
}

function* executeRequestOrganizationProfile({organization}) {
  const resp = yield call(getData, '/api/organization/profile/',
    {organization});
  if (resp.response === 'error') {
    yield put(requestOrganizationProfileFailure(organization));
    return;
  }
  yield put(updateOrganizationProfile(organization, resp.profile));
}

function* executeEditOrganizationProfile({organization, profile}) {
  const resp = yield call(getData, '/api/organization/profile/edit/',
    {organization, profile});
  if (resp.response === 'error') {
    yield put(editOrganizationProfileFailure(organization));
    return;
  }
  yield put(editOrganizationProfileSuccess(organization));
}

function* triggerRequestOrganizationProfile() {
  const organization = yield select(getUserOrganization);
  if (organization) {
    yield put(requestOrganizationProfile(organization.id));
  }
}
