/**
 * App entry point
 */

// Shims and Polyfills

import 'babel-polyfill'; // es6 transpilation
import 'console-polyfill'; // console.log support (for IE)
require('es6-promise').polyfill(); // promise support

const ReactHighcharts = require('react-highcharts');
require('highcharts-more')(ReactHighcharts.Highcharts);

// Libraries
import React from 'react';
import ReactDOM from 'react-dom';
import {applyMiddleware, combineReducers, createStore} from 'redux';
import {Provider} from 'react-redux';
import { createBrowserHistory } from 'history';
import {
  routerMiddleware as createRouterMiddleware,
  routerReducer,
} from 'react-router-redux';
import createSagaMiddleware from 'redux-saga';
import {createLogger} from 'redux-logger';

// styles
require('./styles/core.css');
// Routers
import Router from './routers';

// State
import {reducer as notificationsReducer} from 'react-notification-system-redux';
import reducers from 'state/reducers';
import sagas from 'state/sagas';
import {requestConfigList} from 'state/actions';
import {updateUserSession} from 'state/actions';
import {unauthUserSession} from 'state/user/reducers';


// utils
import {getData} from 'common/request';
import {DEBUG} from 'utils/constants';

import {siteMessages} from 'utils/copy';
import {IntlProvider} from 'react-intl';
// ID of the DOM element to mount app on
const APP_DOM_ID = 'app';

const browserHistory = createBrowserHistory();

const routerMiddleware = createRouterMiddleware(browserHistory);
const sagaMiddleware = createSagaMiddleware();

let middlewares = [
  routerMiddleware,
  sagaMiddleware,
];

if (DEBUG) {
  middlewares.push(createLogger());
}

const store = createStore(
  combineReducers({
    ...reducers,
    notifications: notificationsReducer,
    router: routerReducer,
  }),
  applyMiddleware(...middlewares),
);

// google analytics
import ga from 'react-ga';
ga.initialize('UA-78383433-1');
browserHistory.listen(location => ga.pageview(location.pathname));

getData('/api/user-session/')
  .catch(error => unauthUserSession)
  .then(session => {
    let s = session;
    if (s.username === undefined) {
      s = unauthUserSession;
    }
    store.dispatch(updateUserSession(s));
    store.dispatch(requestConfigList());
  });

sagaMiddleware.run(sagas);

// Start the router
ReactDOM.render(
  <IntlProvider locale='en' messages={siteMessages}>
    <Provider store={store}>
      <Router history={browserHistory} />
    </Provider>
  </IntlProvider>
, document.getElementById(APP_DOM_ID));
