import React from 'react';

import {Table, Tr} from 'common/prototable';
import {siteMessages} from 'src/utils/copy';

let round = num => {
  let n = num * 100;
  n = Math.round(n);
  return n / 100;
};

// eslint-disable-next-line react/no-deprecated
class EnrollmentView extends React.Component {
  render() {
    let tableData = [];
    let scoreLine = null;
    let loading = false;
    if (!this.props.instances.response && this.props.instances.length > 0) {
      scoreLine = this.props.scoreLine;
      if (scoreLine !== null) {
        scoreLine.data_provider__name = siteMessages[
          'amp.page.measures.measures.po.dataprovider.health.aggre'
        ];
      }
      tableData = this.props.instances.filter(elt => {
        if (elt.options.is_score) {
          return false;
        } else {
          return true;
        }
      }).map(elt => {
        let fields = elt.fields;
        Object.keys(fields).map(f => {
          if (Number.isFinite(fields[f])) {
            fields[f] = round(fields[f]);
          }
        });
        fields.data_provider__name = elt.data_provider__name;
        if (elt.data_provider_id == elt.organization_id) {
          fields.data_provider__name = siteMessages[
            'amp.page.measures.measures.po.dataprovider.self'
          ];
        }
        return fields;
      });
    } else {
      loading = true;
    }
    // table columns
    let columns = [];
    if (this.props.measure.fields) {
      columns = this.props.measure.fields.standard.map(elt => {
        let k = Object.keys(elt)[0];
        let v = elt[k];
        return {key: k, label: v};
      });
      columns.unshift({
        key: 'data_provider__name',
        label: siteMessages[
          'amp.page.measures.measures.po.dataprovider.label'
        ],
      });
    }

    // table
    let table;
    if (scoreLine !== null) {
      table = (
        <Table columns={columns}
          className='table table-responsive table-bordered'
          data={tableData} sortable={true}
        >
          <Tr className="score-row"
            style={{fontWeight: 'bold', borderTop: '2px solid #e3e3e3'}}
            columns={columns}
            data={scoreLine} />
        </Table>
      );
    } else {
      table = (
        <Table columns={columns} className='table-responsive' data={tableData}>
        </Table>
      );
    }
    if (loading) {
      table = (
        <div></div>
      );
    }
    let elements = [];
    let renderElt = (
      <div style={this.props.style}>
        {elements}
        {table}
      </div>
    );
    return (
      renderElt
    );
}
};


export default EnrollmentView;
