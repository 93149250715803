import xhr from 'xhr';
import Cookies from 'js-cookie';


export let getData = function(uri, request = {}) {
  return new Promise((resolve, reject) => {
    xhr({
      uri: uri,
      headers: {
        'X-CSRFToken': Cookies.get('csrftoken'),
        'Content-Type': 'application/json',
      },
      json: request,
      method: 'POST',
    }, function(err, resp, body) {
      if (err) {
        reject(err);
      } else {
        resolve(body);
      }
    });
  });
};
