import {
  REQUEST_ORGANIZATION_PROFILE,
  REQUEST_ORGANIZATION_PROFILE_FAILURE,
  UPDATE_ORGANIZATION_PROFILE,
  EDIT_ORGANIZATION_PROFILE,
  EDIT_ORGANIZATION_PROFILE_SUCCESS,
  EDIT_ORGANIZATION_PROFILE_FAILURE,
} from './constants';

export function requestOrganizationProfile(organization) {
  return {
    type: REQUEST_ORGANIZATION_PROFILE,
    organization,
  };
}

export function requestOrganizationProfileFailure(organization, error) {
  return {
    type: REQUEST_ORGANIZATION_PROFILE_FAILURE,
    organization,
    error,
  };
}

export function updateOrganizationProfile(organization, profile) {
  return {
    type: UPDATE_ORGANIZATION_PROFILE,
    organization,
    profile,
  };
}

export function editOrganizationProfile(organization, profile) {
  return {
    type: EDIT_ORGANIZATION_PROFILE,
    organization,
    profile,
  };
}

export function editOrganizationProfileSuccess(organization) {
  return {
    type: EDIT_ORGANIZATION_PROFILE_SUCCESS,
    organization,
  };
}

export function editOrganizationProfileFailure(organization, error) {
  return {
    type: EDIT_ORGANIZATION_PROFILE_FAILURE,
    organization,
    error,
  };
}
