import React from 'react';

import FormattedMessage from 'components/FormattedMessageMarkdown';
import './styles.scss';

const POInstructions = props => {
  if (props.isFrozen) {
    return (
      <div className='po-contract-instructions clearfix'>
        <div className='row center-block'>
          <strong>
            <FormattedMessage
              id='amp.contract.PO.instruction.frozen.content'
              textComponent='span'
            />
          </strong>
        </div>
      </div>
    );
  } else {
    return (
      <div className='po-contract-instructions'>
        <div className='row center-block'>
          <strong>
            <FormattedMessage
              id='amp.contract.PO.instruction.content'
              textComponent='span'
            />
          </strong>
        </div>
        <br />
        <div className='row center-block columns' >
          <div className='column is-one-third' className="customCommercial">
            <FormattedMessage
              id='amp.contract.PO.instruction.commercial.prod.content'
              textComponent='span'
            />
          </div>
          <div className='column is-one-third' className="customMedicare">
            <FormattedMessage
              id='amp.contract.PO.instruction.medicare.prod.content'
              textComponent='span'
            />
          </div>
          <div className='column is-one-third' className="customMedical">
            <FormattedMessage
              id='amp.contract.PO.instruction.medical.prod.content'
              textComponent='span'
            />
          </div>
        </div>
      </div>
    );
  }
};

export default POInstructions;
