/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React from 'react';
import {Table, Tr} from 'common/prototable';
import {ScoreGauge, ScoreGaugeLegend} from 'common/score-gauge';

import {RightScorePanel} from './measure-score-panel';

import {siteMessages} from 'src/utils/copy';

let round = num => {
  let n = num * 100;
  n = Math.round(n);
  return n / 100;
};

let confidenceUpper = (rate, den) => {
  if (rate==='SD') {
    return 'N/A';
  }
  rate = rate / 100;
  let c = ( rate + 1.96 * Math.sqrt((rate * (1-rate))/den) + (1/(2 * den)))*100;
  c = round(c);
  if (c > 100) {
    return '100%';
  } else if (c < 0) {
    return '0%';
  } else {
    return `${c}%`;
  }
};

let confidenceLower = (rate, den) => {
  if (rate==='SD') {
    return 'N/A';
  }
  rate = rate / 100;
  let c = ( rate - 1.96 * Math.sqrt((rate * (1-rate))/den) - (1/(2 * den)))*100;
  c = round(c);
  if (c > 100) {
    return '100%';
  } else if (c < 0) {
    return '0%';
  } else {
    return `${c}%`;
  }
};

// eslint-disable-next-line react/no-deprecated
class MeasureView extends React.Component {
  getNormalElements(scoreLine) {
    let rank = (this.props.stats ?
      (this.props.stats.rank ? this.props.stats.rank : null) : null);
    let num_orgs = (this.props.stats ?
      (this.props.stats.number_orgs ? this.props.stats.number_orgs : null)
      : null
    );
    let last_score = (this.props.stats ?
      (this.props.stats.last_score ? this.props.stats.last_score : null)
      : null
    );
    let spm = (this.props.stats ?
      (this.props.stats.spm ? this.props.stats.spm : null) : null);

    let elements = [];
    let percentiles = (this.props.stats.percentiles ?
      this.props.stats.percentiles : [0, 0, 0, 0, 0]
    );
    let scoreGaugeArea;

    let higher_is_better = this.props.measure.is_reported_higher_better;
    let min_score;
    let max_score;
    if (higher_is_better) {
      min_score = percentiles[0];
      max_score = percentiles[4];
    } else {
      max_score = percentiles[0];
      min_score = percentiles[4];
    }
    let min_gauge = ((min_score<0 || max_score<0) ?
      Math.min(Math.max(Math.abs(min_score), Math.abs(max_score)) * (-1), 0) : 0
    );
    let max_gauge = Math.max(Math.abs(min_score), Math.abs(max_score));
    let pctile_50 = percentiles[2];
    let pctile_75 = percentiles[3];
    let wellScore;
    if (scoreLine[this.props.measure.function__logic]==='SD') {
      wellScore = scoreLine[this.props.measure.function__logic.score];
      scoreGaugeArea = (
        <div style={{height: '50px'}}></div>
      );
    } else {
      wellScore = scoreLine[this.props.measure.function__logic.score] +
        ' ' + this.props.measure.function__suffix;
      scoreGaugeArea = (
        <ScoreGauge
          height={350}
          score={scoreLine[this.props.measure.function__logic.score]}
          hib={higher_is_better}
          yellow={pctile_50}
          green={pctile_75}
          min={min_gauge}
          max={max_gauge}
          suffix={this.props.measure.function__suffix} />
      );
    }
    /* *********************************************************************
    // Legacy config for priority areas that should NOT have a gauge
    let noGaugePriorityAreas = [
      'Utilization', 'Member Years', 'Outpatient Services', 'RRS Information',
    ];
    ************************************************************************/
    let noGaugePriorityAreas = ['Utilization', 'Member Years',
      'RRS Information', 'Membership'];
      // New configuration for priority areas that should NOT have a gauge.
      // NOTE: This is obviously a hideous way to configure what measures show
      // a gauge, but for now, we will continue with this horror.

    let noGaugeMeasureAbbrs = ['TCOC_SERVICE_CATEGORIES'];
      // New configuration for measure abbrs that should NOT have a gauge.
      // NOTE: This is obviously a hideous way to configure what measures show
      // a gauge, but for now, we will continue with this horror.

    if ((noGaugePriorityAreas.indexOf(
          this.props.measure.priority_area__name) === -1
        ) &
        (noGaugeMeasureAbbrs.indexOf(this.props.measure.abbr) === -1)) {
      elements.push(
            <div className="panel panel-body" style={{
              marginBottom: '0px', padding: '0px', marginTop: '-1px'}}
            >
              <div className="col-sm-6" style={{
                width: '50%', padding: '0px', margin: '0px', float: 'left'}}
              >
                {scoreGaugeArea}
                <div className="well" style={{marginRight: '10px', textAlign: 'center'}}>
                  <b style={{fontSize: '22px'}}> {wellScore} </b>
                  <br />
                  {scoreLine.data_provider__name}
                </div>
              </div>
              <div className='col-sm-6 pull-right' style={{
                width: '50%', padding: '0px', margin: '0px'}}
              >
                <ScoreGaugeLegend percentiles={true} sd={true} lr={true}
                  ud={true} prt={false} outlier={true}
                />
                <RightScorePanel
                  rank={rank}
                  numorgs={num_orgs}
                  spm={spm}
                  last_score={last_score} />
              </div>
            </div>
      );
    }

    return elements;
  }
  render() {
    let tableData = [];
    let scoreLine = {};
    let loading = false;
    let oJson = this.props.measure.function__logic.display;
    if (!this.props.instances.response && this.props.instances.length > 0) {
      scoreLine = this.props.scoreLine;
      if (scoreLine.length !== 0) {
        if (scoreLine.data_provider_id == -1) {
          scoreLine.data_provider__name=siteMessages[
            'amp.page.measures.measures.po.dataprovider.name-1'
          ];
        } else {
          scoreLine.data_provider__name=siteMessages[
            'amp.page.measures.measures.po.dataprovider.name'
          ];
        }
      } else {
        scoreLine = {};
      }
      tableData = this.props.instances.map(elt => {
        let fields = elt.fields;
        Object.keys(fields).map(f => {
          if (Number.isFinite(fields[f])) {
            fields[f] = round(fields[f]);
          }
        });
        fields.data_provider__name = elt.data_provider__name;
        if (elt.data_provider_id == elt.organization_id) {
          fields.data_provider__name = siteMessages[
            'amp.page.measures.measures.po.dataprovider.self'
          ];
          if (fields.denominator < 30) {
            fields[this.props.measure.function__logic.score] = siteMessages[
              'amp.page.measures.measures.po.score.fields.SD'
            ];
          }
        }
        if (elt.data_provider__name === siteMessages[
          'amp.page.measures.measures.po.dataprovider.iha'
        ]) {
          fields.data_provider__name = siteMessages[
            'amp.page.measures.measures.po.dataprovider.health'
          ];
        }
        return fields;
      });
    } else {
      loading = true;
    }
    // table column labels
    let allColumns = [];
    if (this.props.measure.fields) {
      allColumns = this.props.measure.fields.standard.map(elt => {
        let k = Object.keys(elt)[0];
        let v = elt[k];
        return {key: k, label: v};
      });
      allColumns.unshift({
        key: 'data_provider__name',
        label: siteMessages['amp.page.measures.measures.po.dataprovider.label'],
      });
    }
    let table;
    let columns;
    if (oJson) {
      columns = allColumns.filter(elt =>
        oJson.indexOf(elt.key) != -1 || elt.key==='data_provider__name'
      );
    } else {
      columns = allColumns;
    }
    // Neverending horribleness. See:
    //   https://phabricator.iha.org/T542#13988
    if (this.props.duration.id === 16) {
      columns = columns.filter(
        elt => [
            'is_high_cost_po',
            'pctile_range_cost_geo_risk_adj',
          ].indexOf(elt.key) === -1
      );
    }

    // table
    if (Object.keys(scoreLine).length != 0) {
      table = (
        <Table columns={columns}
          className='table table-responsive table-bordered'
          data={tableData} sortable={true}
        >
          <Tr className="score-row"
            style={{fontWeight: 'bold', borderTop: '2px solid #e3e3e3'}}
            columns={columns}
            data={scoreLine} />
        </Table>
      );
    } else {
      table = (
        <Table columns={columns}
          className='table table-responsive table-bordered'
          data={tableData} sortable={true}
        >
        </Table>
      );
    }
    if (loading) {
      table = (
        <div></div>
      );
    }

    let elements = [];
    if (Object.keys(scoreLine).length === 0) {
      // Leave empty.
    // eslint-disable-next-line no-constant-condition
    } else if (false) {
      // There used to be a hack here whose logic is retained below
      // for documentation. This is a use-case that we should have a
      // better mechanism for handling, and I have some preliminary
      // thoughts in comments below about how we might handle this.
      // So instead of simply deleting the hack I'm if (false)-ing
      // to retain the context.
      //
      // --- original condition
      // (this.props.duration.id === 16 &&
      //  this.props.measure.domain__suite_name === 'Cost') {
      // ---
      //
      // This case is a horrible special-case to handle:
      //   https://phabricator.iha.org/T873
      //
      // TODO(daishi): Clean this up.
      //
      // Plan Candidate 1:
      //
      // Step 1. Push "up" all of these kinds of special cases to the
      // top of the front-end rendering logic, so there's one place that
      // knows how to "configure" the front-end display. All
      // subcomponents would be "generic" components that can support
      // any configuration (of course, each of these generic components
      // need to have enough flexibility/configurability to support all
      // of our use-cases).
      // Step 2. Move this configuration to the backend so that the
      // front-end is fully generic and we can re-configure the display
      // by tweaking parameters on the backend.
      //
      // Plan Candidate 2:
      //
      // After I wrote up Plan Candidate 1 I realized that might not be
      // the ideal path, once one considers data security. Candidate 1
      // assumes that it's okay for the front-end to receive this data,
      // and that it's just a matter of configuring different ways of
      // presenting the information. However, in cases where we don't
      // want the data to be actually available to the user at all, we
      // really should do better and have the backend enforce these
      // rules. The frontend simply needs to be flexible enough to
      // accommodate and display the appropriate thing regardless of
      // whether there's data or not.
    } else {
      elements = this.getNormalElements(scoreLine);
    }

    let renderElt = (
      <div style={this.props.style}>
          <div className="panel panel-body" style={{
            marginBottom: '0px', padding: '0px', marginTop: '-1px'}}
          >
              <div>{elements}</div>
              <div
              >{table}</div>
          </div>
      </div>
    );
    return (
      renderElt
    );
}
};


export default MeasureView;
