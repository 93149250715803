import React from 'react';
import FormattedMessage from 'components/FormattedMessageMarkdown';
import AddContactModal from './AddContactModal';
import './styles.scss';

class AddContactButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }
  render() {
    return (
      <div className='add-contact-button'>
          <button className="button is-dark add-btn"
           onClick={() => this.setState({showModal: true})}
          >
        <span className="icon">
            <i className="fas fa-plus"></i>
                 </span>
           <span><FormattedMessage
            id='amp.contact.button.add.label' textComponent='span'
          /></span>
          </button>
         
          {this.state.showModal?
        <AddContactModal show={this.state.showModal}
        hide={() => this.setState({showModal: false})}
        container={document.getElementById('app')} />:''}
      </div>
    );
  }
}

export default AddContactButton;
