import React from 'react';
const CategorySelector = ({category, categories, onClick}) => {
  return (
    <div className="column is-2">
      {
        categories.map(c => {
          const className = c.id == category.id ? 'button is-dark' : 'button is-white';
          return (
            <div className="org-profile-left-col">
            <button key={c.id} className={className} onClick={() => onClick(c)}>{c.name}</button>
            </div>

          );
        })
      }
      </div>
  
  );
};

export default CategorySelector;
