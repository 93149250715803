/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Button from 'common/button.jsx';
import FormattedMessage from 'components/FormattedMessageMarkdown';
import { siteMessages } from 'src/utils/copy';
import './styles.scss';
import { getData } from '../../../common/request';
import UrlParams from '../../../common/urlParams';
import { useOutletContext, Outlet } from 'react-router-dom';

/* ##################
# measures reports #
##################*/

const linksDictionary = {
  "Summary": "/measures/reports/summary",
  "Quality Composite Score": "/measures/reports/spm",
  "AMP Worksheets": "/measures/reports/po_worksheet",
}

class MeasuresReportsPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      reports: []
    };
  }
  componentWillMount() {
    document.title = siteMessages['amp.page.measures.reports.title.mount'];
    this.downloadReportsList(this.props);
  }
  componentWillUpdate(nextProps) {
    document.title = siteMessages['amp.page.measures.reports.title.update'];

    if (nextProps.duration != this.props.duration ||
      nextProps.organization != this.props.organization ||
      nextProps.product != this.props.product) {
      this.downloadReportsList(nextProps);
    }
  }
  async downloadReportsList(props) {
    let url = '/api/v2/reports_list/';

    let data = await getData(url, {
      duration_id: props.duration.id,
      organization_id: props.organization.id,
      product_id: props.product.id
    });

    if (data.config) {
      this.setState({
        reports: data.config.map((report) => {
          report["link"] = linksDictionary[report["report_name"]];
          return report;
        })
      });
    }
  }
  render() {
    let reportsElt;
    reportsElt = this.state.reports.map((elt, i) => {
      let button;
      // let description;
      if (elt.link) {
        button = (<Button className="report-btn"
          kind="primary" to={elt.link}><FormattedMessage
            id='amp.page.measures.reports.btn.view.label'
            textComponent='span'
          />
        </Button>);
      } else {
        button = (<Button className="report-btn"
          disabled="true"><FormattedMessage
            id='amp.page.measures.reports.btn.view.label'
            textComponent='span'
          />
        </Button>);
      }
      return (
        <div className="well report-box healthcare-report" key={i}>
          <img src={elt.report_image_url}
            style={{ width: '100%', border: '1px solid black' }}
          />
          <h3 style={{ marginTop: '10px' }}>{elt.report_name}</h3>
          <p className="texty">{elt.report_description}</p>
          {button}
        </div>
      );
    });
    // eslint-disable-next-line no-unused-vars
    let more = (
      <h3 className='more-reports-soon'>
        <span
          className="glyphicon glyphicon-road"
          style={{ color: 'rgb(231,124,37)' }} /> <FormattedMessage
          id='amp.page.measures.reports.more.soon.text'
          textComponent='span'
        />
      </h3>
    );
    let renderElt;
    let pathname = window.location.pathname || '';
    let pathArray = pathname.split('/');
    if (pathArray[pathArray.length - 1] === 'reports' ||
      pathArray[pathArray.length - 1] === 'measures'
    ) {
      renderElt = (
        <div>
          <UrlParams
            location={this.props.location}
            history={this.props.history}
            baseUrl="/measures/reports"
            params={["organization", "duration", "product"]} />
          <br /><h5 className="page-header core-title">{this.props.organization.name}'s Reports</h5>
          <div style={{ display: 'flex' }}>{reportsElt}</div>
        </div>
      );
    } else {
      renderElt = <Outlet context={{
        organization: this.props.organization,
        product: this.props.product,
        duration: this.props.duration,
      }}/>;
    }
    return (
      renderElt
    );
  }
}
const MeasuresReportsPageProxy = (props) => {
  const context = useOutletContext();
  return <MeasuresReportsPage {...props} {...context}/>;
};


export default MeasuresReportsPageProxy;


/**
NEW:

      <div data-ng-repeat="report_row in reports" class="row">
        <div data-ng-repeat="report in report_row" class="col-md-4">
          <div class="well report-box">
            <div class="inner-box">
              <h3 class="texty">{{report.name}}</h3>
              <p class="texty">{{report.desc}}</p>
            </div>
            <p>
              <a class="btn btn-primary" href="#"
                role="button" data-ng-href="{{report.link}}"
              >
              View Report &raquo;$
          </div>
        </div>
      </div>


OLD:
      <div class="row coming-soon">
              <div class="col-md-4">
                <div class="well report-box">
                  <div class="inner-box">
                    <h3 class="texty">Quality Acheivement</h3>
                    <p class="texty">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore
                      magna aliqua.
                    </p>
                  </div>
                  <p>
                    <a class="btn btn-primary" href="#"
                      role="button" disabled
                    >View Report &raquo;</a></p>
                </div>
              </div>


      Quality Acheivement
      Testing Measures
      Enrollment
      Commercial vs. Medicare (Clinical)
      Publicly Reported
      Year-Over-Year Improvements/Declines
      Best/ Worst Performing Measures
      By Priority Area
      Encounter Rates
**/
