import React from 'react';
import { connect } from 'react-redux';

import DomainNav from 'common/domain-nav';

import { getMeasuresState } from 'state/selectors';

import { getUserState } from 'state/selectors';

import MeasureList from 'common/measure-list';
import POMeasureView from './po/page';
import HPMeasureView from './hp/page';

import FormattedMessage from 'components/FormattedMessageMarkdown';
import { siteMessages } from 'src/utils/copy';
import queryString from 'query-string';
import {
  downloadMeasureList
} from 'state/actions';
import UrlParams from '../../../common/urlParams';
import { useOutletContext } from 'react-router-dom';

/* ##################
# measures measure #
##################*/

function findByIdOrFirst(list, searchItem) {
  return list.find(elt => elt.id == searchItem) || list[0];
}

class MeasuresMeasurePage extends React.Component {
  constructor(props) {
    super(props);
    // Bookmark url options
    this.onUrlUpdate(queryString.parse(props.location.search));
    this.props.downloadMeasureList();
  }

  onUrlUpdate(params, onpopstate = false, prevProps) {
    const props = this.props;

    const measure = Array.isArray(props.measureList) && props.measureList.length > 0 ? findByIdOrFirst(
      props.measureList, params.measure) : { id: params.measure };

    const domain = Array.isArray(props.domainList) && props.domainList.length > 0 ? findByIdOrFirst(
      props.domainList, params.domain) : { id: params.domain };

    let newState = {
      selectedMeasure: measure,
      selectedDomain: domain,
      urlEnding: domain.id && measure.id ? `&domain=${domain.id}&measure=${measure.id}` : ''
    }

    if (onpopstate == false) {
      newState['relevantMeasureList'] = []
      this.state = newState;
    }
    else {
      this.setState(newState);
      this.updateMeasureListState(props);
    }

    if (onpopstate && 
      (prevProps.duration.id.toString() !== params.duration || 
      prevProps.product.id.toString() !== params.product)) {
       props.downloadMeasureList();
    }
  }

  componentWillMount() {
    document.title = siteMessages['amp.page.measures.measures.title'];
    this.updateMeasureListState();
  }

  componentWillReceiveProps(props) {
    this.updateMeasureListState(props);
  }
  // given a new measureList (in props) set state properly
  // mainly involves making sure the selectedDomain is in the new measureList
  // else forcing it - the first relevant domain becomes the new selectedDomain
  updateMeasureListState(props) {
    props = props || this.props;
    
    if (!this.state) return;
    
    let domain = findByIdOrFirst(
      props.domainList, this.state.selectedDomain.id);
    this.updateDomain(domain, props);
  }
  // given a new domain, reset state properly
  // this mainly involves making sure the selectedMeasure is in the
  // new selectedDomain, else forcing it - first relevant measure becomes the
  // new selectedMeasure
  updateDomain(domain, props) {
    props = props || this.props;
    let relevantMeasureList = props.measureList
      .filter(measure => measure.domain_id == domain.id);

    let measure = findByIdOrFirst(
      relevantMeasureList, this.state.selectedMeasure.id);

    let newState = {
      relevantMeasureList: relevantMeasureList,
    };
    if (domain) newState.selectedDomain = domain;
    if (measure) newState.selectedMeasure = measure;
    this.setState(newState);
  }
  updateMeasure(measure) {
    window.scrollTo(0, 0);
    this.setState({ selectedMeasure: measure });
    setTimeout(()=>this.updateUrlEnding(), 0);
  }
  updateUrlEnding() {
    let that = this

    const { selectedDomain, selectedMeasure } = that.state;

    this.setState({
      urlEnding:`&domain=${selectedDomain.id}&measure=${selectedMeasure.id}`
    });
  }

  handleSelect(selectedKey) {
    let kv = selectedKey.target.id.split('.');
    if (kv[0] === 'd') {
      this.updateDomain(this.props.domainList.find(elt => elt.id == kv[1]));
    } else if (kv[0] === 'm') {
      this.updateMeasure(this.props.measureList.find(elt => elt.id == kv[1]));
    }
    this.updateUrlEnding()

  }
  render() {
    let RelevantMeasureView;
    if (this.props.organization.org_type == 0) { // HP
      RelevantMeasureView = HPMeasureView;
    } else if (this.props.organization.org_type == 1) { // PO
      RelevantMeasureView = POMeasureView;
    }

    const urlParams = (  <UrlParams
      location={this.props.location}
      history={this.props.history}
      baseUrl="/measures/measure"
      params={["organization", "duration", "product"]}
      urlEnding={this.state.urlEnding}
      onUrlUpdate={(params, onpopstate, prevProps) => this.onUrlUpdate(params, onpopstate, prevProps)} />);

    let renderElt;
    if (this.props.measureList == null) {
      renderElt = (<div>{urlParams}<b><FormattedMessage
        id='amp.page.measures.measures.po.page.loading'
        textComponent='span'
      />
      </b></div>);
    } else if (this.props.measureList.length == 0 || this.props.organization.org_type == 13) {
      renderElt = (<div>{urlParams}<p><FormattedMessage
          id='amp.page.measures.measures.no.measure'
          textComponent='span'
        />
      </p></div>);
    } else {
      renderElt = (
        <div className="measure-domain">{urlParams}
          <DomainNav
            domainList={this.props.domainList}
            selectedDomain={this.state.selectedDomain}
            onSelect={this.handleSelect.bind(this)} />
          <div>
            <div className='measure-list-accordion measure-list-container'>
              <MeasureList
                measures={this.state.relevantMeasureList}
                selectedMeasure={this.state.selectedMeasure}
                updateMeasure={this.updateMeasure.bind(this)} />
            </div>
            <div className='relevant-measure-view-tabs relevant-measure-view-container'>
              <RelevantMeasureView
                measure={this.state.selectedMeasure}
                duration={this.props.duration}
                organization={this.props.organization}
                product={this.props.product} />
            </div>
          </div>
        </div>
      );
    }
    return renderElt;
  }
}

const MeasuresMeasurePageProxy = (props) => {
  const context = useOutletContext();
  return <MeasuresMeasurePage {...props} {...context}/>;
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    downloadMeasureList: () => {
      dispatch(downloadMeasureList());
    }
  };
};

const mapStateToProps = state => {
  return {
    ...getMeasuresState(state),
    userState: getUserState(state)
  }
};

export default connect(mapStateToProps)(
  connect(null, mapDispatchToProps)(MeasuresMeasurePageProxy));