/* eslint-disable react/no-deprecated */
import React from 'react';
import FormattedMessage from 'components/FormattedMessageMarkdown';
import './styles.scss';


class Footer extends React.Component{
  render(){
    let today = new Date();
    return(
        <footer className="footer" >
            <div className="content has-text-centered">
            <FormattedMessage id='amp.footer.text.copyright.tag'
            textComponent='span'
          /> &nbsp;{today.getFullYear()}&nbsp;<FormattedMessage
            id='amp.footer.text.copyright.line' textComponent='span'/>
            </div>
        </footer> 
     
    )
  }
}

export default Footer;
