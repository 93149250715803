
export const calculateTotalBaseIncentive = (measures) => measures.map(measure => parseFloat(getAttainmentValue(measure))).reduce((a, b) => a + b, 0);


export const getAttainmentValue = (measure) => {
    const level = getAttainmentLevel(measure);

    return level === -1 ? 0 : measure["attainment_incentives"][level];
}

export const getAttainmentLevel = (measure) => {
    const inverted = measure["better_direction"] === "down";

    if (measure["current_year_result"] === null || measure["previous_year_result"] === null) {
        return -1;
    }

    return Math.min(
        calculateAttainmentLevelForYear(inverted, measure["current_year_result"], measure["current_year_attainment"]),
        calculateAttainmentLevelForYear(inverted, measure["previous_year_result"], measure["previous_year_attainment"]));
}

const calculateAttainmentLevelForYear = (inverted, result, attainmentArray) => {
    let level = null;

    attainmentArray.forEach((attainment, i) => {
        if (!inverted && result < attainment && level === null) {
            level = i === 0 ? -1 : i - 1;
        }

        if (inverted && result > attainment && level === null) {
            level = i === 0 ? -1 : i - 1;
        }

        if (level === null && i === attainmentArray.length - 1) {
            level = i;
        }
    });

    return level;
};

export const calculateSharedSavings = (measures) => {
    let result = {};

    result.measures = measures.map(measure => {
        const equationFields = getEquationFields(measure);

        equationFields.forEach(fieldName => {
            measure[fieldName.replace("_equation", "")] = calculateField(measure, fieldName);
        });

        return measure;
    });

    result.sharedSavingsTotal = 
    measures.filter(measure => measure.savings_estimate)
            .map(measure => parseFloat(measure["savings_estimate"])).reduce((a, b) => a + b, 0);

    if (result.sharedSavingsTotal < 0) {
        result.sharedSavingsTotal = 0;
    }

    return result;
};

const calculateField = (measure, fieldName) => {
    const fields = Object.keys(measure);
    let equation = measure[fieldName];
    fields.forEach(field => {
        equation = equation.replace(field, measure[field]);
    });
    
    return equation.includes("null") ? null : eval(equation);
};

const getEquationFields = (measure) => Object.keys(measure).filter(fieldName => fieldName.includes("equation"));

export const round = num => {
    let n = num * 100;
    n = Math.round(n);
    return n / 100;
};