/* eslint-disable react/jsx-key */
import React from 'react';
import {Table, Thead} from 'reactable';
import {ScoreGauge} from 'common/score-gauge';
import {siteMessages} from 'src/utils/copy';

let round = num => {
  let n = num * 100;
  n = Math.round(n);
  return n / 100;
};

class MeasureView extends React.Component {
  render() {
    let tableData = [];
    let scoreLine = {};
    let table;
    // if data exists, show view, else show loading view
    if (!this.props.instances.response && this.props.instances.length > 0) {
      scoreLine = this.props.instances.filter(elt => {
        if (elt.options.is_score) {
          return true;
        }
        return false;
      });
      if (scoreLine.length > 0) {
        scoreLine = scoreLine[0].fields;

        Object.keys(scoreLine).map(f => {
          if (Number.isFinite(scoreLine[f])) {
            scoreLine[f] = round(scoreLine[f]);
          }
        });
      }
      scoreLine.data_provider__name='Score';
      tableData = this.props.instances.filter(elt => {
        if (elt.options.is_score) {
          return false;
        } else {
          return true;
        }
      }).map(elt => {
        let fields = elt.fields;
        Object.keys(fields).map(f => {
          if (Number.isFinite(fields[f])) {
            fields[f] = round(fields[f]);
          }
        });
        fields.data_provider__name = elt.data_provider__name;
        return fields;
      });

      // table columns
      let columns = [];
      if (this.props.measure.fields) {
        columns = this.props.measure.fields.standard.map(elt => {
          let k = Object.keys(elt)[0];
          let v = elt[k];
          return {key: k, label: v};
        });
        columns.unshift({
          key: 'data_provider__name',
          label: siteMessages['amp.page.measures.reports.label.data.provider'],
        });
      }

      // table
      if (scoreLine) {
        table = (
          <Table sortable
                 columns={columns}
                 className='table table-responsive table-bordered'
                 data={tableData}>
            <Thead className="score-row"
                   data={scoreLine} />
          </Table>
        );
      } else {
        table = (
          <Table columns={columns}
                 className='table-responsive'
                 data={tableData} />
        );
      }
    } else { // loading
      table = (
        <div></div>
      );
    }

    let elements = [];
    if (Object.keys(scoreLine).length > 0) { // if no score was populated
      elements = [
        <div style={{width: '60%', marginBottom: '10px'}}>
          <ScoreGauge score={scoreLine.rate}
            suffix={this.props.measure.function__suffix}
          />
        </div>,
          <div>{scoreLine.rate}</div>
      ];
    }
    return (
      <div style={this.props.style}>
        {elements}
        {table}
      </div>
    );
}
}

export default MeasureView;
