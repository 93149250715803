import {createSelector} from 'reselect';

export const getContactsState = state => state;
export const getAllContacts = state => state.contacts;
export const getUsers = state => state.users;

const getUserOrganization = (state, props) => props.organization;

export const getContacts = createSelector(
  [getAllContacts, getUserOrganization],
  (contacts, organization) => organization && contacts[organization.id] || [],
);

export const getSortedContacts = createSelector(
  [getContacts],
  contacts => contacts.sort((a, b) => a.last_name.localeCompare(b.last_name)),
);
