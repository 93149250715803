/* eslint-disable camelcase */
/* eslint-disable react/no-deprecated */
import React from 'react';

import {round} from 'utils/format';
import {siteMessages} from 'src/utils/copy';

/* ##############################
# measure summaryPanel (right) #
##############################*/

class PanelItem extends React.Component {
  render() {
  return (
    <tr>
      <td style={{fontWeight: 'bold'}}>{this.props.item}</td>
      <td>{this.props.value}</td>
    </tr>
  );
  }
};

class SubPanel extends React.Component {
  render() {
  return (
    <div className="panel panel-default" style={{marginBottom: '10px'}} >
      <table className="table panel-table"
        style={{marginBottom: '0px', marginTop: '0px'}}
      >
        <tbody>
          {this.props.panelItems}
        </tbody>
      </table>
    </div>
  );
  }
};

export class RightScorePanel extends React.Component {
  render() {
    let values = [];
    let rank = (this.props.rank? this.props.rank : null);
    let numorgs = (this.props.numorgs? this.props.numorgs : null);
    let spm = (this.props.spm? this.props.spm : null);
    let spmTotal = (this.props.spmTotal? this.props.spmTotal : 10);
    let last_score = (this.props.last_score? this.props.last_score : null);
    // eslint-disable-next-line no-constant-condition
    if (true) { // this fucking thing.....no words to describe the annoyance
      let p1 = {
        subpanel: siteMessages[
          'amp.page.measures.measures.po.subpanel.statics'
        ],
        subpanelitems: [
          {
            item: siteMessages[
              'amp.page.measures.measures.po.subpanel.item.rank'
            ],
            value: rank + ' / ' + numorgs,
          }],
      };
      if (this.props.confidenceLower) {
        p1.subpanelitems.push({
          item: siteMessages[
            'amp.page.measures.measures.po.subpanel.interval.rank'
          ],
          value: `${this.props.confidenceLower} -
            ${this.props.confidenceUpper}`,
        });
      }
      values.push(p1);
    }
    if (last_score !== null) {
      values.push({
        subpanel: siteMessages[
          'amp.page.measures.measures.po.subpanel.YoverY'
        ],
        subpanelitems: [{
          item: siteMessages[
            'amp.page.measures.measures.po.subpanel.item.Yrate'
          ],
          value: last_score,
        }],
      });
    }
    if (spm !== null) {
      values.push({
        subpanel: siteMessages[
          'amp.page.measures.measures.po.subpanel.SPM'
        ],
        subpanelitems: [
          {
            item: siteMessages[
              'amp.page.measures.measures.po.subpanel.item.attainment'
            ],
            value: spm.attainment_pts,
          },
          {
            item: siteMessages[
              'amp.page.measures.measures.po.subpanel.item.improve'
            ],
            value: spm.improvement_pts,
          },
          {
            item: siteMessages[
              'amp.page.measures.measures.po.subpanel.item.spm'
            ],
            value: round(spm.earned_pts, 2) + ' / '+ spmTotal,
          },
        ],
      });
    }

    let panels = values.map( (subpanel, idx) => {
        let panelItems = subpanel.subpanelitems.map( (pair, i) => {
            return (<PanelItem
              key={i}
              item={pair.item}
              value={pair.value} />
            );
        });
        return (<SubPanel
          key ={idx}
          panelItems={panelItems} />
        );
      });

    return (
        <div className=""
          style={{width: '100%', marginBottom: '5px', marginTop: '20px'}}
        >
          {panels}
        </div>
    );
  }
};
