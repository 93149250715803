import {fork} from 'redux-saga/effects';

import configSagas from '../config/sagas';
import contactsSagas from '../contacts/sagas';
import contractsSagas from '../contracts/sagas';
import organizationProfileSagas from '../organization-profile/sagas';
import organizationSurveySagas from '../organization-survey/sagas';
import measuresSagas from '../measures/sagas';
import userSagas from '../user/sagas';
import pingSaga from '../ping/saga';
import notificationSagas from './notification';

export default function* () {
  yield fork(configSagas);
  yield fork(contactsSagas);
  yield fork(contractsSagas);
  yield fork(organizationProfileSagas);
  yield fork(organizationSurveySagas);
  yield fork(measuresSagas);
  yield fork(userSagas);
  yield fork(pingSaga);
  yield fork(notificationSagas);
}
