// return a list of domains
let deriveDomainList = (measureList) => {
  let domainMap = {};
  measureList.filter(measure => {
    if (domainMap.hasOwnProperty(measure.domain_id)) {
      return false;
    } else {
      return (domainMap[measure.domain_id] = {
        id: measure.domain_id,
        name: measure.domain__suite_name,
      });
    }
  });
  return Object.keys(domainMap).map(key => domainMap[key]);
};

export default deriveDomainList;
