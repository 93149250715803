/* eslint-disable no-unused-vars */
import {getData} from 'common/request';

let measureListFilter = (measure) => {
  return [55, 58, 59, 60, 62, 63].indexOf(measure.domain_id) === -1;
};

let currentDay = () => {
  let d = new Date();
  return `${d.getYear()}${d.getMonth()}${d.getDay()}`;
};

let fetchMeasureList = (duration, product) => {
  let dateId = currentDay();
  let measureListId = JSON.stringify([
    'measureList', duration.id, product.id, dateId,
  ]);
  // if we can't find the measure list we want, clear local storage, so that
  // old lists don't accumulate and max out local storage.
  return getData('/api/measures/list/', {
    duration: duration.id,
    product: product.id,
  }).then(data => {
    data = data.filter(measureListFilter)
    .sort((a, b) => {
      // Sort by the measure id if we don't get sorting from the sort_order,
      // in cases where measures have a default sort_order of 10000.
      let d = a.sort_order - b.sort_order;
      if (isFinite(d) && (d != 0)) {
        return d;
      }
      // Just use the measure id for sorting.
      return a.id - b.id;
    });
    return Promise.resolve(data);
  });
};

export default fetchMeasureList;
