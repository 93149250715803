import log from 'common/log';

export const UPDATE_USER_SESSION = 'UPDATE_USER_SESSION';
export const UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION';
export const UPDATE_ORGANIZATIONS = 'UPDATE_ORGANIZATIONS';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const UPDATE_PRODUCTS = 'UPDATE_PRODUCTS';
export const UPDATE_DURATION = 'UPDATE_DURATION';
export const UPDATE_DURATIONS = 'UPDATE_DURATIONS';

export function updateUserSession(session) {
  log.debug('updateUserSession', session);
  return {
    type: UPDATE_USER_SESSION,
    session,
  };
}

export function updateOrganization(organization) {
  log.debug('updateOrganization', organization);
  return {
    type: UPDATE_ORGANIZATION,
    organization,
  };
}

export function updateOrganizations(organizations) {
  log.debug('updateOrganizations', organizations);
  return {
    type: UPDATE_ORGANIZATIONS,
    organizations,
  };
}

export function updateProduct(product) {
  log.debug('updateProduct', product);
  return {
    type: UPDATE_PRODUCT,
    product,
  };
}

export function updateProducts(products) {
  log.debug('updateProducts', products);
  return {
    type: UPDATE_PRODUCTS,
    products,
  };
}

export function updateDuration(duration) {
  log.debug('updateDuration', duration);
  return {
    type: UPDATE_DURATION,
    duration,
  };
}

export function updateDurations(durations) {
  log.debug('updateDurations', durations);
  return {
    type: UPDATE_DURATIONS,
    durations,
  };
}
