import {
  REQUEST_ORGANIZATION_SURVEY,
  REQUEST_ORGANIZATION_SURVEY_FAILURE,
  UPDATE_ORGANIZATION_SURVEY,
  EDIT_ORGANIZATION_SURVEY,
  EDIT_ORGANIZATION_SURVEY_SUCCESS,
  EDIT_ORGANIZATION_SURVEY_FAILURE,
  UPDATE_ORGANIZATION_SURVEY_CATEGORY,
} from './constants';

export function requestOrganizationSurvey(organization, category) {
  return {
    type: REQUEST_ORGANIZATION_SURVEY,
    organization,
    category,
  };
}

export function requestOrganizationSurveyFailure(
  organization, category, error) {
  return {
    type: REQUEST_ORGANIZATION_SURVEY_FAILURE,
    organization,
    category,
    error,
  };
}

export function updateOrganizationSurvey(organization, category, survey) {
  return {
    type: UPDATE_ORGANIZATION_SURVEY,
    organization,
    category,
    survey,
  };
}

export function editOrganizationSurvey(organization, category, survey) {
  return {
    type: EDIT_ORGANIZATION_SURVEY,
    organization,
    category,
    survey,
  };
}

export function editOrganizationSurveySuccess(organization, category) {
  return {
    type: EDIT_ORGANIZATION_SURVEY_SUCCESS,
    organization,
    category,
  };
}

export function editOrganizationSurveyFailure(organization, category, error) {
  return {
    type: EDIT_ORGANIZATION_SURVEY_FAILURE,
    organization,
    category,
    error,
  };
}

export function updateOrganizationSurveyCategory(category) {
  return {
    type: UPDATE_ORGANIZATION_SURVEY_CATEGORY,
    category,
  };
}
