import React from 'react';
import { connect } from 'react-redux';
import "./styles.scss"

import {
  updateOrganization,
  updateProduct,
  updateDuration,
  downloadMeasureList
} from 'state/actions';

import { getUserState } from 'state/selectors';
import { siteMessages } from 'src/utils/copy';

class ShareableLink extends React.Component {
  state = {
    linkText: siteMessages['amp.stateselector.shareablelink.text']
  };
  render() {
    return (
      <div className="shareable-link">
        <button onClick={() => {
          navigator.clipboard.writeText(window.location.href);

          this.setState({
            linkText: siteMessages['amp.stateselector.shareablelink.copied']
          });

          setTimeout(() => {
            this.setState({
              linkText: siteMessages['amp.stateselector.shareablelink.text']
            });
          }, 2000);
        }}
          title={siteMessages['amp.stateselector.shareablelink.title']}
        >{this.state.linkText}</button>
      </div>
    );
  }
}

const StateSelector = props => {
  const hideOrganization = props.hideOrganization;
  const hideDuration = props.hideDuration;
  const hideProduct = props.hideProduct;
  // organizations
  const organization = props.organization || { id: null, name: '' };
  const product = props.product || { id: null, name: '' };
  const duration = props.duration || { id: null, name: '' };

  if (props.organizations.length > 0 && props.organization && props.organizations.findIndex(x => x.id === organization.id) === -1) {
    props.updateOrganization(props.organizations[0]);
  }

  // We set 'eventKey' on each menu item, with a key of the form: [type]=[id]
  // In the event-handling function below, we then demux based on the type
  // types: organization, duration, product
  // Ex: eventKey='duration=14' will dispatch an updateDuration(duration14)
  const menuItemFn = type => {


    return function menuItem(elt) {
      return (
        <div eventKey={`${type}=${elt.id}`}
          key={elt.id}>
          {elt.name}
        </div>
      );
    };
  };

  // organizations
  const orgOptions = props.organizations
    .filter(elt => elt.id != organization.id)
    .sort((a, b) => a.name.localeCompare(b.name))
    .map(menuItemFn('organization'));
  //
  // durations
  const durationOptions = props.durations
    .filter(elt => elt.id != duration.id)
    .map(menuItemFn('duration'));

  // products
  const productOptions = props.products
    .filter(elt => elt.id != product.id)
    .map(menuItemFn('product'));

  return (
    <div className='dropdown-wrapper'>
      <div className="tabs is-boxed state-selector">
        <div style={{ marginTop: "50px" }}>
          <div className="select customDropdown" activeKey={-1}>
            {!hideProduct &&
              <div
                style={{
                  display: "inline-block",
                  position: "relative",
                  paddingTop: "8px",
                  paddingBottom: "8px",
                  paddingLeft: "10px",
                  paddingRight: "40px",
                  borderRadius: "4px"
                }}
              >
                <select
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                    opacity: "0"
                  }}
                  onChange={props.handleSelect}  >
                  <option value={`product=${product.id}`}>{product.name}</option>
                  {productOptions.map((item, key) => {
                    return (<option key={item.props.eventKey} value={item.props.eventKey} >
                      {item.props.children}
                    </option>)
                  })}
                </select>
                <div>{product.name}</div>
              </div>
            }
          </div>
          <div className="select customDropdown" activeKey={-1}>
            {!hideOrganization &&
              <div
                style={{
                  display: "inline-block",
                  position: "relative",
                  paddingTop: "8px",
                  paddingBottom: "8px",
                  paddingLeft: "10px",
                  paddingRight: "40px",
                  borderRadius: "4px"
                }}
              >
                <select
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                    opacity: "0"
                  }}
                  onChange={props.handleSelect}  >
                  <option value={`organization=${organization.id}`}>{organization.name}</option>
                  {orgOptions.map((item, key) => {

                    return (<option key={item.props.eventKey} value={item.props.eventKey} >
                      {item.props.children}
                    </option>)
                  })}
                </select>
                <div>{organization.name}</div>
              </div>
            }
          </div>
        </div>
        <div className="select customDropdown" activeKey={-1}>
          {!hideDuration &&
            <div
              style={{
                display: "inline-block",
                position: "relative",
                paddingTop: "8px",
                paddingBottom: "8px",
                paddingLeft: "10px",
                paddingRight: "40px",
                borderRadius: "4px"
              }}
            >
              <select
                style={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                  opacity: "0"
                }}
                onChange={props.handleSelect}  >
                <option value={`duration=${duration.id}`}>{duration.name}</option>
                {durationOptions.map((item, key) => {
                  return (<option key={item.props.eventKey} value={item.props.eventKey} >
                    {item.props.children}
                  </option>)
                })}</select>
              <div>{duration.name}</div>
            </div>
          }
        </div>
        {props.sharedableLink &&
          <ShareableLink />
        }
      </div>
    </div>
  )
};

const mapStateToProps = state => {
  return getUserState(state);
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    handleSelect: (e, selectedKey) => {
      const kv = e.target.value.split('=');
      const match = (elt) => elt.id == kv[1];
      if (kv[0] === 'organization') {
        dispatch(updateOrganization(ownProps.organizations.find(match)));
      } else if (kv[0] === 'duration') {
        dispatch(updateDuration(ownProps.durations.find(match)));
        dispatch(downloadMeasureList());
      } else if (kv[0] === 'product') {
        dispatch(updateProduct(ownProps.products.find(match)));
        dispatch(downloadMeasureList());
      }
    },
    updateOrganization: (organization) => {
      dispatch(updateOrganization(organization));
    }
  };
};

// extra wrapper so mapDispatchToProps' ownProps will include the proper state
export default connect(mapStateToProps)(
  connect(null, mapDispatchToProps)(StateSelector));
