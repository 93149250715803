import React from 'react';
import FormattedMessage from 'components/FormattedMessageMarkdown';

const ContactListHeader = props => {
  return (
    <thead>
      <tr>
        <th>
          <FormattedMessage id='amp.contact.table.header.label.name'
            textComponent='span'
          />
        </th>
        <th>
          <FormattedMessage id='amp.contact.table.header.label.username'
            textComponent='span'
          />
        </th>
        <th>
          <FormattedMessage id='amp.contact.table.header.label.email'
            textComponent='span'
          />
        </th>
        {props.isPrimaryContact && <th />}
      </tr>
    </thead>
  );
};

export default ContactListHeader;
