import {call, put, takeLatest} from 'redux-saga/effects';

import {getData} from 'common/request';

import {
  REQUEST_CONTACT_LIST,
  requestContactList,
  requestContactListFailure,
  updateContactList,
  REQUEST_USER_LIST,
  REQUEST_CONTRACT_USER_LIST,
  requestUserListFailure,
  updateUserList,

  ADD_CONTACT,
  ADD_CONTACT_SUCCESS,
  addContactSuccess,
  addContactFailure,
  REMOVE_CONTACT,
  REMOVE_CONTACT_SUCCESS,
  removeContactSuccess,
  removeContactFailure,
  SWITCH_PRIMARY_CONTACT,
  SWITCH_PRIMARY_CONTACT_SUCCESS,
  switchPrimaryContactSuccess,
  switchPrimaryContactFailure,
} from './actions';

export default function* () {
  yield takeLatest(REQUEST_CONTACT_LIST, executeContactListRequest);
  yield takeLatest(REQUEST_USER_LIST, executeUserListRequest);
  yield takeLatest(REQUEST_CONTRACT_USER_LIST, executeContractUserListRequest);
  yield takeLatest(ADD_CONTACT, executeAddContact);
  yield takeLatest(REMOVE_CONTACT, executeRemoveContact);
  yield takeLatest(SWITCH_PRIMARY_CONTACT, executeSwitchPrimaryContact);
  yield takeLatest([
    ADD_CONTACT_SUCCESS,
    REMOVE_CONTACT_SUCCESS,
    SWITCH_PRIMARY_CONTACT_SUCCESS], triggerContactListRequest);
}

function* triggerContactListRequest({organization}) {
  yield put(requestContactList(organization));
}

function* executeContactListRequest({organization}) {
  const resp = yield call(getData, '/api/contacts/list/', {organization});
  if (resp.response === 'error') {
    yield put(requestContactListFailure(organization, null));
    return;
  }
  yield put(updateContactList(organization, resp.contacts));
}

function* executeUserListRequest(action) {
  const resp = yield call(getData, '/api/users/');
  if ((typeof(resp) != 'object') || (resp.response === 'error')) {
    // temporary fix for https://phabricator.iha.org/T883
    // TODO consider using something like https://caolan.github.io/async/docs.html#retry
    yield put(requestUserListFailure(null));
    executeUserListRequest(action);
    return;
  }
  yield put(updateUserList(resp.users));
}

function* executeContractUserListRequest({hp_id, po_id, pcon_contacts}, action) {
  const resp = yield call(getData, '/api/users-contract/', {hp_id, po_id, pcon_contacts});
  if (resp === null || (typeof(resp) != 'object') || (resp.response === 'error')) {
    yield put(requestUserListFailure("Error fetching users"));
    executeContractUserListRequest(action);
    return;
  }
  yield put(updateUserList(resp.users));
}

function* executeAddContact({organization, username}) {
  const resp = yield call(getData, '/api/contacts/add/', {
    organization, username,
  });
  if (resp.response === 'error') {
    yield put(addContactFailure(organization, username));
    return;
  }
  yield put(addContactSuccess(organization, username));
}

function* executeRemoveContact({organization, username}) {
  const resp = yield call(
    getData, '/api/contacts/remove/', {organization, username});
  if (resp.response === 'error') {
    yield put(removeContactFailure(organization, username));
    return;
  }
  yield put(removeContactSuccess(organization, username));
}

function* executeSwitchPrimaryContact({organization, username}) {
  const resp = yield call(
    getData, '/api/contacts/switch_primary/', {organization, username});
  if (resp.response === 'error') {
    yield put(switchPrimaryContactFailure(organization, username));
    return;
  }
  yield put(switchPrimaryContactSuccess(organization, username));
}
