import React from 'react';
import {Table} from 'common/prototable';
import {ScoreGaugeLegend} from 'common/score-gauge';

import {siteMessages} from 'src/utils/copy';

let round = num => {
  let n = num * 100;
  n = Math.round(n);
  return n / 100;
};

// IMPORTANT: This function needs to be consistent with the
//   show_data_provider_specific_results
// function in p4p_backend p4p/measures/views.py.
// But since it's not as convenient to join domains in Javascript,
// we explicity query against the suitedomain ids.
// TODO(daishi): Eventually just pass this information back from the
// backend so that the frontend can be generic.
let aruTccDomainIds = [
  4, // Resource Use - ARU
  5, // Resource Use - Cost
];
let showDataProviderSpecificResults = measureObj => {
  if (aruTccDomainIds.indexOf(measureObj.domain_id) !== -1) {
    return true;
  }
  return false;
};


class MeasureView extends React.Component {
  render() {
    let oJson = this.props.measure.function__logic.display;
    let tableData = [];
    let table;
    let allColumns = [];
    let columns;
    if (!this.props.instances.response && this.props.instances.length > 0) {
      // this whole thing is table jiggling logic
      tableData = this.props.instances.map(elt => {
        let fields = elt.fields;
        Object.keys(fields).map(f => {
          if (Number.isFinite(fields[f])) {
            fields[f] = round(fields[f]);
          }
        });
        fields.organization__name = elt.organization__name;
        fields.organization__dmhc_id = elt.organization__dmhc_id;
        return fields;
      });

      if (this.props.measure.fields) {
        allColumns = this.props.measure.fields.standard.map(elt => {
          let k = Object.keys(elt)[0];
          let v = elt[k];
          return {key: k, label: v};
        });
        allColumns.unshift({
          key: 'organization__name',
          label: siteMessages[
            'amp.page.measures.measures.hp.overview.org.label'
          ],
        });
        allColumns.unshift({
          key: 'organization__dmhc_id',
          label: siteMessages[
            'amp.page.measures.measures.hp.overview.DMHC.label'
          ],
        });
      }
      if (oJson) {
        columns = allColumns.filter(elt =>
          oJson.indexOf(elt.key) != -1 || elt.key==='organization__name' ||
          elt.key==='organization__dmhc_id'
        );
      } else {
        columns = allColumns;
      }
      // Neverending horribleness. See:
      //   https://phabricator.iha.org/T542#13988
      if (this.props.duration.id === 16) {
        columns = columns.filter(
          elt => [
              'is_high_cost_po',
              'pctile_range_cost_geo_risk_adj',
            ].indexOf(elt.key) === -1
        );
      }
      if (this.props.measure.function_id == 4) {
        columns = columns.filter(elt => elt.key !== 'pts_earned');
      }
      let rowTitle = siteMessages[
        'amp.page.measures.measures.hp.overview.reported.results.title'
      ];
      if (showDataProviderSpecificResults(this.props.measure)) {
        rowTitle = siteMessages[
          'amp.page.measures.measures.hp.overview.unaggre.results.title'
        ];
      }
      table = (
        <Table
          columns={columns}
          className='table table-bordered'
          data={tableData}
          topRow={[['', 2], [rowTitle, columns.length - 2]]}
          sortable={true}>
        </Table>
      );
    } else { // loading
      table = (
         <div></div>
      );
    }
    let renderElt = (
      <div className="panel panel-body measures-hp-overview"
           style={this.props.style}>
        <div className="measures-measure-legend">
          <ScoreGaugeLegend sd lr ud prt outlier />
        </div>
        <div className="table-responsive table-overflow">{table}</div>
      </div>
    );
    return (
      renderElt
    );
  }
}

export default MeasureView;
