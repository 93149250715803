import {put, select, takeLatest} from 'redux-saga/effects';

import {
  REQUEST_MEASURE_LIST,
  DOWNLOAD_MEASURE_LIST,
  requestMeasureList,
  updateMeasureList,
} from '../actions';

import {
  isConsistentUserState,
  getUserState,
} from '../selectors';

import fetchMeasureList from 'common/fetch-measure-list';

export default function* () {
  yield takeLatest(REQUEST_MEASURE_LIST, executeMeasureListRequest);
  yield takeLatest(
    [DOWNLOAD_MEASURE_LIST], triggerMeasureListRequest);
}

function* executeMeasureListRequest({duration, product, program}) {
  const data = yield fetchMeasureList(duration, product);
  yield put(updateMeasureList(data));
}

function* triggerMeasureListRequest() {
  const shouldRequestMeasureList = yield select(isConsistentUserState);
  if (shouldRequestMeasureList) {
    const {duration, product} = yield select(getUserState);
    yield put(requestMeasureList(duration, product));
  }
}
