

import React from 'react';
import ContractModal from './ContractModal';
import FormattedMessage from 'components/FormattedMessageMarkdown';
import './styles.scss';
import '../../../src/common/styles.scss'

class EditContractButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      customClassName:""
    };
  }
  handleCustomClassName=()=>{
    this.setState({
      customClassName:""
    });
  }
  render() {
    return (
      <div className={`modal-container control-item edit-contact ${this.state.customClassName}`}>
          <div className="tooltip_block">
          <button className="button is-warning is-small has-tooltip"
            onClick={() => this.setState({showModal: true,customClassName:"active_bg"})}>
            <span className="icon" >
            <i className="fas fa-wrench"></i>
            </span>
          </button>
          <div className="tooltip_edit_data">
            <FormattedMessage
              id='amp.contract.edit.button.label'
              textComponent='span'
            />
          </div>
          </div>
        <div>
        <ContractModal show={this.state.showModal}
                       handleCustomClassName={this.handleCustomClassName}
                       hide={() => this.setState({showModal: false})}
                       container={document.getElementById('app')}
                       organization={this.props.organization}
                       contract={this.props.contract} />
                       </div>
                       <div className="mymodal"></div>
                       <div></div>
      </div>
    );
  }
}

export default EditContractButton;
