import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import store from 'store';
import { getData } from 'common/request';

import {
  UPDATE_USER_SESSION,
  UPDATE_ORGANIZATIONS,
  updateOrganizations,
  updateOrganization,
  UPDATE_PRODUCTS,
  updateProducts,
  updateProduct,
  UPDATE_DURATIONS,
  updateDurations,
  updateDuration,
  UPDATE_PRODUCT
} from '../actions';

import queryString from 'query-string';

import {
  getUserOrganization,
  getUserProduct,
  getUserDuration,
} from '../selectors';

export default function* () {
  yield takeLatest(UPDATE_ORGANIZATIONS, updateDefaultOrganization);
  yield takeLatest(UPDATE_PRODUCTS, updateDefaultProduct);
  yield takeLatest(UPDATE_DURATIONS, updateDefaultDuration);

  yield takeLatest(UPDATE_USER_SESSION, handleSessionUpdate);
  yield takeLatest(UPDATE_PRODUCT, handleProductUpdate)
}

function* updateDefaultOrganization({ organizations }) {
  const organization = yield select(getUserOrganization);
  if (!organization && organizations.length) {
    const urlParams = queryString.parse(window.location.search);
    let organization = null;

    if (urlParams.organization) {
      organization = organizations.find(x=> urlParams.organization === x.id.toString());
    }

    yield put(updateOrganization(organization || organizations[0]));
  }
}

function* updateFromSavedOrganization() {
  const organization = yield call([store, 'get'], 'organization');
  if (organization) {
    yield put(updateOrganization(organization));
  }
}

function* updateDefaultProduct({ products }) {
  const product = yield select(getUserProduct);
  if (!product && products.length) {
    const urlParams = queryString.parse(window.location.search);
    let product = null;

    if (urlParams.product) {
      product = products.find(x=> urlParams.product === x.id.toString());
    }

    yield put(updateProduct(product || products[0]));
  }
}

function* updateDefaultDuration({ durations }) {
  const duration = yield select(getUserDuration);
  if (!duration && durations.length) {
    yield put(updateDuration(durations[0]));
  }
}

function* handleSessionUpdate(action) {
  const { username, org_types } = action.session; // eslint-disable-line camelcase
  if (!username) {
    yield redirectToLogin();
  } else if (org_types.length > 0) {
    yield fetchP4PState();
  }
}

function* handleProductUpdate(action) {
  // NOTE: The request body will have a key for "product",
  // not "product_id" to be like other requests.
  const orgData = yield call(getData, '/api/v2/orgs/', { "product": action.product.id }),
    hp = orgData.hp || [],
    po = orgData.po || [],
    super_po = orgData.super_po || [],
    orgs = hp.concat(po).concat(super_po);

  yield put(updateOrganizations(orgs));
}

function* fetchP4PState() {
  yield call(updateFromSavedOrganization);
  const [
    products,
    durations,
  ] = yield all([
    call(getData, '/api/products/'),
    call(getData, '/api/durations/'),
  ]);
  yield all([
    put(updateProducts(products)),
    put(updateDurations(durations)),
  ]);
}

function redirectToLogin() {
  window.location.href = '/login/';
}
