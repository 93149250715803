import React from 'react';
import { getData } from 'common/request.js';

import PlainView from './overview';
import ImprovementView from './improvement';

import FormattedMessage from 'components/FormattedMessageMarkdown';
import { siteMessages } from 'utils/copy';

// eslint-disable-next-line react/no-deprecated
class MeasureOverview extends React.Component {
  state = {
    instances: [],
    stats: {},
    loading: true,
  };
  updateMeasureView(obj) {
    if (obj.measure && obj.duration && obj.organization) {
      this.setState({ loading: true });
      getData('/api/measures/dpinstances/', {
        duration: obj.duration.id,
        measure: obj.measure.id,
        organization: obj.organization.id,
      }).then((data) => {
        this.setState({ instances: data, loading: false });
      });
    }
  }
  componentWillMount() {
    this.updateMeasureView(this.props);
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.duration.id != nextProps.duration.id || 
        this.props.measure.id != nextProps.measure.id || 
        this.props.organization.id != nextProps.organization.id) {
      this.updateMeasureView(nextProps);
    }
  }
  handleSelect(selectedKey) {
  }
  render() {
    let renderElt = null;
    if (this.state.loading) {
      renderElt = (<p><b><FormattedMessage
        id='amp.page.measures.measures.overview.loading'
        textComponent='span'
      />
      </b></p>);
    } else if (!Array.isArray(this.state.instances) || this.state.instances.length === 0) {
      renderElt = (<p><b><FormattedMessage
        id='amp.page.measures.measures.overview.nodata'
        textComponent='span'
      />
      </b></p>);
    } else {
      let scoreName = this.props.measure.fields.standard.find(elt => {
        return Object.keys(elt)[0] == this.props.measure.function__logic.score;
      });

      // Default the instance data to the instance data in state, as
      // returned from the server without processing.
      let instances = this.state.instances;
      if (typeof scoreName !== 'undefined') {
        // We have a score field defined, so we can use it to filter out
        // bad rows.
        scoreName = scoreName[this.props.measure.function__logic.score];
        // filter out bad rows (probably not necessary)
        instances = this.state.instances.map(elt => {
          if (elt.options.is_no_benefit) {
            elt.fields[scoreName] =
              siteMessages['amp.page.measures.measures.scorename.NB'];
          } else if (elt.options.is_biased) {
            elt.fields[scoreName] =
              siteMessages['amp.page.measures.measures.scorename.BR'];
          } else if (elt.options.is_not_reported) {
            elt.fields[scoreName] =
              siteMessages['amp.page.measures.measures.scorename.NR'];
          }
          return elt;
        });
      }
      if (this.props.improvement) {
        renderElt = (
          <ImprovementView
            organization={this.props.organization}
            duration={this.props.duration}
            measure={this.props.measure}
            instances={instances}
            stats={this.state.stats} />
        );
      } else {
        renderElt = (
          <PlainView
            duration={this.props.duration}
            measure={this.props.measure}
            instances={instances}
            stats={this.state.stats} />
        );
      }
    }
    return renderElt;
  }
};

export default MeasureOverview;
