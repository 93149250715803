
import React from 'react';
import {connect} from 'react-redux';
import {addContact, requestUserList} from 'state/actions';
import {getUserOrganization, getUsers} from 'state/selectors';

import FormattedMessage from 'components/FormattedMessageMarkdown';
import {siteMessages} from 'src/utils/copy';
import './styles.scss';
import Loading from '../../common/Loading';


class AddContactModal extends React.Component {
  constructor(props) {
    super(props);
    props.requestUserList();
    this.state = {
      search: '',
      selectedUser: null,
    };
  }
  handleSearchChange(event) {
    this.setState({search: event.target.value});
  }
  handleRadioChange(event) {
    this.setState({selectedUser: event.target.value});
  }
  handleAddContact() {
    this.props.addContact(this.props.organization.id, this.state.selectedUser);
    this.hide();
  }
  hide() {
    this.setState({search: '', selectedUser: null});
    this.props.hide();
  }
  searchedUsers() {
    return this.props.users.filter(u => {
      return [u.first_name, u.last_name, u.username].join(' ')
        .toLowerCase()
        .search(this.state.search) != -1;
    }).sort((a, b) => a.first_name.localeCompare(b.first_name));
  }
  render() {
    return (
    
      <div>
        <div className="modal-background" onClick={this.hide.bind(this)}></div>
        <div className={`modal-card ${this.props.show ? 'fadeInDown' : 'fadeOutUp'}`}>
            <header className="modal-card-head">
                <span className="modal-card-title">
                <FormattedMessage id='amp.contact.add.modal.title'
              textComponent='span'
            />
                </span>
                <button className="delete" aria-label="close" onClick={this.hide.bind(this)}></button>
            </header>
            <section className="modal-card-body">
                <div className="field">
                   <label className="label"><FormattedMessage id='amp.contact.add.modal.select.user'
                 textComponent='span'
              /></label>
                    <div className="control">
                        <input className="input" type="text"
                        placeholder= {
                           siteMessages['amp.contact.add.modal.searchbox.placeholder']
                             }
                        value={this.state.search}
                        onChange={this.handleSearchChange.bind(this)} 
                        />
                    </div>
                </div>
                {this.props.usersAreLoading ? <Loading /> :
                  <div className="contact-modal-radio-group">
                    {this.searchedUsers().map(u => {
                      return (
                        <div className="control">
                          <label key={u.username} className="radio">
                            <input type='radio' name="answer"
                              value={u.username}
                              checked={this.state.selectedUser === u.username}
                              onChange={this.handleRadioChange.bind(this)} />
                            {u.first_name} {u.last_name} - {u.username}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                }
                <div className="notification is-link is-light">
                    <p>If you don't see the user you are looking for, they may need to create an account at <a href="https://sso-prod.iha.org/accounts/register/"
                            target="_blank" rel="noopener noreferrer">https://sso.iha.org/accounts/register/</a> first.</p>
                </div>
            </section>
            <footer className="modal-card-foot">
                <div className="buttons">
                    <button className="button "  onClick={this.hide.bind(this)}>
                    <FormattedMessage id='amp.contact.add.modal.button.close.label'
              textComponent='span'
          /> </button>
                    <button className="button is-dark add-contact-btn" onClick={this.handleAddContact.bind(this)}>
                    <FormattedMessage id='amp.contact.add.modal.button.add.label'
              textComponent='span'
            /> <span>{ this.state.selectedUser || ''}</span>
                      </button>
                      
                </div>
            </footer>
            </div>
              </div>
            
    );
  }
}

const mapStateToProps = state => {
  return {
    users: getUsers(state),
    organization: getUserOrganization(state),
    usersAreLoading: state.contacts.usersAreLoading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addContact: (organization, user) => dispatch(
      addContact(organization, user)),
    requestUserList: () => dispatch(requestUserList()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddContactModal);

