/* eslint-disable max-len */
import React from 'react';
import {connect} from 'react-redux';
import {
  removeContact,
  switchPrimaryContact,
} from 'state/actions';

import FormattedMessage from 'components/FormattedMessageMarkdown';
import './styles.scss';

const ContactListRow = props => {
  const label = props.contact.primary_contact &&
  <span className="tag is-dark"><FormattedMessage
        id='amp.contact.list.row.primary.label'
        textComponent='span'
      />
    </span>;
  const primaryContactControl = props.isPrimaryContact &&
    <td>
      {!props.contact.primary_contact &&
        <div className="tooltip-custom-block block-custom-inline">
          <button className=" button is-warning is-small"
                  onClick={props.switchPrimaryContact.bind(
                    null, props.organization, props.contact)}>
            <span className="icon" >
            <i className="fas fa-star"></i>
            </span>
          </button>
          <div className="tooltip-custom-text">
            <FormattedMessage
              id='amp.contact.list.row.primary.data.switch.tooltip.label'
              textComponent='span'
            />
          </div>
        </div>
        }
      {!props.contact.primary_contact &&
        <div className="tooltip-custom-block block-custom-inline">
          <button className="button is-danger is-small"
                  onClick={props.removeContact.bind(
                    null, props.organization, props.contact)}>
            <span className="icon" >
            <i className="fas fa-times"></i>
            </span>
          </button>
          <div className="tooltip-custom-text custom-remove ">
            <FormattedMessage
              id='amp.contact.list.row.primary.data.remove.tooltip.label'
              textComponent='span'
            />
          </div>
        </div>
        }

    </td>;
  return (
    <tr >
      <td>{props.contact.first_name} {props.contact.last_name} {label}</td>
      <td>{props.contact.username}</td>
      <td>{props.contact.email}</td>
      {primaryContactControl}
    </tr>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    removeContact: (organization, contact) => dispatch(
      removeContact(organization.id, contact.username)),
    switchPrimaryContact: (organization, contact) => dispatch(
      switchPrimaryContact(organization.id, contact.username)),
  };
};

export default connect(null, mapDispatchToProps)(ContactListRow);
