import {createSelector} from 'reselect';

export function shiftSelector(preSelector, selector) {
  return createSelector(
    [preSelector, (_, props) => props],
    (newState, props) => selector(newState, props)
  );
}

// takes scoped selectors and moves them to global scope
// eg: before: getUser = userState => userState.user
//     after: getUser = state => state.userState.user
export function shiftSelectors(preSelector, selectors) {
  let obj = {};
  for (let [key, selector] of Object.entries(selectors)) {
    obj[key] = shiftSelector(preSelector, selector);
  }
  return obj;
}
