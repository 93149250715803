import {
  UPDATE_ORGANIZATION_PROFILE,
  REQUEST_ORGANIZATION_PROFILE,
  REQUEST_ORGANIZATION_PROFILE_FAILURE,
} from './constants';

const initialState = {
  // profiles - an object, with the keys being the id of an organization,
  //   and the values being objects representing the organization profile
  //   Caveat: The keys of health plan profile objects are slightly different
  //   than PO contract objects. The differences are best seen looking
  //   at the src/pages/organization-profile/OrganizationProfileForm.js file.
  profiles: {},
};

export default function(state=initialState, action) {
  switch (action.type) {
    case UPDATE_ORGANIZATION_PROFILE:
      return {
        ...state,
        organizationProfileIsloading: false,
        profiles: {
          ...state.profiles,
          [action.organization]: action.profile,
        },
      };
    case REQUEST_ORGANIZATION_PROFILE_FAILURE:
      return {
        ...state,
        organizationProfileIsloading: false,
      };
    case REQUEST_ORGANIZATION_PROFILE:
      return {
        ...state,
        organizationProfileIsloading: true,
      };
  }
  return state;
}
