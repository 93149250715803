import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm'
import {string} from 'prop-types';

function Markdown(props) {
  return (
    <ReactMarkdown
      source={props.source}
      transformLinkUri={false}
      children={props.source}
      remarkPlugins={[remarkGfm]}/>
  );
}

Markdown.propTypes = {
  source: string.isRequired,
};

export default Markdown;
