import React from 'react';
import {connect} from 'react-redux';

import {
  requestContactList,
} from 'state/actions';

import {
  getUserState,
  isConsistentUserState,
  isOrglessAndNotLoading,
} from 'state/selectors';

import Loading from 'common/Loading';
import OrglessWarning from 'common/OrglessWarning';
import StateSelector from 'common/state-selector';
import AddContactButton from './AddContactButton';
import ContactList from './ContactList';
import UrlParams from '../../common/urlParams';

class ContactsPage extends React.PureComponent {
  constructor(props) {
    super(props);
  }
  componentWillUpdate(nextProps) {
    if (nextProps.organization != this.props.organization) {
          this.requestContactList(nextProps);
    }
  }
  componentWillMount() {
    this.requestContactList(this.props);
  }
  requestContactList(props) {
    if (props.isConsistentUserState) {
      this.props.requestContactList(props.organization.id);
    }
  }
  render() {
    let content;
    if (this.props.isConsistentUserState) {
      content = (
        <div>
          <AddContactButton />
          <ContactList {...this.props} />
        </div>
      );
    } else if (this.props.isOrglessAndNotLoading) {
      content = <OrglessWarning />;
    } else {
      content = <Loading />;
    }
    return (
      <div className='contacts-page'>
        <UrlParams
          location={this.props.location}
          history={this.props.history}
          baseUrl="/contacts"
          params={["organization"]} />
        <StateSelector
          hideDuration={true}
          hideProduct={true} />
        <div className='center-block contacts-content'>
          {content}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...getUserState(state),
    isConsistentUserState: isConsistentUserState(state),
    isOrglessAndNotLoading: isOrglessAndNotLoading(state),
    contactsAreLoading: state.contacts.contactsAreLoading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    requestContactList: organization => dispatch(
      requestContactList(organization)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ContactsPage);
