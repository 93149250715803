import {createSelector} from 'reselect';

import {
  STATUS_CONFIG,
  ORGANIZATION_HP,
  ORGANIZATION_PO,
} from 'state/constants';

export const getContractsState = state => state;
export const getAllContracts = state => state.contracts;
export const getOrganizationProducts = state => state.products;
export const isContractsFrozen = state => !!state.frozen;
export const getContractHps = state => state.hps;
export const getContractPos = state => state.pos;

const getUserOrganization = (state, props) => props.organization;

export const getContractOrganizations = createSelector(
  [getContractHps, getContractPos, getUserOrganization],
  (hps, pos, organization) => !organization ?
    [] :
    organization.org_type == ORGANIZATION_PO ?
      hps : organization.org_type == ORGANIZATION_HP ?
        pos : [],
);

export const getSortedContractOrganizations = createSelector(
  [getContractOrganizations],
  (organizations) => organizations.sort((a, b) => a.name.localeCompare(b.name)),
);

const statusString = code => {
  if (STATUS_CONFIG[code] == undefined) {
    return {};
  } else {
    return STATUS_CONFIG[code];
  }
};

export const getContracts = createSelector(
  [getAllContracts, getUserOrganization],
  (contracts, organization) => (organization && contracts[organization.id] ||
    []).map(c => {
      c.status = statusString(c.contract_status);
      return c;
    }),
);

export const getSortedContracts = createSelector(
  [getContracts, getUserOrganization],
  (contracts, organization) =>(organization.org_type == ORGANIZATION_PO) ?
    contracts.sort((a, b) =>
      a.status.sortOrder.po < b.status.sortOrder.po ? 1 : -1) :
      contracts.sort((a, b) =>
        a.status.sortOrder.hp < b.status.sortOrder.hp ? 1 : -1)
);
