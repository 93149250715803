// /* eslint-disable no-unused-vars */
// /* eslint-disable max-len */
// /* eslint-disable camelcase */
// /* eslint-disable react/jsx-key */

import React, { useEffect, useState } from 'react';
import { getData } from '../../../common/request.js';
import Button from '../../../common/button.jsx';
import { report } from 'common/report';

import { useOutletContext } from 'react-router-dom';
import './style.scss'


import {
  PRODUCT_ACO,
} from '../../../state/user/constants';

import FormattedMessage from 'components/FormattedMessageMarkdown';
import { siteMessages } from 'src/utils/copy';
import { downloadList } from './list_download_mock';
import { connect } from 'react-redux';

import {
  downloadMeasureList
} from 'state/actions';
import DownloadButton from '../../../common/download-button.js';
import Dialog from '../../../common/dialog.js';
import UrlParams from '../../../common/urlParams';

// import { getUserState } from 'src/state/selectors';

/* ###################
# measures download #
###################*/

class DownloadItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      failDialogIsActive: false
    }
  }
  render() {
    let button;
    if (this.props.direct) {
      if(this.props.href=="undefined"){
        button = (
          <div className="customCursor"> 
          <Button
              className="download-btn customDisabled"
              kind="danger"
              onClick={() => {}}
          >
              {this.props.name}
          </Button>
          </div>
        );
        
      } else if (this.props.href!="undefined"){
        button = (
        <DownloadButton
          className="download-btn"
          kind="primary"
          onDownloadFailed = {() => this.setState({failDialogIsActive: true})}
          href={this.props.href}>
          {this.props.name}
        </DownloadButton>
      );
    }
    } else if (this.props.s3) {
      button = (
        <DownloadButton
          className="download-btn benchmarkbtn"
          kind="primary"
          onDownloadFailed={() => this.setState({failDialogIsActive: true})}
          onClick={(download) => {
            console.log('s3click', this.props.organization.id, this.props.item );
            getData('/api/measures/dl/link/', { organization: this.props.organization.id, item: this.props.item })
              .then(data => {
                if (data.response === 'success') {
                  download(data.data);
                } else {
                  download(null);
                  this.setState({failDialogIsActive: true});
                }
              });
          }
          }>
          {this.props.name}
        </DownloadButton>
      );
    } else { // to be handled by django backend
      button = (
        <DownloadButton
        className="download-btn benchmarkbtn"
        kind="primary"
        onDownloadFailed={() => this.setState({failDialogIsActive: true})}
        onClick={(download) => {
          const requestData = () => {
            getData(this.props.href).then(data => {
              if (data.response) {
                if (data.response === 'done') {
                  download(this.props.href);
                } else {
                  setTimeout(requestData.bind(this), 2000);
                }
              } else {
                download(null);
                this.setState({failDialogIsActive: true});
              }
            });
          };

          requestData();
        }
        }>
        {this.props.name}
      </DownloadButton>
      );
    }
    let description;
    if (!this.props.href && !this.props.name) {
      return (
        <div>
          <FormattedMessage
            defaultMessage={this.props.description}
          />
        </div>
      );
    }
    if (this.props.descriptionId1 && this.props.descriptionId2) {
      description = (
        <div className="download-btn-description">
          <FormattedMessage id={this.props.descriptionId1}
            textComponent='span'
          /><span>{this.props.params}</span><FormattedMessage
            id={this.props.descriptionId2}
            textComponent='span'
          />
        </div>
      );
    } else if (this.props.descriptionId) {
      description = (
        <div className="download-btn-description">
          <FormattedMessage id={this.props.descriptionId} />
        </div>
      );
    } else {
      description = (
        <div className="download-btn-description">
          <FormattedMessage
            defaultMessage={this.props.description}
          />
        </div>
      );
    }
    return (
      <div style={{ marginBottom: '15px' }}>
        {this.state.failDialogIsActive &&
          <Dialog
            isActive={this.state.failDialogIsActive}
            onCloseClick={() => this.setState({ failDialogIsActive: false })}
            header={siteMessages['amp.measures.download-fail-dialog.header']}>
              <i className="fas fa-exclamation-circle fa-3x"></i><div className="download-fail-message" dangerouslySetInnerHTML={{ __html: siteMessages['amp.measures.download-fail-dialog.text'] }}></div>
          </Dialog>}
        <table>
          <tbody>
            <tr>
              <td>
                <div>
                  {button}
                </div>
              </td>
              <td>
                {description}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}
const DownloadTitle = (props) => {
  if (props.title || props.children) {
    return (
      <h3 className="page-header">
        {props.title || props.children}
      </h3>
    );
  }
  return null;
};

const downloadsTab = (tabContent, organization, isDisabled) => (
  <div
   key={tabContent.tabKey}
    title={tabContent.tabTitle}
    disabled={isDisabled}
  >
    <div className='download-tab-container'>
      <DownloadTitle
        title={tabContent.orgSpecificDownloadsTitle}
      />
      <div>
        {
          tabContent.orgSpecificDownloads.map(download => (
            <DownloadItem
              name={download.name}
              href={download.metadata.link}
              organization={organization}
              description={download.description}
              direct={download.metadata.direct}
              s3={download.metadata.s3}
              item={download.metadata.item}
            />
          ))
        }
			 {tabContent.orgSpecificDownloads.length == 0 && 
			 tabContent.tabKey != 3 ? 
       <span className="text-italic">Downloadable files are not available for the currently selected organization, measurement year and product.
       </span> : "" }
      </div>
      <DownloadTitle
        title={tabContent.otherDownloadTitle}
      />
      <div>
        {
          tabContent.otherDownloads.map(download => (
            <DownloadItem
              name={download.name}
              href={download.metadata.link}
              organization={organization}
              description={download.description}
              direct={download.metadata.direct}
              s3={download.metadata.s3}
              item={download.metadata.item}
            />
          ))
        }
        {tabContent.otherDownloads.length == 0 && 
        tabContent.tabKey != 3 ? 
        <span className="text-italic">Downloadable files are not available for the currently selected organization, measurement year and product.
        </span> : ""} 
        {tabContent.tabKey == 3 && tabContent.otherDownloads.length == 0 ? 
        <span className = "text-italic">Downloadable files are not available for the currently selected organization, measurement year and product.
        </span> : ""} 
      </div>
    </div>
    </div>

 
);

const legacyDownloadsRender = (props) => {
  // populate these arrays
  // current pattern is:
  //   [DownloadTitle, <div>{[DownloadItem, DownloadItem,
  //    etc]}</div>, etc]
  let qualityTabElts = [];
  let efficiencyTabElts = [];
  let opaTabElts = [];

  // eslint-disable-next-line max-len
  let urlEnding = 'organization=' + props.organization.id + '&duration=' + props.duration.id + '&product=' + props.product.id;

  /* NOTE: With the addition of Medi-Cal (Medicaid) product data, ARU/TCOC
  reports need to be take into account product_id, since these data cannot
  be assumed to be from Commercial products anymore. Since urlEnding changes
  below, we preserve its original state that includes the product parameter,
  using the variable: urlEndingOrigWithProduct. */
  let urlEndingOrigWithProduct = urlEnding;

  let qualityThresholdLink;
  let effiencyThresholdLink;

  if (props.duration.id == 12) {
    qualityThresholdLink =
      siteMessages['amp.measures.downloads.quality.threshold.link12'];
  }
  if (props.duration.id == 13) {
    qualityThresholdLink =
      siteMessages['amp.measures.downloads.quality.threshold.link13'];
    effiencyThresholdLink =
      siteMessages['amp.measures.downloads.efficiency.threshold.link13'];
  }
  if (props.duration.id == 14) {
    qualityThresholdLink =
      siteMessages['amp.measures.downloads.quality.threshold.link14'];
    effiencyThresholdLink =
      siteMessages['amp.measures.downloads.efficiency.threshold.link14'];
  }
  if (props.duration.id == 15) {
    qualityThresholdLink =
      siteMessages['amp.measures.downloads.quality.threshold.link15'];
    effiencyThresholdLink =
      siteMessages['amp.measures.downloads.efficiency.threshold.link15'];
  }
  if (props.duration.id == 16) {
    qualityThresholdLink =
      siteMessages['amp.measures.downloads.quality.threshold.link16'];
    effiencyThresholdLink =
      siteMessages['amp.measures.downloads.efficiency.threshold.link16'];
  }
  if (props.duration.id == 17) {
    qualityThresholdLink =
      siteMessages['amp.measures.downloads.quality.threshold.link17'];
    effiencyThresholdLink =
      siteMessages['amp.measures.downloads.efficiency.threshold.link17'];
  }
  if (props.duration.id == 18) {
    qualityThresholdLink =
      siteMessages['amp.measures.downloads.quality.threshold.link18'];
    effiencyThresholdLink =
      siteMessages['amp.measures.downloads.efficiency.threshold.link18'];
  }

  // Prior - Measure Reference
  // <Button kind="primary" href="https://s3-us-west-1.amazonaws.com/iha/data/MY2013-measure-reference.pdf">Measure Reference</Button>
  // <Button kind="primary" href="https://s3-us-west-1.amazonaws.com/iha/data/MY2013-PO-report-description-prelim.pdf">Reports Description</Button>

  // Prior - Other Sections
  // <h3 className="page-header">Resource Use & Cost Results</h3>
  // <div>
  //  <p><b>None at this time.</b></p>
  // </div>
  // <h3 className="page-header">Value Based AMP Incentive Worksheet</h3>
  // <div>
  //  <p><b>None at this time.</b></p>
  // </div>

  // Past Links - HP,PO Downloads
  // <Button style={{marginRight: "5px"}} kind="primary" href={"/api/measures/dl/agg/?" + urlEnding}>Aggregated Data</Button>
  // <Button style={{marginRight: "5px"}} kind="primary" href={"/api/measures/dl/unagg/?" + urlEnding}>Unaggregated Data</Button>
  // <Button kind="primary" href={"/api/measures/dl/spm/?" + urlEnding}>SPM Scores</Button>
  // <Button style={{marginRight: "5px"}} kind="primary" href={qualityThresholdLink}>MY 20{this.props.duration.id} Thresholds</Button>

  // Quality
  let aruComingSoon = siteMessages['amp.measures.downloads.aru.soon.content'];

  // Resource Use: ARU and TCC
  let efficiencyDelayNotice = siteMessages['amp.measures.downloads.efficency.notice.content'];


  let efficiencyStatewideThresholds = 'amp.measures.downloads.efficency.statewide.threshold.content';
  let hpSpecificThresholds = 'amp.measures.downloads.hp.specific.threshold.content';

  let hpNotes = '';
  let poNotes = '';


  let opaReportCardName = siteMessages[
    'amp.measures.downloads.opa.report.card.name'
  ];

  let opaReportCardDesc = 'amp.measures.downloads.opa.reportcard.desc';
  let opaReportCardDesc16 = 'amp.measures.downloads.opa.reportcard.desc16';
  let opaReportCardDesc17 = 'amp.measures.downloads.opa.reportcard.desc17';

  let opaReportCardLink = siteMessages[
    'amp.measures.downloads.opa.reportcard.link'
  ];
  let opaReportCardLink15 = siteMessages[
    'amp.measures.downloads.opa.reportcard.link15'
  ];
  let opaReportCardLink16 = siteMessages[
    'amp.measures.downloads.opa.reportcard.link16'
  ];
  let opaReportCardLink17 = siteMessages[
    'amp.measures.downloads.opa.reportcard.link17'
  ];

  let opaIntro = (
    <div>
      <FormattedMessage
        id='amp.measures.downloads.opa.intro.content'
        textComponent='span'
      /><br />
    </div>
  );
  let opaIntro16 = (
    <div>
      <FormattedMessage
        id='amp.measures.downloads.opa.intro16.content'
        textComponent='span'
      /><br />
    </div>
  );
  let opaIntro17 = (
    <div>
      <FormattedMessage
        id='amp.measures.downloads.opa.intro17.content'
        textComponent='span'
      /><br />
    </div>
  );
  let opaPOFooter = (
    <div>
      <br />
      <FormattedMessage
        id='amp.measures.downloads.opaPO.footer.content'
        textComponent='span'
      />
    </div>
  );

  let opaHPFooter = (
    <div>
      <br />
      <FormattedMessage
        id='amp.measures.downloads.opaHP.footer.content'
        textComponent='span'
      />
    </div>
  );

  let opaMAReportCardDesc = 'amp.measures.downloads.opaMAcard.content';

  let opaMAReportCardLink =
    siteMessages['amp.measures.downloads.opaMAcard.link'];
  let opaMAReportCardLink15 =
    siteMessages['amp.measures.downloads.opaMAcard.link15'];

  let opaMAIntro = (
    <div>
      <FormattedMessage
        id='amp.measures.downloads.opaMA.intro.content'
        textComponent='span'
      />
      <br />
    </div>
  );

  let opaMAPOFooter = (
    <FormattedMessage
      id='amp.measures.downloads.opaMAPO.footer.content'
      textComponent='span'
    />
  );

  let opaMAHPFooter = (
    <FormattedMessage
      id='amp.measures.downloads.opaMAHP.footer.content'
      textComponent='span'
    />
  );

  let disableARURatings = false;
  let disableOPARatings = false;
  opaTabElts = [
    <DownloadTitle
      title={siteMessages['amp.measures.downloads.opacard.title']}
    />,
  ];
  if (props.product.id == 1) {
    if (props.duration.id == 17) {
      opaTabElts.push(opaIntro17);
    } else if (props.duration.id == 16) {
      opaTabElts.push(opaIntro16);
    } else {
      opaTabElts.push(opaIntro);
    }
  } else {
    opaTabElts.push(opaMAIntro);
  }
  let qualityScores = <div />;
  let acoDownloadItem = <div />;


  if (props.duration.id == 14) {
    let qDesc = 'amp.measures.downloads.qDesc.content14';
    qualityScores = (
      <DownloadItem direct
        name={
          siteMessages['amp.measures.downloads.quality.score.name14']
        }
        descriptionId={qDesc}
        href={siteMessages['amp.measures.downloads.quality.score.link14']}
      />
    );

    let opaDownloadItem;
    if (props.product.id == 1) {
      opaDownloadItem = (
        <DownloadItem direct
          name={opaReportCardName}
          href={opaReportCardLink}
          descriptionId={opaReportCardDesc} />
      );
    } else {
      opaDownloadItem = (
        <DownloadItem direct
          name={opaReportCardName}
          href={opaMAReportCardLink}
          descriptionId={opaMAReportCardDesc} />
      );
    }
    opaTabElts.push(opaDownloadItem);
  } else if (props.duration.id == 15) {
    let qDesc = 'amp.measures.downloads.qDesc.content15';
    qualityScores = (
      <DownloadItem s3
        name={siteMessages['amp.measures.downloads.quality.score.name15']}
        descriptionId={qDesc}
        organization={props.organization}
        item={siteMessages['amp.measures.downloads.quality.score.item15']}
      />
    );

    let opaDownloadItem;
    if (props.product.id == 1) {
      opaDownloadItem = (
        <DownloadItem direct
          name={opaReportCardName}
          href={opaReportCardLink15}
          descriptionId={opaReportCardDesc} />
      );
    } else {
      opaDownloadItem = (
        <DownloadItem direct
          name={opaReportCardName}
          href={opaMAReportCardLink15}
          descriptionId={opaMAReportCardDesc} />
      );
    }
    opaTabElts.push(opaDownloadItem);
  } else if (props.duration.id == 16) {
    let qDesc = 'amp.measures.downloads.qDesc.content16';
    qualityScores = (
      <DownloadItem s3
        name={siteMessages['amp.measures.downloads.quality.score.name16']}
        descriptionId={qDesc}
        organization={props.organization}
        item={siteMessages['amp.measures.downloads.quality.score.item16']}
      />
    );

    let opaDownloadItem;
    if (props.product.id == 1) {
      opaDownloadItem = (
        <DownloadItem direct
          name={opaReportCardName}
          href={opaReportCardLink16}
          descriptionId={opaReportCardDesc16} />
      );
      // eslint-disable-next-line no-constant-condition
    } else if (false) {
      opaDownloadItem = (
        <DownloadItem direct
          name={opaReportCardName}
          // eslint-disable-next-line no-undef
          href={opaMAReportCardLink16}
          descriptionId={opaMAReportCardDesc} />
      );
    }
    if (opaDownloadItem) {
      opaTabElts.push(opaDownloadItem);
    } else {
      disableOPARatings = true;
    }
  } else if (props.duration.id == 17) {
    // Updated for MY2017, data now available.
    let qDesc = 'amp.measures.downloads.qDesc.content17';
    qualityScores = (
      <DownloadItem s3
        name={siteMessages['amp.measures.downloads.quality.score.name17']}
        descriptionId={qDesc}
        organization={props.organization}
        item={siteMessages['amp.measures.downloads.quality.score.item17']}
      />
    );

    if (props.config.ENABLE_ACO_DOWNLOAD_UPTO >= props.duration.id && props.product.id == PRODUCT_ACO) {
      let acoDesc = 'amp.measures.downloads.ACO.content_5_my17';
      acoDownloadItem = (
        <DownloadItem s3
          name={siteMessages['amp.measures.downloads.ACO.score.name_5_my17']}
          descriptionId={acoDesc}
          organization={props.organization}
          item={siteMessages['amp.measures.downloads.ACO.score.item_5_my17']}
        />
      );
    }

    let opaDownloadItem;
    if (props.duration.id == 17) {
      if (props.product.id == 1) {
        opaDownloadItem = (
          <DownloadItem direct
            name={opaReportCardName}
            href={opaReportCardLink17}
            descriptionId={opaReportCardDesc17} />
        );
        // eslint-disable-next-line brace-style
      }
      // TODO(daishi): Enable this once we have an OPA Medicare Advantage download file.
      // eslint-disable-next-line no-constant-condition
      else if (false) {
        opaDownloadItem = (
          <DownloadItem direct
            name={opaReportCardName}
            // eslint-disable-next-line no-undef
            href={opaMAReportCardLink16}
            descriptionId={opaMAReportCardDesc} />
        );
      }
    }

    if (opaDownloadItem) {
      opaTabElts.push(opaDownloadItem);
    } else {
      disableOPARatings = true;
    }
  } else if (props.duration.id == 18) {
    if (props.config.ENABLE_ACO_DOWNLOAD_UPTO >= props.duration.id && props.product.id == PRODUCT_ACO) {
      let acoDesc = 'amp.measures.downloads.ACO.content_5_my18';
      acoDownloadItem = (
        <DownloadItem s3
          name={siteMessages['amp.measures.downloads.ACO.score.name_5_my18']}
          descriptionId={acoDesc}
          organization={props.organization}
          item={siteMessages['amp.measures.downloads.ACO.score.item_5_my18']}
        />
      );
    }
  }
  // The qcs/spm download is only available for the commercial and
  // Medicaid (Medi-Cal) products during and after MY2018
  let qcsDownloadHp; let qcsDownloadPo;
  if ((props.product.id == 1) || (
    props.product.id == 4 && props.duration.id >= 18)
  ) {
    qcsDownloadHp = (
      <DownloadItem
        name={siteMessages['amp.measures.downloads.qcs.hp.name1']}
        href={'/api/measures/dl/spm/?' + urlEnding}
        descriptionId='amp.measures.downloads.hp.SPM.content'
      />
    );
    qcsDownloadPo = (
      <DownloadItem
        name={siteMessages['amp.measures.downloads.qcs.po.name1']}
        href={'/api/measures/dl/spm/?' + urlEnding}
        descriptionId='amp.measures.downloads.po.SPM.content'
      />
    );
  }

  if (props.organization.org_type == -1) {
    // Integrated Healthcare Association
  } else if (props.organization.org_type == 0) { // Health Plan
    urlEnding = 'organization=' + props.organization.id + '&duration=' + props.duration.id;

    if (props.config.ENABLE_ACO_DOWNLOAD_UPTO >= props.duration.id && props.product.id == PRODUCT_ACO && props.duration.id == 17) {
      let acoDesc = 'amp.measures.downloads.HPACO.content_5_my17';
      acoDownloadItem = (
        <DownloadItem s3
          name={siteMessages['amp.measures.downloads.HPACO.score.name_5_my17']}
          descriptionId={acoDesc}
          organization={props.organization}
          item={siteMessages['amp.measures.downloads.HPACO.score.item_5_my17']}
        />
      );
    }

    if (props.config.ENABLE_ACO_DOWNLOAD_UPTO >= props.duration.id && props.product.id == PRODUCT_ACO && props.duration.id == 18) {
      let acoDesc = 'amp.measures.downloads.HPACO.content_5_my18';
      acoDownloadItem = (
        <DownloadItem s3
          name={siteMessages['amp.measures.downloads.HPACO.score.name_5_my18']}
          descriptionId={acoDesc}
          organization={props.organization}
          item={siteMessages['amp.measures.downloads.HPACO.score.item_5_my18']}
        />
      );
    }


    qualityTabElts = [
      <DownloadTitle
        title={`${props.organization.name}'s Quality Results`} />,
      <div>
        <DownloadItem
          name={siteMessages['amp.measures.downloads.qualityTab.PO.name']}
          href={'/api/measures/dl/agg/?' + urlEnding}
          descriptionId='amp.measures.downloads.hp.aggregated.content'
        />
        <DownloadItem
          name={siteMessages['amp.measures.downloads.qualityTab.plan.name']}
          href={'/api/measures/dl/unagg/?' + urlEnding}
          descriptionId='amp.measures.downloads.hp.unaggregated.content'
        />
        {qcsDownloadHp}
        {acoDownloadItem}
      </div>,
      <DownloadTitle
        title={siteMessages[
          'amp.measures.downloads.qualityTab.california.statics.title'
        ]}
      />,
      <div>
        <DownloadItem direct
          name={'MY 20' + props.duration.id + ' Thresholds'}
          href={qualityThresholdLink}
          descriptionId='amp.measures.downloads.quality.statewide.content'
        />
      </div>,
    ];
    let hp_spec_threshold = <div />;
    if (props.duration.id == 14) {
      hp_spec_threshold = (
        <DownloadItem s3
          name={
            siteMessages['amp.measures.downloads.hpspec.threshold.name14']
          }
          organization={props.organization}
          item={
            siteMessages['amp.measures.downloads.hpspec.threshold.item14']
          }
          descriptionId={efficiencyStatewideThresholds} />
      );
    }
    if (props.duration.id == 15) {
      hp_spec_threshold = (
        <DownloadItem s3
          name={
            siteMessages['amp.measures.downloads.hpspec.threshold.name15']
          }
          organization={props.organization}
          item={
            siteMessages['amp.measures.downloads.hpspec.threshold.item15']
          }
          descriptionId={efficiencyStatewideThresholds} />
      );
    }

    let hpAllResults = <div />;
    if (props.config.ENABLE_HP_ARU_TCOC_DOWNLOAD) {
      // This enables us to keep this download button hidden
      // incase there is concern over sensitive data leak.
      hpAllResults = (
        <DownloadItem
          name={
            siteMessages['amp.measures.downloads.hp.allresult.name']
          }
          href={'/api/measures/dl/aru/?' + urlEndingOrigWithProduct}
          descriptionId='amp.measures.downloads.hp.efficency.all.content'
        />
      );
    }

    efficiencyTabElts = [
      <DownloadTitle
        title={`${props.organization.name}'s Resource Use Results`} />,
      <div>
        {hpAllResults}
        <DownloadItem
          name={siteMessages[
            'amp.measures.downloads.efficiencyTab.improve.name'
          ]}
          href={
            '/api/measures/dl/aru-improvement/?' + urlEndingOrigWithProduct
          }
          descriptionId='amp.measures.downloads.hp.efficency.YY.content'
        />
      </div>,
      <DownloadTitle
        title={siteMessages[
          'amp.measures.downloads.efficiencyTab.calstatics.name'
        ]}
      />,
      <div>
        <DownloadItem direct
          name="Plan-Aggregated Thresholds"
          href={effiencyThresholdLink}
          descriptionId={efficiencyStatewideThresholds} />
        {hp_spec_threshold}
      </div>,
    ];
    if (props.product.id == 1) {
      opaTabElts.push(opaHPFooter);
    } else {
      opaTabElts.push(opaMAHPFooter);
    }
  } else if (props.organization.org_type == 1) { // Physician Org
    let worksheet = <div />;
    let durationIdsThatHaveWorkSheet = [14, 15, 16, 17];
    if (durationIdsThatHaveWorkSheet.indexOf(props.duration.id) > -1) {
      worksheet = (
        <DownloadItem s3
          name={siteMessages[
            'amp.measures.downloads.worksheet.name'
          ]}
          descriptionId1='amp.measures.downloads.wDesc.content1'
          descriptionId2='amp.measures.downloads.wDesc.content2'
          params={props.duration.id}
          organization={props.organization}
          item={props.duration.id + '-PO-Worksheet'} />
      );
    }
    qualityTabElts = [
      <DownloadTitle
        title={`${props.organization.name}'s Quality Results`} />,
      <div>
        <DownloadItem
          name={siteMessages[
            'amp.measures.downloads.qualityTab.quality.name'
          ]}
          href={'/api/measures/dl/agg/?' + urlEnding}
          descriptionId='amp.measures.downloads.po.aggregated.content'
        />

        <DownloadItem
          name={siteMessages[
            'amp.measures.downloads.qualityTab.unaggre.rates.name'
          ]}
          href={'/api/measures/dl/unagg/?' + urlEnding}
          descriptionId='amp.measures.downloads.po.unaggregated.content'
        />
        {qcsDownloadPo}
        {acoDownloadItem}
        {worksheet}
      </div>,
      <DownloadTitle
        title={siteMessages[
          'amp.measures.downloads.qualityTab.cal.statics.name'
        ]}
      />,
      <div>
        <DownloadItem direct
          name={'MY 20' + props.duration.id + ' Thresholds'}
          href={qualityThresholdLink}
          descriptionId='amp.measures.downloads.quality.statewide.content'
        />
        {qualityScores}
      </div>,
    ];
    efficiencyTabElts = [
      <DownloadTitle
        title={`${props.organization.name}'s Resource Use Results`} />,
      <div>
        <DownloadItem
          name={siteMessages[
            'amp.measures.downloads.effTab.po.allresult.name'
          ]}
          href={'/api/measures/dl/aru/?' + urlEnding}
          descriptionId='amp.measures.downloads.po.efficency.all.content'
        />
        <DownloadItem
          name={siteMessages[
            'amp.measures.downloads.effTab.po.improve.name'
          ]}
          href={'/api/measures/dl/aru-improvement/?' + urlEnding}
          descriptionId='amp.measures.downloads.po.efficency.YY.content'
        />
      </div>,
      <DownloadTitle
        title={siteMessages[
          'amp.measures.downloads.effTab.po.calstatics.name'
        ]}
      />,
      <div>
        <DownloadItem direct
          name={'MY 20' + props.duration.id + ' Thresholds'}
          href={effiencyThresholdLink}
          descriptionId={efficiencyStatewideThresholds} />
      </div>,
    ];
    if (props.product.id == 1) {
      opaTabElts.push(opaPOFooter);
    } else {
      opaTabElts.push(opaMAPOFooter);
    }
  }
  let qualityRenderElt = (
    <div className="download-tab-container">
      {qualityTabElts}
    </div>
  );
  let efficiencyRenderElt = (
    <div className="download-tab-container">
      {efficiencyTabElts}
    </div>
  );
  let opaTabElt = (
    <div className="download-tab-container">
      {opaTabElts}
    </div>
  );
  let renderElt = (
    <div >
      {tabContent.tabKey === 1 && qualityRenderElt}
      {tabContent.tabKey === 2 && efficiencyRenderElt}
      {tabContent.tabKey === 3 && opaTabElt}
    </div>
  );
  return (
    <div>
      <UrlParams
        location={props.location}
        history={props.history}
        baseUrl="/measures/downloads"
        params={["organization", "duration", "product"]} />
      <a id='hidden-link' style={{ display: 'hidden' }} />
      {renderElt}
    </div>
  );
};

const NewDownloadsRender = (props) => {
  // fetch downloads list from mock
  let downloads = props.data.downloads;
  let disableARURatings = false;
  let disableOPARatings = false;
  let qualityTab = {
    tabKey: 1,
    tabTitle: 'Quality',
    orgSpecificDownloadsTitle: `${props.organization.name}'s Quality Results`,
    orgSpecificDownloads: [],
    otherDownloadTitle: 'California Program-Wide Summary Statistics',
    otherDownloads: [],
  };
  let efficiencyTab = {
    tabKey: 2,
    tabTitle: 'Resource Use',
    orgSpecificDownloadsTitle: `${props.organization.name}'s Resource Use Results`,
    orgSpecificDownloads: [],
    otherDownloadTitle: 'California Program-Wide Summary Statistics',
    otherDownloads: [],
  };
  let opaTab = {
    tabKey: 3,
    tabTitle: 'OPA Ratings',
    orgSpecificDownloadsTitle: `OPA Report Card Ratings`,
    orgSpecificDownloads: [],
    otherDownloadTitle: '',
    otherDownloads: [],
  };
  if (downloads) {
    downloads.map(downloadItem => {
      let itemTab = downloadItem.metadata.suite;
      if (itemTab == 'Quality') {
        if (downloadItem.metadata.orgSpecificResult) {
          qualityTab.orgSpecificDownloads.push(downloadItem);
        } else {
          qualityTab.otherDownloads.push(downloadItem);
        }
      } else if (itemTab == 'Resource Use') {
        if (downloadItem.metadata.orgSpecificResult) {
          efficiencyTab.orgSpecificDownloads.push(downloadItem);
        } else {
          efficiencyTab.otherDownloads.push(downloadItem);
        }
      } else if (itemTab == 'OPA Ratings') {
        if (downloadItem.metadata.orgSpecificResult) {
          opaTab.orgSpecificDownloads.push(downloadItem);
        } else {
          opaTab.otherDownloads.push(downloadItem);
        }
      }
    });
  }

  const [selectedTab, setSelectedTab] = useState(qualityTab.tabKey);

  return (
    <div className ="download-page-tabs">
      <UrlParams
                  location={props.location}
                  history={props.history}
                  baseUrl="/measures/downloads"
                  params={["organization", "duration", "product"]} />
      <div className="tabs is-boxed" style={{ clear: 'both' }}
        onClick={() => { }}>
        <ul>
          <li
            className={selectedTab === qualityTab.tabKey && 'is-active'}
            key={qualityTab.tabKey}
            onClick={() => {
              setSelectedTab(qualityTab.tabKey)
            }} >
            <a id={qualityTab.tabKey}>{qualityTab.tabTitle}</a></li>
            <li
            className={selectedTab === efficiencyTab.tabKey && 'is-active'}
            key={efficiencyTab.tabKey}
            onClick={() => {
              setSelectedTab(efficiencyTab.tabKey)
            }} >
            <a id={efficiencyTab.tabKey}>{efficiencyTab.tabTitle}</a></li>
            <li
            className={selectedTab === opaTab.tabKey && 'is-active'}
            key={opaTab.tabKey}
            onClick={() => {
              setSelectedTab(opaTab.tabKey)
            }} >
            <a id={opaTab.tabKey}>{opaTab.tabTitle}</a></li>
        </ul>
          {selectedTab === qualityTab.tabKey && downloadsTab(qualityTab, props.organization, false)}
          {selectedTab === efficiencyTab.tabKey && downloadsTab(efficiencyTab, props.organization, disableARURatings)}
          {selectedTab === opaTab.tabKey && downloadsTab(opaTab, props.organization, disableOPARatings)}
      </div>     
    </div>
  );
};
// filter downloads data in tabs

class MeasuresDownloadPageOLd extends React.Component {

  constructor(props) {
    super(props)
    this.props.downloadMeasureList();
  }

  state = {
    api_data: {},
    refreshIsRequired: true
  };

  async updateApiState(props) {
    if (this.props.config.NEW_MEASURE_DOWNLOAD_IMPLEMENTATION && this.state.refreshIsRequired) {
      if (this.props.config.USE_BACKEND_DOWNLOADS_API) {
        this.setState({
          refreshIsRequired: false
        });
        let url = '/api/v2/atlas/list_downloads/'
        if (process.env.BACKEND_PROXY_URL != undefined) {
          url = process.env.BACKEND_PROXY_URL + url
        };
        let data = await getData(url, {
          organization: props.organization.id,
          filters: {
            'app': 'amp',
            'duration': props.duration.id,
            'product': props.product.id,
          },
        });
        return data
      } else {
        let data = downloadList(props.organization, {
          duration: props.duration,
          product: props.product,
          ENABLE_ACO_DOWNLOAD_UPTO: this.props.config.ENABLE_ACO_DOWNLOAD_UPTO,
          ENABLE_HP_ARU_TCOC_DOWNLOAD: this.props.config.ENABLE_HP_ARU_TCOC_DOWNLOAD,
        });
        return data
      }
    }
  }
  async componentWillReceiveProps(props) {
    if ((this.props.duration.id !== props.duration.id) || (this.props.organization.id !== props.organization.id) || (this.props.product.id !== props.product.id)) {
      this.setState({ refreshIsRequired: true });
      
    }
    document.title = siteMessages['amp.measures.downloads.title'];
    let data = await this.updateApiState(props)

    if (data) {
      this.setState({ api_data: data });
    }
  }
  render() {
    let that = this;
    
    if (that.props.config.NEW_MEASURE_DOWNLOAD_IMPLEMENTATION) {
      if (this.state.api_data === null) {
        return <div>...</div>;
      } else {
        return <NewDownloadsRender data={this.state.api_data} {...this.props} />
      }
    } else {
      return legacyDownloadsRender(this.props);
    }
  }
}

const MeasuresDownloadPage = (props) => {
  const context = useOutletContext();

  const [api_data, setApi_data] = useState(null);
  const [refreshIsRequired, setRefreshIsRequired] = useState(true);

  const updateApiState = (props) => {
    if (props.config.NEW_MEASURE_DOWNLOAD_IMPLEMENTATION && refreshIsRequired) {
      if (props.config.USE_BACKEND_DOWNLOADS_API) {
        setRefreshIsRequired(false);
        let url = '/api/v2/atlas/list_downloads/'
        if (process.env.BACKEND_PROXY_URL != undefined) {
          url = process.env.BACKEND_PROXY_URL + url
        };
        getData(url, {
          organization: context.organization.id,
          filters: {
            'app': 'amp',
            'duration': context.duration.id,
            'product': context.product.id,
          },
        }).then((data) => {
          setApi_data(data);
        });
      } else {
        let data = downloadList(props.organization, {
          duration: context.duration,
          product: context.product,
          ENABLE_ACO_DOWNLOAD_UPTO: props.config.ENABLE_ACO_DOWNLOAD_UPTO,
          ENABLE_HP_ARU_TCOC_DOWNLOAD: props.config.ENABLE_HP_ARU_TCOC_DOWNLOAD,
        });
        setApi_data(data);
      }
    }
  }

  useEffect(() => {
    if ((context.duration.id !== props.user.duration.id) || (context.organization.id !== props.user.organization.id) || (context.product.id !== props.user.product.id)) {
      setRefreshIsRequired(true);
    }
    document.title = siteMessages['amp.measures.downloads.title'];
    updateApiState(props);
  });
    
  if (props.config.NEW_MEASURE_DOWNLOAD_IMPLEMENTATION) {
    if (api_data === null) {
      return <div>...</div>;
    } else {
      return <NewDownloadsRender data={api_data} {...props} {...context}/>;
    }
  } else {
    return legacyDownloadsRender({...props, ...context});
  }
}


const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    downloadMeasureList: () => {
      dispatch(downloadMeasureList());
    }
  };
};

const mapStateToProps = state => {
  return state
};

export default connect(mapStateToProps)(
  connect(null, mapDispatchToProps)(MeasuresDownloadPage));


