import React from 'react';
import { CurrencyInput } from '../../../../common/input';
import {siteMessages} from 'src/utils/copy';
import './incentivedesignsettings.scss';

export function IncentiveDesignSettings({data, updateMeasure, onReset, sharedSavingsAvailable, attainmentAvailable, labels}) {
    const attainmentStyle = attainmentAvailable && {width: `${120 * data[0].attainment_incentives.length}px`};
    return (
        <div className="incentive-design-settings">
            <div className="table">
                <div className="flex-row">
                    <div><span className="title">{siteMessages['amp.page.measures.reports.poworksheet.page.incentivedesign.title']}</span></div>
                    {sharedSavingsAvailable && <div className="shared-savings-column"><span className="column-title">{siteMessages['amp.page.measures.reports.poworksheet.page.incentivedesign.sharedsavings']}</span></div>}
                    {attainmentAvailable && <div className="attainment-column" style={attainmentStyle}><span className="column-title">{siteMessages['amp.page.measures.reports.poworksheet.page.incentivedesign.attainment']}</span></div> }
                </div>
                <div className="flex-row">
                    <div></div>
                    {sharedSavingsAvailable && <div className="shared-savings-column"><span className="column-title">{siteMessages['amp.page.measures.reports.poworksheet.page.incentivedesign.cost']}</span></div> }
                    {attainmentAvailable &&
                        <div className="flex-row attainment-column" style={attainmentStyle}>
                            {labels.attainment_incentive_labels.map((label, i) =>
                                <div key={i}><span className="column-title">{label + " Incentive ($)"}</span></div>
                            )}
                        </div>
                    }
                </div>
                {data.map(measure =>
                    <div key={measure["measure_id"]} className="flex-row">
                        <div className="flex-column"><span>{`${measure["measure_name"]} (${measure["measure_code"]})`}</span></div>
                        { sharedSavingsAvailable && 
                            <div className="shared-savings-column">
                                <div>
                                    <CurrencyInput
                                        value={measure["cost_per_resource_use"]}
                                        editable={true} onChange={(value) => updateMeasure(measure["measure_id"], "cost_per_resource_use", value)} />
                                </div>
                            </div>
                        }
                        {attainmentAvailable && 
                        <div className="flex-row attainment-column" style={attainmentStyle}>
                            {measure.attainment_incentives.map((x, i) => 
                                <div key={i}>
                                    <CurrencyInput
                                        value={x}
                                        editable={true} 
                                        onChange={(value) => {
                                            let newValue = [...measure.attainment_incentives];
                                            newValue[i] = value;
                                            updateMeasure(measure["measure_id"], "attainment_incentives", newValue);
                                        }} />
                                </div>
                            )}
                        </div>
                        }
                    </div>
                )}
                <div className="flex-row">
                    <div><button className="reset-button" onClick={()=>onReset()}>{siteMessages['amp.page.measures.reports.poworksheet.page.incentivedesign.reset']}</button></div>
                    {sharedSavingsAvailable && <div className="shared-savings-column"></div> }
                    {attainmentAvailable && <div className="attainment-column" style={attainmentStyle}></div> }
                </div>
            </div>
        </div>
    );
};