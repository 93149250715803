import React from 'react';
import { CurrencyInput, NumericInput } from '../../../../common/input';
import { ArrowChart, colors } from './arrowchart';
import { getAttainmentValue, getAttainmentLevel } from './calculations';
import {siteMessages} from 'src/utils/copy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './attainmentincentives.scss';

function AttainmentIncentives({data}) {

  const currentColors = colors.slice(0, data.labels.attainment_chart_labels.length);

  const getAttainmentColor = (measure) => {
    const level = getAttainmentLevel(measure);

    return level === -1 ? "gray" : currentColors[currentColors.length - 1 - level];
  };

  const getAttainmentMessage = (measure) => {
    const level = getAttainmentLevel(measure);

    switch (level) {
      case -1:
        return siteMessages['amp.page.measures.reports.poworksheet.page.attainmentincentives.notachieved'];
      default:
        return `${data.labels.attainment_incentive_labels[level]} ${siteMessages['amp.page.measures.reports.poworksheet.page.attainmentincentives.attainment']}`;
    }
  };

  return (
    <div className="attainment-incentives flex-row">
      <div className="flex-column">
        <div className="flex-row">
          <div className="flex-column">
            <div><span className="title">{siteMessages['amp.page.measures.reports.poworksheet.page.attainmentincentives.title']}</span></div>
          </div>
          <div className="flex-column">
            <div><span className="small-label">{siteMessages['amp.page.measures.reports.poworksheet.page.attainmentincentives.totalbase']}</span></div>
            <div><CurrencyInput value={data.totalBaseIncentive} /></div>
          </div>
          <div className="flex-column">
            <div><span className="small-label">{`${data.labels.current_duration_label} ${siteMessages['amp.page.measures.reports.poworksheet.page.attainmentincentives.member']}`}</span></div>
            <div><NumericInput value={data.currentYearMemberYears} /></div>
          </div>
          <div className="flex-column">
            <div><span className="small-label">{siteMessages['amp.page.measures.reports.poworksheet.page.attainmentincentives.total']}</span></div>
            <div><CurrencyInput value={data.totalBaseIncentive * data.currentYearMemberYears} /></div>
          </div>
        </div>
        {data.measures.map(measure =>
          <div key={measure["measure_id"]} className="flex-row">
            <div className="flex-column">
              <span>{`${measure["measure_name"]} (${measure["measure_code"]})`}</span>
              {
                (measure["current_year_result"] === null || measure["previous_year_result"] === null) &&
                <span className="icomplete-result-message"><FontAwesomeIcon icon="exclamation-triangle" /> {siteMessages['amp.page.measures.reports.poworksheet.page.attainmentincentives.incompleteresults']} </span>
              }
            </div>
            <div className="flex-row attainment">
              <div>
                <span className="small-label">{getAttainmentMessage(measure)}</span>
              </div>
              <div>
                <CurrencyInput className={getAttainmentColor(measure)} value={getAttainmentValue(measure)} />
              </div>
            </div>
            <div className="flex-column">
              <ArrowChart
                topData = {measure["current_year_attainment"]}
                bottomData= {measure["previous_year_attainment"]}
                topResult= {measure["current_year_result"]}
                bottomResult= {measure["previous_year_result"]}
                inverted={measure["better_direction"] === "down"}
                unitsLabel={measure["function__score__label"]}
                functionSuffix={measure["function__suffix"]}
                nodataEnding={siteMessages['amp.page.measures.reports.poworksheet.page.attainmentincentives.nodatamessageending']}
                upperAxisLable={data.labels.current_duration_label} 
                bottomAxisLable={data.labels.previous_duration_label} />
            </div>
          </div>
        )}
      </div>
      <div className="flex-column">
        {data.labels.attainment_chart_labels.map((label, i) =>
          <div className="flex-row"><div className={`bar ${currentColors[currentColors.length - 1 - i]}`}></div><span className="small-label">{siteMessages['amp.page.measures.reports.poworksheet.page.attainmentincentives.attainment']} {data.labels.attainment_incentive_labels[i]} - {label}</span></div>
        )}
        <div className="flex-row"><div className="bar blue"></div><span className="small-label">{data.labels.rate_label}</span></div>
      </div>
    </div>
  );
}

export default AttainmentIncentives;

