import React from 'react';

const ConfirmationDialog = ({ show, title, text, noButtonText, yesButtonText, hide, handleCustomClassName, action }) => {
    const hideDialog = () => {
        hide();
        handleCustomClassName();
    }
    return (<div>
        <div className={`modal-card ${show ? 'fadeInDown' : 'fadeOutUp'} customModalCard confirmation-dialog`}>
            <header className="modal-card-head">
                <p className="modal-card-title">{title}</p>
                <button className="delete" aria-label="close"
                    onClick={hideDialog.bind(this)}>
                </button>
            </header>
            <section className="modal-card-body comments">
                <div>{text}</div>
            </section>
            <footer className="modal-card-foot">
                <button onClick={hideDialog.bind(this)}>{noButtonText}</button>
                <button onClick={action.bind(this)}>{yesButtonText}</button>
            </footer>
        </div>
    </div>);
};

export default ConfirmationDialog;