import {
  UPDATE_USER_SESSION,
  UPDATE_ORGANIZATION,
  UPDATE_ORGANIZATIONS,
  UPDATE_PRODUCT,
  UPDATE_PRODUCTS,
  UPDATE_DURATION,
  UPDATE_DURATIONS,
} from './actions';

export const initialUserSession = {
  username: undefined,
  org_types: [],
  USE_REACT: false,
};

export const unauthUserSession = {
  username: '',
  org_types: [],
  USE_REACT: false,
};

const initialState = {
  session: initialUserSession,
  hasNoOrganizationsAssigned: false,

  organization: null,
  organizations: [],
  noOrganizations: false,

  product: null,
  products: [],

  duration: null,
  durations: [],
};

export default function(state=initialState, action) {
  switch (action.type) {
    case UPDATE_USER_SESSION:
      return {
        ...state,
        session: action.session,
        hasNoOrganizationsAssigned: action.session.username && action.session.org_types.length === 0
      };
    case UPDATE_ORGANIZATION:
      return {
        ...state,
        organization: action.organization,
      };
    case UPDATE_ORGANIZATIONS:
      return {
        ...state,
        organizations: action.organizations,
        noOrganizations: !action.organizations.length,
      };
    case UPDATE_PRODUCT:
      return {
        ...state,
        product: action.product,
      };
    case UPDATE_PRODUCTS:
      return {
        ...state,
        products: action.products,
      };
    case UPDATE_DURATION:
      return {
        ...state,
        duration: action.duration,
      };
    case UPDATE_DURATIONS:
      return {
        ...state,
        durations: action.durations,
      };
  }
  return state;
}
