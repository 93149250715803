
import React from 'react';
import MeasureHeader from 'common/measure-header';

import MeasureOverview from './measureoverview';
import MeasureVisualization from './measurevisualization';
import FormattedMessage from 'components/FormattedMessageMarkdown';
import './styles.scss';

class MeasureView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: 1,
    };
  }
  
  handleSelect(selectedKey) {
    let kv = selectedKey.target.id.split('.');
    if (kv[0] === 't') {
      this.setState({ tab: kv[1] });
    }
  }
  render() {
    let tabs = [];
    let tabIndex = 1;
    let tabMap = {};
    // eslint-disable-next-line no-constant-condition
    if (true) {
      tabs.push(<li key={`t.${tabIndex}`} className={this.state.tab == tabIndex ? 'is-active' : ''} ><a id={`t.${tabIndex}`}>Overview</a></li>)
      tabMap[tabIndex] = 'Overview';
      tabIndex += 1;
    }
    // eslint-disable-next-line no-constant-condition
    if (true) {
      tabs.push(<li key={`t.${tabIndex}`} className={this.state.tab == tabIndex ? 'is-active' : ''} ><a id={`t.${tabIndex}`}>Visualizations</a></li>)
      tabMap[tabIndex] = 'Visualizations';
      tabIndex += 1;
    }
    /*
    if(this.props.measure && this.props.measure.function__logic
      && this.props.measure.function__logic.improvement
    ) {
      tabs.push(<NavItem eventKey={`t.${tabIndex}`}>Improvement</NavItem>)
      tabMap[tabIndex] = 'Improvement'
      tabIndex += 1
    }
    */
    let renderElt = null;
    if (tabMap[this.state.tab] === 'Overview') {
      renderElt =
        <MeasureOverview
          organization={this.props.organization}
          duration={this.props.duration}
          product={this.props.product}
          measure={this.props.measure} />;
    } else if (tabMap[this.state.tab] === 'Visualizations') {
      renderElt =
        <MeasureVisualization
          organization={this.props.organization}
          duration={this.props.duration}
          product={this.props.product}
          measure={this.props.measure} />;
    } else if (tabMap[this.state.tab] === 'Improvement') {
      renderElt =
        <MeasureOverview
          improvement={true}
          organization={this.props.organization}
          duration={this.props.duration}
          product={this.props.product}
          measure={this.props.measure} />;
    }
    return (
      <div style={this.props.style} >
        <div className="measures-tabs-conatiner measures-measure-view-container">
          <div className="tabs is-boxed" value={`t.${this.state.tab}`}
            onClick={this.handleSelect.bind(this)}>
            <ul>{tabs}</ul>
          </div>
          <div className=
            "panel panel-default measures-measure-view-content-container"
          >
            <MeasureHeader measure={this.props.measure} />
            {renderElt}
          </div>
        </div>
      </div>
    );
  }
}

export default MeasureView;
