
import React from 'react';

export default class DomainNav extends React.Component {
  static defaultProps = {
    selectedDomain: {
      id: 0,
    },
  }
  render() {
    let domainOptions = this.props.domainList.map(domain => {
      return (
        <li key={`d.${domain.id}`} className={this.props.selectedDomain.id == domain.id ? 'is-active' : ''}  ><a id={`d.${domain.id}`}>{domain.name}</a></li>
      );
    });
    return (
      <div className="tabs is-boxed" value={`d.${this.props.selectedDomain.id}`}
        onClick={this.props.onSelect}>
        <ul>{domainOptions}</ul>
      </div>
    );
  }
}
