// Based on https://github.com/yahoo/react-intl/issues/513#issuecomment-252083860
import React from 'react';
import {any, object, string} from 'prop-types';
import { siteMessages } from 'src/utils/copy';
import Markdown from 'components/Markdown';

function FormattedMessageMarkdown(
  {id, defaultMessage, values, description, textComponent, ...props}
) {
  const Component = textComponent || 'div';
  return (
    <Component {...props} className='markdown-content'>
      <div>
        <p>
            <Markdown source={siteMessages[id] || defaultMessage}/>
        </p>
      </div>
    </Component>
  );
}

FormattedMessageMarkdown.propTypes = {
  id: string,
  defaultMessage: string,
  values: object,
  description: string,
  textComponent: any,
};

export default FormattedMessageMarkdown;
