import * as singleSelectors from './single';
import * as multiSelectors from './multi';


let selectors = {
  ...singleSelectors,
  ...multiSelectors,
};

// using older style export so we can dynamically export all selector functions
// without using default export
module.exports = selectors;
