import {createSelector} from 'reselect';

import {
  ORGANIZATION_PO,
  ORGANIZATION_HP,
  SUPER_ORG,

  BASE_SURVEY_CATEGORIES,
  PO_SURVEY_CATEGORIES,
  HP_SURVEY_CATEGORIES,
} from 'state/constants';

import {
  getAllOrganizationSurveys,
  getUserOrganization,
  getOrganizationSurveyCategory,
} from 'state/selectors/single';

export const getOrganizationSurvey = createSelector(
  getAllOrganizationSurveys,
  getUserOrganization,
  getOrganizationSurveyCategory,
  (surveys, organization, category) =>
    organization && surveys[organization.id] &&
    surveys[organization.id][category.id] || {},
);

export const getOrganizationSurveyCategories = createSelector(
  getUserOrganization,
  organization => {
    if (!organization) {
      return BASE_SURVEY_CATEGORIES;
    } else if (organization.org_type == ORGANIZATION_PO) {
      return PO_SURVEY_CATEGORIES;
    } else if (organization.org_type == ORGANIZATION_HP) {
      return HP_SURVEY_CATEGORIES;
    } else if(organization.org_type == SUPER_ORG) {
      return PO_SURVEY_CATEGORIES;
    } else {
      return BASE_SURVEY_CATEGORIES;
    }
  });
