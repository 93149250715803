import log from 'common/log';

export const REQUEST_MEASURE_LIST = 'REQUEST_MEASURE_LIST';
export const UPDATE_MEASURE_LIST = 'UPDATE_MEASURE_LIST';
export const DOWNLOAD_MEASURE_LIST = 'DOWNLOAD_MEASURE_LIST';

export function requestMeasureList(duration, product, program) {
  log.debug('requestMeasureList', {duration, product, program});
  return {
    type: REQUEST_MEASURE_LIST,
    duration, product, program,
  };
}

export function updateMeasureList(data) {
  log.debug('updateMeasureList', data);
  return {
    type: UPDATE_MEASURE_LIST,
    data,
  };
}

export function downloadMeasureList() {
  log.debug('downloadMeasureList');
  return {
    type: DOWNLOAD_MEASURE_LIST
  };
}
