
import React, { useEffect, useState } from 'react';
import {connect} from 'react-redux';
import {requestContractUserList} from 'state/actions';
import {getUsers} from 'state/selectors';

import FormattedMessage from 'components/FormattedMessageMarkdown';
import {siteMessages} from 'src/utils/copy';
import './styles.scss';
import Loading from '../../common/Loading';

const AddRecipientElem = ({data, show, addRecipients, hide, requestContractUserList, users, usersAreLoading, error}) =>{
  const [search, setSearch] = useState(''),
        [allSelectedUsers, setAllSelectedUsers] = useState(data.recipients);

  useEffect(() => {
    requestContractUserList(data.hp_id, data.po_id, data.pcon_contacts)
  }, []);

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  }
  const handleCheckChange = (event) => {
    if (!allSelectedUsers.includes(event.target.value)) {
      setAllSelectedUsers(allSelectedUsers.concat([event.target.value]))
    }
    else{
      setAllSelectedUsers(allSelectedUsers.filter((elem) => elem != event.target.value))
    }
  }
  const hideModal = () => {
    setSearch('')
    hide()
  }
  const updateRecipientList = () => {
    addRecipients(allSelectedUsers)
    hide()
  }
  const searchedUsers = () => {
    return users.filter(u => {
      return [u.first_name, u.last_name, u.username].join(' ')
        .toLowerCase()
        .search(search) != -1;
    }).sort((a, b) => a.first_name.localeCompare(b.first_name));
  }
  return (
    <div>
      <div className="modal-background" onClick={hideModal}></div>
      <div id="recp" className={`modal-card ${show ? 'fadeInDown' : 'fadeOutUp'}`}>
          <header className="modal-card-head">
              <span className="modal-card-title">
              <FormattedMessage id='amp.recipient.add.modal.title'
            textComponent='span'
          />
              </span>
              <button className="delete" aria-label="close" onClick={hideModal}></button>
          </header>
          <section className="modal-card-body">
            <div>
              <label className="label"><FormattedMessage id='amp.recipient.display.selected.users'
                textComponent='span'
              /></label>
              {allSelectedUsers.length > 0 ? <span className='selected-users'><p>{allSelectedUsers.join(", ")}</p></span> : <div className='none-selected-tag'><span className='tag'>None Selected</span></div>}
            </div>
              <div className="field">
                  <label className="label"><FormattedMessage id='amp.contact.add.modal.select.user'
                textComponent='span'
            /></label>
                  <div className="control">
                      <input className="input" type="text"
                      placeholder= {
                          siteMessages['amp.contact.add.modal.searchbox.placeholder']
                            }
                      value={search}
                      onChange={handleSearchChange}
                      />
                  </div>
              </div>
              {usersAreLoading ? <div className='recipients-loading'><Loading /></div> :
                !error ? <div className="contact-modal-radio-group">
                  {searchedUsers().map(u => {
                    return (
                      <div className="control" key={u.username}>
                        <label className="checkbox">
                          <input type='checkbox' name="answer"
                            value={u.username}
                            checked={allSelectedUsers.includes(u.username)}
                            onChange={handleCheckChange}
                            />
                          <p>{u.first_name} {u.last_name} - {u.username}</p>
                        </label>
                      </div>
                    );
                  })}
                </div>
                : <div className='error-tag'><span className='tag'>{error}</span></div>
              }
              <div className="notification is-link is-light">
                  <p>If you don't see the user you are looking for, they may need to create an account at <a href="https://sso-prod.iha.org/accounts/register/"
                          target="_blank" rel="noopener noreferrer">https://sso.iha.org/accounts/register/</a> first.</p>
              </div>
          </section>
          <footer className="modal-card-foot">
              <div className="buttons">
                  <button className="button "  onClick={hideModal}>
                  <FormattedMessage id='amp.contact.add.modal.button.close.label'
            textComponent='span'
        /> </button>
                  <button className="button is-dark add-contact-btn" onClick={updateRecipientList}>
                  <FormattedMessage id='amp.contact.add.modal.button.add.label'
            textComponent='span'
          />
                    </button>
              </div>
          </footer>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    users: getUsers(state),
    usersAreLoading: state.contacts.usersAreLoading,
    error: state.contacts.error
  };
};

const mapDispatchToProps = dispatch => {
  return {
      requestContractUserList: (hp_id, po_id, pcon_contacts) => {
        dispatch(requestContractUserList(hp_id, po_id, pcon_contacts));
      }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddRecipientElem);