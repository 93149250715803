import React from 'react';

import {getData} from 'common/request.js';
import {Table} from 'common/prototable';

// eslint-disable-next-line no-unused-vars
let round = num => {
  let n = num * 100;
  n = Math.round(n);
  return n / 100;
};

// eslint-disable-next-line react/no-deprecated
class MeasureView extends React.Component {
  defaultProps ={
    instances: [],
  };
  state = {
    data: {},
    loading: false,
  };
  updateMeasureView(obj) {
    if (obj.measure && obj.duration && obj.organization) {
      this.setState({loading: true});
      getData('/api/measures/org/tcc-improvement/', {
        duration: obj.duration.id,
        measure: obj.measure.id,
        organization: obj.organization.id,
      }).then((data) => {
        this.setState({data: data, loading: false});
      });
    }
  }
  componentWillMount() {
    this.updateMeasureView(this.props);
  }
  componentWillReceiveProps(nextProps) {
    this.updateMeasureView(nextProps);
  }
  render() {
    let table;

    if (!this.state.loading) {
      let {columns, topRow, data} = this.state.data;

      table = (
        <Table
          columns={columns}
          className='table table-responsive table-bordered'
          topRow={topRow}
          data={data}
          sortable={true} >
        </Table>
      );
    } else {
      table = (
        <div></div>
      );
    }

    let renderElt = (
      <div style={this.props.style}>
          <div className="panel panel-body"
            style={{
              overflowX: 'auto', marginBottom: '0px',
              padding: '0px', marginTop: '-1px',
            }}
          >
            <div>{table}</div>
          </div>
      </div>
    );
    return (
      renderElt
    );
}
};

export default MeasureView;
