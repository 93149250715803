import deriveDomainList from 'common/derive-domain-list';

import {
  UPDATE_MEASURE_LIST,
} from './actions';

const initialState = {
  measureList: [],
  domainList: [],
};

export default function(state=initialState, action) {
  switch (action.type) {
    case UPDATE_MEASURE_LIST:
      return {
        measureList: action.data,
        domainList: deriveDomainList(action.data),
      };
  }
  return state;
}
