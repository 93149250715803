/* eslint-disable react/no-deprecated */
import React from 'react';
import {NavLink} from 'react-router-dom';

class Button extends React.Component {

  render() {
    // let currentResource = (this.props.to)

    let className = (this.props.kind ? this.props.kind+'-btn btn' : 'btn');
    if (this.props.className) className += ' ' + this.props.className;
    let styles = (this.props.style? this.props.style : {});
    let elt;

    if (this.props.to) {
      elt = <NavLink
        activeClassName='active'
        to={this.props.to}
        params={this.props.params}
        query={this.props.query}
        className={className}
        disabled={this.props.disabled}
        style={styles}
      >
        {this.props.children}
      </NavLink>;
    } else if (this.props.type) {
      elt = <input
        type={this.props.type}
        value={this.props.value}
        className={className}
        disabled={this.props.disabled}
        style={styles} />;
    } else if (this.props.href) {
      elt = <a
        href={this.props.href}
        className={className}
        disabled={this.props.disabled}
        style={styles}
      >
        {this.props.children}
      </a>;
    } else if (this.props.onClick) {
      elt = <a
        onClick={this.props.onClick}
        className={className}
        disabled={this.props.disabled}
        style={styles}
      >
        {this.props.children}
      </a>;
    } else if (this.props.disabled) {
      elt = <a
        className={className + ' disabled'}
        disabled={this.props.disabled}
        style={styles}
      >
        {this.props.children}
      </a>;
    } else {
      elt = <div></div>;
    }

    return (
      elt
    );
  }
};

export default Button;
