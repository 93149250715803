/* eslint-disable react/no-deprecated */
import React from 'react';
import Highchart from 'react-highcharts';
import FormattedMessage from 'components/FormattedMessageMarkdown';
import {siteMessages} from 'src/utils/copy';
import './styles.scss';

/* ####################
# score gauge legend #
####################*/
function LegendRow({shortName, description}) {
  return (
    <tr key={shortName} >
      <td style={{paddingBottom: '2px', paddingLeft: '4px'}}>{shortName}</td>
      <td style={{paddingBottom: '2px', paddingLeft: '8px'}}>{description}</td>
    </tr>
  );
}

export class ScoreGaugeLegend extends React.Component {
  render() {
    // pieces = percentiles, sd, lr
    let percentiles;
    if (this.props.percentiles) {
      percentiles = [
          <tr key="1" className='success-gauge'>
            <td style={{paddingBottom: '2px'}} >
              <button className="button is-success gauge-button" ></button>
            </td>
            <td style={{paddingBottom: '2px', paddingLeft: '8px'}}>
              <FormattedMessage id='amp.score.gauge.success.label'
                textComponent='span'
              />
            </td>
          </tr>,
          <tr key="2" className='warning-gauge'>
            <td style={{paddingBottom: '2px'}} >
              <button className="button is-warning gauge-button"></button>
            </td>
            <td style={{paddingBottom: '2px', paddingLeft: '8px'}}>
              <FormattedMessage id='amp.score.gauge.warning.label'
                textComponent='span'
              />
            </td>
          </tr>,
          <tr key="3" className='danger-gauge'>
            <td style={{paddingBottom: '2px'}} >
              <button className="button is-danger gauge-button" ></button>
            </td>
            <td style={{paddingBottom: '2px', paddingLeft: '8px'}}>
              <FormattedMessage id='amp.score.gauge.danger.label'
                textComponent='span'
              />
            </td>
          </tr>,
      ];
    } else {
      percentiles = <tr></tr>;
    }
    let flags = [
                  {
                    present: this.props.sd,
                    shortName: siteMessages[
                      'amp.measure.overview.legend.small.shortname'
                    ],
                    description: siteMessages[
                      'amp.measure.overview.legend.small.description'
                    ],
                  },
                  {
                    present: this.props.lr,
                    shortName: siteMessages[
                      'amp.measure.overview.legend.low.shortname'
                    ],
                    description: siteMessages[
                      'amp.measure.overview.legend.low.description'
                    ],
                  },
                  {
                    present: this.props.ud,
                    shortName: siteMessages[
                      'amp.measure.overview.legend.undefined.shortname'
                    ],
                    description: siteMessages[
                      'amp.measure.overview.legend.undefined.description'
                    ],
                  },
                  {
                    present: this.props.prt,
                    shortName: siteMessages[
                      'amp.measure.overview.legend.plan.shortname'
                    ],
                    description: siteMessages[
                      'amp.measure.overview.legend.plan.description'
                    ],
                  },
                  {
                    present: this.props.outlier,
                    shortName: siteMessages[
                      'amp.measure.overview.legend.outlier.shortname'
                    ],
                    description: siteMessages[
                      'amp.measure.overview.legend.outlier.description'
                    ],
                  },
                  {
                    present: this.props.is_biased,
                    shortName: siteMessages[
                      'amp.measure.overview.legend.biased.shortname'
                    ],
                    description: siteMessages[
                      'amp.measure.overview.legend.biased.description'
                    ],
                  },
                  {
                    present: this.props.is_no_benefit,
                    shortName: siteMessages[
                      'amp.measure.overview.legend.benefit.shortname'
                    ],
                    description: siteMessages[
                      'amp.measure.overview.legend.benefit.description'
                    ],
                  },
                ];
    flags = flags.filter(flag => flag.present);
    flags = flags.map((flag, i) => <LegendRow key ={i} {...flag} />);

    return (
      <div className="gauge-label-heading">
        <div style={{marginTop: '5px', marginBottom: '5px'}} >
          <b>
            <FormattedMessage id='amp.score.gauge.measures.heading'
              textComponent='span'
            />
          </b>
        </div>
        <table>
          <tbody>
            {percentiles}
            {flags}
          </tbody>
        </table>
    </div>
    );
  }
};


/* #############
# score gauge #
#############*/

export class ScoreGauge extends React.Component {
  render() {
    let score = this.props.score != null ? this.props.score : null;
    let title = this.props.title? this.props.title : null;
    let suffix = this.props.suffix? this.props.suffix : '';
    let dataName = this.props.dataName? this.props.dataName: 'score';
    let hib = this.props.hib!=undefined? this.props.hib : true;
    let max = this.props.max? this.props.max : 100;
    let min = this.props.min? this.props.min : 0;
    let greenCutoff = this.props.green!=undefined? this.props.green : 75;
    let yellowCutoff = this.props.yellow!=undefined? this.props.yellow : 50;
    let style = this.props.style? this.props.style : {};
    let height = this.props.height? this.props.height : null;

    // eslint-disable-next-line one-var
    let redFrom, redTo, yellowFrom, yellowTo, greenFrom, greenTo;
    if (hib) {
      redFrom = min;
      redTo = yellowCutoff;
      yellowFrom = yellowCutoff;
      yellowTo = greenCutoff;
      greenFrom = greenCutoff;
      greenTo = max;
    } else {
      redFrom = yellowCutoff;
      redTo = max;
      yellowFrom = greenCutoff;
      yellowTo = yellowCutoff;
      greenFrom = min;
      greenTo = greenCutoff;
    }

    let config = {

        chart: {
            type: 'gauge',
            // backgroundColor: "#f6f6f6",
            plotBackgroundImage: null,
            plotBorderWidth: 0,
            plotShadow: false,
            borderWidth: 0,
            spacingTop: 0,
            spacingBottom: 0,
            height: height,
        },

        title: {
            text: title,
        },

        pane: {
            startAngle: -150,
            endAngle: 150,
            background: [{
                backgroundColor: {
                    linearGradient: {x1: 0, y1: 0, x2: 0, y2: 1},
                    stops: [
                        [0, '#FFF'],
                        [1, '#333'],
                    ],
                },
                borderWidth: 0,
                outerRadius: '109%',
            }, {
                backgroundColor: {
                    linearGradient: {x1: 0, y1: 0, x2: 0, y2: 1},
                    stops: [
                        [0, '#333'],
                        [1, '#FFF'],
                    ],
                },
                borderWidth: 1,
                outerRadius: '107%',
            }, {
                // default background
            }, {
                backgroundColor: '#DDD',
                borderWidth: 0,
                outerRadius: '105%',
                innerRadius: '103%',
            }],
        },

        // the value axis
        yAxis: {
            min: min,
            max: max,

            minorTickInterval: 'auto',
            minorTickWidth: 1,
            minorTickLength: 10,
            minorTickPosition: 'inside',
            minorTickColor: '#666',

            tickPixelInterval: 30,
            tickWidth: 2,
            tickPosition: 'inside',
            tickLength: 10,
            tickColor: '#666',
            labels: {
                step: 2,
                rotation: 'auto',
            },
            title: {
                text: suffix,
            },
            plotBands: [{
                from: redFrom,
                to: redTo,
                color: '#DF5353', // red
            }, {
                from: yellowFrom,
                to: yellowTo,
                color: '#DDDF0D', // yellow
            }, {
                from: greenFrom,
                to: greenTo,
                color: '#55BF3B', // green
            }],
        },
        credits: {
          enabled: false,
        },
        series: [{
            name: dataName,
            data: [score],
            dataLabels: {
                // eslint-disable-next-line max-len
                format: '<div style="text-align:center"><div style="font-size:25px;color:black;">{y}</div><div style="font-size:12px;color:#8f8f8f;">'+suffix+'</div></div>',
                padding: 10,
                paddingTop: 20,
                useHTML: true,
            },
            tooltip: {
                valueSuffix: ' '+suffix,
            },
        }],

    };

    return (
      <Highchart style={style} config={config}></Highchart>
    );
  }
};
