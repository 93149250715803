import React from 'react';
import './poworksheet/styles.scss';
import PerfomanceGates from './poworksheet/perfomancegates';
import AttainmentIncentives from './poworksheet/attainmentincentives';
import { IncentiveDesignSettings } from './poworksheet/incentivedesignsettings';
import IncentivePayment from './poworksheet/incentivepayment';
import SharedSavings from './poworksheet/sharedsavings';
import { siteMessages } from 'src/utils/copy';
import queryString from 'query-string';
import { calculateSharedSavings, calculateTotalBaseIncentive } from './poworksheet/calculations';
import { getData } from '../../../common/request';
import { connect } from 'react-redux';
import Loading from '../../../common/Loading';
import UrlParams from '../../../common/urlParams';
import HealthPlan from './poworksheet/healthplan';
import { useOutletContext } from 'react-router-dom';

class PoWorksheet extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      config: null,
      orgData: null,
      healthPlans: null,
      selectedPlan: null
    };

    const url = this.props.location.search;

    if (url.includes("&sp")) {
      this.state.urlEnding = url.substring(url.indexOf("&sp"));
    }

    this.url_params = queryString.parse(url, { arrayFormatSeparator: '|' });

    if ((!this.url_params.organization || props.organization.id.toString() === this.url_params.organization) &&
    (!this.url_params.duration || props.duration.id.toString() === this.url_params.duration) &&
    (!this.url_params.product || props.product.id.toString() === this.url_params.product)) {
      this.downloadConfig(props);
      this.downloadOrgData(props);
    }
  }

  updateUrlEnding(props, resetDesignSettings) {
    if (this.state.isLoading) return;

    let urlEnding = '';

    if (this.state.config && !resetDesignSettings) {
      const sharedSavings = this.state.config["shared_savings"];
      const attainment = this.state.config["attainment"];
      const id = queryString.stringify({ id: attainment.map(x => x.measurespec_id) }, { arrayFormatSeparator: '|' });
      const c = this.getSharedSavingsAvailable() && queryString.stringify({ c: sharedSavings.map(x => x.cost_per_resource_use) }, { arrayFormatSeparator: '|' });
      const ati = this.getAttainmentAvailable() && queryString.stringify({ ati: attainment.map(x => JSON.stringify(x.attainment_incentives)) }, { arrayFormatSeparator: '|' });
      const sp = this.state.selectedPlan;

      urlEnding += `&sp=${sp}&${id}`;

      if (c) {
        urlEnding += `&${c}`;
      }

      if (ati) {
        urlEnding += `&${ati}`;
      }
    }

    this.setState({
      urlEnding: urlEnding
    });
  }

  async componentWillReceiveProps(props) {
    if ((this.props.duration.id !== props.duration.id) ||
      (this.props.organization.id !== props.organization.id) ||
    (this.props.product.id !== props.product.id)) {

      this.url_params = queryString.parse(this.props.location.search, { arrayFormatSeparator: '|' });

      if (props.organization.id.toString() !== this.url_params.organization ||
        props.duration.id.toString() !== this.url_params.duration ||
        props.product.id.toString() !== this.url_params.product) {
          this.updateUrlEnding(props, true);
      }

      this.downloadConfig(props);
      this.downloadOrgData(props);
    }
  }

  async downloadConfig(props) {
    let url = '/api/v2/po_worksheet/config/';

    this.setState({
      isLoading: true
    });

    let data = await getData(url, {
      duration_id: props.duration.id,
      product_id: props.product.id
    });

    if (data.config) {
      this.setState({
        config: data.config,
        initialConfig: JSON.parse(JSON.stringify(data.config))
      });
    }

    this.onUrlUpdate(queryString.parse(this.props.location.search, { arrayFormatSeparator: '|' }));
  }

  async downloadOrgData(props) {
    let url = '/api/v2/po_worksheet/org_data/';

    let data = await getData(url, {
      duration_id: props.duration.id,
      product_id: props.product.id,
      organization_id: props.organization.id
    });

    if (data.config) {
      const healthPlans = Object.keys(data.config["health_plan_data"]);

      this.setState({
        orgData: data.config,
        healthPlans: healthPlans,
        selectedPlan: healthPlans[0]
      });
      setTimeout(() => this.onUrlUpdate(queryString.parse(this.props.location.search, { arrayFormatSeparator: '|' })), 0);
    } else {
      this.setState({
        responseMessage: siteMessages['amp.page.measures.reports.poworksheet.page.nodata']
        .replace('$duration', props.duration.name)
        .replace('$product', props.product.name)
        .replace('$organization', props.organization.name),
        orgData: null
      })
    }

    this.setState({
      isLoading: false
    });
  }

  onUrlUpdate(params, onpopstate = false, prevProps) {
    if (Object.keys(params).length >= 5 && this.state.config) {
      const sharedSavings = this.getSharedSavingsAvailable() ? params.id.map((id, index) => {
        return {
          ...this.state.config["shared_savings"].find(item => item["measurespec_id"] == id),
          cost_per_resource_use: params.c[index],
        };
      }) : [];

      const attainment = this.getAttainmentAvailable() ? params.id.map((id, index) => {
        return {
          ...this.state.config["attainment"].find(item => item["measurespec_id"] == id),
          attainment_incentives: JSON.parse(params.ati[index]).map(x => parseFloat(x)),
        };
      }) : [];

      const newConfig = this.state.config;
      newConfig["shared_savings"] = sharedSavings;
      newConfig["attainment"] = attainment;

      this.setState({
        config: newConfig,
        selectedPlan: params.sp
      });
    }
  }

  updateMeasure = (measureId, propName, newValue) => {
    const newState = this.state;

    if (this.getSharedSavingsAvailable() && newState.config["shared_savings"][0][propName]) {
      newState.config["shared_savings"].find(ss => ss["measurespec_id"] === measureId)[propName] = newValue;
    }

    if (this.getAttainmentAvailable() && newState.config["attainment"][0][propName]) {
      newState.config["attainment"].find(ss => ss["measurespec_id"] === measureId)[propName] = newValue;
    }

    this.setState(newState);
    this.updateUrlEnding();
  }

  getSharedSavingsAvailable = () =>  this.state.config["shared_savings"] && this.state.config["shared_savings"].length > 0;
  getAttainmentAvailable = () =>  this.state.config["attainment"] && this.state.config["attainment"].length > 0;

  render() {
    const urlParams = (<UrlParams
      location={this.props.location}
      history={this.props.history}
      baseUrl="/measures/reports/po_worksheet"
      params={["organization", "duration", "product"]}
      urlEnding={this.state.urlEnding}
      onUrlUpdate={(params, onpopstate, prevProps) => this.onUrlUpdate(params, onpopstate, prevProps)} />);

    if (this.state.isLoading) return (<div>{urlParams}<Loading /></div>)
    if (!this.state.config || !this.state.orgData) return (<div>{urlParams}<b><span className="markdown-content"><div><p>{this.state.responseMessage}</p></div></span></b></div>);

    const sharedSavingsAvailable = this.getSharedSavingsAvailable(),
          attainmentAvailable = this.getAttainmentAvailable(),
          isHealthPlan = this.props.organization.org_type === 0;

    const incentiveDesignSettings = sharedSavingsAvailable ? this.state.config["shared_savings"].map(
      ssItem => {
        let setting = {
          measure_id: ssItem["measurespec_id"],
          measure_code: ssItem["measure_abbr"],
          measure_name: ssItem["measure_name"],
          cost_per_resource_use: ssItem["cost_per_resource_use"]
        };

        if (attainmentAvailable) {
          setting.attainment_incentives = this.state.config["attainment"].find(item => item["measurespec_id"] === ssItem.measurespec_id)["attainment_incentives"];
        }

        return setting;
      }) :
      this.state.config["attainment"].map(attainmentItem => {
        return {
          measure_id: attainmentItem["measurespec_id"],
          measure_code: attainmentItem["measure_abbr"],
          measure_name: attainmentItem["measure_name"],
          attainment_incentives: attainmentItem["attainment_incentives"]
        };
      });

    const sharedSavings = sharedSavingsAvailable && calculateSharedSavings(this.state.config["shared_savings"].map(
      ssItem => {
        const orgDataItem = this.state.orgData["health_plan_data"][this.state.selectedPlan]["shared_savings"].find(item => item["measure_id"] === ssItem.measurespec_id);
        return {
          measure_name: `${ssItem.measure_name} (${orgDataItem.measure_code})`,
          cost_per_resource_use: ssItem.cost_per_resource_use,
          previous_year_result: orgDataItem.previous_year_result,
          current_year_result: orgDataItem.current_year_result,
          rate_change_equation: ssItem.rate_change_equation,
          current_year_rate_expected: orgDataItem.rate_expected,
          denominator: orgDataItem.scaling,
          resource_use_savings_equation: ssItem.resource_use_savings_equation,
          savings_estimate_equation: ssItem.savings_estimate_equation,
          better_direction: ssItem.is_reported_higher_better ? "up" : "down"
        };
      }
    ));

    if (sharedSavings) {
      sharedSavings.labels = this.state.config["labels"];
    }

    let attainmentIncentives = attainmentAvailable && {
      labels: this.state.config["labels"],
      currentYearMemberYears: this.state.orgData["health_plan_data"][this.state.selectedPlan]["totals"].current_year_member_years,
      measures: this.state.config["attainment"].map(
        attainmentItem => {
          const orgDataItem = this.state.orgData["health_plan_data"][this.state.selectedPlan]["attainment"].find(item => item["measure_id"] === attainmentItem.measurespec_id);

          return {
            measure_id: orgDataItem.measure_id,
            measure_code: orgDataItem.measure_code,
            measure_name: attainmentItem.measure_name,
            previous_year_attainment: attainmentItem.previous_year_attainment,
            current_year_attainment: attainmentItem.current_year_attainment,
            current_year_result: orgDataItem.current_year_score,
            previous_year_result: orgDataItem.previous_year_score,
            better_direction: attainmentItem.is_reported_higher_better ? "up" : "down",
            function__suffix: attainmentItem.function__suffix,
            function__score__label: attainmentItem.function__score__label,
            attainment_incentives: attainmentItem.attainment_incentives,
          };
        }
      )
    };

    if (attainmentAvailable) {
      attainmentIncentives.totalBaseIncentive = calculateTotalBaseIncentive(attainmentIncentives.measures);
    }

    const perfomanceGates = this.state.orgData["health_plan_data"][this.state.selectedPlan]["performance_gates"].map(gate => {
      const configGate = this.state.config["performance_gates"].find(item => item["gate_id"] === gate.gate_id);
      return {
        ...gate,
        ...configGate
      };
    });

    const incentivePayment = this.state.orgData["health_plan_data"][this.state.selectedPlan]["totals"];
    incentivePayment.poSharedSavings = sharedSavingsAvailable ? sharedSavings.sharedSavingsTotal / 2 : 0;
    incentivePayment.totalAttainmentIncentive = attainmentAvailable ? attainmentIncentives.totalBaseIncentive * attainmentIncentives.currentYearMemberYears : 0;
    incentivePayment.totalLabel = (() => {
      if (!sharedSavingsAvailable) {
        return siteMessages['amp.page.measures.reports.poworksheet.page.incentivepayment.attainmentonly'];
      } else if (!attainmentAvailable) {
        return siteMessages['amp.page.measures.reports.poworksheet.page.incentivepayment.sharedsavingsonly'];
      } else {
        return siteMessages['amp.page.measures.reports.poworksheet.page.incentivepayment.combined'];
      }
    })();

    return (
      <div className="po-worksheet">
        <UrlParams
          location={this.props.location}
          history={this.props.history}
          baseUrl="/measures/reports/po_worksheet"
          params={["organization", "duration", "product"]}
          urlEnding={this.state.urlEnding}
          onUrlUpdate={(params, onpopstate, prevProps) => this.onUrlUpdate(params, onpopstate, prevProps)} />
        {!isHealthPlan &&
          <div className="tabs is-boxed" style={{ clear: 'both' }}
            onClick={() => { }}>
            <ul>
            {this.state.healthPlans.map(plan =>
              <li
                className={this.state.selectedPlan === plan && 'is-active'}
                key={plan}
                onClick={() => {
                  this.setState({ selectedPlan: plan });
                  setTimeout(() => this.updateUrlEnding(), 0);
                }} >
                <a id={plan}>{plan}</a></li>
              )}
            </ul>
          </div>
        }
        <h2 className="core-title"><span className="markdown-content"><div><p>{siteMessages[`amp.page.measures.reports.poworksheet.page.${isHealthPlan ? 'hptitle': 'title'}`]}</p></div></span></h2>
        <div className="po-disclaimer">
          <span className="markdown-content">
            <div>
              <div className="first-disclaimer">
                {siteMessages['amp.page.measures.reports.poworksheet.page.paymentdisclaimer']}
              </div>
              <div dangerouslySetInnerHTML={{ __html: siteMessages['amp.page.measures.reports.poworksheet.page.coviddisclaimer'] }} />
            </div>
          </span>
        </div>
        <div className="flex-row">
          <IncentiveDesignSettings data={incentiveDesignSettings}
          sharedSavingsAvailable={sharedSavingsAvailable}
          attainmentAvailable={attainmentAvailable}
          labels={this.state.config["labels"]}
          updateMeasure={(measureId, propName, newValue) => this.updateMeasure(measureId, propName, newValue)}
            onReset={() => {
            this.setState({
              config: JSON.parse(JSON.stringify(this.state.initialConfig))
            });
            this.updateUrlEnding();
            }} />
          {!isHealthPlan && <PerfomanceGates data={perfomanceGates} />}
          <IncentivePayment data={incentivePayment} isHealthPlan={isHealthPlan}/>
        </div>
        {!isHealthPlan && sharedSavingsAvailable && <SharedSavings data={sharedSavings} />}
        {!isHealthPlan && <div className="po-disclaimer"><font color="red">{siteMessages['amp.page.measures.reports.poworksheet.page.attainmentdisclaimer']}</font></div> }
        {!isHealthPlan && attainmentAvailable && <AttainmentIncentives data={attainmentIncentives}/>}
        {isHealthPlan && <HealthPlan/>}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

const PoWorksheetProxy = (props) => {
  const context = useOutletContext();
  return <PoWorksheet {...props} {...context}/>;
};

export default connect(mapStateToProps)(PoWorksheetProxy);
