import React from 'react';
import FormattedMessage from 'components/FormattedMessageMarkdown';

export default class UnauthPage extends React.Component {
  render() {
    return (
      <div>
        <FormattedMessage id='amp.unauth.text.unauthorized'
          textComponent='span'
        />
      </div>
    );
  }
}
