import React from 'react';
import { NumericInput } from '../../../../common/input';
import { siteMessages } from 'src/utils/copy';
import './perfomancegates.scss';

function PerfomanceGates({ data }) {
  const getGateDirection = (gate) => {
    switch (gate.gate_direction) {
      case "less":
        return "<";
      case "greater":
        return ">";
      case "greater-equal":
        return "≥";
      case "less-equal":
        return "≤";
    }
  }
  const allGatesPassed = data.every(gate => gate.passing);
  return (
    <div className="perfomance-gates">
      <div className="table">
        <div className="flex-row">
          <div>
            <span className="title">{siteMessages['amp.page.measures.reports.poworksheet.page.perfomancegates.title']}</span>
          </div>
        </div>
        <div className="flex-row">
          <font color="red">{siteMessages['amp.page.measures.reports.poworksheet.page.perfomancegates.disclaimer']}</font>
        </div>
        <div className="flex-row">
          <div>
          </div>
          <div>
            <span className="column-title">{siteMessages['amp.page.measures.reports.poworksheet.page.perfomancegates.result']}</span>
          </div>
          <div><span></span></div>
          <div>
            <span className="column-title">{siteMessages['amp.page.measures.reports.poworksheet.page.perfomancegates.threshold']}</span>
          </div>
        </div>
        {data.map(gate =>
          <div key={gate.gate_id} className="flex-row">
            <div>
              <span>{gate.gate_label}</span>
            </div>
            <div>
              <NumericInput value={gate.org_score} />
            </div>
            <div style={{ background: !gate.passing ? "#f2dede" : "#dff0d8" }} ><span>{getGateDirection(gate)}</span></div>
            <div>
              <NumericInput value={gate.threshold} />
            </div>
          </div>
        )}
        <div className={`flex-row gates-message ${allGatesPassed ? 'passed' : 'not-passed'}`}>
          <div>
            <i className={'fas fa-3x fa-check-circle'}></i>
            <i className={'fas fa-3x fa-times-circle'}></i>
            <span className="column-title">{
              allGatesPassed ?
                siteMessages['amp.page.measures.reports.poworksheet.page.perfomancegates.passed'] :
                siteMessages['amp.page.measures.reports.poworksheet.page.perfomancegates.notpassed']}</span>
          </div>
        </div>
      </div>
    </div>);
}

export default PerfomanceGates;