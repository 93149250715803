import React from 'react';
import { Table } from 'common/prototable';
import { CurrencyInput } from '../../../../common/input';
import { siteMessages } from 'src/utils/copy';
import './sharedsavings.scss';

const pctlSetter = function (columnKey, rowObject) {
    if (columnKey === 'rate_change') {
        if (rowObject[columnKey] === null) return '';
        return rowObject[columnKey] > 0 ? 'has-background-success' : 'has-background-danger';
    } else {
        return '';
    }
};

const eltSetter = function (columnKey, rowObject) {
    if (columnKey === 'better_direction') {
        return (
            <span className="better-direction">
                <i className={`fas fa-arrow-${rowObject[columnKey]}`}></i>
            </span>
        );
    }
    if (columnKey === 'rate_change' || columnKey === 'resource_use_savings' || columnKey === 'savings_estimate' || columnKey === 'cost_per_resource_use') {
        if (rowObject[columnKey] === null) return;
        let formatOptions = {};

        if (columnKey === 'savings_estimate') {
            formatOptions = { minimumFractionDigits: 2, maximumFractionDigits: 2 };
        }

        const formatValue = () => {
            let prefix = '';

            if (columnKey === 'savings_estimate' || columnKey === 'cost_per_resource_use') {
                prefix = '$'
            }

            return rowObject[columnKey] > 0 ?
                `${prefix}${rowObject[columnKey].toLocaleString('en-US', formatOptions)}` :
                `(${prefix}${Math.abs(rowObject[columnKey]).toLocaleString('en-US', formatOptions)})`;
        }

        return (
            <span>
                {formatValue()}
            </span>
        );
    }

    if (!isNaN(parseFloat(rowObject[columnKey])) && isFinite(rowObject[columnKey])) {
        return rowObject[columnKey].toLocaleString();
    }

    return rowObject[columnKey];
};

function SharedSavings({ data }) {

    const columns = [
        {
            key: 'measure_name',
            label: siteMessages['amp.page.measures.reports.poworksheet.page.sharedsavings.measure'],
        },
        {
            key: 'better_direction',
            label: siteMessages['amp.page.measures.reports.poworksheet.page.sharedsavings.direction'],
        },
        {
            key: 'cost_per_resource_use',
            label: siteMessages['amp.page.measures.reports.poworksheet.page.sharedsavings.cost'],
        },
        {
            key: 'previous_year_result',
            label: `${data.labels.previous_duration_label} Result`,
        },
        {
            key: 'current_year_result',
            label: `${data.labels.current_duration_label} Result`,
        },
        {
            key: 'rate_change',
            label: data.labels.rate_change_header,
        },
        {
            key: 'current_year_rate_expected',
            label: `${siteMessages['amp.page.measures.reports.poworksheet.page.sharedsavings.rate']} (${data.labels.current_duration_label})`,
        },
        {
            key: 'denominator',
            label: siteMessages['amp.page.measures.reports.poworksheet.page.sharedsavings.denominator'],
        },
        {
            key: 'resource_use_savings',
            label: data.labels.ru_savings_header,
        },
        {
            key: 'savings_estimate',
            label: data.labels.savings_estimate_header,
        }
    ];

    return (
        <div className="shared-savings flex-column">
            <div>
                <div className="flex-row">
                    <div>
                        <span className="title">{siteMessages['amp.page.measures.reports.poworksheet.page.sharedsavings.title']}</span>
                    </div>
                </div>
                <div className="flex-row">
                    <div>
                        <span className="title">{siteMessages['amp.page.measures.reports.poworksheet.page.sharedsavings.total']}</span>
                    </div>
                    <div>
                        <CurrencyInput value={data.sharedSavingsTotal} />
                    </div>
                    <div>
                        <span className="title">{siteMessages['amp.page.measures.reports.poworksheet.page.sharedsavings.poshare']}</span>
                    </div>
                    <div>
                        <CurrencyInput value={data.sharedSavingsTotal / 2} />
                    </div>
                </div>
            </div>
            <Table data={data.measures}
                classSetter={pctlSetter}
                elementSetter={eltSetter}
                columns={columns} />
        </div>);
}

export default SharedSavings;
