
import React from 'react';
import bulmaAccordion from 'bulma-accordion/dist/js/bulma-accordion.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { faDollarSign } from '@fortawesome/free-solid-svg-icons'

class MeasureList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activePriorityArea: props.selectedMeasure.priority_area__name,
    };
    this.instances = [];
  }
  syncAccordionListeners() {
    this.instances.map(i => i.destroy());
    this.instances = bulmaAccordion.attach('.accordions');
  }
  componentDidMount() {
    this.syncAccordionListeners();
  }

  componentDidUpdate() {
    this.syncAccordionListeners();
  }
  updatePriorityArea(pa) {
    this.setState({ activePriorityArea: pa });
  }
  componentWillMount() {
    this.updatePriorityArea(this.props.selectedMeasure.priority_area__name);
  }
  componentWillReceiveProps(nextProps) {
    this.updatePriorityArea(nextProps.selectedMeasure.priority_area__name);
  }
  handleSelect(selectedKey) {
    if (selectedKey.target.innerText == this.state.activePriorityArea) {
      this.updatePriorityArea('');
    } else {
      this.updatePriorityArea(selectedKey.target.innerText);
    }
  }
  render() {
    let priorityAreas = {};
    let priorityOrdering = [];
    this.props.measures.filter(elt => {
      if (!priorityAreas.hasOwnProperty(elt.priority_area__name)) {
        priorityAreas[elt.priority_area__name] = [];
        priorityOrdering.push(elt.priority_area__name);
      }
      priorityAreas[elt.priority_area__name].push(elt);
      return false;
    });

    // Make Priority Areas consistent (alphabetically)
    priorityOrdering.sort();

    let priorityPanels = priorityOrdering.map(elt => {
      let pMeasures = priorityAreas[elt].map(measure => {
        let tags = [];

        // Add a HTML5 workbreak tag to enable long measure
        // abbreviations to have word breaks on '_'.
        let abbrParts = measure.abbr.split('_') || [];
        let displayAbbr = abbrParts.map((abbrPart, i) => {
          let splitter = i === 0 ? '' : <span>_<wbr /></span>;
          return <span key={i}>{splitter}{abbrPart}</span>;
        });
        if (measure.tags) {
          if (measure.tags.indexOf('Testing Measure') != -1) {
            tags.push(
              <FontAwesomeIcon icon={faSearch} className="testing-measure-icon"/>
            );
          }
          if ((measure.tags.indexOf('Paid Measure') != -1 ||
            measure.tags.indexOf('Value Based P4P') != -1)) {
            tags.push(
              <FontAwesomeIcon icon={faDollarSign} className="paid-measure-icon"/>
            );
          }
          if (measure.tags.indexOf('First-Year Measure') != -1) {
            tags.push(
              <FontAwesomeIcon icon={faStar} className="first-year-measure-icon"/>
            );
          }
        }

        let className = 'measure-list-item panel-block list-group-item has-tooltip ';
        if (measure.id === this.props.selectedMeasure.id) className += 'active';

        return (

          <div style={{ cursor: 'pointer' }}
            className={className}
            onClick={this.props.updateMeasure.bind(null, measure)}
          >
        {displayAbbr} {tags}
       <div className="tooltip-wrapper"><div className="tooltip">{measure.name_full}</div></div>
          </div>
        );
      });
      return (
        <div className={this.state.activePriorityArea == elt ? 'accordion is-active' : 'accordion'}>
          <div className="accordion-header toggle"
            value={elt}
            onClick={this.handleSelect.bind(this)}
          >
            <a>{elt}</a>
          </div>
          <div className="accordion-body">
            <div className="accordion-content">
              <a className="panel list-group">
                {pMeasures}
              </a>
            </div>
          </div>
        </div>
      );
    });
    return (
      <div className="accordions" style={{paddingBottom:'20px'}}>
        {priorityPanels}
      </div>
    );
  }
}

export default MeasureList;
