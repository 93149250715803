  /* eslint-disable camelcase */
import React from 'react';
import {connect} from 'react-redux';
import { Link } from 'react-router-dom';
import {
  ORGANIZATION_HP,
  ORGANIZATION_PO,
} from 'state/constants';

import {getUseReact} from 'state/selectors';
import {siteMessages} from 'src/utils/copy';
import FormattedMessage from 'components/FormattedMessageMarkdown';

class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeOrg: false,
      activeContract: false,
      activeMeasures: false,
      activeContact: false,
    }
  }
  componentDidMount() {
    const spltdUrl=window.location.href.split("/",4)[3]
    if (spltdUrl=="organization-profile?organization=222" || spltdUrl=="organization-profile?organization=91" ){
      this.handler("Organisation")
    }
    else if (spltdUrl=="contracts"){
      this.handler("Contract")
    }
    else if(spltdUrl=="measures"){
      this.handler("Measures")
    }
    else if(spltdUrl=="contacts"){
      this.handler("Contact")
    }
  }
 

  handler=(num)=> {
   if(num === "Organisation"){
    this.setState({
      activeOrg : true,
      activeContract: false,
      activeMeasures: false,
      activeContact: false,
        })
   }
   if(num === "Contract"){
    this.setState({
      activeOrg : false,
      activeContract: true,
      activeMeasures: false,
      activeContact: false,
        })
   }
   if(num === "Measures"){
    this.setState({
      activeOrg : false,
      activeContract: false,
      activeMeasures: true,
      activeContact: false,
        })
   }
   if(num === "Contact"){
    this.setState({
      activeOrg : false,
      activeContract: false,
      activeMeasures: false,
      activeContact: true,
        })
    }
  }

  render() {
    let navItemElts = [];
    let otherNavElts = [];

    if (this.props.loggedIn) {
      let orgTypes = [];
      if (this.props.org_types.includes(ORGANIZATION_PO)) {
        orgTypes.push({
          key: 'po',
          label: siteMessages['amp.sitewide-nav.physician-organization.label'],
        });
      }
      if (this.props.org_types.includes(ORGANIZATION_HP)) {
        orgTypes.push({
          key: 'hp',
          label: siteMessages['amp.sitewide-nav.health-plan.label'],
        });
      }
      navItemElts = [
      ];
      orgTypes.forEach(elt => {
        // for each org type, add profile and contracting information
        // for redirection to legacy django-controlled pages
        if (!this.props.USE_REACT) {
          navItemElts = navItemElts.concat([
              <Link  to={`/${elt.key}-profile/`}  className= "navbar-item" key={'2' + elt.key}>
                {elt.label} Profile
              </Link>,
              <Link to={`/contracts/${elt.key}/`}  className='navbar-item' key={'3' + elt.key}>
                <FormattedMessage id='amp.sitewide-nav.contracting'
                  textComponent='span'
                />
              </Link>
          ]);
        }
      });
      if (orgTypes.length > 0) {
        // if we have and org type, add measures and contacts
        // probably other pages would migrate here if controlled by react
        if (this.props.USE_REACT) {
          navItemElts = navItemElts.concat([
           
              <Link to= {siteMessages['amp.sitewide-nav.organization.profile.url']} 
               onClick={()=>this.handler("Organisation")} className= {`navbar-item${this.state.activeOrg ? ' active':''}`} key="3" 
               >
                <FormattedMessage id='amp.sitewide-nav.organization.profile'
                  textComponent='span'
                />
                </Link>,
                <Link to={siteMessages['amp.sitewide-nav.contracting.url']}  onClick={()=>this.handler("Contract")}  className= {`navbar-item${this.state.activeContract ? ' active':''}`} key="4">
                <FormattedMessage id='amp.sitewide-nav.contracting'
                    textComponent='span'
                  />
                  </Link>
           
          ]);
        }
        navItemElts = navItemElts.concat([
          <Link to={siteMessages['amp.sitewide-nav.measures.url']}  className= {`navbar-item${this.state.activeMeasures ? ' active':''}`}
           onClick={()=>this.handler("Measures")} key="5" >
                <FormattedMessage id='amp.sitewide-nav.measures'
                  textComponent='span'
                />
          </Link>,
          <Link   to={siteMessages['amp.sitewide-nav.contacts.url']} 
           onClick={()=>this.handler("Contact")}  className= {`navbar-item${this.state.activeContact ? ' active':''}`} key="6" >
              <FormattedMessage id='amp.sitewide-nav.contacts'
                textComponent='span'
              />
          </Link>,
        ]);
      }
      otherNavElts = [
        <div className="navbar-item" key="6">
            <div className="buttons">
           <a className="button" href={siteMessages['amp.sitewide-nav.logout.url']}>
           
              <FormattedMessage id='amp.sitewide-nav.logout'
              textComponent='span'
            />
             </a>
          </div>
        </div>,
      ];
    } else {
      otherNavElts = [
        <div className="navbar-item" key="7">
          <div className="buttons">
          <a  className="button"  href={siteMessages['amp.sitewide-nav.signup.url']}>
            <FormattedMessage id='amp.sitewide-nav.signup'
              textComponent='span'
            />
              </a> 
          </div>
        </div>,
        <div className="navbar-item" key="8">
           <div className="buttons">
          <a className="button"
            href= {siteMessages['amp.sitewide-nav.login.url']}
          >
            <FormattedMessage id='amp.sitewide-nav.login'
              textComponent='span'
            />
          </a>
          </div>
        </div>,
      ];
    }
    return (
      <nav className="navbar has-background-dark" role="navigation" aria-label="main navigation">
    <div className="navbar-brand">
      <div className="navbar-item">
        <img src= {siteMessages['amp.sitewide-nav.logo-img.link']}/>
      </div>

      <a role="button" className="navbar-burger burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <div className="navbar-menu">
      <div className="navbar-start">
      {navItemElts}
      </div>

      <div className="navbar-end">
      {otherNavElts}
      </div>
    </div>
  </nav>
    );
  }
}

const mapStateToProps = state => {
  return {
    USE_REACT: getUseReact(state),
  };
};

// for whatever reason, our Links are not being rerendered as the location
// changes. (There are some implementation quicks with React Router v2)
// We force the navbar to always be updated so that our navbar always shows
// the correct active link.
export default connect(mapStateToProps, null, null, {pure: false})(Navbar);




