import React from 'react';

import {
  ORGANIZATION_HP,
  ORGANIZATION_PO,

  CONTRACT_PO_ACCEPTED,
  CONTRACT_HP_ACCEPTED,
  CONTRACT_PO_HP_ACCEPTED,

  CONTRACT_PO_REJECTED,
  CONTRACT_HP_REJECTED,
  CONTRACT_PO_HP_REJECTED,

  CONTRACT_PO_REJECTED_HP_ACCEPTED,
  CONTRACT_PO_ACCEPTED_HP_REJECTED,

  // Constant needs to be true to enable the delete button
  CONTRACT_CAN_REMOVE,
} from 'state/constants';

import FormattedMessage from 'components/FormattedMessageMarkdown';
import EditContractButton from './EditContractButton';
import './styles.scss';

const ContractControls = props => {

  let canConfirm;
  if (props.organization.org_type == ORGANIZATION_HP) {
    canConfirm = (
      (props.contract.contract_status != CONTRACT_HP_ACCEPTED) &&
      (props.contract.contract_status != CONTRACT_PO_REJECTED_HP_ACCEPTED) &&
      (props.contract.contract_status != CONTRACT_PO_HP_ACCEPTED)
    );
  } else if (props.organization.org_type == ORGANIZATION_PO) {
    canConfirm = (
      (props.contract.contract_status != CONTRACT_PO_ACCEPTED) &&
      (props.contract.contract_status != CONTRACT_PO_ACCEPTED_HP_REJECTED) &&
      (props.contract.contract_status != CONTRACT_PO_HP_ACCEPTED)
    );
  } else {
    canConfirm = false;
  }

  let canReject;
  if (props.organization.org_type == ORGANIZATION_HP) {
    canReject = (
      (props.contract.contract_status != CONTRACT_HP_REJECTED) &&
      (props.contract.contract_status != CONTRACT_PO_ACCEPTED_HP_REJECTED) &&
      (props.contract.contract_status != CONTRACT_PO_HP_REJECTED)
    );
  } else if (props.organization.org_type == ORGANIZATION_PO) {
    canReject = (
      (props.contract.contract_status != CONTRACT_PO_REJECTED) &&
      (props.contract.contract_status != CONTRACT_PO_REJECTED_HP_ACCEPTED) &&
      (props.contract.contract_status != CONTRACT_PO_HP_REJECTED)
    );
  } else {
    canReject = false;
  }

  return (
    <div className='control-td contracts-control-page'>
      {canConfirm &&
            <div className="tooltip_block block_inline" >
            <button className="button is-success is-small"
              onClick={props.confirmContract.bind(
              null, props.contract, props.organization)}>
              <span className="icon">
            <i className="fas fa-thumbs-up"></i>
              </span>
            </button>
            <div className="tooltip_edit_data">
              <FormattedMessage
                id='amp.contract.control.tooltip.confirm'
                textComponent='span'
              />
            </div>
          </div>
        }
      <EditContractButton {...props} />

      {CONTRACT_CAN_REMOVE &&
      <div className="tooltip_block block_inline" >

          <button className=" button is-danger is-small"
                  onClick={props.removeContract.bind(
                    null, props.contract, props.organization)}>
            <span className="icon">
            <i className="fas fa-times"></i>
                 </span>
          </button>
          <div className="tooltip_edit_data">
              <FormattedMessage
                id='amp.contract.control.tooltip.remove'
                textComponent='span'
              />
            </div>
      </div>}

      {canReject &&
          <div className="tooltip_block block_inline" >
          <button className='button is-danger is-small'
                  onClick={props.rejectContract.bind(
                    null, props.contract, props.organization)}>
            <span className="icon">
            <i className="fas fa-thumbs-down"></i>
                 </span>
          </button>
          <div className="tooltip_edit_data">
              <FormattedMessage
                id='amp.contract.control.tooltip.reject'
                textComponent='span'
              />
            </div>
      </div>}
    </div>
  );
};

export default ContractControls;
