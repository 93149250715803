import {
  UPDATE_CONTACT_LIST,
  UPDATE_USER_LIST,
  REQUEST_CONTACT_LIST_FAILURE,
  REQUEST_CONTACT_LIST,
  REQUEST_USER_LIST,
  REQUEST_CONTRACT_USER_LIST,
  REQUEST_USER_LIST_FAILURE,
} from './actions';

const initialState = {
  usersAreLoading: false,
  contactsAreLoading: false,
  contacts: {},
  users: [],
};

export default function(state=initialState, action) {
  switch (action.type) {
    case UPDATE_CONTACT_LIST:
      return {
        ...state,
        contactsAreLoading: false,
        contacts: {
          ...state.contacts,
          [action.organization]: action.contacts
        },
      };
    case REQUEST_CONTACT_LIST_FAILURE:
      return {
        ...state,
        contactsAreLoading: false,
      };
    case REQUEST_CONTACT_LIST:
      return {
        ...state,
        contactsAreLoading: true,
      };
      case REQUEST_USER_LIST_FAILURE:
        return {
          ...state,
          usersAreLoading: false,
          users: [],
          error: action.error,
        };
    case REQUEST_USER_LIST:
      return {
        ...state,
        usersAreLoading: true
      };
    case REQUEST_CONTRACT_USER_LIST:
        return {
          ...state,
          usersAreLoading: true
        };
    case UPDATE_USER_LIST:
      return {
        ...state,
        usersAreLoading: false,
        users: action.users,
      };
  }
  return state;
}
