/* eslint-disable camelcase */
import React from 'react';
import {Table, Tr} from 'common/prototable';
import {ScoreGauge, ScoreGaugeLegend} from 'common/score-gauge';

import {RightScorePanel} from './measure-score-panel';
import {siteMessages} from 'src/utils/copy';

let round = num => {
  let n = num * 100;
  n = Math.round(n);
  return n / 100;
};

let confidenceUpper = (rate, den) => {
  if (rate==='SD') {
    return 'N/A';
  }
  rate = rate / 100;
  let c = ( rate + 1.96 * Math.sqrt((rate * (1-rate))/den) + (1/(2 * den)))*100;
  c = round(c);
  if (c > 100) {
    return '100%';
  } else if (c < 0) {
    return '0%';
  } else {
    return `${c}%`;
  }
};

let confidenceLower = (rate, den) => {
  if (rate==='SD') {
    return 'N/A';
  }
  rate = rate / 100;
  let c = ( rate - 1.96 * Math.sqrt((rate * (1-rate))/den) - (1/(2 * den)))*100;
  c = round(c);
  if (c > 100) {
    return '100%';
  } else if (c < 0) {
    return '0%';
  } else {
    return `${c}%`;
  }
};

let spmTotal = (duration_id, measure_id) => {
  if (duration_id == 15) {
    if (measure_id == 253) {
      return 25;
    } else {
      return 2.5;
    }
  }
  if (duration_id == 14) {
    if (measure_id == 253) {
      return 25;
    } else {
      return 2.5;
    }
  }
  return 5;
};

// eslint-disable-next-line react/no-deprecated
class MuhitView extends React.Component {
  static defaultProps = {
    duration: {
      id: 0,
    },
    measure: {
      id: 0,
    },
  }
  render() {
    let tableData = [];
    let scoreLine = {};
    let loading = false;
    let rank = (this.props.stats ?
      (this.props.stats.rank ? this.props.stats.rank : null)
      : null
    );
    let num_orgs = (this.props.stats ?
      (this.props.stats.number_orgs ? this.props.stats.number_orgs : null)
      : null
    );
    let last_score = (this.props.stats ?
      (this.props.stats.last_score ? this.props.stats.last_score : null)
      : null
    );
    let spm = (this.props.stats ?
      (this.props.stats.spm ? this.props.stats.spm : null)
      : null
    );
    if (!this.props.instances.response && this.props.instances.length > 0) {
      scoreLine = this.props.scoreLine;
      scoreLine.data_provider__name='Score';
      tableData = this.props.instances.map(elt => {
        let fields = elt.fields;
        Object.keys(fields).map(f => {
          if (Number.isFinite(fields[f])) {
            fields[f] = round(fields[f]);
          }
        });
        fields.data_provider__name = elt.data_provider__name;
        return fields;
      });
    } else {
      loading = true;
    }
    // table columns
    let columns = [];
    if (this.props.measure.fields) {
      columns = this.props.measure.fields.standard.map(elt => {
        let k = Object.keys(elt)[0];
        let v = elt[k];
        return {key: k, label: v};
      }).filter(elt => elt.key!='pts_earned');
      columns.unshift({
        key: 'data_provider__name',
        label: siteMessages[
          'amp.page.measures.measures.po.dataprovider.label'
        ],
      });
    }

    // table
    let table;
    if (scoreLine) {
      table = (
        <Table columns={columns}
          className='table table-responsive table-bordered'
          data={tableData} sortable={false}
        >
          <Tr className="score-row"
            style={{fontWeight: 'bold', borderTop: '2px solid #e3e3e3'}}
            columns={columns}
            data={scoreLine} />
        </Table>
      );
    } else {
      table = (
        <Table columns={columns} className='table-responsive' data={tableData}>
        </Table>
      );
    }
    if (loading) {
      table = (
        <div></div>
      );
    }
    let elements = [];
    let percentiles = (this.props.stats.percentiles ?
      this.props.stats.percentiles : [0, 0, 0, 0, 0]
    );
    if (Object.keys(scoreLine).length > 1) {
      let pctile_50 = percentiles[2];
      let pctile_75 = percentiles[3];
      let higher_is_better = this.props.measure.is_reported_higher_better;
      let scoreGaugeArea;
      let wellScore;
      if (scoreLine.rate==='SD') {
        wellScore = scoreLine.rate;
        scoreGaugeArea = (
          <div style={{height: '50px'}}></div>
        );
      } else {
        wellScore = scoreLine.percent_providers + '%';
        scoreGaugeArea = (
          <ScoreGauge
            height={350}
            score={scoreLine.percent_providers}
            hib={higher_is_better}
            yellow={pctile_50}
            green={pctile_75}
            suffix={'%'} />
        );
      }

      elements.push(
        <div className="panel panel-body"
          style={{marginBottom: '0px', padding: '0px', marginTop: '-1px'}}
        >
          <div className="col-sm-6"
            style={{width: '50%', padding: '0px', margin: '0px', float: 'left'}}
          >
            {scoreGaugeArea}
            <div className="well" style={{marginRight: '10px', textAlign: 'center'}}>
              <b style={{fontSize: '22px'}}> {wellScore} </b>
              <br />
              {scoreLine.data_provider__name}
            </div>
          </div>
          <div className="col-sm-6 pull-right"
            style={{width: '50%', padding: '0px', margin: '0px'}}
          >
            <ScoreGaugeLegend percentiles={true} sd={true} lr={true} />
            <RightScorePanel
              rank={rank}
              numorgs={num_orgs}
              spm={spm}
              spmTotal={spmTotal(this.props.duration.id, this.props.measure.id)}
              last_score={last_score}
              confidenceUpper={
                confidenceUpper(
                  scoreLine.percent_providers,
                  scoreLine.total_eligible_providers
                )
              }
              confidenceLower={
                confidenceLower(
                  scoreLine.percent_providers,
                  scoreLine.total_eligible_providers
                )
              }
            />
          </div>
        </div>
      );
    }
    let renderElt = (
      <div style={this.props.style}>
        {elements}
        {table}
      </div>
    );
    return (
      renderElt
    );
}
};


export default MuhitView;
