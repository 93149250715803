import React from 'react';
import { CurrencyInput, NumericInput } from '../../../../common/input';
import { siteMessages } from 'src/utils/copy';
import './incentivepayment.scss';

export default function IncentivePayment({ data, isHealthPlan}) {
  const combined_shared_attainment = data.poSharedSavings + data.totalAttainmentIncentive,
    quality_multiplier = data.quality_multiplier,
    org_number = data.org_number,
    share_savings = data.share_savings,
    incentive_payment = combined_shared_attainment * quality_multiplier,
    member_incentive_payment = (incentive_payment / data.current_year_member_years) / 12;

  return (
    <div className="incentive-payment">
      <div className="table">
        <div className="flex-row">
          <div>
            <span className="title">{siteMessages[`amp.page.measures.reports.poworksheet.page.incentivepayment.${isHealthPlan ? 'healthplantitle' : 'title'}`]}</span>
          </div>
        </div>
        <div className="disclaimer"><font color="red">{siteMessages['amp.page.measures.reports.poworksheet.page.incentivepayment.disclaimer']}</font></div>
        <div className="flex-row">
          <div>
            <span>{data.totalLabel}</span>
          </div>
          <div><CurrencyInput value={combined_shared_attainment} /></div>
        </div>
        {!isHealthPlan && 
          <div className="flex-row">
            <div>
              <span>{siteMessages['amp.page.measures.reports.poworksheet.page.incentivepayment.multiplier']}</span>
            </div>
            <div><NumericInput value={quality_multiplier} /></div>
          </div>
        }
        <div className="flex-row">
          <div>
            <span>{siteMessages['amp.page.measures.reports.poworksheet.page.incentivepayment.total']}</span>
          </div>
          <div><CurrencyInput value={incentive_payment} /></div>
        </div>
        <div className="flex-row">
          <div>
            <span>{siteMessages['amp.page.measures.reports.poworksheet.page.incentivepayment.percapita']}</span>
          </div>
          <div><CurrencyInput suffix=" PMPM" value={member_incentive_payment} /></div>
        </div>
        {isHealthPlan &&
          <div className="flex-row">
            <div>
              <span>{siteMessages['amp.page.measures.reports.poworksheet.page.incentivepayment.orgnumber']}</span>
            </div>
            <div><NumericInput value={org_number} /></div>
          </div>
        }
        {isHealthPlan &&
          <div className="flex-row">
            <div>
              <span>{siteMessages['amp.page.measures.reports.poworksheet.page.incentivepayment.sharesavings']}</span>
            </div>
            <div><NumericInput suffix="%" value={share_savings} /></div>
          </div>
        }
      </div>
    </div>);
}
