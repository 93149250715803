// eslint-disable-next-line no-undef
export const DEBUG = process.env.NODE_ENV !== 'production';

// Make ACO download button visible for POs and Health Plans.
// This defines a duration up to and including durations we allow
// for ACO downloads.
export const ENABLE_ACO_DOWNLOAD_UPTO = 18;

// Make HP ARU-TCOC download button visible. Set to false
// if there is a worry about sensitive resource or cost
// data becoming visible to health plans.
export const ENABLE_HP_ARU_TCOC_DOWNLOAD = true;

export const SESSION_AUTO_LOGOUT_TIME = 1000 * 60 * 15; // 15 minutes
export const PING_FREQUENCY = 1000 * 60 * 1; // 1 minute
export const NEW_MEASURE_DOWNLOAD_IMPLEMENTATION = true;
export const USE_BACKEND_DOWNLOADS_API = true;

// Intentions
// CONTRACTS_FROZEN determines whether the frontend allows contracts to
// be edited by the user. We currently use this configuration to start
// and stop the intentions process. Note that we should eventually move
// this to the backend, since this means that the endpoint is always
// available to the user.
export const CONTRACTS_FROZEN = false;
export const USE_BACKEND_CONFIG = true;
export const PCON_NOTIF_DEFAULT_FROM_EMAIL = "ampparticipation@iha.org";
export const HIDE_PCON_COMMENTING = false;
export const FREEZE_PRE_PCON_SURVEYS = false;
