import React, { useState } from 'react';
import {connect} from 'react-redux';
import './styles.scss';
import '../../../src/common/styles.scss'
import CommentsModal from './CommentsModal';
import Comment from './Comment';
import { siteMessages } from '../../../src/utils/copy';

const ShowComments = ({data, organization, hidePconCommenting}) => {
  const [showModal, setShowModal] = useState(false),
        [customClassName, setCustomClassName] = useState('');
        
  const comment = data.comments_list[0];

  return (
    <div className={`modal-container control-item edit-contact ${customClassName}`}>
      {comment && !showModal && <Comment comment={comment} tableCellView={true}  />}      
        <button className="button is-small has-tooltip show-all-comments-button"
          onClick={() => { setShowModal(true); setCustomClassName('active_bg') }}>
          {siteMessages[`amp.sitewide-nav.contracting.comments.show-comments${hidePconCommenting ? '-hide-pcon-commenting' : ''}`]}
        </button>
      <div>
        <CommentsModal show={showModal}
          handleCustomClassName={() => setCustomClassName("")}
          hide={() => setShowModal(false)}
          container={document.getElementById('app')}
          organization={organization}
          data={data} />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    hidePconCommenting: state.config.HIDE_PCON_COMMENTING
  };
};
export default connect(mapStateToProps)(ShowComments);