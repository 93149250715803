/* eslint-disable no-invalid-this */
import React from 'react';
import {connect} from 'react-redux';
import IdleTimer from 'react-idle-timer';
import { Outlet } from 'react-router-dom';

import {siteMessages} from 'utils/copy';
import {requestPingSession} from 'state/actions';

import Navbar from './navbar';
import Footer from './footer';
import Notifications from './Notifications';

import {
  isLoggedIn,
  getUserOrgTypes,
} from 'state/selectors';

class RootPage extends React.Component {
  _onIdle = () => {
    if (this.props.isLoggedIn) {
      // auto logs out
      window.location.href = siteMessages['amp.sitewide-nav.logout.url'];
    }
  };

  _onAction = () => {
    if (this.props.isLoggedIn) {
      // ping /about endpoint to keep session active
      this.props.ping();
    }
  }

  render() {
    let rhStyle = {
      minHeight: '100%',
      marginBottom: '-4em',
    };
    if (!this.props.isLoggedIn) {
      rhStyle.background = '#f6f6f6';
    }

    const DEFAULT_PING_FREQUENCY = 6000;

    return (
      <div>
        <IdleTimer
          ref={ref =>{
            this.idleTimer = ref;
          }}
          element={document}
          onAction={this._onAction}
          onIdle={this._onIdle}
          throttle = { this.props.config.PING_FREQUENCY || DEFAULT_PING_FREQUENCY}
          timeout={this.props.config.SESSION_AUTO_LOGOUT_TIME}
        />
        <div id="container" style={{height: '100%', minHeight: '100%'}}>
          <Notifications />
          <Navbar loggedIn={this.props.isLoggedIn}
            org_types={this.props.userOrgTypes}
          />
          { this.props.hasNoOrganizationsAssigned ?
            <div className='no-orgs-message' dangerouslySetInnerHTML={{ __html: siteMessages['amp.page.message.noorganizations'] }}/>
            :
            <div className='root-content'>
              <Outlet/>
            </div>
          }
          <Footer />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    ping() {
      dispatch(requestPingSession());
    },
  };
};

const mapStateToProps = state => {
  return {
    isLoggedIn: isLoggedIn(state),
    userOrgTypes: getUserOrgTypes(state),
    config: state.config,
    hasNoOrganizationsAssigned: state.user.hasNoOrganizationsAssigned
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RootPage);
