import { SHOW_COMMENTS_MODAL_ERROR_MESSAGE } from "./constants";

export const REQUEST_CONTRACT_LIST = 'REQUEST_CONTRACT_LIST';
export const REQUEST_CONTRACT_LIST_FAILURE = 'REQUEST_CONTRACT_LIST_FAILURE';
export const UPDATE_CONTRACT_LIST = 'UPDATE_CONTRACT_LIST';

export const REQUEST_CONTRACT_ORGANIZATIONS = 'REQUEST_CONTRACT_ORGANIZATIONS';
export const REQUEST_CONTRACT_ORGANIZATIONS_FAILURE =
  'REQUEST_CONTRACT_ORGANIZATIONS_FAILURE';
export const UPDATE_CONTRACT_ORGANIZATIONS = 'UPDATE_CONTRACT_ORGANIZATIONS';

export const REQUEST_ORGANIZATION_PRODUCTS = 'REQUEST_ORGANIZATION_PRODUCTS';
export const REQUEST_ORGANIZATION_PRODUCTS_FAILURE =
  'REQUEST_ORGANIZATION_PRODUCTS_FAILURE';
export const UPDATE_ORGANIZATION_PRODUCTS = 'UPDATE_ORGANIZATION_PRODUCTS';

export const ADD_CONTRACT = 'ADD_CONTRACT';
export const ADD_CONTRACT_SUCCESS = 'ADD_CONTRACT_SUCCESS';
export const ADD_CONTRACT_FAILURE = 'ADD_CONTRACT_FAILURE';
export const ADD_CONTRACT_COMMENT = 'ADD_CONTRACT_COMMENT';
export const DELETE_CONTRACT_COMMENT = 'DELETE_CONTRACT_COMMENT';

export const EDIT_CONTRACT = 'EDIT_CONTRACT';
export const EDIT_CONTRACT_SUCCESS = 'EDIT_CONTRACT_SUCCESS';
export const EDIT_CONTRACT_FAILURE = 'EDIT_CONTRACT_FAILURE';

export const REMOVE_CONTRACT = 'REMOVE_CONTRACT';
export const REMOVE_CONTRACT_SUCCESS = 'REMOVE_CONTRACT_SUCCESS';
export const REMOVE_CONTRACT_FAILURE = 'REMOVE_CONTRACT_FAILURE';

export const CONFIRM_CONTRACT = 'CONFIRM_CONTRACT';
export const CONFIRM_CONTRACT_SUCCESS = 'CONFIRM_CONTRACT_SUCCESS';
export const CONFIRM_CONTRACT_FAILURE = 'CONFIRM_CONTRACT_FAILURE';

export const REJECT_CONTRACT = 'REJECT_CONTRACT';
export const REJECT_CONTRACT_SUCCESS = 'REJECT_CONTRACT_SUCCESS';
export const REJECT_CONTRACT_FAILURE = 'REJECT_CONTRACT_FAILURE';

export function requestContractList(organization, duration) {
  return {
    type: REQUEST_CONTRACT_LIST,
    organization,
    duration,
  };
}

export function requestContractListFailure(organization, duration, error) {
  return {
    type: REQUEST_CONTRACT_LIST_FAILURE,
    organization,
    duration,
    error,
  };
}

export function updateContractList(organization, duration, contracts) {
  return {
    type: UPDATE_CONTRACT_LIST,
    organization,
    duration,
    contracts,
  };
}

export function addContractComment(comment, organization) {
  return {
    type: ADD_CONTRACT_COMMENT,
    comment,
    organization
  };
}

export function deleteContractComment(comment, organization) {
  return {
    type: DELETE_CONTRACT_COMMENT,
    comment,
    organization
  };
}

export function requestContractOrganizations(organizationType) {
  return {
    type: REQUEST_CONTRACT_ORGANIZATIONS,
    organizationType,
  };
}

export function requestContractOrganizationsFailure(organizationType, error) {
  return {
    type: REQUEST_CONTRACT_ORGANIZATIONS_FAILURE,
    organizationType,
    error,
  };
}

export function updateContractOrganizations(organizationType, organizations) {
  return {
    type: UPDATE_CONTRACT_ORGANIZATIONS,
    organizationType,
    organizations,
  };
}

export function requestOrganizationProducts(organization) {
  return {
    type: REQUEST_ORGANIZATION_PRODUCTS,
    organization,
  };
}

export function requestOrganizationProductsFailure(organization, error) {
  return {
    type: REQUEST_ORGANIZATION_PRODUCTS_FAILURE,
    organization,
    error,
  };
}

export function updateOrganizationProducts(organization, products) {
  return {
    type: UPDATE_ORGANIZATION_PRODUCTS,
    organization,
    products,
  };
}

export function addContract(
  organization, hp, po, product, duration, notes, options) {
  return {
    type: ADD_CONTRACT,
    organization,
    hp,
    po,
    product,
    duration,
    notes,
    options,
  };
}

export function addContractSuccess(
  organization, hp, po, product, duration, notes, options) {
  return {
    type: ADD_CONTRACT_SUCCESS,
    organization,
    hp,
    po,
    product,
    duration,
    notes,
    options,
  };
}

export function addContractFailure(
  organization, hp, po, product, duration, notes, options, error) {
  return {
    type: ADD_CONTRACT_FAILURE,
    organization,
    hp,
    po,
    product,
    duration,
    notes,
    options,
    error,
  };
}


export function editContract(
  contract, organization, hp, po, product, duration, notes, options) {
  return {
    type: EDIT_CONTRACT,
    contract,
    organization,
    hp,
    po,
    product,
    duration,
    notes,
    options,
  };
}

export function editContractSuccess(
  contract, organization, hp, po, product, duration, notes, options) {
  return {
    type: EDIT_CONTRACT_SUCCESS,
    contract,
    organization,
    hp,
    po,
    product,
    duration,
    notes,
    options,
  };
}

export function editContractFailure(
  contract, organization, hp, po, product, duration, notes, options, error) {
  return {
    type: ADD_CONTRACT_FAILURE,
    contract,
    organization,
    hp,
    po,
    product,
    duration,
    notes,
    options,
    error,
  };
}

export function removeContract(contract, organization) {
  return {
    type: REMOVE_CONTRACT,
    contract,
    organization,
  };
}

export function removeContractSuccess(contract, organization) {
  return {
    type: REMOVE_CONTRACT_SUCCESS,
    contract,
    organization,
  };
}

export function removeContractFailure(contract, organization, error) {
  return {
    type: REMOVE_CONTRACT_FAILURE,
    contract,
    organization,
    error,
  };
}

export function confirmContract(contract, organization) {
  return {
    type: CONFIRM_CONTRACT,
    contract,
    organization,
  };
}

export function confirmContractSuccess(contract, organization) {
  return {
    type: CONFIRM_CONTRACT_SUCCESS,
    contract,
    organization,
  };
}

export function confirmContractFailure(contract, organization, error) {
  return {
    type: CONFIRM_CONTRACT_FAILURE,
    contract,
    organization,
    error,
  };
}

export function rejectContract(contract, organization) {
  return {
    type: REJECT_CONTRACT,
    contract,
    organization,
  };
}

export function rejectContractSuccess(contract, organization) {
  return {
    type: REJECT_CONTRACT_SUCCESS,
    contract,
    organization,
  };
}

export function rejectContractFailure(contract, organization, error) {
  return {
    type: REJECT_CONTRACT_FAILURE,
    contract,
    organization,
    error,
  };
}

export function showCommentsModalErrorMessage(error) {
  return {
    type: SHOW_COMMENTS_MODAL_ERROR_MESSAGE,
    error,
  };
}
