export const REQUEST_CONTACT_LIST = 'REQUEST_CONTACT_LIST';
export const REQUEST_CONTACT_LIST_FAILURE = 'REQUEST_CONTACT_LIST_FAILURE';
export const UPDATE_CONTACT_LIST = 'UPDATE_CONTACT_LIST';

export const REQUEST_USER_LIST = 'REQUEST_USER_LIST';
export const REQUEST_CONTRACT_USER_LIST = 'REQUEST_CONTRACT_USER_LIST';
export const REQUEST_USER_LIST_FAILURE = 'REQUEST_USER_LIST_FAILURE';
export const UPDATE_USER_LIST = 'UPDATE_USER_LIST';

export const ADD_CONTACT = 'ADD_CONTACT';
export const ADD_CONTACT_SUCCESS = 'ADD_CONTACT_SUCCESS';
export const ADD_CONTACT_FAILURE = 'ADD_CONTACT_FAILURE';

export const REMOVE_CONTACT = 'REMOVE_CONTACT';
export const REMOVE_CONTACT_SUCCESS = 'REMOVE_CONTACT_SUCCESS';
export const REMOVE_CONTACT_FAILURE = 'REMOVE_CONTACT_FAILURE';

export const SWITCH_PRIMARY_CONTACT = 'SWITCH_PRIMARY_CONTACT';
export const SWITCH_PRIMARY_CONTACT_SUCCESS = 'SWITCH_PRIMARY_CONTACT_SUCCESS';
export const SWITCH_PRIMARY_CONTACT_FAILURE = 'SWITCH_PRIMARY_CONTACT_FAILURE';

export function requestContactList(organization) {
  return {
    type: REQUEST_CONTACT_LIST,
    organization,
  };
}

export function requestContactListFailure(organization, error) {
  return {
    type: REQUEST_CONTACT_LIST_FAILURE,
    organization,
    error,
  };
}

export function updateContactList(organization, contacts) {
  return {
    type: UPDATE_CONTACT_LIST,
    organization,
    contacts,
  };
}

export function requestUserList() {
  return {
    type: REQUEST_USER_LIST,
  };
}

export function requestContractUserList(hp_id, po_id, pcon_contacts) {
  return {
    type: REQUEST_CONTRACT_USER_LIST,
    hp_id,
    po_id,
    pcon_contacts,
  };
}

export function requestUserListFailure(error) {
  return {
    type: REQUEST_USER_LIST_FAILURE,
    error,
  };
}

export function updateUserList(users) {
  return {
    type: UPDATE_USER_LIST,
    users,
  };
}

export function addContact(organization, username) {
  return {
    type: ADD_CONTACT,
    organization,
    username,
  };
}

export function addContactSuccess(organization, username) {
  return {
    type: ADD_CONTACT_SUCCESS,
    organization,
    username,
  };
}

export function addContactFailure(organization, username) {
  return {
    type: ADD_CONTACT_FAILURE,
    organization,
    username,
  };
}


export function removeContact(organization, username) {
  return {
    type: REMOVE_CONTACT,
    organization,
    username,
  };
}

export function removeContactSuccess(organization, username) {
  return {
    type: REMOVE_CONTACT_SUCCESS,
    organization,
    username,
  };
}

export function removeContactFailure(organization, username) {
  return {
    type: REMOVE_CONTACT_FAILURE,
    organization,
    username,
  };
}


export function switchPrimaryContact(organization, username) {
  return {
    type: SWITCH_PRIMARY_CONTACT,
    organization,
    username,
  };
}

export function switchPrimaryContactSuccess(organization, username) {
  return {
    type: SWITCH_PRIMARY_CONTACT_SUCCESS,
    organization,
    username,
  };
}

export function switchPrimaryContactFailure(organization, username) {
  return {
    type: SWITCH_PRIMARY_CONTACT_FAILURE,
    organization,
    username,
  };
}
