import React from 'react';

export class NumericInput extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isFocused: false
        }
    }

    formatNumber = (input) => {
        let multiplier = Math.pow(10, 2);
        let n = input * multiplier;
        n = Math.round(n);
        return (n / multiplier).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    };

    render() {
        const style = {};

        if (!this.props.editable) {
            style.caretColor = "transparent";
        }

        const replacePrefix = input => this.props.prefix ? input.replace(this.props.prefix, "") : input;

        const value = `${this.props.prefix || ''}${this.formatNumber(this.props.value)}${this.props.suffix || ''}`;
    
        return (
            <input
                style={style}
                value={(this.props.editable && this.state.isFocused) ? this.props.value : value}
                onChange={(e) => { if (this.props.editable && (!e.target.value.includes('.') || e.target.value.split('.')[1].length < 3)) this.props.onChange(e.target.value); }}
                onBlur={(e) => { if (this.props.editable) this.props.onChange(e.target.value);  this.setState({isFocused: false}); }}
                onFocus={() => {this.setState({isFocused: true});}}
                onKeyDown={e => {
                    const isNumeric = /\d|\./.test(e.key) && e.key.length === 1,
                        isControl = e.key.length > 1,
                        isDuplicateDot = e.key === "." && replacePrefix(e.target.value).includes(".");
    
                    if (!isNumeric && !isControl || isDuplicateDot) {
                        e.preventDefault();
                        return;
                    }
                }}
                className={this.props.className}>
            </input>
        );
    };
}

export const CurrencyInput = ({ value, onChange, editable, className, suffix}) => {
    return (
        <NumericInput value={value} prefix="$" suffix={suffix} editable={editable} onChange={onChange} className={className}></NumericInput>
    );
};
