/* eslint-disable react/no-deprecated */
import React from 'react';
import Highchart from 'react-highcharts';
// require('highcharts-more')(ReactHighcharts.Highcharts)

export class LineChart extends React.Component {
  static defaultProps = {
    title: null,
    subtitle: null,
    organization: null,
    height: null,
    series: [],
    dataName: null,
    xAxis: {
      title: null,
    },
    yAxis: {
      title: null,
      min: null,
    },
    colors: {
      primary: '#00ff00',
      secondary: '#ff0000',
    },
    plotBands: [{
      from: null,
      to: null,
      color: null,
      axis: null,
    }],
    tooltip: 'Score: <b>{point.y:.2f}</b>',
    legend: { enabled: true },
    categories: [],
  }
  render() {
    let config = {
      chart: {
        type: 'line',
      },
      title: {
        text: this.props.title,
      },
      subtitle: {
        text: this.props.subtitle,
      },
      xAxis: {
        title: {
          text: this.props.xAxis.title,
        },
        categories: this.props.categories,
      },
      yAxis: {
        title: {
          text: this.props.yAxis.title,
        },
        min: this.props.yAxis.min,
      },
      tooltip: {
        pointFormat: this.props.tooltip,
      },
      legend: this.props.legend,
      credits: {
        enabled: false,
      },
      series: this.props.series,
    };

    // plot bands -- the shaded sections of the graph
    let xPlotBands = [];
    let yPlotBands = [];
    this.props.plotBands.forEach(elt => {
      if (elt.axis === 'x') {
        xPlotBands.push(elt);
      }
      if (elt.axis === 'y') {
        xPlotBands.push(elt);
      }
    });
    config.xAxis.plotBands = xPlotBands;
    config.yAxis.plotBands = yPlotBands;

    return (
      <Highchart style={this.props.style} config={config}></Highchart>
    );
  }
};

export class YoyInstanceChart extends React.Component {
  static defaultProps = {
    title: 'Year-Over-Year Comparison',
    data: [],
    percentiles: [],
    colorPalette: {
      primary: '#000000',
      secondary: '#00b346',
      success: '#00b346',
      warning: '#f2eb3b',
      danger: '#cc1b00',
    },
    legend: {
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'middle',
      borderWidth: 0,
    },
    tooltip: '{series.name}<br>Score: <b>{point.y:.2f}</b>',
  }
  render() {
    // data in:
    //   [{instance}, {instance}...]
    // data out:
    //   [{name: data_provider, data: [[durationName, score],[]], {}...]
    let data = this.props.data.slice();
    let dataProviders = {};
    let durations = {};
    data.forEach(elt => {
      if (elt.data_provider__name === 'Integrated Healthcare Association') {
        elt.data_provider__name = 'Health Plan Aggregate';
      }
      // fill dataProviders
      if (!dataProviders.hasOwnProperty(elt.data_provider__name)) {
        dataProviders[elt.data_provider__name] = {
          name: elt.data_provider__name,
          data: [],
        };
      }
      if (!dataProviders[elt.data_provider__name].data.some(existingElt =>
        existingElt.x == elt.duration__name)) {
        dataProviders[elt.data_provider__name].data.push({
          x: elt.duration__name,
          y: parseFloat(elt.fields[elt.score_field]),
        });
      }

      // get unique durationNames
      if (!durations.hasOwnProperty(elt.duration__name)) {
        durations[elt.duration__name] = true;
      }
    });
    let series = Object.keys(dataProviders).map(elt => {
      return dataProviders[elt];
    });
    durations = Object.keys(durations).sort();
    // rename x values as indices of durations (required for highcharts)
    series = series.map(elt => {
      let d = elt.data;
      d = d.map(e => {
        e.x = durations.indexOf(e.x);
        return e;
      });
      elt.data = d;
      return elt;
    });
    // inherit props we don't use
    let newProps = Object.assign({}, this.props);
    let thisProps = {
      data: null,
      colorPalette: null,
    };
    Object.keys(thisProps).forEach(key => {
      delete newProps[key];
    });
    return (
      <LineChart
        title={this.props.title}
        subtitle={null}
        series={series}
        legend={this.props.legend}
        categories={durations}
        {...newProps} />
    );
  }
};

export class HistogramLineChart extends React.Component {
  static defaultProps = {
    // title: "Year-Over-Year Comparison",
    data: [],
    percentiles: [],
    colorPalette: {
      primary: '#000000',
      secondary: '#00b346',
      success: '#00b346',
      warning: '#f2eb3b',
      danger: '#cc1b00',
    },
    legend: {
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'middle',
      borderWidth: 0,
    },
    bins: 15,
    min: 0,
    max: 100,
    binMin: 0,
    binMax: 100,
    suffix: '',
    tooltip: '{series.name}<br>Number of POs: <b>{point.y}</b>',
  }
  render() {
    // data in:
    //  [{name:xxx, data:[xx,xx,...]}
    // data out:
    //  [{name:xxx,data:[xx,xx,...] * #bins}]
    let binRanges = []

    let scoreArray = []
    this.props.data.map(data => {
      data.data.forEach(element => {
        scoreArray.push(element.score)
      });
    })
    scoreArray.sort(function (a, b) { return a - b });
    let binMax = Math.max.apply(Math, scoreArray);
    let binMin = Math.min.apply(Math, scoreArray);

    // Get order of magnitude and decimal points to show.
    let orderOfMagnitude = Math.pow(10, Math.floor(Math.log(binMax) / Math.LN10 + 0.000001));
    let decimalPoints;
    // Note this is pretty simple and doesn't account for
    // smaller numbers or cases when you might actually
    // want to round above integers.
    if (orderOfMagnitude > 10) {
      decimalPoints = 0;
    } else {
      decimalPoints = 1;
    }

    let binInterval = (binMax - binMin) / this.props.bins

    binRanges = Array(...Array(this.props.bins))
      .map((_, i) => i).map(elt => {
        if (elt == 0) {
          return [
            binMin, (binMin + ((elt + 1) * binInterval)).toFixed(decimalPoints),
          ];
        } else if (elt == (this.props.bins - 1)) {
          return [(binMin + (elt * binInterval)).toFixed(decimalPoints), binMax];
        } else {
          return [
            (binMin + (elt * binInterval)).toFixed(decimalPoints),
            (binMin + ((elt + 1) * binInterval)).toFixed(decimalPoints),
          ];
        }
      });

    // pseudocode (ish):
    // for each data source
    // for each bin range
    // collect count of data elements within the bin range
    let series = this.props.data.map(dataElt => {
      let binData = binRanges.map((interval, index) => {
        let count = dataElt.data.filter(dta => {
          if (index == binRanges.length - 1) {
            return dta.score >= interval[0] && dta.score <= interval[1];
          } else {
            return dta.score >= interval[0] && dta.score < interval[1];
          }
        }).length;
        return count;
      });
      dataElt.data = binData;
      return dataElt;
    });

    // create stringified bin labels
    binRanges = binRanges.map((elt, index) => {
      if (index === 0) {
        return `${Math.floor(binMin).toFixed(decimalPoints)} to ${elt[1]} ${this.props.suffix}`;
      } else if (index === binRanges.length - 1) {
        return `${elt[0]} to ${Math.ceil(binMax).toFixed(decimalPoints)} ${this.props.suffix}`;
      } else {
        return `${elt[0]} to ${elt[1]} ${this.props.suffix}`;
      }
    });

    // inherit props we don't use
    let newProps = Object.assign({}, this.props);
    let thisProps = {
      data: null,
      colorPalette: null,
    };
    Object.keys(thisProps).forEach(key => {
      delete newProps[key];
    });
    return (
      <LineChart
        title={this.props.title}
        subtitle={null}
        series={series}
        legend={this.props.legend}
        yAxis={{ title: 'Number of POs', min: 0 }}
        categories={binRanges}
        {...newProps} />
    );
  }
};

