import React from 'react';
import { Table } from 'common/prototable';
import { siteMessages } from 'src/utils/copy';

/* ###################
 * Clinical Summary #
 *#################*/

// eslint-disable-next-line no-unused-vars
function isReactComponent(thing) {
  return thing !== null &&
    typeof (thing) === 'object' &&
    typeof (thing.props) !== 'undefined';
}

class MeasureView extends React.Component {
  render() {
    let instances = this.props.instances.slice();
    instances.forEach((instance, index) => {
      let nInstance = {
        name_full: instance.name_full,
        abbr: instance.abbr,
        data_provider__name: instance.data_provider__name,
        percent_50: instance.percent_50,
        percent_75: instance.percent_75,
        score_field: instance.score_field,
        is_reported_higher_better: instance.is_reported_higher_better,
      };
      if (nInstance.data_provider__name === siteMessages[
        'amp.page.measures.reports.name.data.provider.iha'
      ]) {
        nInstance.data_provider__name = siteMessages[
          'amp.page.measures.reports.name.data.provider.health'
        ];
      }
      nInstance = Object.assign(nInstance, instance.fields);
      instances[index] = nInstance;
    });
    let columns = [
      {
        key: 'name_full',
        label: siteMessages['amp.page.measures.reports.col.label.mName'],
      },
      {
        key: 'abbr',
        label: siteMessages['amp.page.measures.reports.col.label.mAbbr'],
      },
      {
        key: 'data_provider__name',
        label: siteMessages['amp.page.measures.reports.col.label.dp.name'],
      }];
    if (this.props.instances && this.props.instances.length > 0) {
      this.props.instances.forEach(instance => {
        instance.fieldNames.standard.forEach(fieldName => {
          let k = Object.keys(fieldName)[0];
          let v = fieldName[k];
          let exists = columns.filter(col => {
            return col.key == k;
          });
          if (exists.length === 0) {
            columns.push({ key: k, label: v });
          }
        });
      });
    }
    let pctlSetter = function (columnKey, rowObject) {
      if (columnKey == rowObject['score_field']) {
        let score = rowObject[rowObject['score_field']];
        let reversal;
        if (score === 'SD' || score === 'LR' || score === 'BR'
          || score === 'NR' || score === 'UD'
        ) {
          return '';
        }
        if (rowObject['is_reported_higher_better'] === true) {
          reversal = x => x;
        } else {
          reversal = x => -x;
        }
        if (reversal(score) > reversal(rowObject['percent_75'])) {
          return 'has-background-success';
        } else if (reversal(score) >= reversal(rowObject['percent_50'])) {
          return 'has-background-warning';
        } else {
          return 'has-background-danger';
        }
      } else {
        return '';
      }
    };

    columns = columns.filter(elt => elt.key != 'pts_earned');

    let eltSetter = function (columnKey, rowObject) {
      if (columnKey === 'data_provider__name') {
        if (rowObject[columnKey] === siteMessages[
          'amp.page.measures.reports.name.data.provider.health'
        ]) {
          return (
            <span className="">
              {rowObject[columnKey]}<i className="fa fa-asterisk" aria-hidden="true" style={{ color: 'blue' }}></i>
            </span>
          );
        }
      }
      return rowObject[columnKey];
    };

    return (
      <Table data={instances}
        columns={columns}
        classSetter={pctlSetter}
        elementSetter={eltSetter} />
    );
  }
}

export default MeasureView;
